import React, { useState, useRef, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import XEALogo from './XEALogo/XEALogo';
import { useIsMounted } from '../hooks/useIsMounted';
import RedErrorBang from './Svg/RedErrorBang';

const AmplifyForgotPassword = (props) => {
  const { navigate } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const emailParam = urlParams.get('email');
  const errorMessageParam = urlParams.get('errorMessage');
  const [email, setEmail] = useState('');
  const [forgotPasswordError, setForgotPasswordError] = useState(null);
  const emailFieldRef = useRef(null);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);

  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted.current) {
      setEmail(emailParam || '');
      if (errorMessageParam) {
        setForgotPasswordError(errorMessageParam);
      }
    }
  }, []);

  const sendResetPassword = async () => {
    setForgotPasswordLoading(true);
    const proceed = () => {
      const url = new URL('/reset-password', window.location.origin);
      url.searchParams.set('email', email);
      navigate(url);
    };
    try {
      setForgotPasswordError(null);
      await Auth.forgotPassword(email);
      proceed();
    } catch (e) {
      if (e.name !== 'UserNotFoundException') {
        if (isMounted.current) {
          setForgotPasswordError('Something went wrong, please try again');
        }
        return null;
      }
      proceed();
    } finally {
      if (isMounted.current) {
        setForgotPasswordLoading(false);
      }
    }
    return null;
  };

  useEffect(() => {
    setSendButtonDisabled(!emailFieldRef.current?.validity.valid
                          || !email.length
                          || forgotPasswordLoading);
  }, [emailFieldRef.current, email, forgotPasswordLoading]);

  return (
    <>
      <div id="amplify-forgot-password-container" className="flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
        <div className="w-[452px] p-2 m-auto mt-8">
          <XEALogo {...props} />

          <div className="flex flex-col">
            <div className="text-style-xl-semibold text-xgray-900 text-center">Forgot Password?</div>
            <p className="text-style-base text-xgray-500 text-center mt-2 px-2">Don&apos;t worry! Just fill in your email and we will send you a passcode to reset your password.</p>
            { forgotPasswordError && (
              <div className="message error">
                <span className="error-message">{forgotPasswordError}</span>
              </div>
            )}
            <div className="input-field-container">
              <label className="input-field-label" htmlFor="email-text-input">
                <span className="text-xred-800 mr-1">*</span>
                Your Email
              </label>
              <input
                type="email"
                ref={emailFieldRef}
                id="email-text-input"
                className="input-field login-text-input"
                value={email}
                onChange={e => setEmail(e.target.value)}
                onBlur={e => setEmailFormatError(!e.target.validity.valid)}
              />
              <div className={`input-field-error-message ${emailFormatError ? '' : 'hidden'}`}>The email must be a valid email address</div>
            </div>

            <button className="submit-button" disabled={sendButtonDisabled} onClick={sendResetPassword}>Send Password Passcode</button>
            <p className="mt-4 h-[19px] text-center text-style-small-medium text-xgray-500">
              Go back to the
              <button className="inline-block h-[19px] border-none outline-none text-xray-primary-link  bg-[transparent] ml-2" onClick={() => navigate('/login')}>login screen</button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmplifyForgotPassword;
