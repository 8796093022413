import React, { useEffect, useState } from 'react';
import { CollectUserInfo, workspaceNameForUser, workspaceUrlForUserName } from '@stoatlabs/xea-client-shared-components';
import { useCookies } from 'react-cookie';

import ComponentWrapper from '../ComponentWrapper';
import { getOrCreateSubscription, getCognitoUser, updateStoredUser } from '../../store/currentUser';
import { createWorkspaceWithUser } from './CreateWorkspace';
import { updateUser } from '../../models/user';

const CreateProfile = (props) => {
  const { userId } = props;
  const [user, setUser] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['signup-selection', 'signup-plan', 'signup-invite', 'utm_source', 'utm_campaign', 'utm_content', 'utm_medium', 'utm_term']);
  const selection = cookies['signup-selection'] || 'team';
  const plan = cookies['signup-plan'] || 'monthly';
  const { utm_source, utm_campaign, utm_content, utm_medium, utm_term } = cookies;

  useEffect(() => {
    const updateUser = async () => {
      const { attributes } = await getCognitoUser();
      setUser({
        id: attributes.sub,
        firstName: attributes['custom:firstName'] || "",
        lastName: attributes['custom:firstName'] || "",
        jobTitle: attributes['custom:jobTitle'] || "",
        country: attributes['custom:country'] || "",
        businessName: attributes['custom:businessName'] || "",
        marketingOptIn: attributes['custom:marketingOptIn'] || false,
      });
    };
    updateUser();
  }, [userId]);

  const createUserSubscription = async (userData) => {
    const cognitoUser = await getCognitoUser();
    const userSubscription = await getOrCreateSubscription(userId, selection, plan);
    // save the returned customer id in dynamoDB
    const { marketingOptIn, ...rest } = userData;
    await updateUser(userId, {
      ...rest,
      email: cognitoUser.attributes.email,
      stripeCustomerId: userSubscription.stripeCustomerId,
    });

    // Set our cookies if we created a trial subscription for the user
    if(userSubscription.createdTrial && selection && plan) {
      setCookie('signup-selection', selection, { path: '/' });
      setCookie('signup-plan', plan, { path: '/' });
    }
  };

  const checkoutRedirectFn = async () => {
    // Let's rewrite the browser history and replace the current route with the checkout fallback route
    // this ensure that on browser back, the user is not redirected to the collect user info page again
    window.history.replaceState({}, '', `/signup/${selection}/${plan}/cancel`);
    window.location = '/';
  };

  const childProps = {
    ...props,
    user,
    didCollectUserInfoCb: async (userData) => {
      try {
        await updateStoredUser({...userData, utm_source, utm_campaign, utm_content, utm_medium, utm_term});
        if (!cookies['signup-invite']) {
          const workspaceName = workspaceNameForUser(userData);
          const workspaceUrl = workspaceUrlForUserName(workspaceName);
          await createUserSubscription(userData);
          await createWorkspaceWithUser(workspaceName, workspaceUrl, selection, !!cookies['signup-invite']);
          await checkoutRedirectFn(userData);
          removeCookie('signup-invite', { path: '/' });
          return true;
        } else {
          window.location = '/create-workspace';
          return false;
        }
      } catch(error) {
        console.error(`Error updating user`, error);
        return false
      }
    },
  };
  return (
    <ComponentWrapper {...childProps}>
      <CollectUserInfo {...childProps} />
    </ComponentWrapper>
  );
};

export default CreateProfile;