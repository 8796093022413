import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { getSubscriptionForCustomer } from "../store/currentUser";

import UserProfile from './workspace/UserProfile';
import UpdatePassword from './workspace/UpdatePassword';
import CreateWorkspace from './Account/CreateWorkspace';
import Logout from './Account/Logout';
import NotFound from './404';
import CreateProfile from './Account/CreateProfile';
import ConfirmationView from './Account/ConfirmationView';
import CurrentWorkspace from './workspace/CurrentWorkspace';
import WorkspaceRedirect from './Account/WorkspaceRedirect';
import InviteLanding from './Account/Invite';
import StripeCheckoutCallback from './Account/StripeCheckoutCallback';
import LoadingApp from './LoadingApp';
import { UserContext } from '../context/UserContext';
import ForgotPassword from './Account/ForgotPassword';
import MaybeCreateUserSubscription from "./Account/MaybeCreateUserSubscription";
import XEALauncher from './XEALauncher';

const AuthedApp = (props) => {
  const { currentUser } = useContext(UserContext);

  const [subData, setSubData] = useState(null);
  const [navbarClassType, setNavbarClassType] = useState('without');
  const [bannerMessage, setBannerMessage] = useState(null);

  const location = useLocation();
  if (location.pathname.match(/^\/verification-success($|\/)/) || location.pathname.match(/^\/create-profile($|\/)/)) {
    TagManager.initialize({ gtmId: 'GTM-K7SWWTP2' });
  }

  useEffect(() => {
    const fetchSubscription = async () => {
      const subscriptionData = await getSubscriptionForCustomer(currentUser);
      setSubData(subscriptionData);
    }

    if (currentUser && currentUser?.stripeCustomerId && !subData) {
      fetchSubscription();
    }
  }, [currentUser, subData])

  useEffect(() => {
    const checkSubscriptionState = () => {
      if(subData?.subscription?.status === 'trialing' && subData?.subscription?.payment_method === null) {
        setNavbarClassType('with');

        const userLocale = new Intl.NumberFormat().resolvedOptions().locale;
        const trialEnd = new Date(parseInt(subData.subscription.trial_end, 10) * 1000);
        const trialEndDisplay = new Intl.DateTimeFormat(userLocale, { month: 'long', day: 'numeric' }).format(trialEnd);
        setBannerMessage(<>You've unlocked a <span className='font-semibold'>30-day free trial of a Premium plan! 🎉</span> When the trial ends on <span className='font-semibold'>{trialEndDisplay}</span>, your plan will <span className='font-semibold'>revert to Free.</span> See our plans <a href="https://www.getxray.app/exploratory-testing#pricing" target="_blank" rel="noreferrer" className="text-blue-700">here</a>.</>)
      }
    }

    if(subData) {
      checkSubscriptionState();
    } else {
      setNavbarClassType('without');
      setBannerMessage(null);
    }
  }, [subData, navbarClassType])

  const innerProps = {
    ...props,
    currentUser,
    navbarClassType,
    bannerMessage
  };

  if (!currentUser && !['/verification-success', '/create-profile', '/create-workspace', '/logout'].includes(window.location.pathname)) {
    return <LoadingApp />;
  }

  return (
    <Switch>
      <Route path="/start" exact>
        <XEALauncher {...props} />
      </Route>
      <Route path="/verification-success" exact>
        <ConfirmationView {...props} />
      </Route>
      <Route path="/create-profile" exact>
        <CreateProfile {...props} />
      </Route>
      <Route path="/create-workspace" exact>
        <CreateWorkspace {...props} />
      </Route>
      <Route path="/profile" exact>
        <UserProfile {...innerProps} userProfile={true} />
      </Route>
      <Route path="/profile/change-password" exact>
        <UpdatePassword {...innerProps} changePassword={true} />
      </Route>
      <Route path="/profile/cancel-account" exact>
        <UserProfile {...innerProps} cancelAccount={true} />
      </Route>
      <Route path="/logout" exact>
        <Logout {...props} />
      </Route>
      <Route path="/forgot-password" exact>
        <ForgotPassword {...props} />
      </Route>
      <Route path={["/signup/:selection/:plan", "/signup/:selection"]} exact>
        <MaybeCreateUserSubscription {...innerProps} />
      </Route>
      <Route path="/signup/:selection/:plan/:action" exact>
        <StripeCheckoutCallback {...innerProps} />
      </Route>
      <Route path={["/login", "/signup", "/"]} exact>
        <WorkspaceRedirect {...innerProps} />
      </Route>
      <Route path="/invite/:inviteId">
        <InviteLanding {...props} />
      </Route>
      <Route path="/workspace/:workspaceUrl([a-z0-9\-]+-[a-f0-9]{6})">
        <CurrentWorkspace {...innerProps} />
      </Route>
      <Route path="*">
        <NotFound {...innerProps} />
      </Route>
    </Switch>
  );
};

export default AuthedApp;