import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppDownloadModal from './workspace/modals/AppDownload';

import { openModal, modalTypes } from '../store/slices/modals';

const appDeepURL = 'xeta';

const XEALauncher = (props) => {
  const { hideDownloadButton, navigate, startParams = {} } = props;
  const { jiraURL, jiraType, testRunId, workspaceId, sessionId, firstSession, userId } = startParams;
  const searchParams = new URLSearchParams(window.location.search);
  const jiraURLParam = searchParams.get('jiraurl') || jiraURL;
  const jiraTypeParam = searchParams.get('jiratype') || jiraType;
  const testRunIdParam = searchParams.get('testrunid') || testRunId;
  const workspaceIdParam = searchParams.get('workspaceId') || workspaceId;
  const sessionIdParam = searchParams.get('sessionId') || sessionId;
  const firstSessionParam = searchParams.get('firstSession') || firstSession;
  const userIdParam = searchParams.get('userId') || userId;
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modals);

  const launchItNowURL = useMemo(() => {
    const params = [];
    if (jiraURLParam) {
      params.push(`jiraURL=${jiraURLParam}`);
    }
    if (jiraTypeParam) {
      params.push(`jiraType=${jiraTypeParam}`);
    }
    if (testRunIdParam) {
      params.push(`testRunId=${testRunIdParam}`);
    }
    if (workspaceIdParam) {
      params.push(`workspaceId=${workspaceIdParam}`);
    }
    if (sessionIdParam) {
      params.push(`sessionId=${sessionIdParam}`);
    }
    if (firstSessionParam) {
      params.push(`firstSession=${firstSessionParam}`);
    }
    if (userIdParam) {
      params.push(`userId=${userIdParam}`);
    }
    // Even if the correct URL would be xeta:?jiraURL=...&jiraType=... etc, the app doesn't like the ? so we remove it
    return `${appDeepURL}:${params.join('&')}`;
  }, [jiraURLParam, jiraTypeParam, testRunIdParam, workspaceIdParam, sessionIdParam, firstSessionParam, userIdParam]);

  useEffect(() => {
    window.location = launchItNowURL;
  }, [launchItNowURL]);

  const signUpClicked = e => {
    e.preventDefault();
    navigate('/signup');
  };

  return (
    <div className='flex'>
      { modal.type === modalTypes.DOWNLOAD && <AppDownloadModal {...props} /> }
      <div className='mx-auto max-w-[1024px] p-4 flex flex-col'>
        <div className='mt-10'>
          <img src="/xea-logo-horizontal.svg" alt="Xray Explorary App" className="w-[640px] h-[80px] mx-auto" />
          <link rel='stylesheet' href="/screenshot-bg-style.css" />
          <div className='screenshot-bg-style mt-6' />
          <p className='text-center text-style-2xl-semibold text-xgray-500 mt-8'>Execute with <span className='text-xgray-900'>Xray Exploratory App</span> for Desktop</p>
          <div className='mt-8 flex'>
            {!hideDownloadButton && <button className='ml-auto mr-4 tertiary-button min-w-[250px] text-center' onClick={() => dispatch(openModal(modalTypes.DOWNLOAD))}>Download the App</button>}
            {hideDownloadButton && <a className='ml-auto mr-4 tertiary-button min-w-[250px] text-center' onClick={signUpClicked} href='/signup'>Sign Up</a>}
            <a className='mr-auto ml-4 primary-button min-w-[250px] text-center' href={launchItNowURL}>Launch It Now</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default XEALauncher;