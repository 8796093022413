import React, { useEffect, useCallback, useState, useContext } from 'react';
import { BrowserRouter, useLocation, useHistory } from 'react-router-dom';
import { FlowbiteCustomTheme, Pendo } from '@stoatlabs/xea-client-shared-components';
import { Flowbite } from 'flowbite-react';
import { Provider } from 'react-redux'
import { Amplify, Hub } from 'aws-amplify';
import { CookiesProvider, useCookies } from 'react-cookie';
import { ApolloProvider } from '@apollo/client';

import { setUserContext } from "./sentry";

import store from './store';

import './tailwind.generated.css';
import '@stoatlabs/xea-client-shared-components/dist/style.css';

import UnauthedApp from './components/UnauthedApp';
import AuthedApp from './components/AuthedApp';
import LoadingApp from './components/LoadingApp';
import ClientCheck from './components/ClientStatus';

import { getCognitoUser } from './store/currentUser';
import { WorkspaceScope } from './models';

import awsconfig from './awsconfig';

import MembershipProvider, { MembershipContext } from './context/MembershipContext';
import UserProvider from './context/UserContext';
import WorkspaceProvider, { WorkspaceContext } from './context/WorkspaceContext';
import CurrentWorkspaceProvider, { CurrentWorkspaceContext } from './context/CurrentWorkspaceContext';
import apollo from './apollo';

import { loginRedirectCookieName } from './components/Account/LoginRedirect';

Amplify.configure(awsconfig);

const preferredTheme = 'light';

function App(props) {
  const { userId, navigate } = props;
  const location = useLocation();
  const history = useHistory();

  // Always force light mode on web
  useEffect(() => {
    document.documentElement.classList.add(preferredTheme);
  }, []);

  const childProps = {
    userId,
    lowStorageWarningLimit: 95,
    hideCloseButton: true,
    navigate,
    location,
    history,
    hideXEALogo: true,
    prefersColorScheme: preferredTheme,
  };

  return (
    <Flowbite theme={FlowbiteCustomTheme}>
      {!userId && <UnauthedApp {...childProps} />}
      {!!userId && <AuthedApp {...childProps} />}
    </Flowbite>
  );
}

const AppWithRouter = ({amplifyUser, setAmplifyUser, userId}) => {
  const [cookies, , removeCookie] = useCookies([loginRedirectCookieName]);
  const loginRedirect = cookies[loginRedirectCookieName];
  const { clearMembershipsContext } = useContext(MembershipContext);
  const { workspaces, clearWorkspacesContext } = useContext(WorkspaceContext);
  const { clearCurrentWorkspaceData } = useContext(CurrentWorkspaceContext);

  const history = useHistory();

  const navigate = useCallback((path) => {
    if (path instanceof URL) {
      history.push(path.pathname + path.search);
      return;
    }
    history.push(path);
  }, [history]);

  const amplifyUserPresent = !!amplifyUser;
  const amplifyUserHasNoFirstName = amplifyUserPresent && (amplifyUser?.attributes['custom:firstName'] === undefined || amplifyUser?.attributes['custom:firstName'].length === 0);

  const decideRedirect = useCallback(async () => {
    if (window.location.pathname === "/logout") {
      return;
    }
    if (!amplifyUserPresent) return;

    if (amplifyUserHasNoFirstName) {
      if (!['/confirm-passcode', '/verification-success', '/create-profile'].includes(window.location.pathname)) {
        navigate('/create-profile');
      }
    } else {
      const personalWorkspace = workspaces?.find((w) => w.scope === WorkspaceScope.PRIVATE);

      if (!personalWorkspace) {
        if (window.location.pathname !== '/create-workspace'){
          navigate('/create-workspace');
        }
      } else if (loginRedirect) {
        removeCookie('login-redirect', { path: '/' });
        if (window.location.pathname !== loginRedirect) {
          navigate(loginRedirect);
        }
      }
    }
  }, [removeCookie, amplifyUserPresent, amplifyUserHasNoFirstName, loginRedirect, navigate, workspaces]);

  useEffect(() => {
    document.addEventListener('workspaces-loaded', decideRedirect, { once: true });

    const unlisten = Hub.listen('auth', async (data) => {
      switch (data.payload.event) {
        case 'signIn' || 'tokenRefresh': {
          const user = await getCognitoUser();
          setUserContext(user);
          setAmplifyUser(user);
          apollo.configure(!!user);
          break;
        }
        case 'updateUserAttributes': {
          const user = await getCognitoUser();
          setUserContext(user);
          setAmplifyUser(user);
          break;
        }
        case 'signOut' || 'tokenRefresh_failure':{
          setAmplifyUser(null);
          setUserContext(null);
          navigate('/login');
          apollo.configure(false);

          const logoutEvent = new CustomEvent('logoutEvent');
          document.dispatchEvent(logoutEvent);

          clearCurrentWorkspaceData();
          clearWorkspacesContext();
          clearMembershipsContext();

          await apollo?.client?.clearStore();

          break;
        }
        default:
          break;
      }
    });

    return () => {
      if (unlisten) {
        unlisten();
      }
      document.removeEventListener('workspaces-loaded', decideRedirect);
    };
  }, [userId, amplifyUser, setAmplifyUser, loginRedirect, removeCookie, decideRedirect,
      navigate, workspaces, clearWorkspacesContext, clearCurrentWorkspaceData, clearMembershipsContext]);

  return (
    <Provider store={store}>
      <App userId={userId} navigate={navigate} />
      <Pendo amplifyUser={amplifyUser} />
    </Provider>
  );
};

const AppWithCookies = () => {
  const [amplifyUser, setAmplifyUser] = useState(null);
  const [amplifyChecked, setAmplifyChecked] = useState(false);
  const userId = amplifyUser?.attributes?.sub;

  useEffect(() => {
    const initialUserSetup = async () => {
      try {
        const cognitoUser = await getCognitoUser();
        setAmplifyUser(cognitoUser);
        apollo.configure(!!cognitoUser);
      } catch {
        setAmplifyUser(null);
        apollo.configure(false);
      }
      setAmplifyChecked(true);
    }
    initialUserSetup();
  }, [setAmplifyUser]);

  if (!amplifyChecked) {
    return <LoadingApp />;
  }

  return (
    <ApolloProvider client={apollo.client}>
      <BrowserRouter>
        <CookiesProvider>
          <UserProvider userId={userId}>
            <MembershipProvider userId={userId}>
              <WorkspaceProvider userId={userId}>
                <CurrentWorkspaceProvider>
                  <AppWithRouter amplifyUser={amplifyUser} setAmplifyUser={setAmplifyUser} userId={userId} />
                  <ClientCheck />
                </CurrentWorkspaceProvider>
              </WorkspaceProvider>
            </MembershipProvider>
          </UserProvider>
        </CookiesProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default AppWithCookies;
