import React from 'react';
import { useDispatch } from 'react-redux';

import { openModal, modalTypes } from "../../store/slices/modals";

export default function DownloadButton(props) {
  const dispatch = useDispatch();

  return (
    <>
      <button onClick={() => dispatch(openModal(modalTypes.DOWNLOAD))} className="text-style-xray ml-2 text-xray-primary-link">Download Now</button>
    </>
  )
};
