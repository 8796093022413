import React from "react";
import Workspace from "./workspace/Workspace";

const NotFound = (props) => {
  const { navigate, errorMessage, errorDescription, errorCTA, errorLink, hideCTA } = props;

  const innerProps = {
    ...props,
    hideSidebar: true,
  };

  const nav = (e) => {
    e.preventDefault();
    navigate(errorLink || '/');
  };

  return (
    <Workspace {...innerProps}>
      <div className="flex flex-col items-center justify-center h-full bg-fixed bg-cover bg-bottom">
        <div className="text-style-4xl-semibold text-xgray-900">
          {errorMessage || '404 - Page not found'}
        </div>
        <div className="text-style-xl text-xgray-500 w-[608px] text-center">
          {errorDescription || 'The page you are looking for doesn\'t exist or has been removed.'}
        </div>
        {!hideCTA && <a className="mt-4 primary-button" onClick={nav} href={errorLink || '/'}>
          {errorCTA || 'Back to Workspace'}
        </a>}
      </div>
    </Workspace>
  )
};

export default NotFound;