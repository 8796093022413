import { React, useContext } from "react";
import { Avatar } from "flowbite-react";
import { FlowbiteCustomTheme } from "@stoatlabs/xea-client-shared-components";

import { UserContext } from '../../context/UserContext';

const RoundedUserAvatar = (props) => {
  const { avatarSize } = props;
  const { currentUser } = useContext(UserContext);

  const firstName = currentUser ? currentUser.firstName : 'Anonymous';
  const userInitials = firstName?.charAt(0).toUpperCase();

  return (
    <Avatar theme={FlowbiteCustomTheme.theme.smallAvatar} color="bg-xgreen-400" rounded placeholderInitials={userInitials} size={ avatarSize ?? 'sm' } />
  );
};

export default RoundedUserAvatar;