import { createContext, useState, useCallback } from 'react';

import { useObserveWorkspaces } from '../hooks/useObserveWorkspaces';

export const WorkspaceContext = createContext(null);

const WorkspaceProvider = ({ userId, children }) => {
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [workspaces, _setWorkspaces] = useState([]);
  const [workspacesLoaded, setWorkspacesLoaded] = useState(false);

  const setWorkspaces = useCallback(loadedWorkspaces => {
    _setWorkspaces(loadedWorkspaces);
    if (!workspacesLoaded) {
      setWorkspacesLoaded(true);
      setTimeout(() => document.dispatchEvent(new CustomEvent('workspaces-loaded')), 180);
    }
  }, [workspacesLoaded]);

  const clearWorkspacesContext = useCallback(() => {
    setCurrentWorkspace(null);
    setWorkspaces([]);
    setWorkspacesLoaded(false);
  }, [setCurrentWorkspace, setWorkspaces, setWorkspacesLoaded]);

  useObserveWorkspaces(userId, setWorkspaces);

  const store = {
    workspaces,
    currentWorkspace,
    setCurrentWorkspace,
    workspacesLoaded,
    setWorkspacesLoaded,
    clearWorkspacesContext,
  };

  return (
    <WorkspaceContext.Provider value={store}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;