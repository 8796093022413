import { React, useContext, useEffect, useState } from "react";
import { FlowbiteCustomTheme, CopyToClipboard } from '@stoatlabs/xea-client-shared-components';
import { Avatar, Alert } from "flowbite-react";

import Workspace from "./Workspace";

import { WorkspaceContext } from "../../context/WorkspaceContext";
import { CurrentWorkspaceContext } from "../../context/CurrentWorkspaceContext";
import { getSubscriptionForCustomer } from "../../store/currentUser";
import { UserContext } from "../../context/UserContext";
import { updateWorkspaceStripeSubscriptionId } from "../../models/workspace";

const AboutWorkspace = (props) => {
  const { currentWorkspace } = useContext(WorkspaceContext);
  const { memberships } = useContext(CurrentWorkspaceContext);
  const { currentUser } = useContext(UserContext);
  const [subscriptionId, setSubscriptionId] = useState(null);

  const host = window.location.host;

  useEffect(() => {
    const updateSubscriptionId = async (owner) => {
      if (currentWorkspace?.stripeSubscriptionId) {
        setSubscriptionId(currentWorkspace.stripeSubscriptionId);
      } else if (owner?.stripeCustomerId) {
        const subData = await getSubscriptionForCustomer(owner);
        if (subData?.subscription?.id) {
          setSubscriptionId(subData.subscription.id);
          await updateWorkspaceStripeSubscriptionId(currentWorkspace.id, subData.subscription.id);
        }
      }
    };
    const owner = memberships?.data?.find(m => m.isOwner)?.user;
    if (owner && currentWorkspace && !subscriptionId) {
      updateSubscriptionId(owner);
    }
  }, [currentUser, currentWorkspace, memberships?.data, subscriptionId]);

  const AboutWorkspaceItem = ({ title, borderBottomClass='border-b-2', children }) => (
    <>
      <h3 className='text-style-lg-semibold text-gray-900 mr-auto pt-6 -mb-2'>{title}</h3>
      <div className={`flex flex-row items-center relative ${borderBottomClass} pb-2`}>
        <div className='h-[40px] flex items-center text-gray-500'>
          {children}
        </div>
      </div>
    </>
  );

  const workspaceOwner = memberships?.data?.find(m => m.isOwner )?.user;
  const workspaceOwnerName = workspaceOwner ? `${workspaceOwner?.firstName} ${workspaceOwner?.lastName}` : 'Unknown User';

  return (
    <Workspace {...props}>
      <h1 className='text-style-2xl-semibold text-gray-900 mr-auto'>About Workspace</h1>
      <div className='flex flex-col items-center'>
        <Avatar theme={FlowbiteCustomTheme.theme.teamWorkspaceAvatar} placeholderInitials={currentWorkspace?.name.charAt(0).toUpperCase()} size="md" />
        <h2 className='text-style-xl-semibold text-gray-900 mt-8 mb-6'>{currentWorkspace.name} Workspace</h2>

        <div className='w-[500px]'>
          <AboutWorkspaceItem title={'Workspace URL'}>
            <div>{`https://${host}/workspace/${currentWorkspace.url}`}</div>
            <CopyToClipboard copyToClipboardFn={() => `https://${host}/workspace/${currentWorkspace.url}`} />
          </AboutWorkspaceItem>

          <AboutWorkspaceItem title={'Workspace Owner'}>
            <div className='flex-none'>{workspaceOwnerName}</div>
            { workspaceOwner &&
              <>
                <div className='flex-initial mx-4'>|</div>
                <div className='flex-initial'>{workspaceOwner?.email}</div>
              </>
            }
          </AboutWorkspaceItem>

          <AboutWorkspaceItem title={'Created at'}>
            {currentWorkspace?.createdAt ? new Date(parseInt(currentWorkspace.createdAt, 10)).toLocaleString() : 'Unknown Date'}
          </AboutWorkspaceItem>

          <AboutWorkspaceItem title={'Entitlement Number'} borderBottomClass={''}>
            <div>{subscriptionId ?? 'Unknown Entitlement Number'}</div>
            <CopyToClipboard copyToClipboardFn={() => subscriptionId} />
          </AboutWorkspaceItem>

          <Alert color='gray' className='mt-8'>
            <div className='text-style-lg-semibold text-gray-900'>Looking for Help?</div>
            <div className='text-style-small text-gray-500 mt-2'>For all other support requests, including ownership transfer, team or data migration, custom plans, etc... <a href="https://jira.getxray.app/servicedesk/customer/portal/2" target="_blank" rel="noreferrer" className='text-style-small-semibold cursor-pointer'>Contact Support</a></div>
          </Alert>
        </div>
      </div>
    </Workspace>
  )
};

export default AboutWorkspace;