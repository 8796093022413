import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Toast } from "flowbite-react";
import { MdLoop } from 'react-icons/md';
import { debounce } from 'lodash';

import checkStale from "../utils/StaleClientDetector";

const ClientCheck = () => {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const debounceBase = 1000 * 60; // Every minute
    const checkStaleFn = debounce(async () => {
      // Invoke the refresh function if the document is no longer hidden
      if (!document.hidden) {
        console.debug(`Client is no longer hidden, checking for stale client (showAlert: ${showAlert})...`);
        if (!showAlert) {
          setShowAlert(await checkStale());
        }
      }
    }, debounceBase, {
      leading: true,
      trailing: true,
    });;

    const visibileCheckStaleFn = async () => {
      // Invoke the refresh function if the document is no longer hidden
      if (!document.hidden) {
        await checkStaleFn();
      }
    };

    if (process.env.REACT_APP_CLIENT_VERSION) {
      console.debug(`Setup client version check (current ${process.env.REACT_APP_CLIENT_VERSION})...`);
      document.addEventListener("visibilitychange", visibileCheckStaleFn);
      const interval = setInterval(visibileCheckStaleFn, debounceBase * 5); // Every 5 minutes

      return () => {
        document.removeEventListener("visibilitychange", visibileCheckStaleFn);
        clearInterval(interval);
      };
    }
  }, [showAlert]);

  const root = document.getElementById("client-status");

  const dom = showAlert ? (
    <div className="absolute bottom-4 left-4 mt-auto">
      <Toast>
        <div className="flex items-start">
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-xgreen-200 text-xgreen-600">
            <MdLoop className="h-5 w-5" />
          </div>
          <div className="ml-3 text-sm font-normal">
            <span className="mb-1 text-sm font-semibold text-gray-900 dark:text-white">Update available</span>
            <div className="mb-2 text-sm font-normal">A new software version is available for download.</div>
            <div className="flex-start flex gap-3">
              <div className="w-auto">
                <button onClick={() => window.location.reload()} className="primary-button mt-2 mr-auto">Update</button>
              </div>
              <div className="w-auto">
                <button onClick={() => setShowAlert(false)} className="secondary-button mt-2 mr-auto">Not Now</button>
              </div>
            </div>
          </div>
          <Toast.Toggle onDismiss={() => setShowAlert(false)} />
        </div>
      </Toast>
    </div>
  ) : <></>;

  return createPortal(dom, root);
};

export default ClientCheck;