import React from "react";
import { Dropdown } from "flowbite-react";
import { FlowbiteCustomTheme, QuestionCircleOutline, AngleDown } from "@stoatlabs/xea-client-shared-components";
import { useDispatch, useSelector } from "react-redux";

import { openModal, modalTypes } from "../../../store/slices/modals";
import AppDownloadModal from "../modals/AppDownload";

const HelpMenu = (props) => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modals);

  return (
    <>
      { modal.type === modalTypes.DOWNLOAD && <AppDownloadModal {...props}/> }
      <Dropdown
        theme={FlowbiteCustomTheme.theme.dropdown.helpMenu}
        inline
        label={<div className="flex items-center">
          <QuestionCircleOutline className="w-[18px] h-[18px] text-xgray-500" />
          <AngleDown className="ml-2.5 w-2.5 text-xgray-500" />
        </div>}
        arrowIcon={false}>
        <Dropdown.Item onClick={ () => window.open('https://docs.getxray.app/category/xea', '_blank') }>
          <span>Documentation</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={ () => window.open('https://jira.getxray.app/servicedesk/customer/portal/2', '_blank') }>
          <span>Online Support</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => dispatch(openModal(modalTypes.DOWNLOAD))}>
          Download Desktop App
        </Dropdown.Item>
      </Dropdown>
    </>
  );
};

export default HelpMenu;