import React from 'react';
import { IderaIncFooter, CloseButton, FlowbiteCustomTheme } from '@stoatlabs/xea-client-shared-components';
import { Card, Flowbite } from 'flowbite-react';

const ComponentWrapper = (props) => {
  const { children, hideCloseButton, hideFooter } = props;
  return (
    <Flowbite theme={{...FlowbiteCustomTheme}}>
      <div className="flex flex-col min-w-[calc(100vw-16px)] min-h-[100vh] pt-[125px] pb-4 bg-[#F7F7F7] dark:bg-[#191919]">
        {/* <XEALogo prefersColorScheme={prefersColorScheme} /> */}
        <img
          alt="Flowbite React Logo"
          className="w-[320px] mb-4 mx-auto"
          src="/xea-logo-2024.svg"
        />
        <Card className="mx-auto bg-[#FFFFFF] dark:bg-[#2D2D2D] relative">
          { !hideCloseButton && <CloseButton {...props} /> }
          { children }
        </Card>
        { !hideFooter &&
          <div className="mt-auto">
            <IderaIncFooter {...props} />
          </div>}
      </div>
    </Flowbite>
  );
};

export default ComponentWrapper;
