import React from 'react';
import Workspace from "./Workspace";
import { AmplifyUpdatePassword } from '@stoatlabs/xea-client-shared-components';

const UpdatePassword = (props) => {
  return (
    <Workspace {...props}>
      <div className='text-style-semibold text-xgray-900'>Change Password</div>
      <div className='flex flex-col items-center'>
        <AmplifyUpdatePassword {...props} hideTitle={true} update={true} hideMarketingOptIn={true} ctaCopy="Save changes" />
      </div>
    </Workspace>
  )
};

export default UpdatePassword;