import React, { useContext, useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import * as Sentry from "@sentry/react";

import { modalTypes, closeModal } from "../../../../store/slices/modals";
import { UserContext } from "../../../../context/UserContext";
import { MembershipContext } from "../../../../context/MembershipContext";
import WorkspaceStep from "./WorkspaceStep";
import WelcomeStep from "./WelcomeStep";
import InviteStep from "./InviteStep";
import DownloadStep from "./DownloadStep";
import { addWorkspace } from "../../../../models/workspace";
import { WorkspaceScope, WorkspaceStatus } from "../../../../models";
import { inviteUserEmail } from "../../../../models/membership";
import ConfigurationProvider, { ConfigurationContext } from "../../../../context/CofigurationContext";
import { getAllowedWorkspaceType, subscriptionCreatedEvent } from "../../../../store/currentUser";

const CreateTeamWorkspace = (props) => {
  // const { navigate } = props;
  const [step, setStep] = useState(1);
  const [configuration, setConfiguration] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [createdWorkspace, setCreatedWorkspace] = useState(null);
  const modal = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const { currentUser } = useContext(UserContext);
  const { memberships } = useContext(MembershipContext);
  const  [cookies, setCookie, removeCookie] = useCookies(['signup-selection', 'signup-plan', 'team-workspace-created', 'first-landing', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']);
  const { configurations } = useContext(ConfigurationContext);
  const [isLoading, setIsLoading] = useState(false);
  const { utm_campaign, utm_content, utm_medium, utm_source, utm_term } = cookies;

  useEffect(() => {
    try {
      const getConfiguration = async () => {
        const { selection, subscription } = await getAllowedWorkspaceType(currentUser, memberships?.data);
        if (subscription?.id) {
          setSubscriptionId(subscription?.id);
        }
        let conf = null;
        if (selection) {
          configurations.forEach(c => {
            if (c.name?.toLowerCase() === selection?.toLowerCase()) {
              conf = c;
            }
          });
        }
        if (!conf) {
          conf = configurations.find(conf => conf.name?.toLowerCase() === 'team');
        }
        setConfiguration(conf);
      };
      if (configurations.length && memberships?.data?.length && !configuration) {
        getConfiguration();
      }
    } catch (e) {
      console.error(`Error retrieving configuration`, e);
      Sentry.captureException(e);
      throw e;
    }
  }, [configuration, configurations, currentUser, memberships?.data]);

  const isOpen = modal.type === modalTypes.CREATE_TEAM_WORKSPACE;

  const createWorkspaceCb = async (workspaceName, workspaceSlug, workspaceSuffix) => {
    try {
      setIsLoading(true);
      const allowedWorkspace = await getAllowedWorkspaceType(currentUser, memberships?.data);
      if (allowedWorkspace?.selection) {
        const workspaceUrl = `${workspaceSlug}-${workspaceSuffix}`;
        const newWorkspace = await addWorkspace({
          name: workspaceName,
          url: workspaceUrl,
          status: WorkspaceStatus.ACTIVE,
          scope: WorkspaceScope.SHARED,
          configurationId: configuration?.id,
          ...(subscriptionId && { stripeSubscriptionId: subscriptionId })
        }, currentUser);
        await subscriptionCreatedEvent(
          allowedWorkspace.subscription?.id,
          currentUser.email,
          allowedWorkspace.selection,
          allowedWorkspace.plan,
          allowedWorkspace.subscription.current_period_start,
          allowedWorkspace.subscription.current_period_end,
          newWorkspace.name,
          `${window.location.origin}/workspace/${newWorkspace.url}`,
          { utm_campaign, utm_content, utm_medium, utm_source, utm_term }
        );
        setCreatedWorkspace(newWorkspace);
        removeCookie('signup-selection', { path: '/' });
        removeCookie('signup-plan', { path: '/' });
        setCookie('team-workspace-created', true, { path: '/' });
        setStep(3);
      } else {
        console.error(`No valid subscription found for stripe customer ${currentUser.stripeCustomerId}`);
        removeCookie('signup-selection', { path: '/' });
        removeCookie('signup-plan', { path: '/' });
        dispatch(closeModal(modalTypes.CREATE_TEAM_WORKSPACE));
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    } finally {
      setIsLoading(false);
    }
  }

  const inviteUsersCb = async (invites) => {
    try {
      setIsLoading(true);
      const invitedEmails = [currentUser.email?.toLowerCase()];
      if (invites?.length) {
        for (const {email, role, valid} of invites) {
          if (valid && email?.length > 0 && !invitedEmails.includes(email.toLowerCase())) {
            await inviteUserEmail(createdWorkspace, email, role, currentUser);
            invitedEmails.push(email.toLowerCase());
          }
        }
      }
      setStep(4);
    } catch (e) {
      console.error(`Error inviting users`, invites, e);
      Sentry.captureException(e);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadAppCb = () => {
    removeCookie('first-landing', { path: '/' });
    dispatch(closeModal(modalTypes.CREATE_TEAM_WORKSPACE));
    // @FIXME: navigate instead of redirecting, right now we are not reloading the list of workspaces
    // navigate(`/workspace/${workspaceUrl}`);
    setTimeout(() => {
      window.location = `/workspace/${createdWorkspace.url}`;
    }, 180);
  };

  return (
    <Modal show={isOpen} size="2xl" onClose={() => dispatch(closeModal(modalTypes.CREATE_TEAM_WORKSPACE))}>
      <Modal.Body className='flex flex-col items-center p-0'>
        {step === 1 && <WelcomeStep currentUser={currentUser} nextStep={() => setStep(2)} />}
        {step === 2 && <WorkspaceStep currentUser={currentUser} nextStep={createWorkspaceCb} configurationName={configuration?.name?.toLowerCase()} disableSubmitButton={isLoading} />}
        {step === 3 && <InviteStep currentUser={currentUser} memberships={memberships.data} nextStep={inviteUsersCb} configurationName={configuration?.name?.toLowerCase()} disableSubmitButton={isLoading} />}
        {step === 4 && <DownloadStep currentUser={currentUser} nextStep={downloadAppCb} configurationName={configuration?.name?.toLowerCase()} />}
      </Modal.Body>
    </Modal>
  );
};

const CreateTeamWorkspaceWrapper = (props) => {
  return (
    <ConfigurationProvider>
      <CreateTeamWorkspace {...props} />
    </ConfigurationProvider>
  )
};

export default CreateTeamWorkspaceWrapper;