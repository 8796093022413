import React, { useCallback, useContext, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";

import Workspace from "../workspace/Workspace";
import { UserContext } from "../../context/UserContext";
import { createBillingSession } from "../../store/currentUser";

const SubscriptionManagement = (props) => {
  const [didRedirect, setDidRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(UserContext);
  const { params: { workspaceUrl }} = useRouteMatch();

  const billingRedirectFn = useCallback(async () => {
    try {
      const sessionUrl = await createBillingSession(currentUser, `/workspace/${workspaceUrl}`);
      setDidRedirect(true);
      window.location = sessionUrl;
    } catch(error) {
      console.error(`Error creating billing session:`, error);
      setIsLoading(false);
    }
  }, [currentUser, workspaceUrl]);

  useEffect(() => {
    if (currentUser && !isLoading) {
      setIsLoading(true);
      billingRedirectFn();
    }
  }, [billingRedirectFn, currentUser, isLoading]);

  const retry = () => {
    setDidRedirect(false);
  };

  return (
    <Workspace {...props}>
      <div className="flex flex-col">
        <h1 className='text-style-2xl-semibold text-xgray-900'>Manage Subscription</h1>
        {isLoading && <span>Redirecting you to the customer portal, please wait...</span>}
        {!isLoading && !didRedirect && <span>Error opening subscription manager...</span>}
        {!isLoading && !didRedirect && <button className="mt-4 primary-button btn-xs w-20" onClick={retry}>Retry</button>}
      </div>
    </Workspace>
  );
};

export default SubscriptionManagement;
