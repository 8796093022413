import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from "flowbite-react";

import { closeModal, modalTypes } from "../../../store/slices/modals";
import DownloadAppButtons from "../DownloadAppButtons";
import { useCookies } from "react-cookie";

const AppDownload = (props) => {
  const modal = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const isOpen = modal.type === modalTypes.DOWNLOAD;
  const [,,removeCookie] = useCookies(['first-landing']);

  const dismissModal = () => {
    removeCookie('first-landing', { path: '/' });
    dispatch(closeModal(modalTypes.DOWNLOAD))
  };

  return (
    <Modal show={isOpen} onClose={dismissModal} size="lg">
      <Modal.Header></Modal.Header>
      <Modal.Body className='flex flex-col items-center'>
        <h1 className='text-style-semibold text-xgray-900'>Get Xray Exploratory App</h1>
        <div className='mt-4 text-style-normal text-xgray-500 text-center'>Download the Xray Exploratory Testing application to start managing sessions immediately!</div>
        <DownloadAppButtons {...props} />
        <button className='primary-button mt-4 w-full' onClick={dismissModal}>
          Go to Your Workspace
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default AppDownload;