import React, { useContext } from "react";

import { WorkspaceScope } from "../../models";
import { WorkspaceContext } from "../../context/WorkspaceContext";

export const workspaceTitle = (workspace) => {
  return workspace?.scope === WorkspaceScope.PRIVATE ? 'My Test Sessions' : `${workspace?.name} Test Sessions`
};

const WorkspaceTitle = (props) => {
  const { currentWorkspace } = useContext(WorkspaceContext);

  return (<h1 className="text-style-2xl-semibold text-xgray-900">{workspaceTitle(currentWorkspace)}</h1>);
};

export default WorkspaceTitle;