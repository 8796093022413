import React, { useState, useRef, useEffect } from 'react';
import { countries } from 'country-code-lookup';
import { Checkbox, Select, Tooltip } from 'flowbite-react';

import XEALogo from './XEALogo/XEALogo';
import { theme as FlowbiteCustomTheme } from '../flowbite-theme';

const CollectUserInfo = (props) => {
  const { didCollectUserInfoCb, hideTitle, update, hideMarketingOptIn, ctaCopy, user, userSEN } = props;
  const [firstName, setFirstName] = useState(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [jobTitle, setJobTitle] = useState(user?.jobTitle ?? '');
  const [country, setCountry] = useState(user?.country ?? '');
  const [businessName, setBusinessName] = useState(user?.businessName ?? '');
  const [marketingOptIn, setMarketingOptIn] = useState(user?.marketingOptIn === 'TRUE');
  const [collectUserInfoDisabled, setCollectUserInfoDisabled] = useState(true);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState('Continue');
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const businessNameRef = useRef(null);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setJobTitle(user.jobTitle);
      setCountry(user.country);
      setBusinessName(user.businessName);
      setMarketingOptIn(user.marketingOptIn === 'TRUE');
    }
  }, [user]);

  const submitUserInfo = async () => {
    try {
      setLoading(true);
      setShowUpdateSuccess(false);
      const userInfo = {
        firstName,
        lastName,
        jobTitle,
        country,
        businessName,
        marketingOptIn
      };
      await didCollectUserInfoCb(userInfo);
      if (update) {
        setShowUpdateSuccess(true);
        setTimeout(() => {
          setShowUpdateSuccess(false);
        }, 10000);
      }
      setLoading(false);
    } catch(err) {
      setLoading(false);
      console.error(`Error collecting user info:`, err);
    }
  };

  const initialCountries = [
    { country: 'United States', iso2: 'US' },
    { country: 'United Kingdom', iso2: 'GB' },
    { country: ' --- ', iso2: '' },
  ];
  const initialCountriesIso2 = initialCountries.map(country => country.iso2);

  const countryOption = (country) => (<option key={country.iso2} disabled={!country.iso2.length} value={country.iso2}>{country.country}</option>);
  const restCountriesOption = (country) => {
    if (!initialCountriesIso2.includes(country.iso2)) {
      return countryOption(country);
    }
  };

  useEffect(() => {
    setCollectUserInfoDisabled(!firstNameRef.current?.validity?.valid
                               || firstName.length < 2
                               || !lastNameRef.current?.validity?.valid
                               || lastName.length < 2
                               || !jobTitle.length
                               || !country.length
                               || !businessNameRef.current?.validity?.valid
                               || loading);
  }, [firstNameRef.current, firstName, lastNameRef.current, lastName, jobTitle, country, businessNameRef.current, businessName, loading]);

  useEffect(() => {
    if(loading) {
      setSubmitButtonText(
        <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
        </svg>
      );
    } else {
      setSubmitButtonText(ctaCopy ?? 'Continue');
    }
  }, [loading, ctaCopy]);

  return (
    <div id="collect-user-info-container" className="flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
      <div className="w-[452px] p-2 m-auto mt-8">
        <XEALogo {...props} />

        <div className="flex flex-col">
          <div className={`text-style-xl-semibold text-xgray-900 text-center mb-4 ${hideTitle ? 'hidden' : ''}`}>Tell Us a Little About Yourself</div>

          { showUpdateSuccess && (
            <div className="message success mt-2 mb-4">
              <span className="success-message">User profile updated successfully.</span>
            </div>
          )}

          <div className="flex flex-col">
            <div className="input-field-container split-container">
              <div className="input-field-half-container">
                <label className="input-field-label" htmlFor="first-name-text-input">
                  <span className="text-xred-800 mr-1">*</span>
                  First Name
                </label>
                <input
                  type="text"
                  id="first-name-text-input"
                  className="input-field"
                  ref={firstNameRef}
                  autoFocus={true}
                  minLength="2"
                  maxLength="250"
                  pattern="^[A-Za-zÀ-ÿ,.'\-\s]+$"
                  required
                  tabIndex="1"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
                { !firstNameRef.current?.validity?.valid && firstName.length < 2 && <div className="input-field-error-message">Please fill out this field.</div> }
                { firstNameRef.current?.validity?.patternMismatch && <div className="input-field-error-message">Only letters (Aa-Zz), apostrophes, periods, and spaces are allowed.</div> }
              </div>
              <div className="input-field-half-container">
                <label className="input-field-label" htmlFor="last-name-text-input">
                  <span className="text-xred-800 mr-1">*</span>
                  Last Name
                </label>
                <input
                  type="text"
                  id="last-name-text-input"
                  className="input-field"
                  ref={lastNameRef}
                  minLength="2"
                  maxLength="250"
                  pattern="^[A-Za-zÀ-ÿ,.'\-\s]+$"
                  required
                  tabIndex="2"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
                { !lastNameRef.current?.validity?.valid && lastName.length < 2 && <div className="input-field-error-message">Please fill out this field.</div> }
                { lastNameRef.current?.validity?.patternMismatch && <div className="input-field-error-message">Only letters (Aa-Zz), apostrophes and spaces are allowed.</div> }
              </div>
            </div>

            { update &&
              <div className="input-field-container">
                <label className="input-field-label" htmlFor="email-text-input">
                  <span className="text-xred-800 mr-1">*</span>
                  Email address
                </label>
                <div className="fix-email-tooltip-size">
                  <Tooltip content={<div className="text-style-small-medium">Contact support to change your email address.</div>} theme={FlowbiteCustomTheme.theme.tooltip}>
                    <input
                      type="text"
                      id="email-text-input"
                      className="input-field"
                      value={user?.email || ""}
                      readOnly={true}
                      disabled
                    />
                  </Tooltip>
                </div>
              </div>
            }

            <div className="input-field-container">
              <label className="input-field-label" htmlFor="job-title-select">
                <span className="text-xred-800 mr-1">*</span>
                Job title
              </label>
              <Select
                id="job-title-select"
                tabIndex="3"
                required
                value={jobTitle}
                onChange={e => setJobTitle(e.target.value)}
              >
                <option value="" disabled>-Select-</option>
                <option value="Software Tester / QA Engineer">Software Tester / QA Engineer</option>
                <option value="SDET / Test Automation Engineer">SDET / Test Automation Engineer</option>
                <option value="QA Manager / Lead">QA Manager / Lead</option>
                <option value="Software Engineer / Software Developer">Software Engineer / Software Developer</option>
                <option value="VP / C-Suite / Founder">VP / C-Suite / Founder</option>
                <option value="Consultant">Consultant</option>
                <option value="Intern / Student">Intern / Student</option>
                <option value="Other">Other</option>
              </Select>
            </div>

            <div className="input-field-container">
              <label className="input-field-label" htmlFor="country-select">
                <span className="text-xred-800 mr-1">*</span>
                Country
              </label>
              <Select
                id="country-select"
                tabIndex="4"
                required
                value={country}
                onChange={e => setCountry(e.target.value)}
              >
                <option value="" disabled>-Select-</option>
                {initialCountries.map(countryOption)}
                {countries.map(restCountriesOption)}
              </Select>
            </div>

            <div className="input-field-container">
              <label className="input-field-label" htmlFor="business-name-text-input">
                <span className="text-xred-800 mr-1">*</span>
                Business name
              </label>
              <input
                type="text"
                id="business-name-text-input"
                className="input-field"
                ref={businessNameRef}
                minLength="2"
                maxLength="250"
                pattern="[a-zA-Z0-9\s.,\(\)]+"
                required
                tabIndex="5"
                value={businessName}
                onChange={e => setBusinessName(e.target.value)}
              />
              { !businessNameRef.current?.validity?.valid && businessName.length < 2 && <div className="input-field-error-message">Please fill out this field.</div> }
              { businessNameRef.current?.validity?.patternMismatch && <div className="input-field-error-message">Only letters (Aa-Zz), spaces and these chars , . ( ) are allowed.</div> }
            </div>

            { !hideMarketingOptIn &&
              <div className="input-field-container checkbox-container">
                <Checkbox
                  theme={FlowbiteCustomTheme.theme.checkbox}
                  type="checkbox"
                  id="marketing-opt-in-checkbox"
                  className="checkbox-field"
                  tabIndex="6"
                  checked={marketingOptIn}
                  onChange={e => setMarketingOptIn(!marketingOptIn)}
                />
                <label className="checkbox-field-label" htmlFor="marketing-opt-in-checkbox">
                  I would like to receive marketing communication from Xblend for product updates, sales services, promotions, news and events. I can withdraw my consent at any time and update my communication preference at the subscription center from any email received. 
                </label>
              </div>
            }
          </div>

          { userSEN }

          <button data-testid='continue-button' className="mt-4 submit-button" disabled={collectUserInfoDisabled} tabIndex="7" onClick={submitUserInfo}>{submitButtonText}</button>
        </div>
      </div>
    </div>
  );
};

export default CollectUserInfo;