import React from "react";
import { Button } from "flowbite-react";
import Close from "../flowbite-icons/close.svg";

const CloseButton = (props) => {
  const { history } = props;

  const goBack = () => {
    if (history.goBack) {
      history.goBack();
      return;
    }
    history.back();
  };

  return (
    <Button onClick={goBack} className="absolute top-0 right-0 m-2 z-10">
      <Close className="w-3 h-3 text-xgray-400" />
    </Button>
  )
}

export default CloseButton;