import { sendSQSMessage }  from '@stoatlabs/xea-client-shared-components'

import apollo from '../apollo';

import { observeForWorkspace, observeCreatesForWorkspace, observeUpdatesForWorkspace, observeDeletesForWorkspace, 
         observeForUser, observeCreatesForUser, observeUpdatesForUser, observeDeletesForUser } from '../utils/apollo_utils';

import { CreateMembershipDocument, ListMembershipsDocument, ListWorkspacesDocument,
         GetMembershipDocument, ListMembershipsByWorkspaceDocument, UpdateMembershipDocument,
         DeleteMembershipDocument } from '../apollo/components';

import { MembershipRole, MembershipState, WorkspaceScope } from '../models';
import { sendInviteEmail } from '../models/user'

import { getCognitoUser } from '../store/currentUser';
import awsconfig from '../awsconfig';
import { v4 as uuid } from 'uuid';

const EMAILS_CONSUMER_QUEUE_URL = process.env.REACT_APP_XEA_EMAILS_CONSUMER_QUEUE_URL;
const WAREHOUSE_EVENTS_QUEUE_URL = process.env.REACT_APP_WAREHOUSE_EVENTS_QUEUE_URL;

export async function observeMembership(membershipId, setState) {
  fetchMembership(membershipId, setState);

  const membership = apollo.client.watchQuery({ query: GetMembershipDocument, variables: { membershipId } }).subscribe(() => {
    fetchMembership(membershipId, setState);
  });

  const unsub = () => {
    membership.unsubscribe();
    document.removeEventListener('logoutEvent', unsub);
  };

  document.addEventListener('logoutEvent', unsub, { once: true });

  return unsub;
}

export async function observeMembershipsForUser(setMemberships) {
  return observeForUser('Memberships', setMemberships);
}

export async function observeMembershipCreatesForUser(userId, setMemberships) {
  return observeCreatesForUser('Membership', userId, setMemberships);
}

export async function observeMembershipUpdatesForUser(userId, setMemberships) {
  return observeUpdatesForUser('Membership', userId, setMemberships);
}

export async function observeMembershipDeletesForUser(userId, setMemberships) {
  return observeDeletesForUser('Membership', userId, setMemberships);
}

export async function observeMembershipsForWorkspace(workspaceId, setMemberships, setUsers) {
  return observeForWorkspace('Memberships', workspaceId, setMemberships, (memberships) => {
    // Grab and set our users
    const users = [];
    memberships.forEach(membership => {
      const user = membership.user;
      users.push(user);
      const inviter = membership.inviter;
      users.push(inviter);
    });
    setUsers({ type: 'set', refObj: users.filter(Boolean) });
  });
}

export async function observeMembershipCreatesForWorkspace(workspaceId, setMemberships) {
  return observeCreatesForWorkspace('Membership', workspaceId, setMemberships);
}

export async function observeMembershipUpdatesForWorkspace(workspaceId, setMemberships) {
  return observeUpdatesForWorkspace('Membership', workspaceId, setMemberships);
}

export async function observeMembershipDeletesForWorkspace(workspaceId, setMemberships) {
  return observeDeletesForWorkspace('Membership', workspaceId, setMemberships);
}

export async function fetchMemberships(setState) {
  const memberships = await apollo.client.query({ query: ListMembershipsDocument });
  if (typeof setState === "function") {
    setState(memberships.data.listMemberships);
  }
  return memberships.data.listMemberships;
}

export async function fetchMembership(membershipId, setState) {
  const memberships = await apollo.client.query({query: GetMembershipDocument, variables: {membershipId}});
  setState(memberships.data.getMembership);
  return memberships.data.getMembership;
}

export async function inviteUserEmail(workspace, email, role, inviter) {
  const newMembership = {
    isOwner: false,
    role,
    state: MembershipState.PENDING,
    invitedAt: new Date().toISOString(),
    inviteEmail: email,
    inviterId: inviter.id,
    workspaceId: workspace.id,
  };

  const membership = await addMembership(newMembership);
  await sendInviteEmail(membership, workspace);
};

export async function addMembership(membership) {
  try {
    const savedMembership = await apollo.client.mutate({
      mutation: CreateMembershipDocument,
      variables: { input: membership },
      refetchQueries: [ListMembershipsDocument, ListWorkspacesDocument, ListMembershipsByWorkspaceDocument]
    });

    return savedMembership.data.createMembership;
  } catch (error) {
    console.error('Error adding membership', error);
  }
}


export const sendUserRemovedEmail = async (membership, workspace) => {
  const cognitoUser = await getCognitoUser();
  const { user } = membership;
  const firstName = user.firstName;
  const workspaceName = workspace.name;
  const sqsPayload = {
    action: 'user-removed',
    data: {
      email: user.email,
      subject: `Important: Your Access to ${ workspaceName } Workspace Was Removed`,
      user: {
        firstName,
      },
      workspace: {
        name: workspaceName
      },
    }
  };
  const jwtoken = cognitoUser.signInUserSession.idToken.jwtToken;
  // Send the message with the empty duplicationId since it's going to create one on its own
  await sendSQSMessage(sqsPayload, EMAILS_CONSUMER_QUEUE_URL, null, jwtoken, awsconfig);
};


export async function removeMembership(membership, workspace) {
  try {
    await apollo.client.mutate({
      mutation: DeleteMembershipDocument,
      variables: {id: membership.id},
      refetchQueries: [ListMembershipsByWorkspaceDocument]
    });
    // Inform the user that they have been removed from the workspace only if they accepted the invite
    if (membership.user) {
      await sendUserRemovedEmail(membership, workspace);
      await sendWarehouseMembershipEvent(membership, workspace, 'remove');
    }
    return true;
  } catch (error) {
    console.error('Error removing membership', error);
  }
}

export async function revokeAdmin(membership, workspace) {
  return await changeRole(membership, MembershipRole.USER, workspace);
}

export async function makeAdmin(membership, workspace) {
  return await changeRole(membership, MembershipRole.ADMIN, workspace);
}

export async function changeRole(membership, role, workspace) {
  try {
    const updatedMembership = await apollo.client.mutate({
      mutation: UpdateMembershipDocument,
      variables: {
        input: _buildUpdateInput(membership, { role })
      },
      refetchQueries: [ListMembershipsDocument, ListWorkspacesDocument],
    });

    // Inform the user that their role has changed only if they accepted the invite
    if (membership.user) {
      const sqsPayload = {
        action: 'role-changed',
        data: {
          email: membership.user.email,
          subject: `Important Update: ${ role === MembershipRole.ADMIN ? 'Admin' : 'User' } Role for ${ workspace.name } Workspace`,
          role,
          user: {
            firstName: membership.user.firstName,
          },
          workspace: {
            name: workspace.name
          },
        }
      };
      const cognitoUser = await getCognitoUser();
      const jwtoken = cognitoUser.signInUserSession.idToken.jwtToken;
      // Send the message with the empty duplicationId since it's going to create one on its own
      await sendSQSMessage(sqsPayload, EMAILS_CONSUMER_QUEUE_URL, uuid(), jwtoken, awsconfig);
    }

    return updatedMembership;
  } catch(error) {
    console.error(`Error changing role for membershipId ${membership.id} to ${role}`, error);
  }
}

async function sendWarehouseMembershipEvent(membership, workspace, type) {
  const cognitoUser = await getCognitoUser();
  const jwtoken = cognitoUser.signInUserSession.idToken.jwtToken;
  const sqsPayload = {
    action: 'membership',
    data: {
      type,
      email: membership.inviteEmail,
      subscriptionId: workspace.stripeSubscriptionId,
    }
  };
  // Send the message with the empty duplicationId since it's going to create one on its own
  await sendSQSMessage(sqsPayload, WAREHOUSE_EVENTS_QUEUE_URL, null, jwtoken, awsconfig);
}

export async function acceptMembershipInvite(membership, currentUser, workspace) {
  try {
    await apollo.client.mutate({
      mutation: UpdateMembershipDocument,
      variables: {
        input: _buildUpdateInput(membership, { state: MembershipState.ACCEPTED, userId: currentUser.id })
      },
      refetchQueries: [ListMembershipsDocument, ListWorkspacesDocument, ListMembershipsByWorkspaceDocument],
    });
    await sendWarehouseMembershipEvent(membership, workspace, 'add');
    return true
  } catch(error) {
    console.error(`Error accepting invite for ${membership.id} ${membership.email}`, error);
  }
}

function _buildUpdateInput(input: any, updateData: any): any {
  const { inviter, workspace, user, __typename, ...membershipData } = input;

  return {
    ...membershipData,
    userId: user?.id,
    inviterId: inviter.id,
    workspaceId: workspace.id,
    ...updateData,
  }
}

export const membershipForWorkspace = (memberships, workspace, user = null) => {
  const workspaceId = typeof workspace === 'string' ? workspace : workspace?.id;
  const userId = user && typeof user === 'string' ? user : user?.id;
  return memberships.find(mem => mem.workspace.id === workspaceId && (!user || mem.user?.id === userId));
};

export const isOwnerOfWorkspace = (memberships, workspace) => {
  const membership = membershipForWorkspace(memberships, workspace);
  return membership?.isOwner;
};

export const ownerSharedWorkspace = (memberships, workspaces) => {
  const workspaceIds = memberships.map(m => m.isOwner && m.workspace.id);
  return workspaces.find(w => workspaceIds.includes(w.id) && w?.scope === WorkspaceScope.SHARED);
}

export const workspaceOwnerUser = (workspaceMemberships, users) => {
  const ownerMembership = workspaceMemberships.find(mem => mem.isOwner);
  return users.find(user => user.id === ownerMembership.user.id);
}