import { useContext, useEffect, useRef } from 'react';
import apollo from '../apollo';

import { useObserverHandler } from './useObserverHandler';

import { observeSessionsForWorkspace, observeSessionCreatesForWorkspace, observeSessionDeletesForWorkspace, observeSessionUpdatesForWorkspace } from '../models/session';
import { observeMembershipsForWorkspace, observeMembershipCreatesForWorkspace, observeMembershipDeletesForWorkspace, observeMembershipUpdatesForWorkspace } from '../models/membership';
import { ListSessionsByWorkspaceDocument, ListMembershipsByWorkspaceDocument } from '../apollo/components';

import { WorkspaceContext } from '../context/WorkspaceContext';

const sessionMutations = { observeSessionCreatesForWorkspace, observeSessionDeletesForWorkspace, observeSessionUpdatesForWorkspace }
const membershipMutations = { observeMembershipCreatesForWorkspace, observeMembershipDeletesForWorkspace, observeMembershipUpdatesForWorkspace }

export function useObserveCurrentWorkspace(setSessions, setUsers, setMemberships, clearCurrentWorkspaceData) {
  const { currentWorkspace } = useContext(WorkspaceContext);
  const sessionCreateRefs = useRef({ create: {}, update: {}, delete: {} });
  const membershipCreateRefs = useRef({ create: {}, update: {}, delete: {} });

  useObserverHandler(currentWorkspace,
                     () => observeSessionsForWorkspace(currentWorkspace.id, setSessions),
                     [currentWorkspace, setSessions])

  useObserverHandler(currentWorkspace,
                     () => observeMembershipsForWorkspace(currentWorkspace.id, setMemberships, setUsers),
                     [currentWorkspace, setMemberships, setUsers])

  useSubscriptionsForWorkspace(currentWorkspace, 'Session', sessionMutations, setSessions, sessionCreateRefs);
  useSubscriptionsForWorkspace(currentWorkspace, 'Membership', membershipMutations, setMemberships, membershipCreateRefs);

  useEffect(() => {
    const sessionRefs = sessionCreateRefs;
    const membershipRefs = membershipCreateRefs;

    const clearData = () => {
      if(!currentWorkspace) return;

      clearCurrentWorkspaceData();

      for(const ref in sessionRefs.current) {
        if(typeof sessionRefs.current[ref]?.current === 'function') { sessionRefs.current[ref]?.current(); }
        sessionRefs.current[ref] = {};
      }

      for(const ref in membershipRefs.current) {
        if(typeof membershipRefs.current[ref]?.current === 'function') { membershipRefs.current[ref]?.current(); }
        membershipRefs.current[ref] = {};
      }

      document.removeEventListener('logoutEvent', clearData);
    };

    if(currentWorkspace) {
      apollo.client.refetchQueries({
        include: [
          { query: ListSessionsByWorkspaceDocument, variables: { workspaceId: currentWorkspace?.id } },
          { query: ListMembershipsByWorkspaceDocument, variables: { workspaceId: currentWorkspace?.id } }
        ]
      })

      document.addEventListener('logoutEvent', clearData, { once: true });
    }

    return clearData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorkspace])
};

function useSubscriptionsForWorkspace(currentWorkspace, target, mutations, setCollection, refs) {
  refs.current.create = useSubscriptionForWorkspace(currentWorkspace, target, mutations, setCollection, 'Creates', refs.current.create);
  refs.current.update = useSubscriptionForWorkspace(currentWorkspace, target, mutations, setCollection, 'Updates', refs.current.update);
  refs.current.delete = useSubscriptionForWorkspace(currentWorkspace, target, mutations, setCollection, 'Deletes', refs.current.delete);
}

function useSubscriptionForWorkspace(currentWorkspace, target, mutations, setCollection, mutation, ref) {
  return useObserverHandler(currentWorkspace,
                           () => mutations[`observe${target}${mutation}ForWorkspace`](currentWorkspace.id, setCollection),
                           [currentWorkspace, setCollection, ref], ref, false)
}
