const Urlify = require("urlify");

const urlifyConfig = {
  addEToUmlauts: true,
  trim: true,
  spaces: '-',
};

const urlifyFn = (str) => {
  const urlify = Urlify.create(urlifyConfig);
  return urlify(str);
};

export default urlifyFn;