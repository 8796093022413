import { createContext, useState } from 'react';

import { useObserveMembership } from '../hooks/useObserveMemberships';

export const InviteContext = createContext(null);

const InviteProvider = ({ membershipId, children }) => {
  const [membership, setMembership] = useState(null);
  const [loaded, setLoaded] = useState(null);
  useObserveMembership(membershipId, mem => {
    setLoaded(true);
    setMembership(mem);
  });

  const store = {
    membership,
    loaded,
  };

  return (
    <InviteContext.Provider value={store}>
      {children}
    </InviteContext.Provider>
  );
};

export default InviteProvider;