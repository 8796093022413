import { createContext, useReducer } from 'react';

import { currentUserReducer } from '../reducer/currentUserReducer';
import { useObserveCurrentUser } from '../hooks/useObserveCurrentUser';

export const UserContext = createContext(null);

const UserProvider = ({ userId, children }) => {
  const [currentUser, setCurrentUser] = useReducer(currentUserReducer, null);

  useObserveCurrentUser(userId, setCurrentUser);

  const store = {
    currentUser,
  }

  return (
    <UserContext.Provider value={store}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;