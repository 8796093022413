import React, { useContext } from 'react';
import { Alert } from 'flowbite-react';
import { CheckCircle } from '@stoatlabs/xea-client-shared-components';

import { WorkspaceContext } from '../../context/WorkspaceContext';

export const workspacePickerTooltipContent = `Switch your Personal and shared workspaces with dropdown menu here`;

const TeamWorkspaceCreatedAlert = (props) => {
  const { setShowTeamWorkspaceCreatedAlert } = props;
  const { currentWorkspace } = useContext(WorkspaceContext);
  const wsType = currentWorkspace?.configuration?.name?.toLowerCase() === 'enterprise' ? 'Enterprise' : 'Team';

  const additionalContent = (
    <p className='text-style-small text-xgray-500'>
      Now, you can easily switch between your <span className='text-style-small-semibold text-xgray-900'>Personal</span> and <span className='text-style-small-semibold text-xgray-900'>{wsType} Workspace</span> with the dropdown menu to start collaborative testing.
    </p>
  );
  return (
    <Alert className="mb-4" color="success" icon={CheckCircle} onDismiss={() => setShowTeamWorkspaceCreatedAlert(false)} additionalContent={additionalContent}>
      Congratulations! Your Team Workspace Was Created.
    </Alert>
  );
};

export default TeamWorkspaceCreatedAlert;