import apollo from '../apollo';
import { ListConfigurationsDocument } from '../apollo/components';

export async function observeConfigurations(setConfigurations) {
  const configurations = apollo.client.watchQuery({ query: ListConfigurationsDocument}).subscribe(snapshot => {
    setConfigurations(snapshot.data.listConfigurations);
    fetchConfigurations(setConfigurations);
  });

  const unsub = () => {
    configurations.unsubscribe();
    document.removeEventListener('logoutEvent', unsub);
  }

  document.addEventListener('logoutEvent', unsub, { once: true });
  return unsub;
}

export async function fetchConfigurations(setConfigurations) {
  const configurations = await apollo.client.query({ query: ListConfigurationsDocument});
  setConfigurations(configurations.data.listConfigurations);
  return configurations.data.listConfigurations;
}
