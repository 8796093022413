import React, { useState } from 'react';

const passcodeLength = 6;
// We use an object instead of an array to store the passcode because
// using array index as key will cause React to complain about the key not being unique
// see https://react.dev/learn/rendering-lists
const initialPasscode = {
  0: '',
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
};
const passcodeRegEx = /^\d{6}$/;

const PasscodeField = (props) => {
  const { passcodeDidChange, submitPasscodeCb } = props;
  const [code, setCode] = useState(initialPasscode);

  const getPasscodeString = (codeMap) => [codeMap['0'], codeMap['1'], codeMap['2'], codeMap['3'], codeMap['4'], codeMap['5']].join('');

  const updateCellValue = (cell, idx, focusNextItem = true) => {
    const index = parseInt(idx);
    const newValue = cell.value;
    const updatedCode = {
      ...code,
      [index]: newValue,
    };
    setCode(updatedCode);
    passcodeDidChange(getPasscodeString(updatedCode));
    if (focusNextItem && newValue.length) {
      const currentTabIndex = index + 1;
      const nextInput = document.querySelector(`[tabIndex="${currentTabIndex + 1}"]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const maybePasteClipboard = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const clipboardData = e.clipboardData.getData('Text');
    if (clipboardData.match(passcodeRegEx)) {
      const cells = document.querySelectorAll('.confirm-passcode-cell');
      cells.forEach((cell, idx) => {
        const newValue = clipboardData[idx];
        cell.value = newValue;
      });
      const updatedCode = {
        0: clipboardData[0],
        1: clipboardData[1],
        2: clipboardData[2],
        3: clipboardData[3],
        4: clipboardData[4],
        5: clipboardData[5],
      };
      setCode(updatedCode);
      passcodeDidChange(getPasscodeString(updatedCode));
    }
  };

  /* eslint no-param-reassign:
    [ "error", { "props": true, "ignorePropertyModificationsFor": ["cell"] }] */
  const selectCellText = (cell) => {
    cell.selectionStart = 0;
    cell.selectionEnd = cell.value.length;
  };

  const cellClassName = "outline-none w-[37px] h-[37px] text-style-base text-center rounded border border-px border-xgray-300 focus:border-[#191919] focus:ring-0 focus:outline-none bg-xgray-50 text-xgray-900";

  const keyDownCb = (e, idx) => {
    const curIdx = parseInt(idx) + 1;
    const focusPrevCell = () => {
      const prevTabIndex = curIdx - 1;
      if (prevTabIndex > 0) {
        const prevInput = document.querySelector(`[tabIndex="${prevTabIndex}"]`);
        if (prevInput) {
          prevInput.focus();
          selectCellText(prevInput);
        }
      }
    };
    const focusNextCell = () => {
      const nextTabIndex = curIdx + 1;
      if (nextTabIndex <= passcodeLength) {
        const nextInput = document.querySelector(`[tabIndex="${nextTabIndex}"]`);
        if (nextInput) {
          nextInput.focus();
          selectCellText(nextInput);
        }
      }
    };
    if ((e.keyCode === 37 || e.keyCode === 38) && !e.shiftKey && !e.altKey && !e.ctrlKey) { // left arrow
      focusPrevCell();
    } else if ((e.keyCode === 39 || e.keyCode === 40) && !e.shiftKey && !e.altKey && !e.ctrlKey) { // right arrow
      focusNextCell()
    } else if (e.keyCode === 8 || e.keyCode === 46) { // backspace or delete
      e.preventDefault();
      const cell = document.querySelector(`[tabIndex="${curIdx}"]`);
      if (cell) {
        cell.value = '';
        updateCellValue(cell, idx, false);
      }
      focusPrevCell();
    } else if (e.keyCode === 13 && !e.shiftKey && !e.altKey && !e.ctrlKey) { // enter
      e?.preventDefault();
      e?.stopPropagation();
      if (submitPasscodeCb) {
        submitPasscodeCb();
      }
    }
  };

  return (
    <div id="passcode-field-container" className="my-8 flex">
      <div className="flex items-center gap-[24px] ml-auto mr-auto">
        { Object.entries(code).map(([i, char]) => (
          <input onPaste={maybePasteClipboard}
                 autoFocus={parseInt(i) === 0}
                 className={cellClassName}
                 type="text"
                 key={i}
                 role='passcode-cell'
                 name="passcode"
                 maxLength="1"
                 value={char}
                 onFocus={e => selectCellText(e.target)}
                 onClick={e => selectCellText(e.target)}
                 onKeyDown={(e) => keyDownCb(e, i)}
                 tabIndex={parseInt(i) + 1}
                 onChange={e => updateCellValue(e.target, i)} />)
        )}
      </div>
    </div>
  );
};

export default PasscodeField;
export { passcodeLength, passcodeRegEx }
