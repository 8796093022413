import React from "react";

import Steps from "./Steps";
import DownloadAppButtons from "../../../workspace/DownloadAppButtons";

const InviteStep = (props) => {
  const { nextStep, configurationName } = props;
  const capitalizedConfigurationName = configurationName?.charAt(0).toUpperCase() + configurationName?.slice(1);

  return (
    <>
      <div className="text-style-lg-semibold text-xgray-900 border-b border-b-xgray-200 p-6 w-full items-start">
        Set Up Your {capitalizedConfigurationName}'s Workspace
      </div>
      <div className="flex w-full min-h-[386px] overflow-auto">
        <Steps activeStep={4} />
        <div className="flex flex-col w-[546px] p-6 gap-4 overflow-y-auto">
          <span className="text-style-xl-semibold text-xgray-900 text-center">Get Xray Exploratory App</span>
          <span className="text-style-small text-xgray-500 text-center mx-auto w-[444px]">Download the Xray Exploratory Testing App to start creating exploratory testing sessions!</span>
          <DownloadAppButtons {...props} />
        </div>
      </div>
      <div className="border-t border-t-xgray-200 w-full p-6 flex">
        <button className="primary-button ml-auto" onClick={() => nextStep()}>
          Finish and Close
        </button>
      </div>
    </>
  );
}

export default InviteStep;