import React from "react";

const Steps = (props) => {
  const {activeStep} = props;
  return (
    <div className="flex flex-col w-[204px] border-r border-r-xgray-200 gap-6 my-6 ml-6">
      <div className={`flex items-center ${activeStep === 1 ? 'text-xgreen-600' : 'text-xgray-500'}`}>
        <div className={`w-8 h-8 mr-2 text-style-small-medium flex items-center justify-center border rounded-full ${activeStep === 1 ? 'border-xgreen-600 text-xgreen-600' : 'border-xgray-500 text-xgray-500'}`}>1</div>
        <span className="text-style-medium">Welcome</span>
      </div>
      <div className={`flex items-center ${activeStep === 2 ? 'text-xgreen-600' : 'text-xgray-500'}`}>
        <div className={`w-8 h-8 mr-2 text-style-small-medium flex items-center justify-center border rounded-full ${activeStep === 2 ? 'border-xgreen-600 text-xgreen-600' : 'border-xgray-500 text-xgray-500'}`}>2</div>
        <span className="text-style-medium">Workspace</span>
      </div>
      <div className={`flex items-center ${activeStep === 3 ? 'text-xgreen-600' : 'text-xgray-500'}`}>
        <div className={`w-8 h-8 mr-2 text-style-small-medium flex items-center justify-center border rounded-full ${activeStep === 3 ? 'border-xgreen-600 text-xgreen-600' : 'border-xgray-500 text-xgray-500'}`}>3</div>
        <span className="text-style-medium">Invite Users</span>
      </div>
      <div className={`flex items-center ${activeStep === 4 ? 'text-xgreen-600' : 'text-xgray-500'}`}>
        <div className={`w-8 h-8 mr-2 text-style-small-medium flex items-center justify-center border rounded-full ${activeStep === 3 ? 'border-xgreen-600 text-xgreen-600' : 'border-xgray-500 text-xgray-500'}`}>3</div>
        <span className="text-style-medium">Download the App</span>
      </div>
    </div>
  );
};

export default Steps;