import { ApolloClient, InMemoryCache, split, HttpLink, ApolloLink } from '@apollo/client';
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

import { Auth } from 'aws-amplify';

import awsConfig from '../awsconfig';

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

const httpLink = (authed) => new HttpLink({
  uri: `${awsConfig.aws_apollo_http_endpoint}${authed ? '' : '/memberships'}`,
  fetch: async (uri, options) => {
    if (authed) {
      const token = (await Auth.currentSession())?.getAccessToken()?.getJwtToken();
      options.headers.Authorization = token;
      return fetch(uri, options);
    } else {
      return fetch(uri, options);
    }
  },
});

const wsLink = new GraphQLWsLink(createClient({
  url: async () => {
    const token = (await Auth.currentSession())?.getAccessToken()?.getJwtToken();
    const authHeader = btoa(JSON.stringify({ Authorization: token }));
    return `${awsConfig.aws_apollo_ws_endpoint}?authHeader=${authHeader}`;
  },
}));

const retryLink = new RetryLink();

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = (authed) => split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink(authed)
);

export const configure = (authed) => {
  const apolloLink = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    retryLink,
    splitLink(authed)
  ]);

  if(def.client) {
    def.client.stop();
    def.client.setLink(apolloLink);
  } else {
    def.client = new ApolloClient({
      link: apolloLink,
      cache: new InMemoryCache(),
    });
  }

  def.authed = authed;
};

const def = {
  configure,
  client: null,
  authed: null,
};

export default def;