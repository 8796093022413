import React, { useState, useEffect } from "react";
import { Label, TextInput /*, Tooltip*/ } from "flowbite-react";
import { v4 as uuid } from "uuid";

import CopyToClipboard from "./CopyToClipboard";

import XEALogo from './XEALogo/XEALogo';

import urlify from "../misc/urlify";

export const workspaceNameForUser = (user) => {
  let name = `user-${uuid().slice(0, 6)}`;
  if (user && user?.firstName) {
    name = user?.firstName.slice(0, 80);
  }
  return name;
};

export const workspaceUrlForUserName = (name) => {
  return `${urlify(name.toLowerCase())}-${uuid().slice(0, 6)}`
};

const CreateWorkspace = (props) => {
  const [createWorkspaceBtnDisabled, setCreateWorkspaceBtnDisabled] = useState(true);
  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceUrl, setWorkspaceUrl] = useState('');
  const { user, didCreateWorkspaceCb } = props;
  const [loading, setLoading] = useState(false);

  // Load the user data to preset the workspace name
  useEffect(() => {
    if (!workspaceName && user?.firstName) {
      const name = workspaceNameForUser(user);
      setWorkspaceName(name);
      setWorkspaceUrl(workspaceUrlForUserName(name));
    }
    setCreateWorkspaceBtnDisabled(!workspaceName.length || loading);
  }, [user?.firstName, loading, workspaceName]);

  const { protocol, host } = window.location;
  const getWorkspaceUrl = (http = false) => `${http ? `${protocol}//` : ''}${host}/workspace/${workspaceUrl}`;

  const onCreateWorkspaceClick = async () => {
    try {
      setLoading(true);
      await didCreateWorkspaceCb(workspaceName, workspaceUrl);
    } catch (err) {
      console.error('Error creating workspace', err);
    }
    setLoading(false);
  };

  const workspaceNameLabel = (
    <span className="flex items-center">
      <span className="text-xred-800 mr-1">*</span>
      Workspace Name
      {/* <Tooltip content={tooltipContent} theme={FlowbiteCustomTheme.theme.tooltip}>
        <InfoCircle className="text-xgray-400 w-3 h-3 ml-2" />
      </Tooltip> */}
    </span>
  );

  const copyToClipboardFn = () => getWorkspaceUrl(true);

  return (
    <>
      <div id="create-workspace-container" className="relative flex items-center content-center min-h-full rounded">
        <div className="w-[452px] p-2 m-auto mt-8">
          <XEALogo {...props} />

          <div className="flex flex-col">
            <div className="text-center text-style-xl-semibold text-xgray-900 dark:text-white mb-4">Personal Workspace Details</div>

            <div className="text-center text-xgray-500 text-style-small px-2">Your personal Workspace is where you can access all your exploratory testing sessions.</div>

            <div className="mt-4">
              <Label htmlFor="workspace-name" className="text-style-7 text-xgray-900" value={workspaceNameLabel} />
              <TextInput
                type="email"
                id="workspace-name-text-input"
                maxLength={80}
                minLength={2}
                value={workspaceName}
                disabled={true} />
            </div>

            <div className="mt-4">
              <Label htmlFor="workspace-name" className="text-style-7 text-xgray-900" value="Your workspace name creates a unique URL, like this:" />
              <div className="relative">
                <TextInput
                  type="text"
                  readOnly={true}
                  id="workspace-url-text-input"
                  value={getWorkspaceUrl()}
                  onChange={e => setWorkspaceName(e.target.value)} />
                <CopyToClipboard copyToClipboardFn={copyToClipboardFn} />
              </div>
              <span className="text-style-10 text-xgray-500">
                <strong className="text-style-semibold text-[#374151]">IMPORTANT</strong>: The personal workspace name is permanent and cannot be changed later.
              </span>
            </div>

            <button className="mt-4 submit-button" disabled={createWorkspaceBtnDisabled} onClick={onCreateWorkspaceClick}>Finish Account Setup</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateWorkspace;