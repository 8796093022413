const flowbiteReact = require('flowbite-react');

const theme = {
  theme: {
    alert: {
      ...flowbiteReact.theme.alert,
      base: "flex flex-col gap-2 p-[16px] text-sm"
    },
    pagination: {
      pages: {
        base: 'xs:mt-0 inline-flex items-center -space-x-px',
        previous: {
          base: 'ml-0 rounded-l-lg border border-xgray-200 bg-white py-1 px-2 leading-tight text-xgray-500 enabled:hover:bg-xgray-100 enabled:hover:text-xgray-700 dark:border-xgray-700 dark:bg-xgray-800 dark:text-xgray-400 enabled:dark:hover:bg-xgray-700 enabled:dark:hover:text-white',
          icon: 'h-6 w-6'
        },
        next: {
          base: "rounded-r-lg border border-xgray-200 bg-white py-1 px-2 leading-tight text-xgray-500 enabled:hover:bg-xgray-100 enabled:hover:text-xgray-700 dark:border-xgray-700 dark:bg-xgray-800 dark:text-xgray-400 enabled:dark:hover:bg-xgray-700 enabled:dark:hover:text-white",
          icon: 'h-6 w-6'
        },
        selector: {
          base: "w-8 border border-xgray-200 bg-white py-1.5 -mt-0.5 leading-tight text-xgray-500 enabled:hover:bg-xgray-100 enabled:hover:text-xgray-700 dark:border-xgray-700 dark:bg-xgray-800 dark:text-xgray-400 enabled:dark:hover:bg-xgray-700 enabled:dark:hover:text-white",
          active: "bg-xgreen-200 text-xray-primary-link hover:bg-xgreen-300 hover:text-xray-primary-hover dark:border-xgray-700 dark:bg-xgray-700 dark:text-white",
        }
      }
    },
    tableFooter: {
      root: {
        base: 'w-full rounded-b-lg bg-white shadow dark:bg-xgray-800 md:flex md:items-center md:justify-between',
        container: 'w-full p-4'
      }
    },
    tooltip: {
      base: "absolute inline-block z-10 rounded-lg py-2.5 px-3 text-style-2 shadow-sm",
      style: {
        dark: "bg-xgray-800 text-white dark:bg-xgray-700",
      }
    },
    // navbar: {
    //   base: "fixed top-0 left-0 w-full bg-white px-2 py-3 border-b border-b-xgray-200 dark:border-xgray-700 dark:bg-xgray-800 sm:px-4 z-10"
    // },
    navbar: {
      root: {
        base: "fixed top-0 left-0 w-full bg-white border-b border-b-xgray-200 dark:border-xgray-700 dark:bg-xgray-800 z-10",
        inner: {
          base: "mx-auto flex flex-col"
        }
      }
    },
    dropdown: {
      electronDropdown: {
        ...flowbiteReact.theme.dropdown,
        inlineWrapper: "flex items-center p-0 mt-[18px]",
      },
      main: {
        inlineWrapper: "flex items-center p-0"
      },
      inviteRole: {
        ...flowbiteReact.theme.dropdown,
        floating: {
          ...flowbiteReact.theme.dropdown.floating,
          base: "z-10 w-[262px] rounded divide-y divide-xgray-100 shadow focus:outline-none !left-0",
        }
      },
      inviteRoleDisabled: {
        ...flowbiteReact.theme.dropdown,
        inlineWrapper: `${flowbiteReact.theme.dropdown.inlineWrapper} cursor-not-allowed pointer-events-none`,
        floating: {
          ...flowbiteReact.theme.dropdown.floating,
          base: "z-10 w-[262px] rounded divide-y divide-xgray-100 shadow focus:outline-none !left-0",
        }
      },
      helpMenu: {
        inlineWrapper: "flex items-center p-[6px] ml-3 mr-4"
      },
      workspacePicker: {
        arrowIcon: "ml-auto my-auto h-4 w-4",
        floating: {
          base: "z-10 w-[224px] rounded divide-y divide-xgray-100 shadow focus:outline-none -ml-2",
          item: {
            container: "w-full",
            base: "flex items-center justify-start py-1.5 px-2 text-sm text-xgray-700 cursor-pointer w-full hover:bg-xgray-100 focus:bg-xgray-100 dark:text-xgray-200 dark:hover:bg-xgray-600 focus:outline-none dark:hover:text-white dark:focus:bg-xgray-600 dark:focus:text-white",
            icon: "mr-2 h-4 w-4",
          },
        },
        inlineWrapper: "flex items-start w-full",
      },
      electronWorkspacePicker: {
        ...flowbiteReact.theme.dropdown,
        content: "text-gray-900",
        arrowIcon: "ml-auto my-auto h-4 w-4 text-gray-900",
        floating: {
          ...flowbiteReact.theme.dropdown.floating,
          base: "z-10 w-[224px] rounded divide-y divide-xgray-100 shadow focus:outline-none -ml-2",
          item: {
            container: "w-full",
            base: "flex items-center justify-start py-1.5 px-2 text-sm text-xgray-700 cursor-pointer w-full hover:bg-xgray-100 focus:bg-xgray-100 dark:text-xgray-200 dark:hover:bg-xgray-600 focus:outline-none dark:hover:text-white dark:focus:bg-xgray-600 dark:focus:text-white",
            icon: "mr-2 h-4 w-4",
          },
        },
        inlineWrapper: "flex items-start w-full",
      }
    },
    sidebar: {
      root: {
        base: "fixed w-[250px] flex-1 flex flex-col justify-between px-0 py-4 bg-xray-background",
        collapsed: {
          "on": "w-16",
          "off": "w-[250px]"
        },
        inner: "px-5 py-0 h-full overflow-y-auto",
      }
    },
    teamWorkspaceAvatar: {
      root: {
        base: "flex justify-center items-center space-x-4 rounded",
        initials: {
          text: "font-medium text-white dark:text-xgray-300",
          base: "inline-flex overflow-hidden relative justify-center items-center bg-xblue-450 text-white dark:bg-xgray-600 rounded-lg"
        },
        size: {
          xs: "w-6 h-6",
          sm: "w-8 h-8",
          md: "w-12 h-12",
          lg: "w-20 h-20",
          xl: "w-36 h-36"
        }
      }
    },
    avatar: {
      root: {
        base: "flex justify-center items-center space-x-4 rounded",
        initials: {
          text: "font-medium text-white dark:text-xgray-300",
          base: "inline-flex overflow-hidden relative justify-center items-center bg-[#000000] text-white dark:bg-xgray-600 rounded-lg"
        },
        size: {
          xs: "w-6 h-6",
          sm: "w-8 h-8",
          md: "w-12 h-12",
          lg: "w-20 h-20",
          xl: "w-36 h-36"
        }
      }
    },
    smallAvatar: {
      root: {
        base: "flex justify-center items-center space-x-4 rounded",
        initials: {
          text: "font-medium text-xgray-900 dark:text-xgray-300",
          base: "inline-flex overflow-hidden relative justify-center items-center bg-xgray-100 text-xgray-900 dark:bg-xgray-600 rounded-lg"
        },
        size: {
          xs: "w-6 h-6",
          sm: "w-8 h-8",
          md: "w-12 h-12",
          lg: "w-20 h-20",
          xl: "w-36 h-36"
        }
      }
    },
    sessionPickerCard: {
      ...flowbiteReact.theme.card,
      root: {
        ...flowbiteReact.theme.card.root,
        children: "flex flex-1 flex-col h-full justify-start px-1 py-4 overflow-hidden"
      },
      sessionTable: {
        ...flowbiteReact.theme.table,
        body: {
          cell: {
            base: 'px-2 py-4 group-first/body:group-first/row:first:pl-4 group-first/body:group-first/row:first:pr-4 text-style-base text-xgray-900',
          }
        },
        head: {
          cell: {
            base: 'px-2 py-4 group-first/head:first:pl-4 group-first/head:last:pr-4 border-b border-xgray-200',
          }
        },
        row: {
          base: 'group/row hover:cursor-pointer hover:bg-xray-background',
        }
      }
    },
    envPickerCard: {
      ...flowbiteReact.theme.card,
      root: {
        ...flowbiteReact.theme.card.root,
        children: "flex h-full flex-col justify-center p-6"
      },
    },
    envPickerModal: {
      ...flowbiteReact.theme.modal,
      content: {
        base: "relative h-full w-full !py-4 !px-12 md:h-auto",
        inner: "relative rounded-lg bg-xray-background shadow dark:bg-gray-700 flex flex-col max-h-[90vh] mt-[5vh]"
      },
      root: {
        sizes: {
          sm: "max-w-sm",
          md: "max-w-md",
          lg: "max-w-[532px]",
          xl: "max-w-[640px]",
          "2xl": "max-w-[782px]",
          "3xl": "max-w-[532px]",
          "4xl": "max-w-[532px]",
          "5xl": "max-w-[532px]",
          "6xl": "max-w-[532px]",
          "7xl": "max-w-[532px]"
        },
      },
      header: {
        base: "flex items-start justify-between rounded-t border-b-none !pt-3 !px-3 shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)]",
        title: "text-style-medium text-xgray-900 dark:text-white bg-xray-background",
      },
      body: {
        base: "!p-4 flex-1 overflow-auto"
      },
      footer: {
        base: "flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600 shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)]",
        popup: "border-t"
      },
    },
    jiraConnectModal: {
      ...flowbiteReact.theme.modal,
      content: {
        base: "relative h-full w-full !py-4 !px-24 md:h-auto",
        inner: "relative rounded-lg bg-xray-background shadow dark:bg-gray-700 flex flex-col max-h-[90vh] mt-[5vh]"
      },
      root: {
        sizes: {
          sm: "max-w-sm",
          md: "max-w-md",
          lg: "max-w-[532px]",
          xl: "max-w-[640px]",
          "2xl": "max-w-[782px]",
          "3xl": "max-w-[532px]",
          "4xl": "max-w-[532px]",
          "5xl": "max-w-[532px]",
          "6xl": "max-w-[532px]",
          "7xl": "max-w-[532px]"
        },
      },
      header: {
        base: "flex items-start justify-between rounded-t border-b-none !pt-3",
        title: "text-style-medium text-xgray-900 dark:text-white bg-xray-background",
      },
      body: {
        base: "!px-8 !pb-4 !pt-0 flex-1 overflow-auto"
      },
      footer: {
        base: "flex items-center rounded-b border-gray-200 p-4 dark:border-gray-600 shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)]",
        popup: "border-t"
      },
    },
    sessionPickerModal: {
      ...flowbiteReact.theme.modal,
      content: {
        base: "relative h-full w-full !py-4 !px-12 md:h-auto",
        inner: "relative rounded-lg bg-xray-background shadow dark:bg-gray-700 flex flex-col max-h-[90vh] mt-[5vh]"
      },
      root: {
        sizes: {
          sm: "max-w-sm",
          md: "max-w-md",
          lg: "max-w-[532px]",
          xl: "max-w-[640px]",
          "2xl": "max-w-[782px]",
          "3xl": "max-w-[532px]",
          "4xl": "max-w-[532px]",
          "5xl": "max-w-[532px]",
          "6xl": "max-w-[532px]",
          "7xl": "max-w-[532px]"
        },
      },
      header: {
        base: "flex items-start justify-between rounded-t border-b-none !pt-3 !px-3 shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)]",
        title: "text-style-medium text-xgray-900 dark:text-white bg-xray-background h-[32px] flex items-center",
      },
      body: {
        base: "!p-4 flex flex-col overflow-auto text-gray-900"
      },
      footer: {
        base: "flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600 shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)]",
        popup: "border-t"
      },

      workspacePicker: {
        search: {
          select: {
            base: 'flex w-1/3',
            field: {
              select: {
                base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-50 focus:outline-none focus:ring-0 focus:!border-gray-300',
                withAddon: {
                  off: 'rounded-l-lg border-r-0 truncate'
                }
              }
            }
          },
          input: {
            field: {
              rightIcon: {
                svg: 'h-3 w-3 text-gray-500 dark:text-gray-400',
              },
              input: {
                base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-50 focus:outline-none focus:ring-0 focus:!border-gray-300 bg-white',
                withAddon: {
                  off: 'rounded-r-lg'
                },
                colors: {
                  gray: "bg-white border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
                }
              }
            }
          }
        }
      }
    },
    modal: {
      ...flowbiteReact.theme.modal,
      content: {
        base: "relative h-full w-full p-4 md:h-auto",
        inner: "relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col max-h-[90vh] mt-[10vh]"
      },
      root: {
        sizes: {
          sm: "max-w-sm",
          md: "max-w-md",
          lg: "max-w-[532px]",
          xl: "max-w-[640px]",
          "2xl": "max-w-[782px]",
          "3xl": "max-w-[532px]",
          "4xl": "max-w-[532px]",
          "5xl": "max-w-[532px]",
          "6xl": "max-w-[532px]",
          "7xl": "max-w-[532px]"
        },
      },
      header: {
        base: "flex items-start justify-between rounded-t border-b-none pt-3 px-3",
        title: "text-sm font-medium text-xgray-900 dark:text-white"
      },
      body: {
        base: "pb-8 px-8 flex-1 overflow-auto"
      },
    },
    checkbox: {
      root: {
        base: "h-4 w-4 rounded border border-xgray-300 bg-white focus:outline-none focus:ring-0 text-xgreen-400 focus:ring-xgreen-400 dark:ring-offset-cyan-600 dark:focus:ring-cyan-600",
        color: ""
      }
    },
    textfield: {
      field: {
        colors: {
          gray: "bg-xgray-50 border-xgray-300 text-xgray-900 focus:border-cyan-500 focus:ring-0 dark:border-xgray-600 dark:bg-xgray-700 dark:text-white dark:placeholder-xgray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
          info: "border-cyan-500 bg-cyan-50 text-cyan-900 placeholder-cyan-700 focus:border-cyan-500 focus:ring-0 dark:border-cyan-400 dark:bg-cyan-100 dark:focus:border-cyan-500 dark:focus:ring-cyan-500",
          failure: "border-xred-500 bg-xred-50 text-xred-900 placeholder-xred-700 focus:border-xred-500 focus:ring-xred-500 dark:border-xred-400 dark:bg-xred-100 dark:focus:border-xred-500 dark:focus:ring-xred-500",
          warning: "border-yellow-500 bg-yellow-50 text-yellow-900 placeholder-yellow-700 focus:border-yellow-500 focus:ring-yellow-500 dark:border-yellow-400 dark:bg-yellow-100 dark:focus:border-yellow-500 dark:focus:ring-yellow-500",
          success: "border-xgreen-500 bg-xgreen-50 text-xgreen-900 placeholder-xgreen-700 focus:border-xgreen-500 focus:ring-xgreen-500 dark:border-xgreen-400 dark:bg-xgreen-100 dark:focus:border-xgreen-500 dark:focus:ring-xgreen-500"
        }
      }
    },
    badge: {
      root: {
        base: "flex h-fit items-center gap-1 font-semibold",
        color: {
          success: "bg-xgreen-200 text-xgreen-800 dark:bg-xgreen-200 dark:text-xgreen-900 group-hover:bg-xgreen-200 dark:group-hover:bg-xgreen-300",
          indigo: "bg-xindigo-200 text-xindigo-800 dark:bg-xindigo-200 dark:text-xindigo-900 group-hover:bg-xindigo-200 dark:group-hover:bg-xindigo-300",
          gray: "bg-xgray-100 text-xgray-400 dark:bg-xgray-700 dark:text-xgray-300 group-hover:bg-xgray-200 dark:group-hover:bg-xgray-600"
        },
        href: "group",
        size: {
          xs: "px-[10px] py-[3px] text-xs",
          sm: "p-1.5 text-sm"
        }
      },
    },
    workspaceCard: {
      root: {
        base: "flex rounded-lg border border-xgray-200 bg-[#FFFFFF] shadow-md dark:border-xgray-700 dark:bg-[#2D2D2D]",
        children: "flex h-full flex-col justify-center gap-4 py-4 px-6",
        horizontal: {
          off: "flex-col",
          on: "flex-col md:max-w-xl md:flex-row"
        },
        href: "hover:bg-xgray-100 dark:hover:bg-xgray-700"
      },
      img: {
        base: "",
        horizontal: {
          off: "rounded-t-lg",
          on: "h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
        }
      }
    },
    workspaceSelector: {
      alert: {
        ...flowbiteReact.theme.alert,
        base: "flex flex-col gap-2 py-[16px] px-[12px] text-sm"
      },
    },
    textInput: {
      inviteEmail: {
        ...flowbiteReact.theme.textInput,
      },
      inviteEmailInvalid: {
        ...flowbiteReact.theme.textInput,
        field: {
          ...flowbiteReact.theme.textInput.field,
          icon: {
            ...flowbiteReact.theme.textInput.field.icon,
            svg: "h-5 w-5 text-red-500 dark:text-red-500"
          },
        }
      },
    },
  },
};

export { theme };