import React, { useContext, useRef, useState } from "react";
import { TextInput, Dropdown } from "flowbite-react";
import { Envelope, FlowbiteCustomTheme, AngleDown } from "@stoatlabs/xea-client-shared-components";
import titleize from "titleize";

import {  MembershipRole } from "../../models";
import { UserContext } from "../../context/UserContext";
import { inviteUserEmail } from "../../models/membership";
import { WorkspaceContext } from "../../context/WorkspaceContext";

const InviteInlineForm = (props) => {
  const { memberships, disableInput, hideButton, onFieldChange } = props;
  const { currentWorkspace } = useContext(WorkspaceContext);
  const [isLoading, setIsLoading] = useState(false);
  const emailRef = useRef(null);
  const { currentUser } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(MembershipRole.USER);
  const [emailValid, setEmailValid] = useState(true);

  const inviteFn = async () => {
    if (!disableInput) {
      try {
        setIsLoading(true);
        await inviteUserEmail(currentWorkspace, email, role, currentUser);
        setEmail('');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const emailChanged = (e) => {
    const value = e.target.value.toLowerCase();
    setEmail(value);
    setEmailValid(emailRef.current?.value?.length && emailRef.current?.validity.valid);
    if (typeof onFieldChange === 'function') {
      onFieldChange(emailRef.current?.validity.valid, value, role);
    }
  };

  const roleChanged = (value) => {
    setRole(value);
    if (typeof onFieldChange === 'function') {
      onFieldChange(emailRef.current?.validity.valid, email, value);
    }
  };

  const membershipAlreadyExists =  memberships.find(u => u.inviteEmail === email);

  const dropdownLabel = (<div className="border border-xgray-300 rounded-lg flex items-center px-2 py-2.5 bg-xgray-50 w-[126px]">
    <span className="text-style-8">{titleize(role)}</span>
    <AngleDown className="ml-auto w-3 h-3" />
  </div>);

  return (
    <div className="flex items-start">
      <div className="flex flex-col items-start">
        <TextInput
          className="mr-4 w-[300px] ml-2"
          ref={emailRef}
          icon={Envelope}
          id="email"
          helperText={emailValid ? "" : <span className="ml-2">Please enter a valid email.</span>}
          placeholder="Enter a User Email"
          value={email}
          onChange={emailChanged}
          required
          disabled={disableInput}
          theme={emailValid ? FlowbiteCustomTheme.theme.textInput.inviteEmail : FlowbiteCustomTheme.theme.textInput.inviteEmailInvalid}
          color={emailValid ? '' : 'failure'}
          type="email"
        />
      </div>
      <Dropdown
        theme={disableInput ? FlowbiteCustomTheme.theme.dropdown.inviteRoleDisabled : FlowbiteCustomTheme.theme.dropdown.inviteRole}
        label={dropdownLabel}
        inline
        arrowIcon={false}>
        <Dropdown.Item onClick={() => roleChanged(MembershipRole.USER)}>
          <div className="flex flex-col items-start">
            <span className="text-style-small-medium text-xgray-900 text-left">User</span>
            <span className="text-style-small text-xgray-500 text-left">Can only manage test sessions (create, read, delete, and move).</span>
          </div>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => roleChanged(MembershipRole.ADMIN)}>
          <div className="flex flex-col items-start">
            <span className="text-style-small-medium text-xgray-900 text-left">Admin</span>
            <span className="text-style-small text-xgray-500 text-left">Full control over the workspace, including test sessions and users, but not payments.</span>
          </div>
        </Dropdown.Item>
      </Dropdown>
      {!hideButton &&
        <button className="primary-button flex items-center ml-4" disabled={!emailRef.current?.validity.valid || membershipAlreadyExists || isLoading || disableInput} onClick={inviteFn}>
          Invite
        </button>}
    </div>
  );
};

export default InviteInlineForm;
