import React from "react";
import prettyBytes from "pretty-bytes";

import { CloudArrow } from "@stoatlabs/xea-client-shared-components";
import { getUpgradeLink } from "../../Account/utils/upgrade";
import { membershipForWorkspace } from "../../../models/membership";

const StorageIndicator = (props) => {
  const { workspaceSize, workspaceMax, workspacePercent, workspaceWarning, memberships, workspaces, currentWorkspace } = props;
  const currentMembership = membershipForWorkspace(memberships, currentWorkspace);

  const upgradeLink = getUpgradeLink(memberships, workspaces, currentWorkspace);

  const title = workspacePercent < 100 ? 'Low Cloud storage!' : 'Cloud Storage is Full!';
  const description = currentMembership?.isOwner ? 'Free up space or upgrade your plan to create test sessions.' : 'Free up space to create test sessions.'

  return (
    <div className="mt-auto pt-8 flex flex-col">
      <div className="flex">
        <CloudArrow className="w-5 text-xgray-500" />
        <span className="ml-3 text-style-medium text-xgray-900">Cloud Storage</span>
      </div>
      <span className={`text-right text-style-xs-medium text-xgray-500`}>{Math.min(workspacePercent, 100)}%</span>
      <div className="h-6px w-full">
        <div className="relative h-[6px] w-full bg-xgray-200 rounded-full overflow-x-hidden">
          <div className={`absolute top-0 left-0 h-full rounded-full ${workspaceWarning ? 'bg-xred-500' : 'bg-xgreen-400'}`} style={{ width: `${workspacePercent}%` }}></div>
        </div>
      </div>
      <div className="mt-2 mb-4 flex text-style-7 text-xgray-900">
        {prettyBytes(workspaceSize || 0)} of {prettyBytes(workspaceMax || 0, {maximumFractionDigits: 1, minimumFractionDigits: 0})} used
      </div>
      { workspaceWarning &&
        <div className="message error flex-col items-start mb-2 px-3 py-2">
          <span className="text-style-small-medium text-xred-700">{title}</span>
          <span className="text-style-small text-xgray-700">{description}</span>
        </div>
      }
      {!!upgradeLink &&
        <a className="flex text-style-medium text-xray-primary-link" href={upgradeLink.href} title={upgradeLink.title} target="_blank" rel="noreferrer">
          Get More Storage
        </a>}
    </div>
  );
};

export default StorageIndicator;