import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.SENTRY_DSN || "https://54b74e211205366ba7662dac19b466f6@o4506183033683968.ingest.sentry.io/4506190334263296",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/[a-z0-9-]+\.xea-nonprod\.com/, /^https:\/\/dev\.d9k289b2as8h2\.amplifyapp\.com/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        window.location.origin,
        'amazonaws.com',
      ]
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  debug: false,
  release: process.env.RELEASE_VERSION || "1.0.0",
  enabled: process.env.NODE_ENV !== "development",
});

export const setUserContext = cognitoUser => {
  const identifiedUser = cognitoUser ? {
    email: cognitoUser?.attributes?.email,
    id: cognitoUser?.attributes?.pub,
    emailVerified: cognitoUser?.attributes?.email_verified,
    name: `${cognitoUser?.attributes['custom:firstName']} ${cognitoUser?.attributes['custom:lastName']}`,
    businessName: cognitoUser?.attributes['custom:businessName'],
  } : null;
  Sentry.setUser(identifiedUser);
};

export const report = (error, errorInfo) => {
  return Sentry.captureException(error, { extra: errorInfo });
};

window.XEA_SENTRY_TEST = report;
