import React, { useContext, useEffect } from "react";
import { Navbar as FlowbiteNavbar } from "flowbite-react";
import { FlowbiteCustomTheme, CustomTooltip } from "@stoatlabs/xea-client-shared-components";
import { FiSettings } from "react-icons/fi";

import UserMenu from "./dropdown-menus/UserMenu";
import HelpMenu from "./dropdown-menus/HelpMenu";
import { MembershipRole, WorkspaceScope } from "../../models";
import { UserContext } from "../../context/UserContext";
import { MembershipContext } from "../../context/MembershipContext";
import { workspacePickerTooltipContent } from "./TeamWorkspaceCreatedAlert";
import { WorkspaceContext } from "../../context/WorkspaceContext";
import { getUpgradeLink } from "../Account/utils/upgrade";

const Navbar = (props) => {
  const { navigate, showTeamWorkspaceCreatedTooltip, setShowTeamWorkspaceCreatedTooltip, navbarClassType, bannerMessage } = props;
  const { currentWorkspace, workspaces } = useContext(WorkspaceContext);
  const { currentUser } = useContext(UserContext);
  const { memberships } = useContext(MembershipContext)

  const currentWorkspaceUrl = currentWorkspace?.url ? `/workspace/${currentWorkspace.url}` : "/";
  const workspaceMembership = memberships?.data?.find(m => m.workspace.id === currentWorkspace?.id && m.user?.id === currentUser?.id);

  const goToHome = (e) => {
    if (navigate) {
      e.preventDefault();
      navigate(currentWorkspaceUrl);
    }
  };

  // We have to render the workspace picker tooltip here because it needs to be visible on top of all the page elements
  const workspacePickerTooltip = (
    <CustomTooltip visible={true} styleClasses="absolute z-10 top-[34px] left-[56px] w-[323px]" arrowStyle="top-[51px] left-[calc(50%-2px)]">
      {workspacePickerTooltipContent}
    </CustomTooltip>
  );

  const topBanner = (message) => (
    <div id="banner" tabIndex="-1" className="flex bg-xblue-100 h-[53px] z-10 gap-8 justify-center items-center py-3 px-4 w-full">
      <p className="text-sm font-light text-gray-500">{message}</p>
    </div>
  )

  useEffect(() => {
    const fn = _ => {
      if (setShowTeamWorkspaceCreatedTooltip) {
        setShowTeamWorkspaceCreatedTooltip(false)
      }
    };
    window.addEventListener('click', fn);
    return () => {
      window.removeEventListener('click', fn);
    }
  }, [setShowTeamWorkspaceCreatedTooltip]);

  const upgradeLink = getUpgradeLink(memberships?.data, workspaces, currentWorkspace);

  const userLoggedIn = !!currentUser;

  return (
    <FlowbiteNavbar
      fluid
      theme={FlowbiteCustomTheme.theme.navbar}
      className={`navbar-${navbarClassType}-banner`}>
      {bannerMessage && topBanner(bannerMessage)}
      <div className="flex w-full items-center content-center justify-between h-[64px] py-3 px-4">
        <FlowbiteNavbar.Brand href={currentWorkspaceUrl} onClick={goToHome}>
          <img
            alt="Flowbite React Logo"
            className="mr-3 w-[218px]"
            src="/xea-logo-2024.svg"
          />
        </FlowbiteNavbar.Brand>
        { showTeamWorkspaceCreatedTooltip && workspacePickerTooltip }
        <div className="flex items-center md:order-2">
          {!!upgradeLink &&
            <a className="primary-button btn-xs mr-2" href={upgradeLink.href} title={upgradeLink.title} target="_blank" rel="noreferrer">
              Upgrade
            </a>}
          { currentWorkspace?.scope === WorkspaceScope.SHARED && workspaceMembership?.role === MembershipRole.ADMIN &&
            <button onClick={() => navigate(`${currentWorkspaceUrl}/users`)} className="ml-2 mr-0.5 w-8 h-8 flex flex-col items-center">
              <FiSettings className="my-auto w-[18px] h-[18px] text-xgray-500" />
            </button>}
          {userLoggedIn && <HelpMenu {...props} />}
          {userLoggedIn && <UserMenu {...props} />}
          {!userLoggedIn && <a className="btn-xs text-xray-primary-link p-3 mr-4" href="/signup">Create an Account</a>}
          {!userLoggedIn && <a className="primary-button btn-xs" href="/login">Login</a>}
          <FlowbiteNavbar.Toggle />
        </div>
      </div>
    </FlowbiteNavbar>
  )
};

export default Navbar;