import React, { useContext } from "react";
import { Alert }  from "flowbite-react";

import { CloseCircle } from "@stoatlabs/xea-client-shared-components";
import { WorkspaceContext } from "../../context/WorkspaceContext";
import { membershipForWorkspace, workspaceOwnerUser } from "../../models/membership";
import { CurrentWorkspaceContext } from "../../context/CurrentWorkspaceContext";
import { UserContext } from "../../context/UserContext";
import { getUpgradeLink } from "../Account/utils/upgrade";
import { MembershipContext } from "../../context/MembershipContext";

const daysLeft = (expirationDate) => {
  if (!expirationDate) return `after 30 days`;

  const today = new Date();
  const expiration = new Date(parseInt(expirationDate, 10));
  const diff = expiration - today;
  const leftDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
  if (leftDays <= 0) return `today`;
  return leftDays !== 1 ? `in ${leftDays} days` : `in ${leftDays} day`;
};

const deletionDate = (expirationDate) => {
  if (!expirationDate) return <></>

  const userLocale = new Intl.NumberFormat().resolvedOptions().locale;
  const deletionDateVal = new Date(parseInt(expirationDate, 10));
  const deletionDisplay = new Intl.DateTimeFormat(userLocale, { month: 'long', day: 'numeric' }).format(deletionDateVal);

  return (<> on <span className="text-style-small-semibold">{deletionDisplay}</span></>);
}

const descriptionText = (ownerText, wsType, planType, reason, expiresAt, textSuffix) => <div>{ownerText} <span className="text-style-small-semibold">{wsType} Workspace</span> {planType} has expired{reason}, but do not worry - your personal workspace and data are still secure and available with the <span className="text-style-small-semibold">free plan.</span> {ownerText} {wsType} Workspace and data will be removed <span className="text-style-small-semibold">{daysLeft(expiresAt)}</span>{deletionDate(expiresAt)}. Please download any critical data to prevent data loss.  {textSuffix}</div>;

const SubscriptionExpiredWarning = () => {
  const { currentWorkspace, workspaces } = useContext(WorkspaceContext);
  const { currentUser } = useContext(UserContext);
  const { memberships } = useContext(MembershipContext);
  const { memberships: workspaceMemberships, users: workspaceUsers } = useContext(CurrentWorkspaceContext);

  const wsConfigurationName = currentWorkspace?.configuration?.name?.toLowerCase();
  const workspaceTypeLabel = wsConfigurationName === 'enterprise' ? 'Enterprise' : ( wsConfigurationName === 'team' ? 'Team' : 'Free');

  const currentMembership = membershipForWorkspace(workspaceMemberships?.data, currentWorkspace, currentUser);
  const ownerUser = workspaceOwnerUser(workspaceMemberships?.data, workspaceUsers?.data);

  if(currentWorkspace && currentMembership) {
    let title = '';
    let planType = '';
    let reason = '';
    let ownerText = currentMembership?.isOwner ? 'Your' : 'This';
    let textSuffix = currentMembership?.isOwner ? 'Upgrade your account to remain connected with your team and continue collaborating.' : <>Contact the workspace owner (<span className="text-style-small-semibold hover:underline"><a href={`mailto:${ownerUser?.email}`} target="_blank" rel="noreferrer">{ownerUser?.email}</a></span>) to </>;
    let actionText = '';

    if(currentWorkspace.stripeSubscriptionTrialEndedAt !== null && currentWorkspace.stripeSubscriptionTrialEndedAt === currentWorkspace.stripeSubscriptionEndedAt) {
      title = `${ownerText} Premium Trial Ended!`;
      planType = 'trial';
      actionText = 'Upgrade Now';

      if(!currentMembership?.isOwner) textSuffix = <>{textSuffix}upgrade the account.</>;
    } else {
      title = `${ownerText} Subscription Has Expired!`;
      planType = 'subscription';
      reason = ' due to a payment issue';
      actionText = 'Renew Subscription';

      if(!currentMembership?.isOwner) textSuffix = <>{textSuffix}address the payment issue.</>;
    }

    const description = descriptionText(ownerText, workspaceTypeLabel, planType, reason, currentWorkspace.expiresAt, textSuffix)
    const upgradeLink = getUpgradeLink(memberships?.data, workspaces, currentWorkspace);

    const additionalContent = (
      <div className="flex flex-col">
        <div className="text-style-small text-xgray-700">
          {description}
        </div>
        {currentMembership?.isOwner && <a className="red-button btn-xs mt-2 mr-auto" href={upgradeLink?.href} title={upgradeLink?.title} target="_blank" rel="noreferrer">{actionText}</a>}
      </div>
    );

    return (
      <Alert color="failure" icon={CloseCircle} additionalContent={additionalContent} className="mt-4">
        <span>
          <p>
          <span className="text-style-semibold text-xred-700">{title}</span>
          </p>
        </span>
      </Alert>
    );
  } else {
    return null;
  }
};

export default SubscriptionExpiredWarning;
