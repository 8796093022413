import React, { useState } from "react";
import { AngleDown } from "@stoatlabs/xea-client-shared-components";

const DownloadAppButtons = (props) => {
  const [expandMac, setExpandMac] = useState(false);

  async function generateDownloadLink(fileKey) {
    let fileName;
    switch (fileKey) {
      case 'mac-intel':
        fileName = `macos-x64/xray-exploratory-app-latest-x64.dmg`;
        break;
      case 'mac-arm':
        fileName = `macos-arm64/xray-exploratory-app-latest-arm64.dmg`;
        break;
      default:
        fileName = `win/xray-exploratory-app-setup-latest.exe`
        break;
    }
    window.open(`https://${process.env.REACT_APP_SESSION_STORAGE_BUCKET}.s3.us-east-2.amazonaws.com/${fileName}`, '_blank');
  }


  return (
    <div className="flex flex-col w-full">
      <button onClick={() => generateDownloadLink('win')} className='mt-4 w-full flex items-center px-4 py-2 text-center border border-xgray-200 rounded-lg'>
        <img src='/windows.svg' className='ml-auto' alt='Windows' />
        <span className='ml-2 mr-auto text-style-5 text-xgray-800'>Download for Windows</span>
      </button>
      <div className="relative w-full mac-expand-btn">
        <button onClick={() => setExpandMac(!expandMac)} className={`mt-4 w-full flex items-center text-center rounded-lg ${expandMac ? 'border-xgray-300 border-[3px] px-3.5 py-1.5' : 'px-4 py-2 border border-xgray-200'}`}>
          <span className="w-4 h-2"></span>  {/* This is needed to keep the text below aligned in the middle */}
          <img src='/apple-original.svg' className='ml-auto' alt='macOS' />
          <span className='ml-2 mr-auto text-style-5 text-xgray-800'>Download for macOS</span>
          <AngleDown className='w-4' />
        </button>
        { expandMac &&
          <div className="rounded-md absolute top-[60px] left-0 w-full border border-xgray-300 bg-white py-2 shadow-lg">
            <button onClick={() => generateDownloadLink('mac-intel')} className="w-full flex items-center px-4 py-1 hover:bg-xgray-100">
              <span className="mx-auto text-style-5 text-xgray-800">Mac with Intel Chip</span>
            </button>
            <span className='w-full h-[1px] bg-xgray-300 block'></span>
            <button onClick={() => generateDownloadLink('mac-arm')} className="w-full flex items-center px-4 py-1 hover:bg-xgray-100">
              <span className="mx-auto text-style-5 text-xgray-800">Mac with Apple Chip</span>
            </button>
          </div>
        }
      </div>
      <ul className='mt-4 text-xgray-500 text-style-2 text-left self-start ml-0'>
        <li>1. After downloading, find the installer in your Downloads.</li>
        <li>2. Double-click the file to open it.</li>
        <li>3. Follow the <a href='https://docs.getxray.app/display/XEA/Installation' target='_blank' rel='noreferrer' className='text-xray-primary-link cursor-pointer'>installation process</a> to install XEA.</li>
      </ul>
    </div>
  )
};

export default DownloadAppButtons;