import React, { useEffect } from 'react';

const pendoScript = `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('43510f9b-c1eb-4a04-4046-3d9ed563277a');`;

export const pendoVisitorFromAmplifyUser = (cognitoUser, XEA_Version, local_id = null) => {
  const xeaVersion = XEA_Version ? {XEA_Version} : {};
  const localId = local_id ? {local_id} : {};
  return cognitoUser ? {
    id: cognitoUser?.username || cognitoUser?.id,
    email: cognitoUser?.attributes?.email || cognitoUser?.email,
    XEA_customer_first_name: cognitoUser?.attributes?.['custom:firstName'] || cognitoUser?.XEA_customer_first_name,
    XEA_customer_last_name: cognitoUser?.attributes?.['custom:lastName'] || cognitoUser?.XEA_customer_last_name,
    XEA_job_title: cognitoUser?.attributes?.['custom:jobTitle'] || cognitoUser?.XEA_job_title,
    XEA_marketing_opt_in: cognitoUser?.attributes?.['custom:marketingOptIn'] === 'TRUE' || cognitoUser?.XEA_marketing_opt_in,
    XEA_business_name: cognitoUser?.attributes?.['custom:businessName'] || cognitoUser?.XEA_business_name,
    product: 'XEA',
    ...xeaVersion,
    ...localId,
  } : null;
};

const Pendo = ({amplifyUser, version, localId = null}) => {
  useEffect(() => {
    const addPendoSnippet = () => {
      const el = document.createElement('script');
      el.id = 'pendo-snippet';
      el.innerHTML = pendoScript;
      document.body.appendChild(el);
    };
    if (document.querySelector('#pendo-snippet') === null) {
      addPendoSnippet();
    }
  }, []);

  useEffect(() => {
    const setUser = () => {
      if (window?.pendo) {
        const pendoVisitor = pendoVisitorFromAmplifyUser(amplifyUser, version, localId);
        console.log(`Pendo user`, pendoVisitor);
        if (pendoVisitor) {
          if (window.pendo?.isReady && window.pendo.isReady()) {
            window.pendo.identify({
              visitor: pendoVisitor,
              account: {
                id: pendoVisitor?.id
              }
            });
          } else {
            window.pendo.initialize({
              visitor: pendoVisitor,
              account: {
                id: pendoVisitor?.id
              }
            });
          }
        } else if (window.pendo?.clearSession) {
          // In case there is no user logged in we clear the pendo session and let it create an anonymous user
          window.pendo.clearSession();
        }
      } else {
        setTimeout(setUser, 1000);
      }
    };

    setUser();
  }, [amplifyUser]);

  return <></>;
}

export default Pendo;
