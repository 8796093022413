import React from "react";

const useMultipleClicks = (callback, clicks = 2, delay = 350) => {
  const [clickCount, setClickCount] = React.useState(0);
  const [clickTimeout, setClickTimeout] = React.useState(null);

  const handleClick = () => {
    setClickCount(clickCount + 1);
    clearTimeout(clickTimeout);
    setClickTimeout(
      setTimeout(() => {
        if (clickCount === clicks - 1) {
          callback();
        }
        setClickCount(0);
      }, delay)
    );
  };

  return handleClick;
}

export default useMultipleClicks;