export function currentUserReducer(state, action) {
  const { type, refObj } = action;

  if(type === 'create') {
    return refObj;
  } else if(action.type === 'update') {
    return {
      ...state,
      ...refObj,
    }
  } else if(type === 'delete') {
    return {}
  } else if(type === 'set') {
    return refObj;
  } else {
    return state;
  }
}