import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { useIsMounted } from '../../hooks/useIsMounted';

const PasswordPopup = (props) => {
  const { fieldRef, validity } = props;

  const [positionClass, setPositionClass] = useState('left'); // left or top
  const [showPopup, setShowPopup] = useState(false);
  const [recalcLocation, setRecalcLocation] = useState(uuid());

  const isMounted = useIsMounted();

  const {
    hasNumber,
    hasLowerCaseLetter,
    hasUpperCaseLetter,
    hasSpecialCharacter,
    hasMinimumLength,
  } = validity;

  useEffect(() => {
    if (isMounted.current) {
      const currentField = fieldRef.current;
      if (currentField) {
        const fieldBoundingBox = currentField.getBoundingClientRect();
        const leftPos = fieldBoundingBox.left - 294;
        if (leftPos < 0) {
          setPositionClass('top');
        } else {
          setPositionClass('left');
        }
      }
    }
  }, [fieldRef.current, recalcLocation]);

  const showOnFocus = () => setShowPopup(true);
  const hideOnBlur = () => setShowPopup(false);

  /* eslint-disable-next-line consistent-return */
  useEffect(() => {
    if (isMounted.current) {
      const currentField = fieldRef.current;
      if (currentField) {
        currentField.addEventListener('focus', showOnFocus);
        currentField.addEventListener('blur', hideOnBlur);
        return () => {
          currentField.removeEventListener('focus', showOnFocus);
          currentField.removeEventListener('blur', hideOnBlur);
          return null;
        };
      }
    }
  }, [fieldRef.current]);

  useEffect(() => {
    window.addEventListener('resize', () => setRecalcLocation(uuid()));
    return () => window.removeEventListener('resize', () => setRecalcLocation(uuid()));
  }, []);

  const numberClass = fieldRef.current?.value.length > 0 ? (hasNumber ? 'valid' : 'invalid') : '';
  const lowerCaseLetterClass = fieldRef.current?.value.length > 0 ? (hasLowerCaseLetter ? 'valid' : 'invalid') : '';
  const upperCaseLetterClass = fieldRef.current?.value.length > 0 ? (hasUpperCaseLetter ? 'valid' : 'invalid') : '';
  const specialCharacterClass = fieldRef.current?.value.length > 0 ? (hasSpecialCharacter ? 'valid' : 'invalid') : '';
  const minLengthClass = fieldRef.current?.value.length > 0 ? (hasMinimumLength ? 'valid' : 'invalid') : '';

  return (
    <div className={`password-popup-container ${showPopup ? '' : 'hidden'} ${positionClass}`} >
      <div className="password-popup-triangle" />
      <div className="password-popup">
        <h3 className="password-popup-title">Password must include at least:</h3>
        <div className={`password-requirement ${minLengthClass}`}>
          <span className='text-xgray-500 mr-2'>•</span>
          8 characters length
        </div>
        <div className={`password-requirement ${numberClass}`}>
          <span className='text-xgray-500 mr-2'>•</span>
          One number (0-9)
        </div>
        <div className={`password-requirement ${lowerCaseLetterClass}`}>
          <span className='text-xgray-500 mr-2'>•</span>
          One lower case letter (a-z)
        </div>
        <div className={`password-requirement ${upperCaseLetterClass}`}>
          <span className='text-xgray-500 mr-2'>•</span>
          One upper case letter (A-Z)
        </div>
        <div className={`password-requirement ${specialCharacterClass}`}>
          <span className='text-xgray-500 mr-2'>•</span>
          One special character
        </div>
      </div>
    </div>
  );
};

export default PasswordPopup;
