const dateStr = d => new Date(parseInt(d, 10) * 1000).toLocaleString();

let clientVersion = process.env.REACT_APP_CLIENT_VERSION;
let clientDate = process.env.REACT_APP_CLIENT_DATE;

const getDeploy = async () => {
  const resp = await fetch('/deploy.json', {
    method: 'GET',
    cache: "no-store",
    headers: {
      'Content-Type': 'application/json',
    }
  });
  return await resp.json();
};

const refresh = async () => {
  const deploy = await getDeploy();
  if (deploy.clientVersion !== clientVersion) {
    console.debug(`Client version mismatch, versions: ${clientVersion} -> ${deploy.clientVersion}, date: ${dateStr(clientDate)} -> ${dateStr(deploy.clientDate)}...`);
    clientVersion = deploy.clientVersion;
    clientDate = deploy.clientDate;
    return true;
  }
  return false;
};

export default refresh;