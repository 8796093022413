import React, { useContext } from 'react';

import DownloadButton from "./DownloadButton";
import LowStorageWarning from './LowStorageWarning';
import SubscriptionExpiredWarning from './SubscriptionExpiredWarning';
import WorkspaceTitle from './WorkspaceTitle';
import { WorkspaceContext } from '../../context/WorkspaceContext';
import { WorkspaceStatus } from '../../models';

const FirstSession = (props) => {
  const { lowStorageWarningPanel } = props;
  const { currentWorkspace } = useContext(WorkspaceContext);

  const firstSession = () => {
    const url = new URL(window.location.origin);
    url.pathname = '/start';
    url.searchParams.set('jiraURL', '');
    url.searchParams.set('workspaceId', currentWorkspace.id);
    url.searchParams.set('sessionId', '');
    url.searchParams.set('sessionType', 'local');
    url.searchParams.set('jiraType', 'JS');
    url.searchParams.set('firstSession', 'true');
    window.open(url.href, '_blank');
  };

  const inactiveWorkspace = currentWorkspace && currentWorkspace.status === WorkspaceStatus.INACTIVE;

  return (
    <div className="flex flex-col h-full">
      <WorkspaceTitle {...props} />
      {!inactiveWorkspace && lowStorageWarningPanel && <LowStorageWarning {...props} />}
      {inactiveWorkspace && <SubscriptionExpiredWarning {...props} />}
      {!inactiveWorkspace &&
        <div className="flex flex-col items-center justify-center my-auto">
          <span className="text-style-xl-semibold text-xgray-900">Start Your First Test Session</span>
          <span className="text-style-xray text-xgray-600">Press the button to start using the desktop app and creating sessions.</span>
          <button className="my-4 primary-button btn-xs" onClick={firstSession}>
            Start Your First Session
          </button>
          <div className="flex">
            <span className="text-style-xray text-xgray-600">Need the desktop app?</span>
            <DownloadButton {...props} />
          </div>
        </div>
      }
    </div>
  )
};

export default FirstSession;