import React from 'react';

import XEALogo from './XEALogo/XEALogo';

const passcodeVerifiedSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
    <path d="M145.9 56.6479H125.84V85.7239H145.9V56.6479Z" fill="#E0E0E0"/>
    <path d="M144.896 56.6479H124.836V85.7239H144.896V56.6479Z" fill="#F5F5F5"/>
    <path d="M142.404 59.7959H127.328V82.5759H142.404V59.7959Z" fill="white"/>
    <path d="M142.402 82.5719C142.402 82.5719 142.402 82.536 142.402 82.464V82.14C142.402 81.852 142.402 81.4359 142.402 80.892C142.402 79.7999 142.402 78.208 142.402 76.196C142.402 72.196 142.402 66.492 142.378 59.796L142.426 59.844H127.354L127.41 59.788C127.41 68.38 127.41 76.304 127.41 82.564L127.366 82.524H138.342H141.37H142.402H142.138H141.338H138.314H127.314H127.27V82.4799C127.27 76.2199 127.27 68.2959 127.27 59.7039V59.6479H142.45V59.7C142.45 66.412 142.43 72.0999 142.426 76.136C142.426 78.136 142.426 79.7359 142.426 80.8159C142.426 81.3559 142.426 81.772 142.426 82.056V82.3719C142.425 82.4393 142.417 82.5063 142.402 82.5719Z" fill="#E0E0E0"/>
    <path d="M144.953 56.6479C144.85 56.8316 144.731 57.0056 144.597 57.168C144.365 57.476 144.033 57.9 143.657 58.368C143.281 58.836 142.925 59.232 142.665 59.52C142.529 59.6826 142.381 59.8338 142.221 59.9719C142.341 59.7956 142.475 59.6285 142.621 59.4719L143.589 58.3L144.553 57.128C144.672 56.9565 144.806 56.7959 144.953 56.6479Z" fill="#E0E0E0"/>
    <path d="M142.48 82.5C143.232 83.6911 144.115 84.794 145.112 85.788L142.48 82.5Z" fill="white"/>
    <path d="M145.112 85.7878C144.945 85.6716 144.791 85.5375 144.652 85.3878C144.303 85.0315 143.972 84.6577 143.66 84.2678C143.349 83.8836 143.058 83.483 142.788 83.0678C142.667 82.8992 142.564 82.7182 142.48 82.5278C142.619 82.6819 142.743 82.8479 142.852 83.0238C143.072 83.3358 143.384 83.7598 143.744 84.2238C144.104 84.6878 144.456 85.0758 144.708 85.3598C144.853 85.4924 144.988 85.6354 145.112 85.7878Z" fill="#E0E0E0"/>
    <path d="M124.836 85.7L127.46 82.46L124.836 85.7Z" fill="white"/>
    <path d="M127.46 82.46C127.066 83.0323 126.643 83.5837 126.192 84.112C125.464 85.008 124.856 85.712 124.836 85.712C125.229 85.1378 125.652 84.5851 126.104 84.056C126.529 83.5015 126.981 82.9688 127.46 82.46Z" fill="#E0E0E0"/>
    <path d="M124.836 56.6479C125.29 57.1316 125.716 57.6404 126.112 58.1719C126.539 58.6841 126.94 59.2182 127.312 59.7719C126.858 59.2883 126.432 58.7795 126.036 58.2479C125.607 57.7373 125.206 57.2032 124.836 56.6479Z" fill="#E0E0E0"/>
    <path d="M137.708 64.4199H132.66V78.7519H137.708V64.4199Z" fill="#E0E0E0"/>
    <path d="M106.001 138C105.089 138.301 104.247 138.784 103.525 139.418C102.803 140.052 102.217 140.826 101.801 141.692C100.981 143.432 100.571 145.337 100.601 147.26C100.553 150.968 102.157 154.92 104.021 158.124C107.441 155.828 108.745 151.236 109.261 148.864C110.061 145.112 109.261 137.844 106.005 138" fill="#F5F5F5"/>
    <path d="M99.6763 163.708C100.02 162.689 100.088 161.597 99.873 160.543C99.658 159.489 99.1679 158.511 98.4523 157.708C97.7257 156.919 96.8491 156.283 95.8739 155.836C94.8987 155.39 93.8443 155.142 92.7723 155.108C91.7843 155.056 90.6883 155.208 90.0243 155.944C89.7353 156.307 89.5484 156.74 89.483 157.199C89.4176 157.659 89.4761 158.127 89.6523 158.556C90.0174 159.412 90.6025 160.156 91.3483 160.712C93.8003 162.712 96.5483 164.22 99.6763 163.7" fill="#F5F5F5"/>
    <path d="M102.241 172.62C102.287 171.802 102.249 170.982 102.129 170.172C102.031 169.281 101.827 168.406 101.521 167.564C101.178 166.529 100.768 165.517 100.293 164.536C99.4168 162.733 98.1087 161.175 96.4849 160C95.9825 159.657 95.4464 159.366 94.8849 159.132C94.6846 159.036 94.4696 158.973 94.2489 158.948C94.2169 159.016 95.1089 159.348 96.2969 160.236C97.8154 161.446 99.0452 162.979 99.8969 164.724C100.363 165.69 100.775 166.681 101.133 167.692C101.446 168.506 101.674 169.351 101.813 170.212C102.061 171.696 102.177 172.628 102.241 172.62Z" fill="#E0E0E0"/>
    <path d="M106.124 143.6C105.996 143.991 105.902 144.392 105.844 144.8C105.692 145.6 105.488 146.72 105.248 148.124C104.764 150.924 104.144 154.82 103.56 159.132C102.976 163.444 102.564 167.36 102.324 170.204C102.204 171.624 102.12 172.772 102.076 173.568C102.029 173.979 102.021 174.394 102.052 174.808C102.149 174.406 102.21 173.996 102.236 173.584C102.336 172.716 102.464 171.584 102.636 170.232C102.952 167.4 103.412 163.492 103.992 159.188C104.572 154.884 105.148 151.06 105.556 148.168C105.748 146.816 105.904 145.688 106.024 144.828C106.093 144.422 106.126 144.011 106.124 143.6Z" fill="#E0E0E0"/>
    <path d="M104.141 165.7C105.086 163.643 106.507 161.841 108.287 160.443C110.067 159.045 112.155 158.091 114.377 157.66C115.505 157.452 116.897 157.492 117.517 158.46C118.137 159.428 117.593 160.716 116.909 161.66C115.445 163.563 113.451 164.991 111.177 165.763C108.904 166.535 106.453 166.618 104.133 166" fill="#F5F5F5"/>
    <path d="M112.801 160.64C112.583 160.65 112.368 160.696 112.165 160.776C111.765 160.888 111.185 161.084 110.485 161.344C108.634 162.016 106.922 163.025 105.437 164.32C104.636 165.059 103.961 165.924 103.437 166.88C103.018 167.664 102.71 168.503 102.521 169.372C102.393 169.953 102.318 170.545 102.297 171.14C102.26 171.355 102.26 171.576 102.297 171.792C102.381 171.792 102.413 170.868 102.785 169.444C103.274 167.589 104.288 165.915 105.705 164.624C107.161 163.367 108.808 162.352 110.585 161.616C111.965 161.02 112.833 160.704 112.801 160.64Z" fill="#E0E0E0"/>
    <path d="M182.238 67.9036C182.238 67.9036 178.046 66.6676 176.35 76.3036C174.654 85.9396 174.518 91.2476 174.518 91.2476C174.518 91.2476 173.93 93.3396 172.378 92.5636C170.826 91.7876 168.19 89.0556 166.33 90.2636C164.398 91.5156 164.426 94.0916 165.894 97.4356C167.362 100.78 170.294 108.236 170.566 108.908C170.838 109.58 170.742 110.688 169.602 110.332C168.462 109.976 161.202 105.6 159.322 109.2C157.442 112.8 163.066 121.424 163.662 122.4C164.258 123.376 164.19 123.864 163.774 124.272C163.358 124.68 161.054 122.98 158.934 121.82C156.814 120.66 154.154 119.688 153.25 121.84C152.346 123.992 154.05 131.248 164.378 140.732L169.442 144.812L175.574 142.556C188.402 136.924 192.374 130.62 192.222 128.288C192.07 125.956 189.23 126 186.85 126.4C184.47 126.8 181.738 127.676 181.474 127.152C181.21 126.628 181.314 126.128 182.19 125.416C183.066 124.704 191.182 118.336 190.57 114.348C189.958 110.36 181.662 112.148 180.466 112.116C179.27 112.084 179.558 111 180.014 110.46C180.47 109.92 185.682 103.812 188.154 101.12C190.626 98.4276 191.486 96.0036 190.066 94.1916C188.698 92.4436 185.314 94.1916 183.598 94.4076C181.882 94.6236 181.998 92.4716 181.998 92.4716C181.998 92.4716 183.598 87.4076 185.106 77.7356C186.614 68.0636 182.146 67.8956 182.146 67.8956" fill="#F5F5F5"/>
    <path d="M180.704 76.54C180.704 76.54 180.704 76.608 180.684 76.744L180.6 77.34C180.52 77.872 180.404 78.644 180.26 79.628C179.96 81.628 179.508 84.492 178.936 88.028C177.792 95.128 176.136 104.92 174.26 115.732C172.384 126.544 170.7 136.332 169.52 143.424C168.932 146.964 168.456 149.824 168.128 151.824C167.964 152.808 167.836 153.576 167.728 154.108C167.684 154.36 167.652 154.556 167.624 154.7C167.596 154.844 167.58 154.9 167.58 154.9C167.58 154.9 167.58 154.832 167.604 154.696C167.628 154.56 167.652 154.356 167.684 154.1C167.764 153.568 167.88 152.796 168.024 151.812C168.324 149.812 168.776 146.948 169.348 143.412C170.492 136.312 172.148 126.516 174.028 115.704C175.908 104.892 177.6 95.108 178.8 88C179.384 84.46 179.86 81.6 180.192 79.6L180.572 77.316L180.676 76.724C180.682 76.6623 180.692 76.6008 180.704 76.54Z" fill="#E0E0E0"/>
    <path d="M169.865 94.248C169.975 94.3609 170.071 94.4857 170.153 94.6201C170.329 94.8681 170.553 95.2321 170.865 95.6841C171.453 96.5921 172.233 97.8641 173.065 99.2841L175.181 102.936L175.825 104.036C175.917 104.159 175.991 104.293 176.045 104.436C175.934 104.325 175.836 104.202 175.753 104.068C175.581 103.82 175.353 103.456 175.049 103C174.469 102.084 173.705 100.8 172.873 99.38L170.733 95.744C170.477 95.312 170.257 94.944 170.081 94.644C169.992 94.5221 169.919 94.389 169.865 94.248Z" fill="#E0E0E0"/>
    <path d="M176.653 104.4C176.617 104.348 179.173 102.628 182.361 100.552C185.549 98.4757 188.153 96.8397 188.189 96.8877C188.225 96.9357 185.665 98.6637 182.481 100.736C179.297 102.808 176.685 104.444 176.653 104.4Z" fill="#E0E0E0"/>
    <path d="M173.029 120.864C173.173 120.793 173.325 120.738 173.481 120.7L174.729 120.32C175.781 119.996 177.237 119.548 178.821 118.992C180.405 118.436 181.829 117.896 182.857 117.492L184.057 117.016C184.203 116.945 184.358 116.894 184.517 116.864C184.385 116.955 184.242 117.031 184.093 117.092C183.813 117.228 183.409 117.416 182.893 117.636C181.885 118.076 180.469 118.648 178.893 119.2C177.317 119.752 175.837 120.184 174.773 120.468C174.237 120.608 173.805 120.716 173.501 120.784C173.348 120.829 173.189 120.856 173.029 120.864Z" fill="#E0E0E0"/>
    <path d="M164.869 113.04C164.997 113.116 165.115 113.207 165.221 113.312C165.437 113.496 165.749 113.768 166.125 114.112C166.885 114.796 167.921 115.748 169.057 116.808C170.193 117.868 171.213 118.84 171.945 119.552C172.309 119.904 172.601 120.196 172.801 120.4C172.912 120.5 173.011 120.611 173.097 120.732C172.968 120.66 172.849 120.57 172.745 120.464C172.525 120.28 172.217 120.004 171.837 119.664C171.081 118.984 170.041 118.032 168.905 116.968C167.769 115.904 166.749 114.936 166.021 114.228C165.653 113.872 165.361 113.584 165.161 113.376C165.05 113.277 164.952 113.164 164.869 113.04Z" fill="#E0E0E0"/>
    <path d="M159.82 127.176C159.982 127.277 160.132 127.395 160.268 127.528L161.432 128.548C162.408 129.416 163.744 130.624 165.216 131.972C166.688 133.32 168.016 134.548 168.956 135.444L170.072 136.516C170.219 136.635 170.353 136.769 170.472 136.916C170.313 136.812 170.163 136.694 170.024 136.564L168.86 135.544C167.884 134.676 166.548 133.464 165.076 132.12C163.604 130.776 162.276 129.544 161.34 128.648L160.212 127.6C160.067 127.473 159.936 127.33 159.82 127.176Z" fill="#E0E0E0"/>
    <path d="M170.273 136.624C170.455 136.534 170.643 136.461 170.837 136.404L172.405 135.864C173.725 135.408 175.549 134.772 177.545 134.016C179.541 133.26 181.329 132.532 182.617 131.996L184.149 131.36C184.333 131.273 184.525 131.204 184.721 131.152C184.553 131.266 184.374 131.363 184.185 131.44C183.833 131.612 183.321 131.84 182.681 132.136C181.405 132.712 179.621 133.464 177.621 134.22C175.621 134.976 173.789 135.6 172.453 136C171.785 136.208 171.253 136.368 170.853 136.472C170.666 136.541 170.471 136.592 170.273 136.624Z" fill="#E0E0E0"/>
    <path d="M41.5368 153.34C50.7728 148.068 53.7728 136.996 54.3368 126.848C54.4328 125.152 54.7808 123.392 54.1128 121.836C53.4448 120.28 51.6648 119.096 50.0888 119.668C48.7808 120.144 48.0888 121.552 47.2888 122.696C46.2032 124.218 44.71 125.404 42.9808 126.116C41.8528 126.576 40.4928 126.792 39.4848 126.116C38.1008 125.176 38.1128 123.148 38.2848 121.472C38.6021 118.336 38.9075 115.205 39.2008 112.08C39.3648 110.404 39.5288 108.688 39.1528 107.048C38.7768 105.408 37.7568 103.816 36.2008 103.224C34.6448 102.632 32.5808 103.368 32.0928 104.984C31.8928 105.656 31.9648 106.376 31.8568 107.068C31.7488 107.76 31.3488 108.504 30.6568 108.64C29.9648 108.776 29.3408 108.24 28.8848 107.7C27.2848 105.844 26.3288 103.552 25.4288 101.276C24.5288 98.9996 23.6648 96.6836 22.2608 94.6876C20.8568 92.6916 18.8168 91.0316 16.4248 90.6876C14.0328 90.3436 11.3328 91.5836 10.5088 93.8876C9.68479 96.1916 10.8408 98.7276 12.1688 100.784C14.1496 103.826 16.5583 106.568 19.3208 108.924C19.8168 109.348 20.3528 109.808 20.5208 110.448C20.7808 111.6 19.6168 112.616 18.4808 112.884C17.1888 113.184 15.8408 112.928 14.5208 112.964C13.2008 113 11.7208 113.444 11.1168 114.64C10.2688 116.292 11.5408 118.24 12.8448 119.532C15.4581 122.152 18.5042 124.303 21.8488 125.888C23.0488 126.452 24.3048 126.968 25.2208 127.928C26.1368 128.888 26.6008 130.452 25.8928 131.58C25.1848 132.708 23.6008 132.948 22.3288 132.588C21.0568 132.228 19.9648 131.42 18.7728 130.844C16.5448 129.76 13.5488 129.644 11.8888 131.504C10.7968 132.728 10.5448 134.544 10.8928 136.152C11.2408 137.76 12.1128 139.2 13.0568 140.552C16.3568 145.232 20.7728 149.16 25.9608 151.508C31.1488 153.856 36.0568 154.812 41.5608 153.356" fill="#F5F5F5"/>
    <path d="M40.9842 160.852C40.9418 156.117 40.4476 151.397 39.5082 146.756C38.3317 141.09 36.8319 135.495 35.0162 130L33.6002 125.676C33.1362 124.256 32.6762 122.848 32.1402 121.496C31.1096 118.906 29.9358 116.376 28.6242 113.916C27.3882 111.56 26.1322 109.368 24.9402 107.328C23.7482 105.288 22.5922 103.408 21.4722 101.728C19.7144 99.0918 17.7509 96.5987 15.6002 94.272C14.8402 93.448 14.2322 92.828 13.8122 92.412L13.3322 91.944C13.2791 91.8887 13.2215 91.8379 13.1602 91.792C13.2052 91.8529 13.2547 91.9104 13.3082 91.964L13.7682 92.456C14.1682 92.884 14.7682 93.52 15.5122 94.356C17.6241 96.7118 19.5562 99.2228 21.2922 101.868C22.4002 103.536 23.5362 105.42 24.7202 107.468C25.9042 109.516 27.1522 111.708 28.3762 114.06C29.6779 116.508 30.8463 119.025 31.8762 121.6C32.4042 122.944 32.8602 124.34 33.3202 125.764C33.7802 127.188 34.2482 128.628 34.7242 130.092C36.5336 135.574 38.0373 141.152 39.2282 146.8C40.1767 151.422 40.6909 156.122 40.7642 160.84" fill="#E0E0E0"/>
    <path d="M32.0684 121.476C32.1746 121.253 32.2525 121.018 32.3004 120.776C32.4324 120.32 32.5964 119.656 32.7924 118.828C33.1924 117.176 33.6444 114.88 34.1444 112.344C34.6444 109.808 35.1364 107.524 35.5524 105.884C35.7564 105.084 35.9284 104.4 36.0564 103.944C36.1345 103.71 36.1881 103.469 36.2164 103.224C36.0978 103.44 36.0052 103.67 35.9404 103.908C35.7804 104.356 35.5724 105.012 35.3404 105.828C34.8684 107.456 34.3404 109.744 33.8404 112.284L32.5764 118.8C32.4204 119.58 32.2884 120.24 32.1764 120.768C32.1181 121 32.082 121.237 32.0684 121.476Z" fill="#E0E0E0"/>
    <path d="M10.8457 115.464C11.124 115.548 11.409 115.608 11.6977 115.644C12.2497 115.744 13.0457 115.884 14.0217 116.092C15.9817 116.492 18.6657 117.124 21.5977 117.96C24.5297 118.796 27.1497 119.676 29.0297 120.36C29.9697 120.696 30.7257 120.988 31.2457 121.188C31.5104 121.309 31.7864 121.404 32.0697 121.472C31.8249 121.313 31.5649 121.179 31.2937 121.072C30.7857 120.836 30.0417 120.512 29.1057 120.148C27.2417 119.404 24.6257 118.496 21.6817 117.652C19.1742 116.925 16.6308 116.328 14.0617 115.864C13.0777 115.688 12.2737 115.58 11.7177 115.516C11.4296 115.469 11.1374 115.451 10.8457 115.464Z" fill="#E0E0E0"/>
    <path d="M39.2773 146.336C39.3469 146.261 39.4049 146.176 39.4493 146.084L39.8933 145.336C40.2933 144.688 40.7973 143.736 41.4293 142.536C42.6973 140.16 44.3493 136.82 46.1173 133.108C47.8853 129.396 49.5013 126.044 50.7213 123.644L52.1693 120.804L52.5693 120.028C52.6186 119.939 52.6575 119.846 52.6853 119.748C52.6178 119.823 52.56 119.906 52.5133 119.996L52.0733 120.744C51.6933 121.396 51.1693 122.344 50.5333 123.544C49.2653 125.924 47.6173 129.264 45.8453 132.976C44.0733 136.688 42.4613 140.036 41.2453 142.44L39.7933 145.28L39.3933 146.056C39.3419 146.144 39.3029 146.238 39.2773 146.336Z" fill="#E0E0E0"/>
    <path d="M11.4297 134.456C11.5143 134.517 11.6072 134.566 11.7057 134.6L12.5057 134.956L15.4857 136.236C18.0057 137.312 21.4857 138.844 25.2857 140.54C29.0857 142.236 32.5817 143.74 35.1257 144.764C36.3937 145.28 37.4257 145.684 38.1457 145.964L38.9737 146.268C39.0688 146.307 39.1681 146.335 39.2697 146.352C39.187 146.29 39.0953 146.242 38.9977 146.208L38.1977 145.852L35.2017 144.56C32.6817 143.48 29.2017 141.948 25.4017 140.252C21.6017 138.556 18.1017 137.052 15.5617 136.028C14.2937 135.516 13.2617 135.108 12.5417 134.828L11.7137 134.528C11.6222 134.493 11.5268 134.469 11.4297 134.456Z" fill="#E0E0E0"/>
    <path d="M69.9366 32.6797V65.6957H22.5206L20.8086 32.9117L69.9366 32.6797Z" fill="#E0E0E0"/>
    <path d="M20.8086 32.9159L20.8086 65.6279H68.2246V32.9159H20.8086Z" fill="white"/>
    <path d="M68.452 65.852H20.584V32.688H68.452V65.852ZM21.036 65.4H68V33.136H21.036V65.4Z" fill="#E0E0E0"/>
    <path d="M63.0893 36.9756H25.9453V61.5596H63.0893V36.9756Z" fill="white"/>
    <path d="M63.0929 36.9758H62.9089H62.4009H60.3609L52.7049 37.0038L25.9649 37.0558L26.0729 36.9478C26.0729 44.4598 26.0729 52.7838 26.0729 61.5318L25.9529 61.4158L63.1009 61.4398L63.0049 61.5318C63.0289 54.0518 63.0449 47.9318 63.0569 43.6478C63.0569 41.5118 63.0569 39.8438 63.0809 38.6998C63.0809 38.1358 63.0809 37.6998 63.0809 37.3998C63.0809 37.0998 63.0809 36.9478 63.0809 36.9478C63.0809 36.9478 63.0809 37.0918 63.0809 37.3798C63.0809 37.6678 63.0809 38.1038 63.0809 38.6638C63.0809 39.7998 63.0809 41.4638 63.1049 43.5878C63.1049 47.8718 63.1329 54.0278 63.1529 61.5318V61.6278H63.0609L25.9129 61.6518H25.7969V61.5318C25.7969 52.7838 25.7969 44.4598 25.7969 36.9478V36.8398H25.9009L52.7009 36.8958L60.3329 36.9238H62.3329H62.8489L63.0929 36.9758Z" fill="#E0E0E0"/>
    <path d="M20.8014 32.8198C21.0876 32.9643 21.3561 33.1415 21.6014 33.3478C22.0894 33.6998 22.7494 34.1958 23.4614 34.7678C24.1734 35.3398 24.8014 35.8758 25.2454 36.2798C25.4996 36.482 25.7268 36.7159 25.9214 36.9758C25.8774 37.0278 24.7214 36.0878 23.3134 34.9478C21.9054 33.8078 20.7694 32.8758 20.8014 32.8198Z" fill="#E0E0E0"/>
    <path d="M68.2298 32.9116C67.4497 33.6784 66.6155 34.3883 65.7338 35.0356C64.9015 35.7449 64.0192 36.3932 63.0938 36.9756C63.879 36.2098 64.7171 35.5001 65.6018 34.8516C66.4305 34.1433 67.3088 33.495 68.2298 32.9116Z" fill="#E0E0E0"/>
    <path d="M68.1883 65.6318L62.9043 61.3438L68.1883 65.6318Z" fill="white"/>
    <path d="M62.9055 61.3438C63.8549 61.9675 64.7621 62.653 65.6215 63.3958C66.5284 64.0805 67.3865 64.8277 68.1895 65.6318C67.2391 65.0096 66.3317 64.3241 65.4735 63.5797C64.0015 62.3997 62.8615 61.3957 62.9055 61.3438Z" fill="#E0E0E0"/>
    <path d="M20.8008 65.628C21.5735 64.8685 22.3996 64.1654 23.2728 63.524C24.0971 62.8232 24.9699 62.1816 25.8848 61.604C25.1141 62.3641 24.2893 63.0673 23.4168 63.708C22.5912 64.4089 21.717 65.0505 20.8008 65.628Z" fill="#E0E0E0"/>
    <path d="M33.4883 44.6396V52.8716H56.8603V44.6396H33.4883Z" fill="#E0E0E0"/>
    <path d="M179.424 25.0078H154.072V61.7558H179.424V25.0078Z" fill="#E0E0E0"/>
    <path d="M178.157 25.0078H152.805V61.7558H178.157V25.0078Z" fill="#F5F5F5"/>
    <path d="M175.005 28.9878H155.953V57.7758H175.005V28.9878Z" fill="white"/>
    <path d="M175.008 57.7759C175.008 57.7759 175.008 57.7279 175.008 57.6359V57.2359C175.008 56.8719 175.008 56.3439 175.008 55.6559C175.008 54.2759 175.008 52.2679 175.008 49.7279C175.008 44.6519 174.988 37.4599 174.976 28.9959L175.04 29.0599H155.952L156.024 28.9919C156.024 39.8519 156.024 49.8639 156.024 57.7919L155.968 57.7359L169.836 57.7679H173.668H174.676H175.024H174.688H173.692H169.872L155.968 57.7959H155.916V57.7399C155.916 49.8239 155.916 39.8119 155.916 28.9399V28.9199H175.116V28.9839C175.116 37.4679 175.096 44.6679 175.088 49.7559C175.088 52.2879 175.088 54.2959 175.068 55.6719C175.068 56.3519 175.068 56.8719 175.068 57.2359V57.6359C175.008 57.7319 175.008 57.7759 175.008 57.7759Z" fill="#E0E0E0"/>
    <path d="M178.229 25.0078C178.101 25.2408 177.949 25.4606 177.777 25.6638C177.481 26.0638 177.065 26.5958 176.577 27.1758C176.089 27.7558 175.653 28.2678 175.321 28.6318C175.157 28.8368 174.975 29.0269 174.777 29.1998C174.92 28.9754 175.082 28.7639 175.261 28.5678L176.481 27.0878C176.941 26.5238 177.361 26.0158 177.701 25.6038C177.86 25.3905 178.037 25.1911 178.229 25.0078Z" fill="#E0E0E0"/>
    <path d="M175.105 57.6836C176.054 59.189 177.169 60.5831 178.429 61.8396L175.105 57.6836Z" fill="white"/>
    <path d="M178.43 61.8396C178.219 61.684 178.024 61.507 177.85 61.3116C177.51 60.9676 177.05 60.4756 176.594 59.8996C176.202 59.4063 175.835 58.8936 175.494 58.3636C175.338 58.1511 175.204 57.9233 175.094 57.6836C175.266 57.8804 175.422 58.0904 175.562 58.3116C175.842 58.7116 176.234 59.2396 176.69 59.8116C177.146 60.3836 177.586 60.8876 177.91 61.2436C178.097 61.4295 178.271 61.6287 178.43 61.8396Z" fill="#E0E0E0"/>
    <path d="M152.801 61.7278L156.121 57.6318L152.801 61.7278Z" fill="white"/>
    <path d="M156.121 57.6318C155.625 58.3573 155.091 59.0556 154.521 59.7238C153.601 60.8518 152.833 61.7518 152.805 61.7238C153.301 60.9991 153.835 60.3008 154.405 59.6318C154.942 58.9359 155.514 58.2683 156.121 57.6318Z" fill="#E0E0E0"/>
    <path d="M152.801 25.0078C153.368 25.6213 153.902 26.2649 154.401 26.9358C154.937 27.5753 155.439 28.2429 155.905 28.9358C155.336 28.3235 154.802 27.6798 154.305 27.0078C153.768 26.3683 153.266 25.7007 152.801 25.0078Z" fill="#E0E0E0"/>
    <path d="M169.073 34.832H162.693V52.944H169.073V34.832Z" fill="#E0E0E0"/>
    <path d="M184.773 174.8C184.773 174.86 145.805 174.904 97.7526 174.904C49.7006 174.904 10.7246 174.876 10.7246 174.8C10.7246 174.724 49.6806 174.696 97.7526 174.696C145.825 174.696 184.773 174.76 184.773 174.8Z" fill="#263238"/>
    <path d="M135.88 105.6C136.177 106.808 136.142 108.074 135.78 109.263C135.417 110.453 134.74 111.523 133.82 112.36C132.6 113.44 131.02 114.064 129.9 115.252C129.206 116.042 128.73 116.998 128.516 118.027C128.302 119.056 128.359 120.123 128.68 121.124C129.02 122.118 129.557 123.032 130.261 123.811C130.964 124.591 131.818 125.22 132.772 125.66C134.07 126.267 135.493 126.557 136.925 126.509C138.357 126.46 139.758 126.073 141.012 125.38C144.104 123.632 145.904 120.124 146.36 116.6C146.816 113.076 146.1 109.508 145.16 106.08" fill="#263238"/>
    <path d="M174.877 167.74C178.077 167.216 181.077 168.192 184.245 168.892C185.087 169.142 185.977 169.19 186.841 169.032C188.213 168.68 189.189 167.344 189.401 165.944C189.572 164.718 189.288 163.471 188.601 162.44C188.325 161.996 187.95 161.622 187.505 161.348C187.028 161.109 186.506 160.973 185.973 160.948C184.032 160.792 182.084 161.159 180.333 162.012C178.587 162.861 177.003 164.009 175.653 165.404C174.981 166.076 175.181 166.848 174.885 167.752" fill="#263238"/>
    <path d="M110 116.092C109.487 113.041 109.549 109.921 110.184 106.892C110.452 105.411 110.995 103.994 111.784 102.712C112.053 102.234 112.462 101.85 112.956 101.612C113.314 101.493 113.699 101.476 114.067 101.563C114.435 101.65 114.772 101.837 115.04 102.104C115.381 102.46 115.644 102.884 115.809 103.349C115.975 103.814 116.04 104.309 116 104.8C115.928 105.784 115.687 106.747 115.288 107.648C114.052 110.808 112.088 113.432 109.988 116.088" fill="#263238"/>
    <path d="M172 127.656C172.724 128.172 188.8 162.8 188.8 162.8C187.507 162.248 186.075 162.108 184.7 162.4L183.828 162.632C182.267 163.052 180.787 163.728 179.448 164.632L174.86 167.736C174.679 167.632 174.525 167.485 174.411 167.309C174.298 167.133 174.228 166.933 174.208 166.724V166.592L161.744 145.2L172 127.656Z" fill="#64A172"/>
    <path d="M111.637 72.5283L115.925 96.9003C117.305 100.18 133.397 120.052 133.397 120.052L128.077 137.148L106.941 108.248C104.978 106.15 103.911 103.369 103.965 100.496L104.789 74.4563L111.637 72.5283Z" fill="#FFBE9D"/>
    <path d="M134.456 116.232L145.492 117.068L155.116 118.56C155.116 118.56 172 122.996 172 124.844C172 125.46 167.392 140.136 164.8 148.928C164.164 151.064 163.646 153.232 163.248 155.424L172 174.8H130.468V157.036C130.468 157.036 127.668 133.704 128.16 130.516C128.652 127.328 134.456 116.232 134.456 116.232Z" fill="#64A172"/>
    <path d="M106.4 62.68C106.465 60.1976 106.719 57.7238 107.16 55.28C107.22 54.932 107.364 54.512 107.712 54.48C108.144 54.424 108.364 54.98 108.42 55.412C108.804 58.3592 108.681 61.3503 108.056 64.256" fill="#EB996E"/>
    <path d="M111.32 114.288C116.056 110.056 116.216 102.724 115.812 96.8885C115.733 96.4623 115.798 96.0219 115.996 95.6365C116.448 94.9925 117.548 95.2365 117.996 95.8845C118.444 96.5325 118.396 97.3845 118.372 98.1685C118.616 97.5045 119.656 97.5085 120.144 98.0205C120.632 98.5325 120.716 99.3045 120.772 100.02L120.928 101.972L134.764 115.172C134.764 115.172 135.144 113.972 136.16 114.664C137.176 115.356 137.832 116.816 137.832 116.816L128.084 138.092L109.992 116.092C110.34 115.427 110.788 114.818 111.32 114.288Z" fill="#64A172"/>
    <path d="M130.096 129.012C128.653 130.853 127.575 132.954 126.92 135.2C126.268 137.461 126.34 139.87 127.124 142.088C127.731 143.623 128.726 144.975 130.011 146.011C131.296 147.047 132.828 147.732 134.456 148" fill="#64A172"/>
    <path d="M174.876 167.74L174.352 174.8H184.604L187.004 162.3C183.881 162.126 180.801 163.091 178.336 165.016L174.876 167.74Z" fill="#FFBE9D"/>
    <path d="M116.141 112.724C116.199 112.53 116.273 112.341 116.365 112.16C116.525 111.808 116.741 111.288 116.985 110.64C117.229 109.992 117.489 109.208 117.733 108.328C117.986 107.368 118.188 106.394 118.337 105.412C118.475 104.43 118.559 103.44 118.589 102.448C118.589 101.532 118.589 100.708 118.533 100.016C118.477 99.3243 118.425 98.7683 118.373 98.3843C118.338 98.1847 118.319 97.9828 118.317 97.7803C118.382 97.9726 118.427 98.1709 118.453 98.3723C118.556 98.912 118.632 99.4567 118.681 100.004C118.759 100.818 118.792 101.635 118.781 102.452C118.75 104.456 118.46 106.447 117.917 108.376C117.693 109.163 117.426 109.936 117.117 110.692C116.853 111.34 116.617 111.852 116.433 112.196C116.349 112.379 116.251 112.556 116.141 112.724Z" fill="#263238"/>
    <path d="M119.152 109.008C119.283 108.664 119.432 108.328 119.6 108C120.301 106.377 120.707 104.642 120.8 102.876C120.8 102.509 120.82 102.142 120.86 101.776C120.939 102.139 120.969 102.51 120.948 102.88C120.935 104.674 120.525 106.443 119.748 108.06C119.596 108.403 119.395 108.722 119.152 109.008Z" fill="#263238"/>
    <path d="M135.401 120.072C135.369 120.052 135.537 119.792 135.633 119.32C135.745 118.703 135.709 118.069 135.529 117.468C135.339 116.864 135.107 116.273 134.837 115.7C134.71 115.463 134.616 115.21 134.557 114.948C134.715 115.162 134.849 115.393 134.957 115.636C135.273 116.2 135.529 116.795 135.721 117.412C135.912 118.044 135.927 118.716 135.765 119.356C135.714 119.625 135.588 119.873 135.401 120.072Z" fill="#263238"/>
    <path d="M132.996 148.248C132.934 148.222 132.875 148.189 132.82 148.148L132.348 147.836C131.948 147.56 131.352 147.156 130.656 146.608C129.834 145.975 129.081 145.254 128.412 144.46C127.589 143.477 126.996 142.322 126.676 141.08C126.371 139.838 126.292 138.551 126.444 137.28C126.567 136.25 126.796 135.235 127.128 134.252C127.349 133.59 127.604 132.94 127.892 132.304C127.996 132.08 128.076 131.904 128.136 131.792C128.163 131.73 128.196 131.671 128.236 131.616C128.161 131.861 128.071 132.101 127.968 132.336C127.788 132.792 127.528 133.456 127.268 134.296C126.961 135.275 126.75 136.281 126.636 137.3C126.497 138.547 126.578 139.809 126.876 141.028C127.188 142.242 127.763 143.373 128.56 144.34C129.215 145.13 129.948 145.851 130.748 146.492C131.428 147.052 132 147.472 132.396 147.772L132.844 148.116C132.948 148.196 133 148.24 132.996 148.248Z" fill="#263238"/>
    <path d="M155.656 152.204C155.601 152.233 155.542 152.255 155.48 152.268C155.36 152.3 155.188 152.36 154.96 152.404C154.316 152.544 153.66 152.623 153 152.64C150.802 152.708 148.634 152.115 146.776 150.94C144.917 149.764 143.453 148.059 142.572 146.044C142.304 145.441 142.093 144.815 141.944 144.172C141.888 143.944 141.868 143.772 141.844 143.644C141.83 143.582 141.824 143.519 141.824 143.456C141.906 143.682 141.973 143.913 142.024 144.148C142.661 146.563 144.087 148.696 146.075 150.208C148.063 151.72 150.499 152.524 152.996 152.492C153.649 152.489 154.301 152.433 154.944 152.324C155.179 152.272 155.417 152.232 155.656 152.204Z" fill="#263238"/>
    <path d="M162.02 160C161.964 160 162.492 157.772 163.22 155.06C163.948 152.348 164.548 150.16 164.6 150.176C164.652 150.192 164.128 152.4 163.42 155.112C162.712 157.824 162.076 160 162.02 160Z" fill="#263238"/>
    <path d="M167.601 155.284C167.553 155.312 165.945 152.684 164.001 149.412C162.057 146.14 160.537 143.464 160.589 143.436C160.641 143.408 162.245 146.032 164.189 149.304C166.133 152.576 167.669 155.252 167.601 155.284Z" fill="#263238"/>
    <path d="M182.956 161.76C182.956 161.8 182.372 161.848 181.46 162.08C180.927 162.215 180.404 162.388 179.896 162.596C179.275 162.859 178.676 163.169 178.104 163.524C177.539 163.888 177.004 164.296 176.504 164.744C176.096 165.117 175.715 165.518 175.364 165.944C174.764 166.668 174.468 167.176 174.432 167.144C174.48 167.015 174.543 166.891 174.62 166.776C174.805 166.449 175.015 166.136 175.248 165.84C175.589 165.398 175.965 164.984 176.372 164.6C176.875 164.134 177.418 163.714 177.996 163.344C178.578 162.982 179.191 162.672 179.828 162.416C180.347 162.211 180.882 162.049 181.428 161.932C181.793 161.847 182.165 161.791 182.54 161.764C182.677 161.743 182.818 161.741 182.956 161.76Z" fill="#263238"/>
    <path d="M184.917 159.352C184.917 159.396 184.273 159.316 183.245 159.404C182.646 159.458 182.051 159.563 181.469 159.716C180.042 160.095 178.707 160.764 177.549 161.68C177.078 162.055 176.639 162.469 176.237 162.916C175.553 163.684 175.233 164.244 175.197 164.224C175.251 164.083 175.322 163.948 175.409 163.824C175.495 163.67 175.591 163.522 175.697 163.38C175.83 163.187 175.973 163 176.125 162.82C176.518 162.351 176.955 161.92 177.429 161.532C178.016 161.062 178.648 160.652 179.317 160.308C179.99 159.979 180.695 159.719 181.421 159.532C182.016 159.383 182.625 159.29 183.237 159.256C183.47 159.245 183.704 159.245 183.937 159.256C184.115 159.257 184.293 159.268 184.469 159.288C184.621 159.291 184.771 159.312 184.917 159.352Z" fill="#263238"/>
    <g opacity="0.3">
      <path d="M142.253 144.308C141.313 146.092 142.097 148.268 142.957 150.096C143.625 151.512 144.369 152.948 145.585 153.936C146.182 154.425 146.875 154.784 147.618 154.992C148.361 155.201 149.139 155.253 149.903 155.147C150.668 155.04 151.402 154.776 152.059 154.373C152.717 153.969 153.284 153.433 153.725 152.8C151.175 152.762 148.696 151.954 146.615 150.481C144.533 149.008 142.945 146.94 142.061 144.548" fill="black"/>
    </g>
    <g opacity="0.3">
      <path d="M160.617 143.436C159.897 145.152 160.441 147.5 161.193 149.2C161.945 150.9 162.745 152.8 162.141 154.564C161.985 154.877 161.905 155.222 161.905 155.572C161.945 155.916 162.305 156.248 162.629 156.088C162.797 155.97 162.913 155.793 162.953 155.592C163.521 153.88 164.09 152.169 164.661 150.46" fill="black"/>
    </g>
    <path d="M122.777 120.84C122.842 119.766 122.975 118.697 123.177 117.64C123.313 116.578 123.518 115.525 123.789 114.488C123.729 115.563 123.595 116.632 123.389 117.688C123.255 118.751 123.05 119.804 122.777 120.84Z" fill="#FAFAFA"/>
    <path d="M118.565 119.956C118.513 119.988 117.825 119.028 116.873 117.924C115.921 116.82 115.081 115.992 115.121 115.948C115.838 116.473 116.481 117.092 117.033 117.788C117.635 118.441 118.15 119.17 118.565 119.956Z" fill="#FAFAFA"/>
    <path d="M122.181 109.652C122.061 109.91 121.909 110.152 121.729 110.372C121.425 110.8 120.981 111.372 120.465 111.972C119.949 112.572 119.453 113.112 119.081 113.48C118.894 113.694 118.681 113.882 118.445 114.04C118.401 114 119.277 113.052 120.305 111.836C121.333 110.62 122.133 109.6 122.181 109.652Z" fill="#FAFAFA"/>
    <path d="M120.768 104.616C120.708 104.64 120.48 104.132 120.04 103.624C119.6 103.116 119.128 102.824 119.16 102.764C119.576 102.884 119.94 103.139 120.196 103.488C120.503 103.792 120.704 104.188 120.768 104.616Z" fill="#FAFAFA"/>
    <path d="M117.12 107.72C116.793 107.874 116.44 107.965 116.08 107.988C115.73 108.079 115.366 108.101 115.008 108.052C115.684 107.807 116.401 107.694 117.12 107.72Z" fill="#FAFAFA"/>
    <path d="M126.325 112.164C126.334 111.542 126.29 110.919 126.193 110.304C126.027 109.703 125.809 109.119 125.541 108.556C125.995 109.022 126.292 109.618 126.393 110.26C126.564 110.887 126.54 111.551 126.325 112.164Z" fill="#FAFAFA"/>
    <path d="M131.24 117.452C130.502 116.275 129.511 115.276 128.34 114.528C129.001 114.785 129.601 115.178 130.101 115.682C130.601 116.185 130.989 116.789 131.24 117.452Z" fill="#FAFAFA"/>
    <path d="M123.348 126.628C122.157 127.074 120.838 127.03 119.68 126.504C119.7 126.444 120.48 126.696 121.508 126.724C122.536 126.752 123.332 126.568 123.348 126.628Z" fill="#FAFAFA"/>
    <path d="M127.053 128.528C126.997 128.504 127.309 127.584 127.569 126.416C127.829 125.248 127.945 124.28 128.005 124.288C128.047 125.02 127.968 125.754 127.773 126.46C127.646 127.184 127.403 127.882 127.053 128.528Z" fill="#FAFAFA"/>
    <path d="M135.145 128.836C134.512 128.302 133.927 127.713 133.397 127.076C132.812 126.487 132.276 125.85 131.797 125.172C132.427 125.71 133.011 126.3 133.541 126.936C134.126 127.526 134.662 128.161 135.145 128.836Z" fill="#FAFAFA"/>
    <path d="M138.913 138.184C138.433 137.836 137.996 137.433 137.609 136.984C137.175 136.581 136.789 136.13 136.457 135.64C136.937 135.988 137.374 136.391 137.761 136.84C138.198 137.239 138.585 137.691 138.913 138.184Z" fill="#FAFAFA"/>
    <path d="M131.909 139.076C131.449 139.566 130.908 139.972 130.309 140.276C129.746 140.651 129.12 140.922 128.461 141.076C128.461 141.02 129.261 140.676 130.205 140.112C131.149 139.548 131.869 139.028 131.909 139.076Z" fill="#FAFAFA"/>
    <path d="M135.133 147.968C135.073 147.968 135.313 146.904 135.433 145.588C135.553 144.272 135.517 143.188 135.577 143.188C135.723 143.98 135.745 144.79 135.641 145.588C135.601 146.404 135.429 147.207 135.133 147.968Z" fill="#FAFAFA"/>
    <path d="M147.256 140.74C146.595 140.336 146.009 139.82 145.524 139.216C144.989 138.658 144.557 138.009 144.248 137.3C144.3 137.264 144.848 138.132 145.68 139.08C146.512 140.028 147.296 140.688 147.256 140.74Z" fill="#FAFAFA"/>
    <path d="M151.684 144.512C151.636 144.476 152.244 143.644 152.924 142.576C153.604 141.508 154.104 140.612 154.156 140.64C153.897 141.368 153.54 142.056 153.096 142.688C152.712 143.359 152.237 143.973 151.684 144.512Z" fill="#FAFAFA"/>
    <path d="M151.037 135.28C149.671 135.053 148.331 134.692 147.037 134.2C147.727 134.282 148.407 134.43 149.069 134.64C149.745 134.787 150.404 135.001 151.037 135.28Z" fill="#FAFAFA"/>
    <path d="M147.809 151.528C147.738 150.898 147.738 150.262 147.809 149.632C147.814 148.998 147.887 148.367 148.025 147.748C148.097 148.378 148.097 149.014 148.025 149.644C148.021 150.278 147.949 150.91 147.809 151.528Z" fill="#FAFAFA"/>
    <path d="M150.6 157.092C150.809 156.446 150.977 155.787 151.104 155.12C151.159 154.443 151.17 153.763 151.136 153.084C151.342 153.75 151.401 154.452 151.308 155.143C151.216 155.834 150.974 156.496 150.6 157.084V157.092Z" fill="#FAFAFA"/>
    <path d="M143.365 155.864C143.161 154.482 143.432 153.072 144.133 151.864C144.189 151.888 143.821 152.732 143.613 153.84C143.405 154.948 143.425 155.86 143.365 155.864Z" fill="#FAFAFA"/>
    <path d="M136.765 158.684C136.113 158.231 135.486 157.744 134.885 157.224C134.243 156.759 133.625 156.261 133.033 155.732C133.752 156.091 134.424 156.537 135.033 157.06C135.672 157.532 136.253 158.077 136.765 158.684Z" fill="#FAFAFA"/>
    <path d="M141.232 150.836C139.701 151.808 138.096 152.657 136.432 153.376C137.185 152.858 137.976 152.398 138.8 152C139.579 151.55 140.392 151.161 141.232 150.836Z" fill="#FAFAFA"/>
    <path d="M155.116 168.448C155.031 168.476 154.944 168.497 154.856 168.512C154.684 168.544 154.432 168.608 154.104 168.652C153.178 168.794 152.241 168.854 151.304 168.832C148.884 168.797 146.499 168.249 144.307 167.225C142.114 166.2 140.164 164.722 138.584 162.888C137.667 161.833 136.881 160.67 136.244 159.424C135.81 158.59 135.451 157.718 135.172 156.82C135.064 156.504 135.012 156.252 134.964 156.084C134.937 155.998 134.92 155.91 134.912 155.82C134.95 155.901 134.981 155.986 135.004 156.072C135.064 156.236 135.128 156.472 135.248 156.796C136.015 158.989 137.197 161.014 138.728 162.76C140.312 164.552 142.244 166.001 144.407 167.02C146.57 168.039 148.918 168.605 151.308 168.684C152.243 168.719 153.179 168.682 154.108 168.572C154.44 168.54 154.692 168.488 154.864 168.472C154.947 168.455 155.031 168.447 155.116 168.448Z" fill="#263238"/>
    <path d="M143.165 166.376C143.029 165.741 143.094 165.08 143.353 164.484C143.548 163.864 143.935 163.322 144.457 162.936C144.104 163.447 143.8 163.991 143.549 164.56C143.366 165.152 143.237 165.76 143.165 166.376Z" fill="#FAFAFA"/>
    <path d="M139.872 165C139.492 165.294 139.143 165.626 138.832 165.992C138.572 166.395 138.352 166.822 138.176 167.268C138.189 166.761 138.359 166.271 138.663 165.864C138.967 165.458 139.389 165.156 139.872 165Z" fill="#FAFAFA"/>
    <path d="M152.532 172.536C151.877 172.484 151.245 172.275 150.688 171.927C150.131 171.579 149.666 171.102 149.332 170.536C150.202 171.475 151.307 172.166 152.532 172.536Z" fill="#FAFAFA"/>
    <path d="M154.5 161.456C154.416 162.044 154.168 162.596 153.783 163.049C153.399 163.502 152.895 163.837 152.328 164.016C152.796 163.693 153.23 163.325 153.624 162.916C153.966 162.461 154.259 161.972 154.5 161.456Z" fill="#FAFAFA"/>
    <path d="M160.096 169.716C160.048 169.676 160.576 169.008 161.064 168.088C161.552 167.168 161.812 166.356 161.864 166.376C161.637 167.649 161.016 168.817 160.088 169.716H160.096Z" fill="#FAFAFA"/>
    <path d="M167.492 170.8C167.516 170.86 166.788 171.148 166.056 171.752C165.324 172.356 164.884 173.004 164.832 172.952C165.062 172.4 165.44 171.923 165.924 171.572C166.365 171.174 166.908 170.907 167.492 170.8Z" fill="#FAFAFA"/>
    <path d="M161.853 161.332C161.789 161.352 161.453 160.184 160.705 158.888C159.957 157.592 159.157 156.68 159.209 156.64C159.429 156.795 159.622 156.984 159.781 157.2C160.623 158.184 161.265 159.322 161.669 160.552C161.77 160.801 161.832 161.064 161.853 161.332Z" fill="#FAFAFA"/>
    <path d="M162.957 149.424C161.712 149.854 160.425 150.151 159.117 150.308C160.363 149.879 161.65 149.583 162.957 149.424Z" fill="#FAFAFA"/>
    <path d="M159.552 145.864C159.095 145.44 158.685 144.968 158.328 144.456C157.92 143.985 157.565 143.472 157.268 142.924C157.729 143.343 158.139 143.814 158.492 144.328C158.903 144.798 159.258 145.313 159.552 145.864Z" fill="#FAFAFA"/>
    <path d="M168 143.728C166.704 143.696 165.428 143.394 164.256 142.84C164.889 142.949 165.516 143.094 166.132 143.276C166.763 143.389 167.387 143.54 168 143.728Z" fill="#FAFAFA"/>
    <path d="M174 143.62C174.172 143.054 174.376 142.499 174.612 141.956C174.781 141.388 174.985 140.831 175.224 140.288C175.191 140.887 175.056 141.475 174.824 142.028C174.646 142.603 174.367 143.142 174 143.62Z" fill="#FAFAFA"/>
    <path d="M172.632 151.748C171.391 151.471 170.271 150.807 169.432 149.852C169.472 149.804 170.104 150.356 170.98 150.872C171.856 151.388 172.652 151.688 172.632 151.748Z" fill="#FAFAFA"/>
    <path d="M176.528 151.5C176.608 150.697 176.755 149.903 176.968 149.124C177.112 148.333 177.324 147.556 177.6 146.8C177.375 148.397 177.012 149.971 176.516 151.504L176.528 151.5Z" fill="#FAFAFA"/>
    <path d="M173.277 160.588C172.279 159.458 171.387 158.239 170.613 156.944C171.137 157.487 171.611 158.076 172.029 158.704C172.502 159.293 172.92 159.923 173.277 160.588Z" fill="#FAFAFA"/>
    <path d="M178.829 157.112C178.773 157.14 178.409 156.416 177.833 155.608C177.257 154.8 176.701 154.212 176.745 154.168C177.248 154.521 177.676 154.97 178.005 155.488C178.387 155.968 178.667 156.521 178.829 157.112Z" fill="#FAFAFA"/>
    <path d="M184.037 156.332C183.581 155.797 183.153 155.237 182.757 154.656C182.302 154.121 181.875 153.563 181.477 152.984C182.541 153.922 183.41 155.06 184.037 156.332Z" fill="#FAFAFA"/>
    <path d="M182.8 159.784C182.729 159.304 182.567 158.841 182.324 158.42C182.031 158.032 181.669 157.701 181.256 157.444C181.256 157.42 181.44 157.444 181.692 157.568C182.017 157.741 182.292 157.995 182.492 158.304C182.696 158.613 182.818 158.968 182.848 159.336C182.888 159.6 182.836 159.784 182.8 159.784Z" fill="#FAFAFA"/>
    <path d="M178.752 164.516C178.72 164.516 178.732 164.408 178.7 164.268C178.662 164.088 178.565 163.926 178.424 163.808C178.277 163.699 178.096 163.644 177.912 163.652C177.768 163.652 177.676 163.688 177.66 163.652C177.644 163.616 177.72 163.544 177.892 163.496C178.005 163.467 178.124 163.464 178.238 163.487C178.353 163.511 178.46 163.561 178.552 163.633C178.644 163.705 178.719 163.797 178.769 163.902C178.82 164.008 178.846 164.123 178.844 164.24C178.84 164.432 178.776 164.524 178.752 164.516Z" fill="#FAFAFA"/>
    <path d="M137.136 172.08C136.389 171.792 135.71 171.352 135.142 170.787C134.575 170.223 134.131 169.546 133.84 168.8C134.656 170.149 135.787 171.28 137.136 172.096V172.08Z" fill="#FAFAFA"/>
    <path d="M111.676 72.8002C111.032 66.7162 109.868 60.9922 109.552 55.0282C109.565 54.8787 109.546 54.7282 109.497 54.5863C109.448 54.4444 109.37 54.3143 109.268 54.2042C109.181 54.1533 109.085 54.1206 108.985 54.1082C108.884 54.0958 108.783 54.1038 108.686 54.1319C108.589 54.16 108.499 54.2074 108.421 54.2714C108.343 54.3354 108.279 54.4146 108.232 54.5042C108.058 54.8786 107.989 55.2935 108.032 55.7042C108.039 58.8759 107.498 62.0249 106.432 65.0122C105.748 66.9522 104.832 68.8122 104.32 70.8002C103.808 72.7882 104.772 73.4642 105.668 75.3162" fill="#FFBE9D"/>
    <path d="M105.681 75.308C104.888 74.7002 104.274 73.8905 103.902 72.9637C103.529 72.0369 103.413 71.0272 103.565 70.04C103.769 68.84 104.365 67.752 104.837 66.632C105.309 65.512 105.673 64.268 105.385 63.088C105.225 62.6501 105.118 62.195 105.065 61.732C105.065 61.268 105.365 60.748 105.829 60.728C106.004 60.7422 106.174 60.7969 106.324 60.8878C106.475 60.9788 106.603 61.1034 106.697 61.252C107.477 62.216 108.101 65.032 107.709 67.136" fill="#FFBE9D"/>
    <path d="M107.708 67.136C107.708 67.136 107.748 66.812 107.772 66.228C107.798 65.4042 107.729 64.5803 107.568 63.772C107.461 63.2153 107.3 62.6701 107.088 62.144C106.977 61.8577 106.826 61.5885 106.64 61.344C106.557 61.2128 106.448 61.1 106.319 61.0127C106.19 60.9253 106.045 60.8652 105.892 60.836C105.735 60.8435 105.585 60.9023 105.464 61.0034C105.343 61.1045 105.259 61.2424 105.224 61.396C105.139 61.737 105.161 62.0962 105.288 62.424C105.423 62.7899 105.517 63.1695 105.568 63.556C105.607 63.9562 105.592 64.3597 105.524 64.756C105.244 66.38 104.38 67.692 103.924 68.992C103.7 69.5994 103.579 70.2404 103.568 70.888C103.566 71.452 103.65 72.0132 103.816 72.552C104.059 73.3382 104.468 74.0627 105.016 74.676C105.416 75.104 105.676 75.292 105.664 75.312C105.593 75.2757 105.526 75.2314 105.464 75.18C105.284 75.0425 105.114 74.8914 104.956 74.728C104.372 74.125 103.934 73.3955 103.676 72.596C103.494 72.0449 103.4 71.4685 103.396 70.888C103.4 70.2196 103.518 69.5567 103.744 68.928C104.184 67.6 105.036 66.288 105.3 64.724C105.369 64.3439 105.384 63.9562 105.344 63.572C105.296 63.1965 105.207 62.8275 105.076 62.472C104.938 62.1046 104.919 61.7031 105.02 61.324C105.08 61.1347 105.189 60.9649 105.336 60.832C105.41 60.7633 105.497 60.7104 105.592 60.6767C105.687 60.643 105.788 60.6291 105.888 60.636C106.072 60.6602 106.248 60.723 106.405 60.8202C106.562 60.9174 106.697 61.0469 106.8 61.2C106.995 61.4648 107.151 61.7556 107.264 62.064C107.476 62.6012 107.631 63.1588 107.728 63.728C107.88 64.5481 107.927 65.3841 107.868 66.216C107.854 66.4417 107.825 66.6662 107.78 66.888C107.768 66.9737 107.744 67.0572 107.708 67.136Z" fill="#EB996E"/>
    <path d="M160.001 99.6202C160.132 100.192 160.442 100.708 160.885 101.092C161.329 101.476 161.884 101.709 162.469 101.756C163.055 101.785 163.639 101.664 164.166 101.405C164.693 101.146 165.145 100.758 165.481 100.276C166.145 99.2967 166.413 98.1014 166.229 96.9322C166.001 95.2482 164.881 93.6282 163.257 93.1282C161.633 92.6282 159.613 93.5282 159.165 95.1602L157.293 97.7722L160.001 99.6202Z" fill="#263238"/>
    <path d="M157.788 94.4882C157.97 93.0407 157.859 91.5715 157.462 90.1677C157.065 88.764 156.39 87.4543 155.477 86.3164C154.564 85.1785 153.431 84.2356 152.147 83.5436C150.863 82.8517 149.453 82.4248 148 82.2882C142.196 81.7362 137.032 86.0602 136.164 91.8282C135.4 96.8842 134.744 101.988 134.768 103.828C134.84 109.428 138.108 111.596 139.436 112.228C139.913 112.4 140.406 112.522 140.908 112.592C140.913 112.593 140.918 112.595 140.922 112.598C140.926 112.601 140.929 112.604 140.932 112.608C140.934 112.612 140.936 112.617 140.937 112.622C140.938 112.627 140.937 112.631 140.936 112.636L139.696 119.036C139.132 123.24 141.48 126.992 145.676 127.604C149.872 128.216 153.18 124.988 153.824 120.78L157.788 94.4882Z" fill="#FFBE9D"/>
    <path d="M137.129 95.0961C137.221 95.2281 137.957 94.8321 138.905 94.9721C139.853 95.1121 140.473 95.6881 140.593 95.5841C140.653 95.5401 140.569 95.3201 140.301 95.0561C139.933 94.7121 139.468 94.49 138.969 94.4201C138.477 94.3486 137.974 94.4339 137.533 94.6641C137.201 94.8441 137.081 95.0361 137.129 95.0961Z" fill="#263238"/>
    <path d="M145.544 96.92C145.636 97.052 146.376 96.656 147.32 96.796C148.264 96.936 148.892 97.512 149.012 97.408C149.068 97.364 148.988 97.144 148.716 96.876C148.348 96.534 147.885 96.3133 147.388 96.244C146.895 96.1722 146.393 96.2576 145.952 96.488C145.628 96.668 145.5 96.86 145.544 96.92Z" fill="#263238"/>
    <path d="M142.272 101.392C141.796 101.164 141.293 100.997 140.776 100.892C140.536 100.832 140.316 100.748 140.3 100.58C140.315 100.328 140.408 100.087 140.564 99.8885L141.548 98.1525C142.92 95.6765 143.948 93.6285 143.852 93.5725C143.756 93.5165 142.556 95.4805 141.184 97.9725L140.228 99.7205C140.036 99.992 139.943 100.321 139.964 100.652C139.989 100.74 140.033 100.822 140.093 100.891C140.154 100.96 140.228 101.015 140.312 101.052C140.44 101.107 140.574 101.146 140.712 101.168C141.22 101.309 141.744 101.384 142.272 101.392Z" fill="#263238"/>
    <path d="M140.945 112.632C140.945 112.632 144.545 113.464 149.525 111.384C149.525 111.384 147.381 116.004 140.613 114.36L140.945 112.632Z" fill="#EB996E"/>
    <path d="M142.341 103.732C142.553 103.537 142.81 103.398 143.09 103.328C143.369 103.258 143.662 103.26 143.941 103.332C144.146 103.383 144.34 103.474 144.51 103.6C144.681 103.727 144.824 103.886 144.933 104.068C145.036 104.244 145.083 104.447 145.067 104.651C145.05 104.854 144.971 105.047 144.841 105.204C144.665 105.353 144.448 105.445 144.219 105.468C143.99 105.492 143.759 105.446 143.557 105.336C143.132 105.115 142.756 104.81 142.453 104.44C142.355 104.35 142.276 104.241 142.221 104.12C142.193 104.063 142.183 103.998 142.193 103.935C142.203 103.872 142.232 103.814 142.277 103.768" fill="#EB996E"/>
    <path d="M144.96 102.452C144.808 102.42 144.656 103.448 143.672 104.052C142.688 104.656 141.608 104.38 141.58 104.524C141.556 104.588 141.796 104.76 142.256 104.84C142.856 104.935 143.47 104.816 143.992 104.504C144.492 104.203 144.856 103.72 145.008 103.156C145.108 102.728 145.032 102.456 144.96 102.452Z" fill="#263238"/>
    <path d="M146.197 93.8403C146.253 94.1123 147.217 94.1283 148.305 94.4243C149.393 94.7203 150.253 95.1523 150.433 94.9443C150.513 94.8443 150.393 94.5803 150.069 94.2763C149.63 93.9005 149.111 93.6296 148.551 93.4841C147.992 93.3385 147.407 93.3222 146.841 93.4363C146.401 93.5403 146.177 93.7123 146.197 93.8403Z" fill="#263238"/>
    <path d="M138.085 92.1802C138.217 92.4202 138.905 92.2922 139.685 92.3962C140.465 92.5002 141.113 92.7482 141.285 92.5482C141.369 92.4482 141.285 92.2162 141.033 91.9722C140.679 91.6706 140.244 91.4814 139.782 91.4287C139.321 91.3759 138.854 91.4621 138.441 91.6762C138.149 91.8562 138.025 92.0642 138.085 92.1802Z" fill="#263238"/>
    <path d="M135.068 90.5762C136.224 92.3402 139.896 91.2482 142.112 90.0362C143.269 90.7746 144.669 91.0322 146.014 90.7544C147.359 90.4765 148.542 89.685 149.312 88.5482C150.58 90.1482 153.16 89.6282 154.112 87.8082C155.064 85.9882 154.512 83.4802 153.232 81.8882C151.952 80.2962 150.056 79.4882 148.192 79.1482C143.772 78.3482 138.96 80.0442 136.316 84.0402C135.096 85.8842 134.364 88.3682 135.088 90.5762" fill="#263238"/>
    <path d="M147.284 79.7761C147.284 79.8561 146.24 79.6561 144.6 79.9281C142.47 80.2824 140.517 81.3333 139.048 82.9161C137.665 84.5733 136.751 86.5704 136.4 88.7001C136.278 89.5879 136.231 90.4844 136.26 91.3801C136.175 91.1489 136.125 90.9062 136.112 90.6601C136.042 89.9946 136.053 89.323 136.144 88.6601C136.434 86.4478 137.353 84.3653 138.792 82.6601C140.313 81.0133 142.362 79.9505 144.584 79.6561C145.247 79.5669 145.918 79.5562 146.584 79.6241C146.824 79.6387 147.06 79.6899 147.284 79.7761Z" fill="#455A64"/>
    <path d="M141.309 88C141.243 87.7975 141.213 87.5849 141.221 87.372C141.2 86.7918 141.26 86.2114 141.401 85.648C141.615 84.7031 142.02 83.812 142.591 83.0295C143.163 82.2471 143.888 81.5898 144.723 81.0982C145.558 80.6066 146.484 80.291 147.446 80.1709C148.407 80.0509 149.383 80.1288 150.313 80.4C150.874 80.5539 151.412 80.7826 151.913 81.08C152.106 81.1804 152.278 81.3161 152.421 81.48C151.723 81.1499 150.998 80.8822 150.253 80.68C148.441 80.2185 146.521 80.4919 144.91 81.4405C143.299 82.389 142.128 83.936 141.653 85.744C141.261 87.08 141.385 88 141.309 88Z" fill="#455A64"/>
    <path d="M151.913 86.08C151.479 87.6456 151.229 89.2565 151.169 90.88C151.109 92.5125 151.616 94.1154 152.605 95.416C154.149 97.276 156.725 97.844 159.101 98.296C160.372 95.1252 160.755 91.6681 160.209 88.296C159.937 86.66 159.409 84.992 158.257 83.8C157.4 82.9551 156.288 82.4182 155.093 82.2734C153.899 82.1286 152.69 82.3842 151.657 83" fill="#263238"/>
    <path d="M153.233 93.8242C152.609 95.1762 152.653 96.7402 152.869 98.2242C153.085 99.7082 153.461 101.16 153.445 102.648C153.429 104.136 152.957 105.716 151.789 106.648C151.435 106.884 151.103 107.152 150.797 107.448C150.646 107.598 150.545 107.792 150.508 108.001C150.471 108.211 150.499 108.427 150.589 108.62C150.813 108.98 151.489 108.972 151.585 108.56L151.525 108.664C151.466 108.764 151.435 108.877 151.433 108.993C151.432 109.109 151.461 109.223 151.518 109.324C151.575 109.425 151.657 109.509 151.757 109.568C151.856 109.627 151.969 109.659 152.085 109.66C152.494 109.641 152.894 109.538 153.262 109.357C153.629 109.177 153.956 108.924 154.221 108.612C154.985 107.728 155.289 106.54 155.489 105.412C156.105 101.88 155.985 98.2122 156.961 94.7682" fill="#263238"/>
    <path d="M139.669 117.02C139.944 118.223 140.475 119.353 141.226 120.333C141.977 121.313 142.93 122.12 144.02 122.699C145.11 123.278 146.312 123.615 147.544 123.689C148.776 123.762 150.01 123.57 151.161 123.124C153.233 122.324 154.905 120.764 156.517 119.24C156.805 120.975 156.726 122.751 156.284 124.453C155.841 126.156 155.046 127.746 153.95 129.121C152.853 130.496 151.48 131.626 149.919 132.436C148.358 133.246 146.644 133.719 144.889 133.824C142.237 133.984 139.389 133.176 137.721 131.108C136.557 129.668 136.089 127.76 136.121 125.908C136.202 124.053 136.555 122.22 137.169 120.468C137.641 118.956 138.389 117.268 139.917 116.848" fill="#64A172"/>
    <path d="M158.644 98.4002C160.244 99.2602 161.1 101.06 161.408 102.836C161.716 104.612 161.56 106.436 161.636 108.232C161.712 110.028 162.056 111.916 163.196 113.312C164.396 114.8 166.296 115.504 167.996 116.412C171.724 118.412 174.796 121.824 175.916 125.912C177.036 130 176.02 134.712 172.96 137.652C170.16 140.316 166.12 141.252 162.264 141.252C159.516 141.252 156.496 140.66 154.78 138.512C153.064 136.364 153.18 133.352 153.78 130.704C154.38 128.056 155.48 125.484 155.524 122.768C155.114 124.45 154.398 126.041 153.412 127.464C153.128 127.864 152.644 128.304 152.212 128.072C151.78 127.84 151.912 127.092 152.136 126.592C155.448 119.22 154.296 104.36 156.26 96.2202" fill="#263238"/>
    <path d="M155.916 97.5641C156.016 97.5321 159.884 96.8001 159.384 100.624C158.884 104.448 155.152 103.16 155.16 103.052C155.168 102.944 155.916 97.5641 155.916 97.5641Z" fill="#FFBE9D"/>
    <path d="M156.516 101.756C156.516 101.756 156.576 101.808 156.684 101.876C156.833 101.964 157.011 101.991 157.18 101.952C157.604 101.856 158.028 101.256 158.136 100.572C158.186 100.245 158.164 99.91 158.072 99.592C158.052 99.4677 158.001 99.3502 157.925 99.2498C157.849 99.1494 157.75 99.0691 157.636 99.016C157.564 98.9905 157.486 98.9897 157.413 99.0136C157.341 99.0375 157.278 99.0848 157.236 99.148C157.172 99.248 157.184 99.328 157.164 99.332C157.144 99.336 157.092 99.256 157.14 99.104C157.174 99.0117 157.239 98.934 157.324 98.884C157.383 98.8482 157.449 98.8252 157.518 98.8162C157.587 98.8073 157.657 98.8126 157.724 98.832C157.877 98.8837 158.014 98.9737 158.123 99.0934C158.231 99.2131 158.307 99.3586 158.344 99.516C158.462 99.8686 158.494 100.245 158.436 100.612C158.312 101.38 157.824 102.048 157.236 102.136C157.129 102.153 157.02 102.146 156.917 102.115C156.814 102.083 156.719 102.029 156.64 101.956C156.512 101.848 156.504 101.76 156.516 101.756Z" fill="#EB996E"/>
    <path d="M135.717 125.26C135.904 124.852 136.121 124.458 136.365 124.08C136.922 123.158 137.385 122.181 137.745 121.164C138.095 120.147 138.212 119.065 138.089 117.996C138.011 117.558 137.909 117.124 137.785 116.696C137.86 116.794 137.918 116.903 137.957 117.02C138.087 117.327 138.181 117.648 138.237 117.976C138.405 119.068 138.305 120.184 137.945 121.228C137.582 122.26 137.094 123.243 136.493 124.156C136.269 124.508 136.093 124.788 135.945 124.976C135.797 125.164 135.729 125.268 135.717 125.26Z" fill="#263238"/>
    <path d="M137.665 120.848C137.609 120.848 137.501 120.52 137.157 120.292C136.813 120.064 136.469 120.064 136.461 120.004C136.453 119.944 136.545 119.936 136.701 119.932C136.908 119.934 137.109 120 137.277 120.12C137.447 120.238 137.578 120.404 137.653 120.596C137.705 120.744 137.693 120.84 137.665 120.848Z" fill="#FAFAFA"/>
    <path d="M140.136 120.84C139.836 120.503 139.507 120.194 139.152 119.916C138.758 119.696 138.343 119.516 137.912 119.38C138.15 119.347 138.393 119.363 138.625 119.425C138.857 119.488 139.075 119.596 139.264 119.744C139.687 119.982 140 120.375 140.136 120.84Z" fill="#FAFAFA"/>
    <path d="M141.689 128.956C141.699 128.405 141.777 127.856 141.921 127.324C141.999 126.775 142.145 126.237 142.357 125.724C142.348 126.277 142.27 126.827 142.125 127.36C142.048 127.908 141.901 128.445 141.689 128.956Z" fill="#FAFAFA"/>
    <path d="M147.928 130.18C147.828 129.032 147.864 127.876 148.036 126.736C148.142 127.884 148.105 129.041 147.928 130.18Z" fill="#FAFAFA"/>
    <path d="M20.5235 147.956L14.3555 174.816L27.3995 174.856L33.4235 151.236L20.5235 147.956Z" fill="#FFBE9D"/>
    <path d="M84.5791 114.956C83.3391 113.296 81.9151 111.7 81.2631 109.732C80.9471 108.78 80.8231 107.772 80.4871 106.828C80.1511 105.884 79.5391 104.964 78.6031 104.608C77.6671 104.252 76.9551 104.608 76.3471 105.28L71.8711 118.372L74.4511 128.428C77.0271 127.848 80.2511 127.16 82.6591 126.076C85.0671 124.992 86.6591 123.256 86.8271 120.62C86.9431 118.552 85.8191 116.62 84.5791 114.956Z" fill="#263238"/>
    <path d="M43.848 112.644C43.848 112.644 28.288 119.416 26.344 122.472C24.4 125.528 18 147.316 18 147.316L34.4 152.116L34.12 174.8H74.32L73.988 134.8L68.948 112.696C68.948 112.696 60.072 112.124 59.248 111.72C58.424 111.316 43.848 112.644 43.848 112.644Z" fill="#64A172"/>
    <path d="M99.1444 57.1998C99.2724 56.2838 98.6284 55.2758 99.0924 54.4718C99.3993 54.0575 99.838 53.7599 100.336 53.6278C100.578 53.5175 100.839 53.4563 101.104 53.4478C101.237 53.4457 101.367 53.4825 101.478 53.5536C101.59 53.6247 101.678 53.727 101.732 53.8478C101.761 54.0008 101.748 54.1588 101.693 54.3046C101.639 54.4505 101.546 54.5789 101.424 54.6758C101.181 54.8674 100.976 55.1036 100.82 55.3718C100.712 56.0302 100.763 56.7051 100.968 57.3398C101.501 58.7366 101.944 60.1662 102.292 61.6198" fill="#FFBE9D"/>
    <path d="M95.8594 57.5121C95.9154 56.1241 97.0594 50.6561 97.0994 49.2681C98.0394 49.5561 98.5834 50.5561 98.7594 51.5241C98.9354 52.4921 98.8434 53.4881 99.0114 54.4561C99.2354 55.7561 99.9114 56.9281 100.591 58.0561C101.271 59.1841 101.983 60.3321 102.291 61.6121C102.599 62.8921 103.759 66.2201 102.855 67.1801" fill="#FFBE9D"/>
    <path d="M95.5993 66.8721L90.0353 93.3041C89.8393 94.5041 68.9473 112.724 68.9473 112.724L73.0713 129.2L100.271 102.588C102.235 100.489 103.304 97.7065 103.251 94.8321L102.787 70.0321L95.5993 66.8721Z" fill="#FFBE9D"/>
    <path d="M94.6481 57.6799L95.6001 66.8799L102.8 70.0479C102.8 70.0479 102.888 69.3959 103.2 66.2079C103.54 62.6959 99.7641 61.1879 98.0801 58.6079C97.3601 57.4999 97.5481 55.4599 96.9921 52.9319C96.9281 52.6399 96.5281 50.8719 96.3121 49.9239C96.1985 49.3971 95.8898 48.9327 95.4481 48.6239C95.1201 48.4079 94.7521 48.2999 94.4841 48.6239C94.2161 48.9479 94.6481 57.6799 94.6481 57.6799Z" fill="#FFBE9D"/>
    <path d="M99.1438 68.4C99.9438 67.708 100.136 66.468 99.8358 65.44C99.4685 64.4277 98.8888 63.5057 98.1358 62.736C97.3837 61.9682 96.7513 61.0917 96.2598 60.136C95.9558 59.448 95.8118 58.688 95.4598 58.012C95.1078 57.336 94.5118 56.716 93.7598 56.688C94.3638 60.492 94.3478 62.688 95.5838 66.856" fill="#FFBE9D"/>
    <path d="M94.508 61.0317C94.508 61.0317 94.48 60.9277 94.436 60.7277C94.392 60.5277 94.316 60.1957 94.244 59.8557C94.072 59.0557 93.844 58.0357 93.608 56.8637L93.584 56.7517H93.7C93.9462 56.7427 94.1918 56.7827 94.4224 56.8692C94.6531 56.9557 94.8644 57.0871 95.044 57.2557C95.5932 57.7784 95.9842 58.4452 96.172 59.1797C96.3316 59.7506 96.5417 60.3062 96.8 60.8397C96.9957 61.1902 97.232 61.5164 97.504 61.8117C97.6032 61.9252 97.6842 62.0534 97.744 62.1917C97.6345 62.0902 97.5329 61.9806 97.44 61.8637C97.1422 61.5825 96.8839 61.2622 96.672 60.9117C96.3894 60.3764 96.159 59.8152 95.984 59.2357C95.7979 58.5379 95.4244 57.9044 94.904 57.4037C94.5796 57.0993 94.1488 56.9341 93.704 56.9437L93.8 56.8237C94.284 59.2317 94.596 61.0357 94.508 61.0317Z" fill="#FFBE9D"/>
    <path d="M97.7431 58.6918C97.4528 57.4543 97.2311 56.2017 97.0791 54.9398C96.8569 53.6878 96.7034 52.4245 96.6191 51.1558C96.9115 52.3942 97.1332 53.6482 97.2831 54.9118C97.5052 56.1624 97.6588 57.4243 97.7431 58.6918Z" fill="#FFBE9D"/>
    <path d="M100.035 59.0039C100.035 59.0039 100.063 59.0439 100.087 59.1439H100.027C99.73 58.2881 99.4868 57.4143 99.2994 56.5279C99.1274 55.7559 99.0034 55.0519 98.9274 54.5279C98.8656 54.2538 98.8414 53.9725 98.8554 53.6919C98.9154 53.6919 99.1594 54.9359 99.5034 56.4919C99.7794 57.7359 100.035 58.7839 100.087 59.1359H100.027C100.023 59.0519 100.023 59.0039 100.035 59.0039Z" fill="#FFBE9D"/>
    <path d="M101.456 64.0717C101.424 64.0717 101.28 63.8637 100.992 63.5557C100.232 62.7473 99.1987 62.2487 98.0924 62.1557C97.861 62.1578 97.6297 62.1431 97.4004 62.1117C97.6218 62.0258 97.8595 61.9903 98.0964 62.0077C98.6729 62.015 99.2408 62.1488 99.7599 62.3997C100.279 62.6506 100.737 63.0125 101.1 63.4597C101.264 63.6339 101.386 63.8432 101.456 64.0717Z" fill="#FFBE9D"/>
    <path d="M96.2507 67.6001C96.0528 67.2558 95.8932 66.891 95.7747 66.5121C95.4459 65.6145 95.1708 64.6983 94.9507 63.7681C94.7269 62.8456 94.5586 61.9106 94.4467 60.9681C94.3813 60.5716 94.3571 60.1695 94.3747 59.7681C94.4751 60.1503 94.5472 60.5393 94.5907 60.9321C94.7107 61.6481 94.8947 62.6401 95.1507 63.7321C95.4067 64.8241 95.6947 65.7801 95.9147 66.4761C96.0527 66.8425 96.1649 67.2181 96.2507 67.6001Z" fill="#FFBE9D"/>
    <path d="M64.0625 113.2C64.4189 112.654 64.9717 112.266 65.6063 112.117C66.2409 111.968 66.9085 112.07 67.4705 112.4C70.4065 110 73.4105 107.672 76.3465 105.284C75.3865 110.084 74.7465 116.776 76.8945 121.168C76.8945 121.168 79.6025 126.2 83.9145 125.432L80.0665 129.2L73.9985 134.828" fill="#64A172"/>
    <path d="M63.1113 115.44C63.1159 114.796 63.3122 114.168 63.6753 113.636C63.9819 113.068 64.4495 112.603 65.0193 112.3C64.1155 113.158 63.4569 114.242 63.1113 115.44Z" fill="#263238"/>
    <path d="M66.2836 118.4C66.1171 118.133 66.0031 117.837 65.9476 117.528C65.7749 116.786 65.7543 116.017 65.887 115.267C66.0197 114.517 66.3029 113.802 66.7196 113.164C66.8785 112.891 67.0875 112.651 67.3356 112.456C67.3636 112.48 67.1316 112.752 66.8476 113.256C66.4782 113.894 66.2224 114.591 66.0916 115.316C65.9664 116.043 65.9664 116.785 66.0916 117.512C66.1916 118.032 66.3156 118.368 66.2836 118.4Z" fill="#263238"/>
    <path d="M80.9794 128.672C80.6842 128.855 80.3614 128.99 80.0234 129.072C79.0993 129.295 78.1324 129.266 77.2234 128.988C75.8771 128.56 74.699 127.721 73.8554 126.588C72.8421 125.198 72.1582 123.597 71.8554 121.904C71.2905 118.855 71.1291 115.746 71.3754 112.656C71.4674 111.456 71.5994 110.52 71.7114 109.856C71.7634 109.528 71.8114 109.276 71.8474 109.104C71.8598 109.015 71.8813 108.928 71.9114 108.844C71.9142 108.934 71.9061 109.024 71.8874 109.112L71.7914 109.868C71.7074 110.524 71.5954 111.468 71.5234 112.668C71.3188 115.745 71.4988 118.835 72.0594 121.868C72.3589 123.517 73.0216 125.079 73.9994 126.44C74.8082 127.561 75.9484 128.401 77.2594 128.84C78.1446 129.125 79.0886 129.176 79.9994 128.988C80.3328 128.904 80.6602 128.799 80.9794 128.672Z" fill="#FAFAFA"/>
    <path d="M34.4751 158.868C34.4191 158.868 34.3711 153.18 34.3711 146.168C34.3711 139.156 34.4191 133.468 34.4751 133.468C34.5311 133.468 34.5791 139.152 34.5791 146.168C34.5791 153.184 34.5351 158.868 34.4751 158.868Z" fill="#263238"/>
    <path d="M34.1558 129.08C34.1118 129.08 34.1238 128.512 33.9278 127.628C33.8111 127.111 33.6436 126.608 33.4278 126.124C33.159 125.535 32.8278 124.978 32.4398 124.46C32.0411 123.949 31.5892 123.483 31.0918 123.068C30.6823 122.733 30.2416 122.438 29.7758 122.188C28.9758 121.768 28.4198 121.628 28.4318 121.588C28.5684 121.595 28.7031 121.622 28.8318 121.668C28.9834 121.706 29.1319 121.755 29.2758 121.816C29.4721 121.884 29.6632 121.965 29.8478 122.06C30.3283 122.295 30.7817 122.582 31.1998 122.916C31.7133 123.335 32.1788 123.81 32.5878 124.332C32.987 124.863 33.3227 125.439 33.5878 126.048C33.8057 126.546 33.968 127.066 34.0718 127.6C34.1129 127.803 34.1409 128.009 34.1558 128.216C34.1745 128.371 34.1826 128.528 34.1798 128.684C34.1871 128.816 34.179 128.949 34.1558 129.08Z" fill="#263238"/>
    <path d="M61.552 112.468C61.5852 112.579 61.608 112.693 61.62 112.808C61.6739 113.132 61.7087 113.459 61.724 113.788C61.7718 115.001 61.6025 116.214 61.224 117.368C60.6604 119.089 59.6614 120.635 58.324 121.856C56.6466 123.322 54.54 124.206 52.3189 124.376C50.0977 124.546 47.8811 123.993 46 122.8C44.4963 121.794 43.2782 120.416 42.464 118.8C41.9186 117.715 41.5705 116.542 41.436 115.336C41.4035 115.009 41.3888 114.68 41.392 114.352C41.3824 114.237 41.3824 114.122 41.392 114.008C41.4108 114.12 41.4201 114.234 41.42 114.348C41.4303 114.674 41.457 115 41.5 115.324C41.6638 116.513 42.0291 117.665 42.58 118.732C43.3961 120.315 44.6038 121.663 46.088 122.648C47.935 123.809 50.1058 124.348 52.2815 124.184C54.4572 124.021 56.5232 123.164 58.176 121.74C59.4988 120.544 60.4931 119.029 61.064 117.34C61.4473 116.202 61.6366 115.008 61.624 113.808C61.624 113.384 61.588 113.056 61.556 112.832C61.524 112.608 61.548 112.472 61.552 112.468Z" fill="#263238"/>
    <path d="M34.1315 147.172C34.1315 147.228 30.8075 146.184 26.7515 144.844C22.6955 143.504 19.4195 142.372 19.4395 142.316C19.4595 142.26 22.7595 143.304 26.8195 144.644C30.8795 145.984 34.1475 147.116 34.1315 147.172Z" fill="#FAFAFA"/>
    <path d="M73.5993 141.62C73.5993 141.68 64.7793 141.764 53.8993 141.808C43.0193 141.852 34.1953 141.844 34.1953 141.808C34.1953 141.772 43.0153 141.664 53.8993 141.62C64.7833 141.576 73.5993 141.564 73.5993 141.62Z" fill="#FAFAFA"/>
    <path d="M73.7993 145.104C73.7993 145.16 64.9313 145.208 53.9993 145.208C43.0673 145.208 34.1953 145.16 34.1953 145.104C34.1953 145.048 43.0593 145 53.9993 145C64.9393 145 73.7993 145.044 73.7993 145.104Z" fill="#FAFAFA"/>
    <path d="M68.3665 149.336C68.3394 149.447 68.3019 149.556 68.2545 149.66C68.1665 149.884 68.0465 150.188 67.8985 150.572L67.6345 151.228C67.5305 151.468 67.4065 151.72 67.2785 151.996C67.0145 152.54 66.7425 153.172 66.3625 153.836L65.7865 154.884C65.5865 155.248 65.3465 155.608 65.1145 155.992C64.6585 156.764 64.0985 157.54 63.5145 158.368C62.2156 160.127 60.7848 161.786 59.2345 163.328C57.6621 164.849 55.9758 166.248 54.1905 167.512C53.3505 168.072 52.5625 168.62 51.7905 169.06C51.3905 169.284 51.0385 169.516 50.6705 169.712L49.5985 170.264C48.9305 170.628 48.2905 170.892 47.7425 171.144C47.4665 171.264 47.2105 171.384 46.9705 171.484L46.3065 171.736L45.3905 172.076C45.2848 172.121 45.175 172.156 45.0625 172.18C45.1623 172.123 45.2667 172.075 45.3745 172.036L46.2785 171.66L46.9305 171.392C47.1705 171.288 47.4225 171.164 47.6945 171.036C48.2385 170.776 48.8705 170.504 49.5305 170.132L50.5825 169.572C50.9465 169.372 51.3105 169.136 51.6945 168.912C52.4665 168.468 53.2465 167.916 54.0945 167.356C57.6575 164.842 60.7911 161.769 63.3745 158.256C63.9545 157.436 64.5185 156.656 64.9745 155.9C65.2105 155.52 65.4545 155.164 65.6585 154.804L66.2385 153.764C66.6385 153.112 66.9065 152.484 67.1785 151.944C67.3105 151.672 67.4425 151.424 67.5505 151.188C67.6585 150.952 67.7465 150.736 67.8305 150.54C67.9945 150.164 68.1265 149.868 68.2305 149.644C68.2705 149.539 68.3159 149.436 68.3665 149.336Z" fill="#263238"/>
    <g opacity="0.3">
      <path d="M66.7425 152.8C64.0545 156.072 61.2385 158.444 57.2905 159.972C55.3985 160.7 53.4185 161.2 51.5865 162.068C49.7545 162.936 48.0265 164.244 47.1865 166.096C46.5505 167.528 46.8585 169.696 48.3865 170.008C48.9801 170.081 49.5823 169.988 50.1265 169.74C57.9465 166.904 63.0865 159.74 66.7185 152.8" fill="black"/>
    </g>
    <path d="M38.7723 81.6318C37.0843 82.9558 36.9723 85.4318 37.0563 87.5758C37.1403 89.7198 37.1643 92.1598 35.6003 93.6398C34.3203 94.8598 32.0803 95.3118 31.7123 97.0438C31.6191 97.4519 31.6564 97.8789 31.8187 98.2647C31.9811 98.6505 32.2604 98.9756 32.6173 99.1943C32.9742 99.4129 33.3908 99.5141 33.8082 99.4835C34.2257 99.4529 34.623 99.2922 34.9443 99.0238C34.4003 100.38 34.6483 102.116 35.8043 103.024C36.9603 103.932 38.9803 103.5 39.4043 102.108C39.3387 102.338 39.3206 102.579 39.3511 102.817C39.3816 103.054 39.46 103.283 39.5816 103.489C39.7031 103.696 39.8653 103.875 40.0583 104.017C40.2513 104.159 40.4711 104.26 40.7043 104.314C40.9375 104.368 41.1793 104.374 41.415 104.332C41.6507 104.29 41.8754 104.201 42.0755 104.069C42.2756 103.938 42.447 103.767 42.5791 103.567C42.7112 103.368 42.8014 103.143 42.8443 102.908" fill="#263238"/>
    <path d="M63.268 97.1557C63.3484 97.4367 63.4858 97.6981 63.6715 97.9237C63.8573 98.1494 64.0874 98.3344 64.3477 98.4674C64.6079 98.6003 64.8928 98.6784 65.1845 98.6966C65.4761 98.7149 65.7685 98.673 66.0433 98.5735C66.3181 98.474 66.5695 98.3191 66.7819 98.1183C66.9943 97.9176 67.1632 97.6753 67.2781 97.4066C67.3929 97.1379 67.4513 96.8484 67.4495 96.5561C67.4478 96.2639 67.386 95.9751 67.268 95.7077C66.936 94.8757 66.068 94.2477 66.048 93.3557C65.996 92.2757 67.132 91.5597 68.128 91.1397C69.124 90.7197 70.292 90.1597 70.448 89.0917C70.604 88.0237 69.712 87.2037 68.968 86.4717C68.224 85.7397 67.508 84.6277 68.032 83.7237C68.3 83.2637 68.832 83.0157 69.176 82.6117C69.952 81.7157 69.64 80.2117 68.744 79.4477C67.848 78.6837 66.564 78.5077 65.392 78.6477C64.22 78.7877 63.1 79.2277 61.952 79.5117C60.0483 79.9712 58.07 80.0307 56.1422 79.6864C54.2144 79.3422 52.3789 78.6017 50.752 77.5117" fill="#263238"/>
    <path d="M39.9204 89.8801L44.0364 113.736C44.207 114.735 44.5735 115.689 45.1147 116.546C45.656 117.402 46.3614 118.142 47.1902 118.725C48.019 119.307 48.9549 119.719 49.9438 119.938C50.9328 120.157 51.9553 120.178 52.9524 120C54.8417 119.662 56.5358 118.628 57.7011 117.103C58.8664 115.578 59.4184 113.672 59.2484 111.76C59.0604 109.644 58.9004 107.892 58.9004 107.892C58.9004 107.892 64.8764 106.356 64.3604 100.292C64.1084 97.3561 63.1044 91.3521 62.1284 85.9281C61.6224 83.1208 60.078 80.6068 57.8026 78.8865C55.5271 77.1662 52.6874 76.3656 49.8484 76.6441L49.2644 76.7001C47.7767 76.9332 46.3508 77.462 45.0707 78.2553C43.7907 79.0485 42.6825 80.0901 41.8116 81.3186C40.9406 82.5471 40.3246 83.9375 39.9998 85.408C39.675 86.8784 39.648 88.399 39.9204 89.8801Z" fill="#FFBE9D"/>
    <path d="M60.8506 89.8437C60.8767 90.0694 60.8128 90.2963 60.6728 90.4752C60.5328 90.6541 60.3279 90.7707 60.1026 90.7997C59.9934 90.8197 59.8813 90.8175 59.773 90.7933C59.6646 90.7691 59.5623 90.7234 59.472 90.6588C59.3817 90.5943 59.3053 90.5122 59.2473 90.4175C59.1894 90.3229 59.151 90.2175 59.1346 90.1077C59.1086 89.882 59.1724 89.6552 59.3124 89.4762C59.4524 89.2973 59.6573 89.1807 59.8826 89.1517C59.9918 89.1317 60.1039 89.1339 60.2122 89.1581C60.3206 89.1823 60.4229 89.228 60.5132 89.2926C60.6036 89.3572 60.68 89.4392 60.7379 89.5339C60.7959 89.6286 60.8342 89.7339 60.8506 89.8437Z" fill="#263238"/>
    <path d="M61.1157 89.0157C61.0197 89.1357 60.3157 88.7157 59.3837 88.8077C58.4517 88.8997 57.8117 89.4357 57.6997 89.3317C57.6477 89.2837 57.7357 89.0717 58.0117 88.8237C58.3884 88.504 58.8554 88.3096 59.3477 88.2677C59.8322 88.2194 60.3195 88.3258 60.7397 88.5717C61.0477 88.7637 61.1637 88.9597 61.1157 89.0157Z" fill="#263238"/>
    <path d="M51.7307 90.9119C51.7535 91.1372 51.6878 91.3625 51.5474 91.5401C51.4071 91.7178 51.2032 91.8339 50.9787 91.8639C50.8696 91.8833 50.7577 91.8808 50.6496 91.8563C50.5414 91.8319 50.4393 91.7861 50.3491 91.7216C50.2589 91.6572 50.1826 91.5753 50.1245 91.4809C50.0663 91.3865 50.0277 91.2815 50.0107 91.1719C49.9868 90.9466 50.0515 90.7208 50.1911 90.5424C50.3307 90.364 50.5343 90.2469 50.7587 90.2159C50.8684 90.1965 50.9808 90.1992 51.0894 90.2238C51.1981 90.2485 51.3006 90.2946 51.3912 90.3594C51.4817 90.4242 51.5584 90.5065 51.6167 90.6014C51.675 90.6963 51.7138 90.8019 51.7307 90.9119Z" fill="#263238"/>
    <path d="M51.9673 90.0799C51.8713 90.2039 51.1673 89.7839 50.2353 89.8759C49.3033 89.9679 48.6673 90.5039 48.5513 90.3999C48.4993 90.3519 48.5873 90.1399 48.8633 89.8919C49.2408 89.5735 49.7074 89.3793 50.1993 89.3359C50.6839 89.2856 51.1718 89.3922 51.5913 89.6399C51.8993 89.8319 51.9993 90.0279 51.9673 90.0799Z" fill="#263238"/>
    <path d="M56.0274 96.6678C56.0274 96.6118 56.5874 96.4558 57.5114 96.2438C57.7474 96.1958 57.9714 96.1278 57.9954 95.9598C57.9887 95.7131 57.9094 95.4738 57.7674 95.2718L56.8834 93.5278C55.6554 91.0398 54.7434 88.9878 54.8434 88.9398C54.9434 88.8918 56.0194 90.8678 57.2434 93.3398L58.0954 95.0998C58.2727 95.3717 58.3478 95.6977 58.3074 96.0198C58.2834 96.1096 58.2387 96.1926 58.1769 96.2621C58.1151 96.3315 58.0379 96.3855 57.9514 96.4198C57.8222 96.4671 57.688 96.4993 57.5514 96.5158C57.0515 96.6282 56.5397 96.6792 56.0274 96.6678Z" fill="#263238"/>
    <path d="M51.8518 86.3997C51.7838 86.6637 50.8438 86.6317 49.7638 86.8717C48.6838 87.1117 47.8238 87.4957 47.6598 87.2717C47.5838 87.1717 47.7118 86.9157 48.0598 86.6357C48.5041 86.2848 49.0248 86.0436 49.5798 85.9317C50.1333 85.8099 50.708 85.8222 51.2558 85.9677C51.6598 86.0997 51.8798 86.2797 51.8518 86.3997Z" fill="#263238"/>
    <path d="M60.511 86.348C60.3671 86.576 59.7111 86.42 58.9111 86.484C58.1111 86.548 57.499 86.768 57.311 86.56C57.235 86.46 57.3111 86.236 57.5831 86.008C57.9414 85.7248 58.3789 85.5598 58.835 85.536C59.2895 85.4989 59.7442 85.6053 60.135 85.84C60.459 86.032 60.5711 86.24 60.511 86.348Z" fill="#263238"/>
    <path d="M45.8552 82.5838L45.8232 82.6158C44.9317 83.4713 44.4045 84.6375 44.3512 85.8718C44.3512 85.9158 44.3512 85.9598 44.3512 85.9958C44.2832 87.7518 44.4952 89.5078 44.4112 91.2598C44.3272 93.0118 43.8992 94.8278 42.7352 96.1438C42.2592 96.6838 41.3632 97.1118 40.8752 96.5798C40.7054 96.3557 40.6027 96.088 40.5792 95.8078C39.8512 91.6638 38.3472 88.0678 37.9312 83.6958C37.8715 83.1112 37.9525 82.5207 38.1675 81.9738C38.3826 81.4269 38.7253 80.9393 39.1672 80.5518C40.0152 79.8038 41.0672 79.0158 42.1312 79.3998C43.1952 79.7838 44.6952 81.5558 45.5632 82.2838" fill="#263238"/>
    <path d="M42.2311 92.4238C42.1231 92.3918 37.9431 91.5998 38.4791 95.7318C39.0151 99.8638 43.0551 98.4758 43.0471 98.3558C43.0391 98.2358 42.2311 92.4238 42.2311 92.4238Z" fill="#FFBE9D"/>
    <path d="M41.5985 96.9557C41.5985 96.9557 41.5345 97.0158 41.4185 97.0838C41.3391 97.1323 41.2508 97.1647 41.1589 97.1791C41.0669 97.1935 40.973 97.1897 40.8825 97.1677C40.4265 97.0637 39.9665 96.4157 39.8505 95.6757C39.7937 95.3218 39.817 94.9596 39.9185 94.6157C39.9413 94.4815 39.9961 94.3547 40.0782 94.2461C40.1604 94.1374 40.2675 94.0502 40.3905 93.9917C40.4703 93.9604 40.5585 93.9577 40.6402 93.9839C40.7218 94.0101 40.7919 94.0638 40.8385 94.1357C40.9105 94.2437 40.8945 94.3317 40.9185 94.3357C40.9425 94.3397 40.9985 94.2557 40.9425 94.0877C40.9063 93.9878 40.8372 93.9032 40.7465 93.8477C40.6245 93.7806 40.4816 93.762 40.3465 93.7958C40.1808 93.8517 40.0321 93.9491 39.9145 94.0786C39.7969 94.2081 39.7143 94.3654 39.6745 94.5357C39.5445 94.9218 39.5102 95.3335 39.5745 95.7357C39.7105 96.5637 40.2385 97.2878 40.8585 97.3838C40.9737 97.4024 41.0916 97.3945 41.2033 97.3605C41.3149 97.3265 41.4173 97.2674 41.5025 97.1877C41.5985 97.0557 41.5985 96.9597 41.5985 96.9557Z" fill="#FFBE9D"/>
    <path d="M42.0481 80.0561C40.8481 78.4961 43.4321 75.8401 45.4161 74.9961C47.4001 74.1521 50.1961 74.8841 52.3001 75.3601C53.6241 75.6641 54.9641 76.1321 56.3001 75.9441C57.1959 75.7651 58.06 75.4537 58.8641 75.0201C59.6673 74.5793 60.552 74.3071 61.4641 74.2201C61.9209 74.1854 62.3785 74.2762 62.7874 74.4827C63.1963 74.6893 63.541 75.0038 63.7841 75.3921C64.2081 76.1921 63.8281 77.3921 62.9401 77.6121C63.2132 77.4242 63.5278 77.3054 63.857 77.2659C64.1861 77.2264 64.52 77.2674 64.8298 77.3854C65.1396 77.5034 65.4162 77.6948 65.6357 77.9432C65.8552 78.1917 66.0111 78.4897 66.0901 78.8117C66.169 79.1337 66.1686 79.47 66.0889 79.7918C66.0092 80.1136 65.8525 80.4112 65.6324 80.6591C65.4123 80.907 65.1353 81.0978 64.8251 81.215C64.515 81.3322 64.1811 81.3724 63.8521 81.3321C64.4001 80.8281 65.3761 81.4801 65.3761 82.2201C65.3761 82.9601 64.7561 83.5681 64.1081 83.9321C62.4521 84.8601 60.4121 84.8121 58.5641 84.3841C56.7161 83.9561 54.9641 83.1841 53.1241 82.7041C51.284 82.2241 48.2 81.3601 46.4801 82.1641C45.216 82.7521 44.456 84.4201 43.68 84.1641C43.3128 84.0117 42.9865 83.7753 42.7274 83.4737C42.4683 83.1722 42.2835 82.8141 42.1881 82.4281C42.0094 81.6523 41.958 80.8525 42.036 80.0601" fill="#263238"/>
    <path d="M42.2832 93.1317C42.3348 95.3287 42.5865 97.5164 43.0352 99.6677C43.4837 101.826 44.5078 103.824 45.9992 105.448C47.9992 107.5 50.6232 109.448 53.4632 109.932C56.5632 110.444 60.3552 109.796 62.8792 107.932C64.0792 107.048 64.9472 105.132 64.5752 103.684C65.0833 103.444 65.518 103.073 65.8345 102.609C66.151 102.145 66.3377 101.604 66.3752 101.044C66.4392 99.9237 65.7552 98.5237 64.8712 98.3757C65.5352 98.2637 65.7272 97.2077 65.4752 96.6117C65.2232 96.0157 64.3472 95.8357 63.6832 95.9637C63.0269 96.1355 62.4165 96.4496 61.8952 96.8837C61.3769 97.3184 60.7921 97.6668 60.1632 97.9157C58.0192 98.6117 55.8472 96.7157 53.5952 96.8077C51.5592 96.8877 49.7472 98.5797 47.7272 98.2957C47.1665 98.1888 46.6345 97.9652 46.1659 97.6392C45.6972 97.3132 45.3025 96.8923 45.0072 96.4037C44.3832 95.4477 44.6552 91.9157 44.4112 90.8037" fill="#263238"/>
    <g opacity="0.6">
      <path d="M58.624 102.656C58.4554 102.617 58.2897 102.566 58.128 102.504C57.7363 102.392 57.324 102.372 56.923 102.444C56.5221 102.517 56.1434 102.681 55.816 102.924C55.56 103.108 55.416 103.256 55.416 103.236C55.416 103.216 55.496 103.04 55.74 102.836C56.0685 102.547 56.4699 102.353 56.9007 102.275C57.3315 102.198 57.7753 102.239 58.184 102.396C58.48 102.496 58.636 102.636 58.624 102.656Z" fill="#455A64"/>
    </g>
    <path d="M54.2443 98.06C54.3963 98.036 54.4963 99.048 55.4443 99.66C56.3923 100.272 57.4443 100.08 57.4683 100.22C57.4883 100.28 57.2482 100.44 56.7922 100.496C56.2013 100.565 55.6049 100.424 55.1083 100.096C54.6325 99.7783 54.2996 99.2875 54.1802 98.728C54.0882 98.32 54.1763 98.06 54.2443 98.06Z" fill="white"/>
    <path d="M51.2363 109.256C51.3268 109.276 51.4142 109.309 51.4963 109.352C51.6683 109.416 51.9203 109.504 52.2523 109.596C53.1932 109.85 54.1588 110.002 55.1323 110.048C55.799 110.087 56.4678 110.074 57.1323 110.008C57.8988 109.939 58.6561 109.792 59.3923 109.568C60.2052 109.325 60.9805 108.971 61.6963 108.516C62.4747 108.046 63.1541 107.429 63.6963 106.7C64.3405 105.841 64.6272 104.766 64.4963 103.7V103.616L64.5683 103.584C65.0659 103.352 65.4966 102.999 65.8203 102.556C65.9773 102.347 66.1114 102.121 66.2203 101.884C66.3159 101.653 66.3753 101.409 66.3963 101.16C66.4399 100.709 66.3755 100.255 66.2086 99.8338C66.0416 99.413 65.7769 99.0379 65.4363 98.7397L65.3763 98.6917L65.4243 98.6317C65.6047 98.4028 65.7225 98.1308 65.766 97.8425C65.8094 97.5543 65.7772 97.2597 65.6723 96.9877C65.5901 96.7814 65.4658 96.5945 65.3075 96.4389C65.1491 96.2833 64.96 96.1623 64.7523 96.0837C64.4872 95.9778 64.2003 95.938 63.9163 95.9677C63.7203 95.9917 63.6243 96.0277 63.6203 96.0157C63.7127 95.9718 63.8111 95.9421 63.9123 95.9277C64.2923 95.8662 64.682 95.932 65.0206 96.1151C65.3592 96.2982 65.6277 96.5881 65.7843 96.9397C65.9028 97.233 65.9429 97.552 65.9009 97.8655C65.8588 98.1789 65.7359 98.4761 65.5443 98.7277V98.6197C65.9097 98.9317 66.1954 99.3263 66.3777 99.7709C66.56 100.215 66.6336 100.697 66.5923 101.176C66.5719 101.451 66.5084 101.721 66.4043 101.976C66.2883 102.226 66.1476 102.465 65.9843 102.688C65.6438 103.163 65.1864 103.542 64.6563 103.788L64.7163 103.668C64.8769 104.788 64.5894 105.926 63.9163 106.836C63.358 107.587 62.6582 108.221 61.8563 108.704C61.126 109.168 60.3325 109.525 59.5003 109.764C58.7511 109.989 57.9801 110.134 57.2003 110.196C56.5227 110.249 55.8419 110.249 55.1643 110.196C54.1832 110.134 53.2126 109.958 52.2723 109.672C52.0174 109.592 51.7676 109.498 51.5243 109.388C51.4239 109.354 51.3273 109.31 51.2363 109.256Z" fill="#455A64"/>
    <path d="M33.1037 98.6999C33.0271 98.6789 32.9552 98.6436 32.8917 98.5959C32.7115 98.4609 32.5729 98.2779 32.4917 98.0679C32.3565 97.7299 32.3273 97.3589 32.4077 97.0039C32.5339 96.5339 32.816 96.1207 33.2077 95.8319C33.6679 95.485 34.1812 95.2149 34.7277 95.0319C35.3401 94.8394 35.9323 94.5876 36.4957 94.2799C37.0886 93.9046 37.5532 93.3577 37.8277 92.7119C38.1289 92.0169 38.2893 91.2692 38.2997 90.5119C38.3317 88.9479 37.9717 87.4639 37.9317 86.0839C37.8917 84.7039 38.1317 83.4159 38.7597 82.5519C39.2301 81.8593 39.9442 81.3694 40.7597 81.1799C40.9809 81.1358 41.2074 81.125 41.4317 81.1479C41.51 81.157 41.5875 81.1717 41.6637 81.1919C41.3674 81.1778 41.0704 81.2006 40.7797 81.2599C39.9937 81.4675 39.3116 81.9574 38.8637 82.6359C38.2797 83.4759 38.0357 84.7239 38.1077 86.0759C38.1797 87.4279 38.5077 88.9119 38.5077 90.5199C38.4911 91.306 38.3184 92.081 37.9997 92.7999C37.7057 93.4813 37.2101 94.0563 36.5797 94.4479C36.0009 94.7612 35.3909 95.0133 34.7597 95.1999C34.2288 95.3678 33.7279 95.6189 33.2757 95.9439C32.9043 96.2069 32.6323 96.5873 32.5037 97.0239C32.4165 97.3567 32.4319 97.708 32.5477 98.0319C32.6532 98.3105 32.8489 98.5457 33.1037 98.6999Z" fill="#455A64"/>
    <path d="M63.0923 77.5439C63.1491 77.5095 63.2094 77.4813 63.2723 77.4599C63.3483 77.426 63.4273 77.3992 63.5083 77.3799C63.6169 77.3479 63.7279 77.3252 63.8403 77.3119C64.2136 77.2713 64.5913 77.316 64.9447 77.4429C65.2981 77.5697 65.6181 77.7752 65.8803 78.0439C66.1426 78.3957 66.3325 78.7961 66.4389 79.2218C66.5453 79.6475 66.5662 80.0901 66.5003 80.5239C66.4218 81.062 66.2363 81.5789 65.9546 82.044C65.6729 82.5091 65.3008 82.9131 64.8603 83.2319C63.8461 83.9703 62.6341 84.3883 61.3803 84.4319C60.0211 84.4854 58.6614 84.3399 57.3443 83.9999C55.2521 83.4864 53.267 82.6076 51.4803 81.4039C50.7166 80.8789 49.9143 80.4122 49.0803 80.0079C48.3499 79.6699 47.5634 79.4693 46.7603 79.4159C45.5905 79.3351 44.4192 79.5526 43.3563 80.0479C42.6083 80.3999 42.2523 80.7039 42.2363 80.6799C42.5648 80.3971 42.9293 80.1591 43.3203 79.9719C44.3895 79.4432 45.5775 79.2006 46.7683 79.2679C47.5974 79.3104 48.4113 79.5071 49.1683 79.8479C50.0143 80.2513 50.8286 80.7179 51.6043 81.2439C53.3783 82.4238 55.3434 83.2872 57.4123 83.7959C58.7091 84.1363 60.0483 84.2872 61.3883 84.2439C62.6039 84.2014 63.7798 83.8006 64.7683 83.0919C65.1927 82.7884 65.5526 82.4038 65.8272 81.9602C66.1019 81.5167 66.2858 81.0231 66.3683 80.5079C66.4388 80.091 66.426 79.6642 66.3305 79.2523C66.2351 78.8403 66.059 78.4514 65.8123 78.1079C65.5642 77.8478 65.2616 77.6459 64.9261 77.5165C64.5907 77.3872 64.2309 77.3337 63.8723 77.3599C63.608 77.4011 63.3472 77.4626 63.0923 77.5439Z" fill="#455A64"/>
    <path d="M60.9716 74.02C60.9712 74.3349 60.9072 74.6464 60.7836 74.936C60.4367 75.7319 59.8342 76.3893 59.0716 76.804C58.5566 77.1037 57.9914 77.307 57.4036 77.404C56.7298 77.5022 56.0453 77.5022 55.3716 77.404C53.9436 77.224 52.4676 76.664 50.9156 76.18C49.3636 75.696 47.7996 75.408 46.4236 75.704C45.2043 75.9703 44.1 76.6142 43.2676 77.544C42.7005 78.1642 42.2663 78.8938 41.9916 79.688C41.8076 80.256 41.7716 80.588 41.7476 80.584C41.7378 80.5043 41.7378 80.4237 41.7476 80.344C41.7796 80.1139 41.8291 79.8866 41.8956 79.664C42.1441 78.8424 42.5686 78.0849 43.1396 77.444C43.9802 76.4728 45.1102 75.797 46.3636 75.516C47.7876 75.2 49.3956 75.492 50.9556 75.98C52.5156 76.468 53.9796 77.028 55.3756 77.212C56.0321 77.3079 56.6991 77.3079 57.3556 77.212C57.9259 77.1258 58.476 76.9374 58.9796 76.656C59.7251 76.265 60.3242 75.6434 60.6876 74.884C60.8051 74.604 60.9001 74.3151 60.9716 74.02Z" fill="#455A64"/>
    <path d="M91.2485 48.7039C91.2125 48.7479 88.9645 46.9199 86.2245 44.6199C83.4845 42.3199 81.2965 40.3999 81.3365 40.3719C81.3765 40.3439 83.6205 42.1559 86.3605 44.4599C89.1005 46.7639 91.2885 48.6599 91.2485 48.7039Z" fill="#263238"/>
    <path d="M102.656 45.9638C102.6 45.9638 102.372 42.4118 102.152 38.0238C101.932 33.6358 101.796 30.0758 101.852 30.0718C101.908 30.0678 102.136 33.6238 102.36 38.0158C102.584 42.4078 102.712 45.9638 102.656 45.9638Z" fill="#263238"/>
    <path d="M95.3907 43.4239C95.3347 43.4239 94.6347 41.4239 93.8227 38.8919C93.0107 36.3599 92.3987 34.3159 92.4547 34.2999C92.5107 34.2839 93.2107 36.2999 94.0227 38.8279C94.8347 41.3559 95.4427 43.4079 95.3907 43.4239Z" fill="#263238"/>
    <path d="M88.916 52.4556C87.8627 52.085 86.8328 51.6509 85.832 51.1556C84.8019 50.7213 83.7999 50.223 82.832 49.6636C83.8854 50.0341 84.9153 50.4683 85.916 50.9636C86.9457 51.3988 87.9476 51.8971 88.916 52.4556Z" fill="#263238"/>
    <path d="M78.3996 47.8596C78.3996 47.9156 78.0596 47.9236 77.6556 47.8596C77.2516 47.7956 76.9316 47.7236 76.9396 47.6676C76.9476 47.6116 77.2796 47.6036 77.6796 47.6676C78.0796 47.7316 78.3996 47.8036 78.3996 47.8596Z" fill="#263238"/>
    <path d="M92.7153 31.7159C92.6553 31.7159 92.6113 31.3919 92.6113 30.9879C92.6113 30.5839 92.6553 30.2559 92.7153 30.2559C92.7753 30.2559 92.8193 30.5839 92.8193 30.9879C92.8193 31.3919 92.7713 31.7159 92.7153 31.7159Z" fill="#263238"/>
    <path d="M116.8 37.3637C116.844 37.4037 114.996 39.5197 112.68 42.0917C110.364 44.6637 108.452 46.7197 108.4 46.6797C108.348 46.6397 110.203 44.5277 112.519 41.9517C114.835 39.3757 116.76 37.3277 116.8 37.3637Z" fill="#263238"/>
    <path d="M119.74 34.4556C119.78 34.4956 119.568 34.7756 119.264 35.0796C118.96 35.3836 118.684 35.5916 118.644 35.5516C118.604 35.5116 118.816 35.2316 119.12 34.9316C119.424 34.6316 119.7 34.3996 119.74 34.4556Z" fill="#263238"/>
    <path d="M126.867 44.6958C126.695 44.8194 126.513 44.9278 126.323 45.0198C125.967 45.2158 125.447 45.4918 124.799 45.8198C123.499 46.4758 121.679 47.3318 119.631 48.1838C117.583 49.0358 115.691 49.7158 114.307 50.1838C113.619 50.4118 113.059 50.5838 112.667 50.6998C112.468 50.7719 112.263 50.8241 112.055 50.8558C112.244 50.7622 112.441 50.6846 112.643 50.6238L114.243 50.0438C115.611 49.5518 117.487 48.8438 119.535 48.0038C121.583 47.1638 123.403 46.3198 124.735 45.6998L126.287 44.9638C126.473 44.8603 126.667 44.7707 126.867 44.6958Z" fill="#263238"/>
    <path d="M121.019 54.5439C120.677 54.6962 120.321 54.8128 119.955 54.8919C119.291 55.0759 118.355 55.2919 117.323 55.4719C116.291 55.6519 115.323 55.7519 114.647 55.7999C114.277 55.8471 113.903 55.8538 113.531 55.8199C113.894 55.7362 114.264 55.6801 114.635 55.6519C115.315 55.5679 116.255 55.4399 117.287 55.2519C118.319 55.0639 119.251 54.8839 119.923 54.7399C120.282 54.641 120.649 54.5754 121.019 54.5439Z" fill="#263238"/>
    <path d="M126.335 53.5596C125.574 53.6982 124.793 53.6982 124.031 53.5596C124.793 53.4209 125.574 53.4209 126.335 53.5596Z" fill="#263238"/>
    <path d="M96.2709 152.8C96.8339 152.896 97.3719 153.104 97.8529 153.411C98.3339 153.719 98.7481 154.121 99.0709 154.592C99.7081 155.544 100.119 156.629 100.271 157.764C100.635 159.94 100.047 162.412 99.2389 164.468C97.0189 163.424 95.8349 160.84 95.3189 159.488C94.5189 157.356 94.3149 153 96.2509 152.8" fill="#64A172"/>
    <path d="M102.532 167.544C102.198 166.999 102.014 166.375 101.999 165.735C101.984 165.096 102.139 164.464 102.448 163.904C102.765 163.349 103.189 162.863 103.696 162.474C104.203 162.086 104.782 161.802 105.4 161.64C105.676 161.535 105.972 161.491 106.267 161.511C106.562 161.532 106.849 161.617 107.108 161.76C107.323 161.933 107.489 162.16 107.587 162.418C107.686 162.676 107.714 162.956 107.668 163.228C107.569 163.772 107.329 164.281 106.972 164.704C105.812 166.2 104.42 167.432 102.532 167.544Z" fill="#64A172"/>
    <path d="M101.971 174.44C101.925 174.292 101.899 174.138 101.895 173.984C101.867 173.688 101.827 173.256 101.799 172.724C101.698 171.332 101.783 169.933 102.051 168.564C102.338 167.18 102.992 165.9 103.943 164.856C104.237 164.549 104.567 164.28 104.927 164.056C105.047 163.964 105.183 163.893 105.327 163.848C104.872 164.189 104.447 164.568 104.055 164.98C103.168 166.025 102.557 167.277 102.279 168.62C102.014 169.971 101.907 171.348 101.959 172.724C101.998 173.295 102.002 173.868 101.971 174.44Z" fill="#263238"/>
    <path d="M96.7988 156.116C96.911 156.345 97.0046 156.583 97.0788 156.828C97.2428 157.292 97.4788 157.964 97.7428 158.8C98.2908 160.472 99.0068 162.8 99.7428 165.38C100.479 167.96 101.071 170.32 101.479 172.032C101.683 172.888 101.839 173.58 101.939 174.064C102.005 174.31 102.051 174.562 102.075 174.816C101.977 174.58 101.901 174.337 101.847 174.088C101.707 173.568 101.527 172.888 101.311 172.088C100.859 170.388 100.231 168.04 99.5028 165.46C98.7748 162.88 98.0868 160.548 97.5828 158.864L96.9948 156.864C96.9089 156.62 96.8433 156.37 96.7988 156.116Z" fill="#263238"/>
    <path d="M99.5751 169.6C99.0168 168.377 98.1758 167.304 97.1215 166.469C96.0673 165.635 94.8298 165.063 93.5111 164.8C92.8391 164.672 92.0111 164.688 91.6351 165.264C91.2591 165.84 91.5831 166.608 91.9871 167.16C92.8491 168.305 94.0313 169.168 95.3842 169.641C96.737 170.114 98.1996 170.175 99.5871 169.816" fill="#64A172"/>
    <path d="M94.2462 166.716C94.3957 166.71 94.5453 166.726 94.6902 166.764C94.8619 166.788 95.0317 166.824 95.1982 166.872C95.423 166.925 95.6447 166.99 95.8622 167.068C96.4305 167.267 96.9773 167.523 97.4942 167.832C98.7703 168.587 99.8502 169.633 100.646 170.884C100.966 171.389 101.234 171.925 101.446 172.484C101.533 172.698 101.606 172.917 101.666 173.14C101.722 173.306 101.764 173.476 101.794 173.648C101.836 173.791 101.855 173.939 101.85 174.088C101.806 174.088 101.682 173.476 101.286 172.552C101.051 172.012 100.769 171.494 100.442 171.004C100.038 170.408 99.576 169.853 99.0622 169.348C98.5415 168.851 97.9735 168.406 97.3662 168.02C96.865 167.708 96.3377 167.44 95.7902 167.22C94.8622 166.868 94.2382 166.764 94.2462 166.716Z" fill="#263238"/>
  </svg>
);

const ConfirmationView = (props) => {
  const { confirmationViewCb, confirmationViewCTA, confirmationViewTitle, confirmationViewDescription } = props;

  const proceedCb = () => {
    confirmationViewCb();
  };

  return (
    <div id="confirmation-view-container" className="flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
      <div className="w-[452px] p-8">
        <XEALogo {...props} />

        <div className="flex flex-col">
          {confirmationViewTitle &&
            <div className="text-center font-semibold text-lg text-[#191919] dark:text-[#FFFFFF]">{confirmationViewTitle}</div>}
          {confirmationViewDescription &&
            <p className="mt-2 text-center font-medium text-sm mb-4 text-[#5F5F5F] dark:text-[#FFFFFF]">{confirmationViewDescription}</p>}

          <div className="mx-auto">
            {passcodeVerifiedSVG}
          </div>

          <button className="submit-button" tabIndex="1" onClick={proceedCb}>{confirmationViewCTA}</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationView;