import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Login from './Account/Login';
import ForgotPassword from './Account/ForgotPassword';
import Signup from './Account/Signup';
import LoginRedirect from './Account/LoginRedirect';
import ResetPassword from './Account/ResetPassword';
import ConfirmPasscode from './Account/ConfirmPasscode';
import InviteLanding from './Account/Invite';
import NotFound from './404';
import XEALauncher from './XEALauncher';

const UnauthedApp = (props) => {
  const location = useLocation();
  if (location.pathname.match(/^\/signup($|\/)/)) {
    TagManager.initialize({ gtmId: 'GTM-K7SWWTP2' });
  }
  return (
    <Switch>
      <Route path="/start" exact>
        <XEALauncher {...props} hideDownloadButton={true} />
      </Route>
      <Route path="/login" exact>
        <Login {...props} />
      </Route>
      <Route path="/forgot-password" exact>
        <ForgotPassword {...props} />
      </Route>
      <Route path="/reset-password" exact>
        <ResetPassword {...props} />
      </Route>
      <Route path="/signup" exact>
        <Signup {...props} />
      </Route>
      <Route path={["/signup/:selection", "/signup/:selection/:plan"]} exact>
        <Signup {...props} />
      </Route>
      <Route path="/confirm-passcode" exact>
        <ConfirmPasscode {...props} />
      </Route>
      <Route path="/invite/:inviteId">
        <InviteLanding {...props} />
      </Route>
      <Route path="/workspace/*">
        <LoginRedirect {...props} />
      </Route>
      <Route path="/" exact>
        <LoginRedirect {...props} />
      </Route>
      <Route path="*">
        <NotFound {...props} hideCTA={true} />
      </Route>
    </Switch>
  )
};

export default UnauthedApp;
