import React from "react";
import { Alert }  from "flowbite-react";

import { CloseCircle } from "@stoatlabs/xea-client-shared-components";

const InvitesOverflowAlert = (props) => {
  const { removeInvitesOverflowAlert, pendingInvitationsCount, remainingSeatsCount, upgradeLink } = props;

  const additionalContent = (
    <div className="flex flex-col">
      <div className="text-style-small text-xgray-700">
      There {`${pendingInvitationsCount === 1 ? 'is 1 user' : `are ${pendingInvitationsCount} users`}`} with pending invitations. Only
      <span className="text-style-small-semibold">{`${remainingSeatsCount === 1 ? ' 1 seat remains ' : ` ${remainingSeatsCount} seats remain `}`}</span>
      in your plan. Some of the invited users will not be able to join once capacity is reached.
      </div>
      {!!upgradeLink && <a className="red-button btn-xs mt-2 mr-auto" href={upgradeLink.href} title={upgradeLink.title} target="_blank" rel="noreferrer">Upgrade Plan</a>}
    </div>
  );

  return (
    <Alert color="failure" onDismiss={removeInvitesOverflowAlert} icon={CloseCircle} additionalContent={additionalContent} className="mt-4">
      <span>
        <p>
          <span className="text-style-semibold text-xred-700">Upgrade to Accommodate All Invited Users</span>
        </p>
      </span>
    </Alert>
  );
};

export default InvitesOverflowAlert;