import React from 'react';
import { AmplifyLogin } from '@stoatlabs/xea-client-shared-components';

import ComponentWrapper from '../ComponentWrapper';
import { login } from '../../store/currentUser';

const Login = (props) => {
  const { navigate } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const signupSearchParam = urlParams.get('signup') ?? '/signup';

  const childProps = {
    ...props,
    showSignupLink: true,
    hideCloseButton: true,
    signupInsteadCb: () => {
      navigate(signupSearchParam);
    },
    goToForgotPassword: (email) => {
      const url = new URL('/forgot-password', window.location.origin);
      url.searchParams.set('email', email);
      navigate(url);
    },
    loginWithEmailAndPassword: (email, pswd) => {
      return new Promise(async (resolve, reject) => {
        try {
          const amplifyUser = await login(email, pswd);
          if (amplifyUser) {
            navigate('/');
          }
          resolve(amplifyUser);
        } catch (e) {
          if (e.name === 'UserNotConfirmedException') {
            // needs confirm
            const url = new URL('/confirm-passcode', window.location.origin);
            url.searchParams.set('email', email);

            navigate(url);
            resolve(true);
            return;
          }
          if (e.name === 'UserNotFoundException' || e.name === 'NotAuthorizedException') {
            resolve(false);
            return;
          }
          reject(e);
        }
      });
    },
  };

  return (
    <ComponentWrapper {...childProps}>
      <AmplifyLogin {...childProps} />
    </ComponentWrapper>
  );
};

export default Login;