import React, { useState, useContext, useEffect } from "react";

import UsersTable from "./UsersTable";
import Workspace from "../workspace/Workspace";
import InviteInlineForm from "./InviteInlineForm";
import { UserContext } from "../../context/UserContext";
import { MembershipState, WorkspaceStatus } from "../../models";
import InvitesOverflowAlert from "./InvitesOverflowAlert";
import SeatsLimitReachedAlert from "./SeatsLimitReachedAlert";
import { CurrentWorkspaceContext } from "../../context/CurrentWorkspaceContext";
import { WorkspaceContext } from "../../context/WorkspaceContext";
import { getUpgradeLink } from "./utils/upgrade";
import { MembershipContext } from "../../context/MembershipContext";
import SubscriptionExpiredWarning from "../workspace/SubscriptionExpiredWarning";

const alertTypes = {
  INVITES_OVERFLOW: 'INVITES_OVERFLOW',
  SEATS_LIMIT_REACHED: 'SEATS_LIMIT_REACHED',
  NONE: 'NONE',
};

const UsersManagement = (props) => {
  const { currentUser } = useContext(UserContext);
  const { currentWorkspace, workspaces } = useContext(WorkspaceContext);
  const [showAlert, setShowAlert] = useState(alertTypes.NONE);
  const { memberships: allMemberships } = useContext(CurrentWorkspaceContext);
  const { memberships: userMemberships } = useContext(MembershipContext);
  const [memberships, setMemberships] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!currentUser || !currentWorkspace) { return; }

    const fetchUsers = async () => {
      const filteredMemberships = allMemberships?.data?.filter(m => m.workspace.id === currentWorkspace.id);
      const filteredUsers = filteredMemberships?.map(m => m.state === MembershipState.ACCEPTED && m.user).filter(u => Boolean(u?.id));

      setMemberships(filteredMemberships);
      setUsers(filteredUsers);
    };

    fetchUsers();
  }, [allMemberships, currentUser, currentWorkspace]);

  const innerProps = {
    ...props,
    memberships,
    currentUser,
  };

  const purchasedSeats = currentWorkspace.configuration.maxUsers || 1;
  const pendingMemberships = memberships.filter(m => m.state === MembershipState.PENDING);
  const remainingSeatsCount = purchasedSeats - users.length;
  const showRemainingSeats = remainingSeatsCount >= 0;
  // const expiredMemberships = memberships.filter(m => m.state === MembershipState.EXPIRED);

  useEffect(() => {
    if (users.length >= purchasedSeats) {
      setShowAlert(alertTypes.SEATS_LIMIT_REACHED);
    } else if (pendingMemberships.length > remainingSeatsCount) {
      setShowAlert(alertTypes.INVITES_OVERFLOW);
    } else {
      setShowAlert(alertTypes.NONE);
    }
  }, [users.length, pendingMemberships.length, purchasedSeats, remainingSeatsCount]);

  const removeAlert = () => {
    setShowAlert(false);
  };

  const upgradeLink = getUpgradeLink(userMemberships?.data, workspaces, currentWorkspace);

  const inactiveWorkspace = currentWorkspace?.status === WorkspaceStatus.INACTIVE;

  return (
    <Workspace {...innerProps}>
      <div className="flex flex-row items-center">
        <h1 className='text-style-2xl-semibold text-xgray-900 mr-auto'>Manage Team</h1>
        <InviteInlineForm {...innerProps} disableInput={inactiveWorkspace || remainingSeatsCount <= 0}/>
      </div>
      {inactiveWorkspace && <SubscriptionExpiredWarning {...props} />}
      { showAlert === alertTypes.INVITES_OVERFLOW &&
        <InvitesOverflowAlert {...innerProps}
          removeInvitesOverflowAlert={removeAlert}
          pendingInvitationsCount={pendingMemberships.length}
          remainingSeatsCount={remainingSeatsCount}
          upgradeLink={upgradeLink} /> }
      { showAlert === alertTypes.SEATS_LIMIT_REACHED &&
        <SeatsLimitReachedAlert {...innerProps} removeSeatsLimitReachedAlert={removeAlert} upgradeLink={upgradeLink} /> }
      <div className="mt-6">
        <span className="text-style-8 text-xgray-500">
          <strong className="text-style-small-semibold text-xgray-900 mr-2">{ purchasedSeats }</strong>
          Purchased
        </span>
        <span className="text-style-8 text-xgray-500 border-l border-xgray-500 pl-2 ml-2">
          <strong className="text-style-small-semibold text-xgray-900 mr-2">{ users.length }</strong>
          Used
        </span>
        {/* { !!pendingMemberships.length &&
          <span className="text-style-8 text-xgray-500 border-l border-xgray-500 pl-2 ml-2">
            <strong className="text-style-small-semibold text-xgray-900 mr-2">{ pendingMemberships.length }</strong>
            Pending
          </span>}
        { !!expiredMemberships.length &&
          <span className="text-style-8 text-xgray-500 border-l border-xgray-500 pl-2 ml-2">
            <strong className="text-style-small-semibold text-xgray-900 mr-2">{ expiredMemberships.length }</strong>
            Expired
          </span>} */}
        { showRemainingSeats &&
          <span className="text-style-8 text-xgray-500 border-l border-xgray-500 pl-2 ml-2">
            <strong className="text-style-small-semibold text-xgray-900 mr-2">{ remainingSeatsCount }</strong>
            Remaining
          </span>}
      </div>
      { users.length > 0 && <UsersTable {...innerProps} /> }
    </Workspace>
  );
};

export default UsersManagement;
