import React, { useState, useRef, useEffect } from 'react';
import { Alert } from "flowbite-react";

import { useIsMounted } from '../hooks/useIsMounted';
import useMultipleClicks from '../hooks/useMultipleClicks';
import XEALogoVertical from './XEALogo/XEALogoVertical';
import GreenSucceedCheckmark from './Svg/GreenSucceedCheckmark';
import ShowPasswordIcon from './Svg/ShowPasswordIcon';
import { CloseCircle } from '../flowbite-icons';
import { theme as FlowbiteCustomTheme } from '../flowbite-theme';

const passwordMinLength = 8;

const LoginErrorStates = {
  NONE: 0,
  CREDENTIALS: 1,
  TECHNICAL: 2,
}

const AmplifyLogin = (props) => {
  const { showSignupLink, loginWithEmailAndPassword, hideCTA, signupInsteadCb, goToForgotPassword, openEnvPickerCb } = props;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(LoginErrorStates.NONE);
  const [pswd, setPswd] = useState('');
  const [showPswd, setShowPswd] = useState(false);
  const [authWorking, setAuthWorking] = useState(false);
  const [loginBtnDisabled, setLoginBtnDisabled] = useState(true);
  const emailFieldRef = useRef(null);
  const pswdFieldRef = useRef(null);

  const multipleClicksHandler = useMultipleClicks(() => {
    if (typeof openEnvPickerCb === "function") {
      openEnvPickerCb();
    }
  }, 7);

  // Password reset message
  const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const passwordResetParam = urlParams.get('passwordReset');
  const messageParam = urlParams.get('message');
  const [resetPasswordMessage, setResetPasswordMessage] = useState(null);
  const isMounted = useIsMounted();

  useEffect(() => {
    setLoginBtnDisabled(!email.length
                        || !emailFieldRef.current?.validity?.valid
                        || !pswd.length
                        || !pswdFieldRef.current?.validity?.valid
                        || authWorking);
  }, [email, emailFieldRef.current, pswd, pswdFieldRef.current, authWorking]);

  const onLoginClick = async (_event) => {
    setAuthWorking(true);
    setEmailError(LoginErrorStates.NONE);
    try {
      const loginDone = await loginWithEmailAndPassword(email, pswd);
      if (!loginDone) {
        setEmailError(LoginErrorStates.CREDENTIALS);
        setAuthWorking(false);
      }
    } catch (e) {
      setEmailError(LoginErrorStates.TECHNICAL);
      setAuthWorking(false);
    }
  };

  const loginErrorTitle = {
    [LoginErrorStates.CREDENTIALS]: (<div><span className='text-style-semibold'>Invalid email or password.</span><span className='text-style-xray'> Please check your credentials and try again.</span></div>),
    [LoginErrorStates.TECHNICAL]: (<span className='text-style-semibold'>Login Failed</span>),
  };
  const loginErrorAdditionalContent = {
    [LoginErrorStates.TECHNICAL]: (<span className='text-xgray-700'>We are currently facing technical difficulties. Please try logging in again later. We apologize for any inconvenience this may cause.</span>)
  };
  const loginErrorIcon = {
    [LoginErrorStates.TECHNICAL]: () => <CloseCircle className="w-4 h-4 mr-2" />,
  };

  useEffect(() => {
    if (isMounted.current) {
      if (passwordResetParam === 'ok') {
        setResetPasswordMessage(messageParam);
      }
    }
    setTimeout(() => {
      setShowPasswordResetMessage(false);
    }, 10000);
  }, [isMounted.current, passwordResetParam, messageParam]);

  const keyDownHandler = async (e) => {
    if (e.keyCode === 13 && !loginBtnDisabled) {
      e.preventDefault();
      e.stopPropagation();
      await onLoginClick();
    }
  };

  const preventSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <div id="amplify-login-container" className="flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
        <div className="w-[452px] p-2 m-auto mt-8">
          <div className='w-[375px] mx-auto text-center' onClick={multipleClicksHandler}><XEALogoVertical {...props} /></div>

          <form className="flex flex-col" onSubmit={preventSubmit}>
            {!hideCTA && <div className="text-style-xl-semibold text-xgray-900 text-center">Log In to Your Account</div>}

            { showPasswordResetMessage && resetPasswordMessage && (
              <div className="message success mt-4">
                <span className="success-icon">
                  <GreenSucceedCheckmark />
                </span>
                <span className="success-message">{resetPasswordMessage}</span>
              </div>
            )}

            { emailError !== LoginErrorStates.NONE && (
              <Alert theme={FlowbiteCustomTheme.theme.alert} color="failure" icon={loginErrorIcon[emailError]} additionalContent={loginErrorAdditionalContent[emailError]} className="mt-4">
                {loginErrorTitle[emailError]}
              </Alert>
            )}
            <div className="input-field-container">
              <label className="input-field-label" htmlFor="email-text-input">
                <span className="text-xred-800 mr-1">*</span>
                Your Email
              </label>
              <input
                type="email"
                ref={emailFieldRef}
                autoFocus={true}
                tabIndex="1"
                id="email-text-input"
                className="input-field login-text-input"
                value={email}
                onChange={e => setEmail(e.target.value.toLowerCase())}
                onKeyDown={keyDownHandler}
                autoComplete="email"
              />
            </div>
            <div className="input-field-container">
              <label className="input-field-label" htmlFor="pswd-text-input">
                <span className="text-xred-800 mr-1">*</span>
                Password
              </label>
              <div className="input-field pswd-input-field">
                <input
                  tabIndex="2"
                  ref={pswdFieldRef}
                  type={showPswd ? 'text' : 'password'}
                  minLength={passwordMinLength}
                  id="pswd-text-input"
                  className="login-text-input"
                  value={pswd}
                  onChange={e => setPswd(e.target.value)}
                  onKeyDown={keyDownHandler}
                  autoComplete="password"
                />
                <button tabIndex="-1" className="show-pswd-btn" type="button" onClick={() => setShowPswd(!showPswd)}>
                  <ShowPasswordIcon hide={showPswd} />
                </button>
              </div>
              <div className="input-field-footer-link">
                <button tabIndex="4" onClick={() => goToForgotPassword(email)}>Forgot password?</button>
              </div>
            </div>
            <button tabIndex="3" type='submit' className="submit-button" disabled={loginBtnDisabled} onClick={onLoginClick}>Log In</button>
            { showSignupLink &&
              <p className="mt-[16px] h-[19px] text-center text-style-small-medium text-xgray-500">
                Not registered?
                <button tabIndex="5" className="inline-block h-[19px] border-none outline-none text-xray-primary-select ml-2" onClick={signupInsteadCb}>Create account</button>
              </p>
            }
          </form>
        </div>
      </div>
    </>
  );
};

export default AmplifyLogin;
