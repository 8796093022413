import { useEffect, useContext } from 'react';

import { MembershipContext } from '../context/MembershipContext';
import { uniq } from 'lodash';

export function useObserveWorkspaces(userId, setWorkspaces) {
  const { memberships } = useContext(MembershipContext);

  useEffect(() => {
    const workspaces = uniq(memberships?.data?.map(membership => membership.workspace));
    if(!userId || !workspaces || !workspaces.length) return;

    setWorkspaces(workspaces);
  }, [userId, memberships, setWorkspaces]);
};
