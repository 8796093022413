import { DocumentNode, Observable } from '@apollo/client';
import apollo from '../apollo';
import * as Components from '../apollo/components';

export async function observeCurrentUser(setUser: Function) {
  return _subscribeToQueryForUser(Components[`CurrentUserDocument`], (_loading, data) => { _observeSnapshot(`currentUser`, data, setUser); });
}

export async function observeCurrentUserUpdates(id: string, setUser: Function) {
  return _subscribeToSubscriptionForUser(Components[`OnUpdateUserDocument`], { id }, (_loading, data) => { _observeSnapshot(`currentUser`, data, setUser); });
}

export function observeForUser(target: string, setCollection: Function, callback?: Function) {
  return _subscribeToQueryForUser(Components[`List${target}Document`], (_loading, data) => { _observeSnapshot(`list${target}`, data, setCollection, callback); });
}

export function observeCreatesForUser(target: string, id: string, setCollection: Function) {
  return _subscribeToSubscriptionForUser(Components[`OnCreate${target}Document`], { id }, (_loading, data) => { _createSnapshot(`onCreate${target}`, data, setCollection); });
}

export function observeUpdatesForUser(target: string, id: string, setCollection: Function) {
  return _subscribeToSubscriptionForUser(Components[`OnUpdate${target}Document`], { id }, (_loading, data) => { _updateSnapshot(`onUpdate${target}`, data, setCollection); });
}

export function observeDeletesForUser(target: string, id: string, setCollection: Function) {
  return _subscribeToSubscriptionForUser(Components[`OnDelete${target}Document`], { id }, (_loading, data) => { _deleteSnapshot(`onDelete${target}`, data, setCollection); });
}

export function observeForWorkspace(target: string, workspaceId: string, setCollection: Function, callback?: Function) {
  return _subscribeToQueryForWorkspace(Components[`List${target}ByWorkspaceDocument`], { workspaceId }, workspaceId, (_loading, data) => { _observeSnapshot(`list${target}ByWorkspace`, data, setCollection, callback); });
}

export function observeCreatesForWorkspace(target: string, workspaceId: string, setCollection: Function) {
  return _subscribeToSubscriptionForWorkspace(Components[`OnCreate${target}ForWorkspaceDocument`], { workspaceId }, workspaceId, (_loading, data) => { _createSnapshot(`onCreate${target}ForWorkspace`, data, setCollection); });
}

export function observeUpdatesForWorkspace(target: string, workspaceId: string, setCollection: Function) {
  return _subscribeToSubscriptionForWorkspace(Components[`OnUpdate${target}ForWorkspaceDocument`], { workspaceId }, workspaceId, (_loading, data) => { _updateSnapshot(`onUpdate${target}ForWorkspace`, data, setCollection); });
}

export function observeDeletesForWorkspace(target: string, workspaceId: string, setCollection: Function) {
  return _subscribeToSubscriptionForWorkspace(Components[`OnDelete${target}ForWorkspaceDocument`], { workspaceId }, workspaceId, (_loading, data) => { _deleteSnapshot(`onDelete${target}ForWorkspace`, data, setCollection); });
}

function _observeSnapshot(target: string, data: any, setCollection: Function, callback?: Function) {
  const objectData = data?.[target];
  if(!objectData) return;

  setCollection({ type: 'set', refObj: objectData, loading: false });
  if(callback) { callback(objectData); }
}

function _createSnapshot(target: string, data: any, setCollection: Function) {
  const newObject = data?.[target];
  if(!newObject) return;

  setCollection({ type: 'create', refObj: newObject });
}

function _updateSnapshot(target: string, data: any, setCollection: Function) {
  const updatedObject = data?.[target];
  if(!updatedObject) return;

  setCollection({ type: 'update', refObj: updatedObject });
}

function _deleteSnapshot(target: string, data: any, setCollection: Function) {
  const deletedObject = data?.[target];
  if(!deletedObject) return;

  setCollection({ type: 'delete', refObj: deletedObject });
}

function _subscribeAction(observable: Observable<any>, nextCallback: Function): Function {
  const sub = observable.subscribe({
    next({ _loading, _error, data}) { nextCallback(_loading, data); },
    error(err) { console.error('err', err); },
  })

  const unsub = () => {
    if(!sub.closed) { sub.unsubscribe(); }
    document.removeEventListener('logoutEvent', unsub);
  };

  document.addEventListener('logoutEvent', unsub, { once: true });

  return unsub;
}

export function _subscribeToQueryForUser(query: DocumentNode, nextCallback: Function): Function {
  const observable = apollo.client.watchQuery({ query });
  return _subscribeAction(observable, nextCallback);
}

export function _subscribeToQueryForWorkspace(query: DocumentNode, variables: object, requiredId: string, nextCallback: Function): Function {
  if(!requiredId) return () => {};

  const observable = apollo.client.watchQuery({ query, variables });
  return _subscribeAction(observable, nextCallback);
}

export function _subscribeToSubscriptionForUser(query: DocumentNode, variables: object, nextCallback: Function): Function {
  const observable = apollo.client.subscribe({ query, variables });
  return _subscribeAction(observable, nextCallback);
}

export function _subscribeToSubscriptionForWorkspace(query: DocumentNode, variables: object, requiredId: string, nextCallback: Function): Function {
  if(!requiredId) return () => {};

  const observable = apollo.client.subscribe({ query, variables });
  return _subscribeAction(observable, nextCallback);
}