import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { UserContext } from "../../context/UserContext";
import { MembershipContext } from "../../context/MembershipContext";
import LoadingApp from "../LoadingApp";
import { getAllowedWorkspaceType } from "../../store/currentUser";

const StripeCheckoutCallback = (props) => {
  const { navigate } = props;
  const { action } = useParams();
  const [,setCookie,removeCookie] = useCookies(['signup-selection', 'signup-plan']);
  const { currentUser } = useContext(UserContext);
  const { memberships } = useContext(MembershipContext);
  const [subscriptionChecked, setSubscriptionChecked] = useState(false);

  useEffect(() => {
    const checkStripeSubscription = async () => {
      const { selection, plan } = await getAllowedWorkspaceType(currentUser, memberships?.data);
      if (selection && plan) {
        setCookie('signup-selection', selection, { path: '/' });
        setCookie('signup-plan', plan, { path: '/' });
      } else {
        removeCookie('signup-selection', { path: '/' });
        removeCookie('signup-plan', { path: '/' });
      }
      window.location = "/";
    };

    if (!subscriptionChecked && currentUser && memberships?.data?.length) {
      setSubscriptionChecked(true);
      if (action === 'success') {
        checkStripeSubscription();
      } else if (action === 'cancel') {
        navigate('/create-workspace');
      }
    }
  } , [action, currentUser, memberships, navigate, removeCookie, setCookie, subscriptionChecked]);

  return (
    <LoadingApp />
  );
};

export default StripeCheckoutCallback;