import { createSlice } from '@reduxjs/toolkit'

const urlParams = new URLSearchParams(window.location.search);
const openModalParam = urlParams.get('modal');

const modalTypes = {
  NO_MODAL: null,
  DOWNLOAD: 'DOWNLOAD_MODAL',
  DELETE_SESSION: 'DELETE_SESSION_MODAL',
  UNLOCK_SESSION: 'UNLOCK_SESSION_MODAL',
  LOCKED_SESSION: 'LOCKED_SESSION_MODAL',
  RENAME_SESSION: 'RENAME_SESSION_MODAL',
  DOWNLOAD_SESSION_ERROR: 'DOWNLOAD_SESSION_ERROR_MODAL',
  DUPLICATE_SESSION: 'DUPLICATE_SESSION_MODAL',
  CREATE_TEAM_WORKSPACE: 'CREATE_TEAM_WORKSPACE_MODAL',
  REMOVE_USER: 'REMOVE_USER_MODAL',
};

const initialState = {
  type: openModalParam || modalTypes.NO_MODAL,
};

export const sessionErrors = {
  DELETE_SESSION: {
    NOT_EXISTS: 'DELETE_SESSION__NOT_EXISTS',
    NOT_OWNER: 'DELETE_SESSION__NOT_OWNER'
  },
  UNLOCK_SESSION: {
    NOT_EXISTS: 'UNLOCK_SESSION__NOT_EXISTS',
    NOT_OWNER: 'UNLOCK_SESSION__NOT_OWNER'
  },
  LOCKED_SESSION: {
    NOT_EXISTS: 'LOCKED_SESSION__NOT_EXISTS',
  },
  RENAME_SESSION: {
    NOT_EXISTS: 'RENAME_SESSION__NOT_EXISTS',
    NOT_OWNER: 'RENAME_SESSION__NOT_OWNER',
    INVALID_NAME: 'RENAME_SESSION__INVALID_NAME'
  },
}

export const modalsSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      if (typeof action.payload === 'string') {
        return {
          type: action.payload,
        }
      }
      return action.payload;
    },
    closeModal: (state, action) => {
      if (action.payload === state.type) {
        return {
          ...state,
          type: modalTypes.NO_MODAL
        };
      }
      return state;
    },
    errorMessage: (state, action) => {
      return {
        ...state,
        error: true,
        errorType: action.payload.errorType,
        errorMessage: action.payload.errorMessage
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { openModal, closeModal, errorMessage } = modalsSlice.actions;

export { modalTypes };

export default modalsSlice.reducer;
