import React, { useEffect, useRef, useState } from "react";
import { Alert } from "flowbite-react";
import { CopyToClipboard, urlify } from "@stoatlabs/xea-client-shared-components";
import { v4 as uuid } from 'uuid';

import Steps from "./Steps";

const WorkspaceStep = (props) => {
  const { nextStep, configurationName, disableSubmitButton } = props;
  const workspaceNameRef = useRef();
  const [workspaceName, setWorkspaceName] = useState('');
  const [workspaceSlug, setWorkspaceSlug] = useState('')
  const [workspaceSuffix] = useState(uuid().slice(0, 6));

  useEffect(() => {
    workspaceNameRef.current.focus()
  }, []);

  const workspaceNameError = workspaceNameRef.current && !workspaceNameRef.current?.validity.valid;
  const showCopyButton = workspaceName?.length && !workspaceNameError;

  const host = window.location.host;

  const updateWorkspaceName = (val) => {
    setWorkspaceName(val);
    const urlified = urlify(val.toLowerCase())
    setWorkspaceSlug(urlified);
  };

  const submitButtonDisabled = disableSubmitButton || workspaceName?.trim()?.length <= 4 || workspaceNameError
  const capitalizedConfigurationName = configurationName?.length ? (configurationName?.charAt(0).toUpperCase() + configurationName?.slice(1)) : 'Team';

  const copyToClipboardFn = () => `${host}/workspace/${workspaceSlug}-${workspaceSuffix}`;

  return (
    <>
      <div className="text-style-lg-semibold text-xgray-900 border-b border-b-xgray-200 p-6 w-full items-start">
        Set Up Your {capitalizedConfigurationName}'s Workspace
      </div>
      <div className="flex w-full min-h-[386px] overflow-auto">
        <Steps activeStep={2} />
        <div className="flex flex-col w-[546px] p-6">
          <span className="text-style-xl-semibold text-xgray-900 text-center">Create Your {capitalizedConfigurationName} Workspace</span>
          <div className="text-style-medium text-xgray-900 mt-4">
            <span className="text-xred-800 mr-1">*</span>
            Set Your Workspace Name
          </div>
          <span className="text-style-8 text-xgray-500">
            Workspace name generates a unique URL used to accessing shared testing sessions. 
          </span>
          <div className="mt-4 input-field-container flex items-center">
            <input
              type="text"
              className={`input-field focus-error !inline-flex !mt-0 ${workspaceNameError ? 'error' : ''}`}
              name="team-workspace-name"
              minLength={4}
              maxLength={20}
              pattern="^[A-Za-z0-9À-ÿ,.'\-\s]{4,20}$"
              value={workspaceName}
              placeholder="Workspace Name"
              ref={workspaceNameRef}
              id="team-workspace-name"
              onChange={e => updateWorkspaceName(e.target.value)}
              />
          </div>
          <div className={`mt-4 text-style-8 ${workspaceNameError ? 'text-xred-700' : 'text-xgray-500'}`}>
            <ul>
              <li>1. Should be 4-20 characters.</li>
              <li>2. Avoid numbers, symbols or special characters.</li>
            </ul>
          </div>
          <div className="text-style-medium text-xgray-900 mt-4">
            <span className="text-xred-800 mr-1">*</span>
            Your Workspace URL
          </div>
          <div className="mt-4 input-field-container flex items-center relative">
            <span className="text-style-small-medium text-xgray-900">{host}/workspace/</span>
            <input
              type="text"
              className={`input-field !inline-flex !mt-0 mx-1 !w-[173px] ${workspaceNameError ? 'error' : ''}`}
              name="team-workspace-name"
              minLength={4}
              maxLength={20}
              readOnly
              value={workspaceSlug}
              placeholder="workspace-name"
              />
            <span className="text-style-small-medium text-xgray-900">-{workspaceSuffix}</span>
            <div className={`inline-flex -mt-2 ml-2 ${showCopyButton ? '' : 'opacity-0 pointer-events-none'}`}>
              <CopyToClipboard copyToClipboardFn={copyToClipboardFn} />
            </div>
          </div>
          <Alert color="gray" className="mt-8 w-full">
            <span>
              <p className="text-style-small text-xgray-500">
                <span className="text-style-small-semibold mr-2">
                  Please note:
                </span>
                Choose your team workspace name wisely as it creates your team's unique URL which cannot be changed once the workspace setup is completed.
              </p>
            </span>
          </Alert>
        </div>
      </div>
      <div className="border-t border-t-xgray-200 w-full p-6 flex">
        <button className="primary-button ml-auto" disabled={submitButtonDisabled} onClick={() => nextStep(workspaceName, workspaceSlug, workspaceSuffix)}>
          Create {capitalizedConfigurationName} Workspace
        </button>
      </div>
    </>
  );
}

export default WorkspaceStep;