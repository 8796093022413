import { useObserverHandler } from './useObserverHandler';
import { observeCurrentUser, observeCurrentUserUpdates } from '../utils/apollo_utils';

export function useObserveCurrentUser(userId, setCurrentUser) {
  useObserverHandler(userId,
                    () => observeCurrentUser(setCurrentUser),
                    [userId, setCurrentUser]);

  useObserverHandler(userId,
                    () => observeCurrentUserUpdates(userId, setCurrentUser),
                    [userId, setCurrentUser])
}