import React from 'react';

import XrayLogoVerticalBrand from '../Svg/XrayLogoVerticalBrand';

const XEALogo = (props) => {
  const { hideXEALogo } = props;

  return (
    <div className={`xray-logo-container w-[375px] h-[150px] mx-auto mb-[24px] ${ hideXEALogo ? 'hidden' : '' }`}>
      <XrayLogoVerticalBrand {...props} />
    </div>
  );
};

export default XEALogo;
