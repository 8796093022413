import { React, useContext } from "react";
import { Label, TextInput } from "flowbite-react";
import { CollectUserInfo, CopyToClipboard } from '@stoatlabs/xea-client-shared-components';

import Workspace from "./Workspace";
import RoundedUserAvatar from "./RoundedUserAvatar";

import { updateStoredUser } from '../../store/currentUser';
import { UserContext } from '../../context/UserContext';
import { MembershipContext } from "../../context/MembershipContext";

import { WorkspaceScope } from '../../models';

const UserProfile = (props) => {
  const { currentUser } = useContext(UserContext);
  const { memberships } = useContext(MembershipContext);

  // Check if this user is a member of any paid workspaces.  If they are, we are not going to show them
  // their Cognito ID, as per directive of Idera
  const memberOfPaidWorkspaces = memberships?.data?.some(m => m?.workspace?.scope === WorkspaceScope.SHARED);
  const userSEN = memberOfPaidWorkspaces ? (<></>) : (
    <div className="mt-4">
      <Label htmlFor="user-sed-text" className="text-style-7 text-xgray-900" value="Entitlement Number" />
      <div className="relative">
        <TextInput
          id="user-sed-text"
          type="text"
          readOnly
          disabled
          value={ currentUser?.id }/>
        <CopyToClipboard copyToClipboardFn={() => currentUser?.id} />
      </div>
    </div>
  );

  const collectUserInfoProps = {
    ...props,
    userSEN,
    hideTitle: true,
    update: true,
    hideMarketingOptIn: true,
    ctaCopy: "Save changes",
    user: currentUser,
    didCollectUserInfoCb: async (userData) => await updateStoredUser({...userData, update: true}),
  };

  return (
    <Workspace {...props}>
      <div className='text-style-semibold text-xgray-900'>My Profile</div>
      <div className='flex flex-col items-center'>
        <RoundedUserAvatar {...props} avatarSize="md" />
        <CollectUserInfo {...collectUserInfoProps} />
      </div>
    </Workspace>
  )
};

export default UserProfile;