import { MembershipRole, MembershipState, WorkspaceScope } from '.';
import { addMembership, fetchMemberships } from './membership';

import apollo from '../apollo';
import { ListWorkspacesDocument, CreateWorkspaceDocument, ListMembershipsDocument, GetWorkspaceDocument, UpdateWorkspaceStripeSubscriptionIdDocument } from '../apollo/components';
import { getCognitoUser } from '../store/currentUser';

export async function getPrivateWorkspaceForUser() {
  const memberships = await fetchMemberships();
  if(memberships.length) {
    const privateMembership = memberships.find(m => m.workspace.scope === WorkspaceScope.PRIVATE);
    if(privateMembership) {
      return privateMembership.workspace;
    }
  }
}

export async function lookupWorkspace(workspaceId) {
  const workspace = await apollo.client.query({ query: GetWorkspaceDocument, variables: { id: workspaceId } });
  if(workspace.data.getWorkspace) {
    return workspace.data.getWorkspace;
  }
}

export async function updateWorkspaceStripeSubscriptionId(workspaceId, subscriptionId) {
  try {
    const updatedWorkspace = await apollo.client.mutate({
      mutation: UpdateWorkspaceStripeSubscriptionIdDocument,
      variables: {
        id: workspaceId,
        subscriptionId,
      },
      refetchQueries: [ListMembershipsDocument, ListWorkspacesDocument],
    });

    return updatedWorkspace;
  } catch (error) {
    console.error('Error updating workspace', error);
  }
}

export async function addWorkspace(workspace, user) {
  try {
    const savedWorkspace = await apollo.client.mutate({
      mutation: CreateWorkspaceDocument,
      variables: {
        input: workspace
      },
      refetchQueries: [ListMembershipsDocument, ListWorkspacesDocument],
    });

    const newWorkspace = savedWorkspace.data.createWorkspace;

    // When creating a workspace, go ahead and create an membership for the user,
    // marking them as the owner, an admin, and having accepted the membership already
    // as well as being both the inviter and the user, and assign it to the workspace
    // that was just created
    const workspaceOwnerMembershipData = {
      id: '',
      isOwner: true,
      role: MembershipRole.ADMIN,
      state: MembershipState.ACCEPTED,
      invitedAt: new Date().toISOString(),
      inviteEmail: user.email,
      inviterId: user.id,
      userId: user.id,
      workspaceId: newWorkspace.id,
    };
    await addMembership(workspaceOwnerMembershipData);

    return newWorkspace;
  } catch (error) {
    console.error('Error adding workspace', error);
  }
}

export async function checkWorkspace(workspaceId) {
  try {
    const user = await getCognitoUser();
    const url = new URL(`${process.env.REACT_APP_STORAGE_AUTHORIZER_ENDPOINT}/workspaceChecker`);
    url.searchParams.append('workspaceId', workspaceId);
    const resp = await fetch(url, {
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken,
      },
    });
    const respJson = await resp.json();
    if (resp.status === 507) {
      return {
        ...respJson,
        success: false,
        workspaceFull: true,
        paymentsRequired: false,
        message: 'Workspace is full',
      };
    } else if (resp.status === 403) {
      return {
        ...respJson,
        success: false,
        workspaceFull: false,
        paymentsRequired: true,
        message: 'Payment required',
      };
    } else if (resp.status === 200) {
      return {
        ...respJson,
        success: true,
        workspaceFull: false,
        paymentsRequired: false,
        message: 'Workspace is ok',
      };
    } else {
      return {
        ...respJson,
        success: false,
        workspaceFull: false,
        paymentsRequired: false,
        message: 'Generic error checking workspace',
      };
    }
  } catch(error) {
    console.error('Error checking workspace', error);
    return {
      success: false,
      workspaceFull: false,
      paymentsRequired: false,
      message: 'Error checking workspace',
    };
  }
}