import React from "react";
import { Alert }  from "flowbite-react";

import { CloseCircle } from "@stoatlabs/xea-client-shared-components";

const SeatsLimitReachedAlert = (props) => {
  const { removeSeatsLimitReachedAlert, upgradeLink } = props;

  const additionalContent = (
    <div className="flex flex-col">
      <div className="text-style-small text-xgray-700">
        You've reached the seat limit. You can remove unused seats to free up a seat or upgrade your plan for additional seats.
      </div>
      {!!upgradeLink && <a className="red-button btn-xs mt-2 mr-auto" href={upgradeLink.href} title={upgradeLink.title} target="_blank" rel="noreferrer">Upgrade Plan</a>}
    </div>
  );

  return (
    <Alert color="failure" onDismiss={removeSeatsLimitReachedAlert} icon={CloseCircle} additionalContent={additionalContent} className="mt-4">
      <span>
        <p>
        <span className="text-style-semibold text-xred-700">Seating Limit Reached for Subscription</span>
        </p>
      </span>
    </Alert>
  );
};

export default SeatsLimitReachedAlert;