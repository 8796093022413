import React, { useContext, useEffect, useState } from 'react';
import { CreateWorkspace as AmplifyCreateWorkspace, sendSQSMessage } from '@stoatlabs/xea-client-shared-components';
import { useCookies } from 'react-cookie';

import ComponentWrapper from '../ComponentWrapper';
import { addWorkspace, getPrivateWorkspaceForUser } from '../../models/workspace';
import { WorkspaceStatus, WorkspaceScope } from '../../models';

import awsconfig from '../../awsconfig';

import { UserContext } from '../../context/UserContext';
import { lookupUser } from '../../models/user';
import { WorkspaceContext } from '../../context/WorkspaceContext';
import { getCognitoUser } from '../../store/currentUser';

const USER_INFO_CONSUME_QUEUE_URL = process.env.REACT_APP_USER_INFO_CONSUMER_QUEUE_URL;

const addWorkspaceFn = async (storedUser, workspaceName, workspaceUrl) => {
  const workspaceData = {
    name: workspaceName,
    description: '',
    url: workspaceUrl,
    status: WorkspaceStatus.ACTIVE,
    scope: WorkspaceScope.PRIVATE,
  };
  return await addWorkspace(workspaceData, storedUser);
};

const sendWelcomeEmailFn = (authedUser, signupSelection, signupInvite) => {
  return new Promise(async (resolve, reject) => {
    await authedUser.getUserData(async (err, data) => {
      if (err) {
        console.error(`Error getting user data`, err);
        reject(err);
        return;
      }
      try {
        const sqsPayload = {
          sendWelcomeEmail: true,
          email: data.UserAttributes.find((attr) => attr.Name === 'email').Value,
          firstName: data.UserAttributes.find((attr) => attr.Name === 'custom:firstName').Value,
          signupSelection: signupSelection,
          signupInvite: signupInvite,
        };
        const jwtoken = authedUser.signInUserSession.idToken.jwtToken;
        // Send the message with the empty duplicationId since it's going to create one on its own
        await sendSQSMessage(sqsPayload, USER_INFO_CONSUME_QUEUE_URL, null, jwtoken, awsconfig);
        resolve();
      } catch (e) {
        console.error(`Error sending SQS message`, e);
        reject(e);
      }
    });
  });
};

export const createWorkspaceWithUser = async (workspaceName, workspaceUrl, signupSelection, signupInvite) => {
  const authedUser = await getCognitoUser();
  if (authedUser) {
    const storedUser = await lookupUser(authedUser.attributes.sub);
    if (storedUser) {
      // Create workspace in DynamoDB
      const existingWorkspace = await getPrivateWorkspaceForUser();
      if (!existingWorkspace) {
        await sendWelcomeEmailFn(authedUser, signupSelection, signupInvite);
        await addWorkspaceFn(storedUser, workspaceName, workspaceUrl);
      } else {
        console.warn(`User ${storedUser.email} already has a private workspace`);
        console.warn(`Redirecting to existing workspace, /${existingWorkspace.url}`)
      }
    } else {
      console.error(`No user found for id ${authedUser.attributes.sub}`);
    }
  } else {
    console.error(`User not authenticated with cognito:`, authedUser);
  }
}

const CreateWorkspace = (props) => {
  const { navigate } = props;
  const { currentUser } = useContext(UserContext);
  const { workspaces, workspacesLoaded } = useContext(WorkspaceContext);
  const [cookies,, removeCookie] = useCookies(['signup-invite']);
  const { 'signup-invite': signupInvite } = cookies;
  const [creatingWorkspace, setCreatingWorkspace] = useState(false);

  useEffect(() => {
    async function lookupExistingWorkspace() {
      if(!creatingWorkspace && currentUser && workspacesLoaded) {
        const existingWorkspace = await getPrivateWorkspaceForUser();
        if(existingWorkspace) {
          navigate(`/workspace/${existingWorkspace.url}`);
        }
      }
    }

    lookupExistingWorkspace();
  }, [currentUser, workspacesLoaded, workspaces, navigate, creatingWorkspace]);

  const childProps = {
    ...props,
    didCreateWorkspaceCb: async (workspaceName, workspaceUrl) => {
      setCreatingWorkspace(true);
      await createWorkspaceWithUser(workspaceName, workspaceUrl, undefined, !!cookies['signup-invite']);

      if (typeof signupInvite === 'string' && signupInvite.startsWith('/')) {
        removeCookie('signup-invite', { path: '/' });
        window.location = signupInvite;
      } else {
        window.location = `/workspace/${workspaceUrl}`;
      }
    }
  };

  return (
    <ComponentWrapper {...childProps}>
      <AmplifyCreateWorkspace {...childProps} user={currentUser} />
    </ComponentWrapper>
  )
};

export default CreateWorkspace;