import React from 'react';
import { useSelector } from 'react-redux';

import { modalTypes } from '../../store/slices/modals';
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import AppDownloadModal from './modals/AppDownload';

const Workspace = (props) => {
  const { children, hideSidebar, navbarClassType } = props;
  const modal = useSelector((state) => state.modals);

  return (
    <div>
      <Navbar {...props} />
      <div className={`z-0 relative body-${navbarClassType}-banner bg-xray-background flex`}>
        {!hideSidebar && <Sidebar {...props} navbarClassType={navbarClassType} />}
        { modal.type === modalTypes.DOWNLOAD && <AppDownloadModal {...props} /> }
        <div className={`z-0 relative w-full bg-white container-${navbarClassType}-banner p-6 ${hideSidebar ? '' : 'ml-[250px]'}`}>
          { children }
        </div>
      </div>
    </div>
  )
};

export default Workspace;