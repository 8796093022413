import apollo from '../apollo';
import { faker } from '@faker-js/faker';
import { startCase } from 'lodash';

import { observeForWorkspace, observeCreatesForWorkspace, observeUpdatesForWorkspace, observeDeletesForWorkspace } from '../utils/apollo_utils';

import { CreateSessionDocument, UpdateSessionDocument, DeleteSessionDocument, DuplicateSessionDocument, UnlockSessionDocument } from '../apollo/components';

import { SessionStatus, SessionMode, SessionType } from '.';

export async function generateFakeSession(userId, workspaceId, wsType) {
  const mode = faker.helpers.arrayElement([SessionMode.LOCAL, SessionMode.JIRA, SessionMode.XRAY]);
  let domain;

  if(mode === SessionMode.JIRA || mode === SessionMode.XRAY) {
    const suffix = faker.helpers.arrayElement(['atlassian.com', 'atlassian.net', 'atlassian.io']);
    domain = `${faker.internet.domainWord()}.${suffix}`;
  }

  const now = new Date().getTime();
  const createdAt = now - faker.number.int(1000 * 60 * 60 * 24 * 30);
  const updatedAt = Math.min(now, createdAt + faker.number.int(1000 * 60 * 60 * 24));

  // 500MB for personal, 5GB for team, 500GB for enterprise
  const sizeRangeFactor = wsType === 'enterprise' ? 500000 : (wsType === 'team' ? 250000 : 500);

  await addSession({
    name: startCase(faker.lorem.words({ min: 1, max: 5})),
    description: faker.lorem.sentence(),
    status: SessionStatus.INACTIVE,
    type: faker.helpers.arrayElement([SessionType.SESSION, SessionType.SESSION, SessionType.SESSION, SessionType.SESSION]),
    mode: mode,
    domain: domain,
    size: faker.number.int(sizeRangeFactor * 1000 * 1000).toString(),
    userId: userId,
    workspaceId: workspaceId,
    createdAt: `${createdAt}`,
    updatedAt: `${updatedAt}`
  });
}

export async function observeSessionsForWorkspace(workspaceId, setSessions) {
  return observeForWorkspace('Sessions', workspaceId, setSessions);
}

export async function observeSessionCreatesForWorkspace(workspaceId, setSessions) {
  return observeCreatesForWorkspace('Session', workspaceId, setSessions);
}

export async function observeSessionUpdatesForWorkspace(workspaceId, setSessions) {
  return observeUpdatesForWorkspace('Session', workspaceId, setSessions);
}

export async function observeSessionDeletesForWorkspace(workspaceId, setSessions) {
  return observeDeletesForWorkspace('Session', workspaceId, setSessions);
}

export async function addSession(session) {
  try {
    const savedSession = await apollo.client.mutate({
      mutation: CreateSessionDocument,
      variables: {
        input: session
      },
    });

    return savedSession;
  } catch (error) {
    console.error('Error adding session', error);
  }
}

export async function removeSession(id) {
  try {
    await apollo.client.mutate({
      mutation: DeleteSessionDocument,
      variables: {
        id
      },
    });

    return true;
  } catch (error) {
    console.error(`Error removing session with id ${id}`, error);
  }
  return false;
}

export async function renameSession(session, sessionName, currentWorkspace) {
  try {
    const updatedSession = {
      id: session.id,
      name: sessionName,
      mode: session.mode,
      status: session.status,
      type: session.type,
      userId: session.user?.id,
      workspaceId: currentWorkspace.id,
    };

    await apollo.client.mutate({
      mutation: UpdateSessionDocument,
      variables: {
        input: updatedSession
      },
    })
  } catch (error) {
    console.error(`Error renaming session with id ${session.id}`, error);
  }
}

export async function duplicateSession(session, sessionName, workspaceId) {
  try {
    const duplicatedSession = await apollo.client.mutate({
      mutation: DuplicateSessionDocument,
      variables: {
        id: session.id,
        name: sessionName,
        destinationWorkspaceId: workspaceId,
      },
    });

    return duplicatedSession;
  } catch (error) {
    console.error('Error duplicating session', error);
  }
}

export async function unlockSession(session) {
  try {
    await apollo.client.mutate({
      mutation: UnlockSessionDocument,
      variables: {
        id: session.id,
      },
    });

    return true;
  } catch (error) {
    console.error('Error unlocking session', error);
  }
  false
}