'xray-button-xs';

import AmplifySignup from './components/AmplifySignup';
import AmplifyLogin from './components/AmplifyLogin';
import AmplifyForgotPassword from './components/AmplifyForgotPassword';
import AmplifyConfirmPasscode from './components/AmplifyConfirmPasscode';
import AmplifyResetPassword from './components/AmplifyResetPassword';
import CollectUserInfo from './components/CollectUserInfo';
import ConfirmationView from './components/ConfirmationView';
import PasswordPopup from './components/Password/PasswordPopup';
import PasscodeField from './components/Passcode/PasscodeField';
import XEALogo from './components/XEALogo/XEALogo';
import IderaIncFooter from './components/IderaIncFooter/IderaIncFooter';
import CloseButton from './helpers/close-button';
import CreateWorkspace, { workspaceNameForUser, workspaceUrlForUserName } from './components/CreateWorkspace';
import CopyToClipboard from './components/CopyToClipboard';
import { theme as FlowbiteCustomTheme} from './flowbite-theme';
import AmplifyUpdatePassword from './components/AmplifyUpdatePassword';
import CustomTooltip from './components/Tooltip/CustomTooltip';
import urlify from './misc/urlify';
import ShowPasswordIcon from './components/Svg/ShowPasswordIcon';
import useMultipleClicks from './hooks/useMultipleClicks';
import Pendo, { pendoVisitorFromAmplifyUser } from './components/Pendo';

export * from './misc/helpers';
export * from "./flowbite-icons";
export * from "./icons";

export {
  AmplifySignup,
  AmplifyLogin,
  AmplifyForgotPassword,
  AmplifyConfirmPasscode,
  PasswordPopup,
  AmplifyResetPassword,
  PasscodeField,
  CopyToClipboard,
  CollectUserInfo,
  ConfirmationView,
  XEALogo,
  IderaIncFooter,
  CloseButton,
  CreateWorkspace,
  FlowbiteCustomTheme,
  urlify,
  AmplifyUpdatePassword,
  CustomTooltip,
  ShowPasswordIcon,
  useMultipleClicks,
  workspaceNameForUser,
  workspaceUrlForUserName,
  Pendo,
  pendoVisitorFromAmplifyUser,
};
