import React from 'react';

const CustomTooltip = (props) => {
  const { children, visible, styleClasses, arrowStyle } = props;
  return (
    <span role="tooltip" className={`absolute z-10 ${visible ? 'visible opacity-1' : 'invisible opacity-0'} inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-xgray-900 rounded-lg shadow-sm tooltip dark:bg-xgray-700 ${styleClasses}`}>
      { children }
      <span className={`tooltip-arrow ${arrowStyle}`} data-popper-arrow></span>
    </span>
  );
};

export default CustomTooltip;