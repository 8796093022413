import React, { useContext, useEffect } from 'react';
import UnauthedForgotPassword from './UnauthedForgotPassword';
import { Redirect } from 'react-router-dom';

import { UserContext } from '../../../context/UserContext';
import { logoutCurrentUser } from '../../../store/currentUser';

const ForgotPassword = (props) => {
  const { currentUser } = useContext(UserContext);

  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email');

  const sameUser = email === currentUser?.email;

  useEffect(() => {
    const logout = async () => {
      await logoutCurrentUser();
      window.location = '/forgot-password';
    }
    if (currentUser && !sameUser) {
      logout();
    }
  }, [currentUser, sameUser])

  if (currentUser) {
    if (sameUser) {
      return (
        <Redirect to="/profile/change-password" />
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <UnauthedForgotPassword {...props} />
    );
  }
};

export default ForgotPassword;