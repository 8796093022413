import React, { useState, useRef, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import XEALogo from './XEALogo/XEALogo';
import { checkPassword } from '../misc/helpers';
import PasswordPopup from './Password/PasswordPopup';
import ShowPasswordIcon from './Svg/ShowPasswordIcon';

const AmplifyUpdatePassword = (props) => {
  const [oldPswd, setOldPswd] = useState('');
  const [newPswd, setNewPswd] = useState('');
  const [newPswdValid, setNewPswdValid] = useState({valid: false});
  const [showOldPswd, setShowOldPswd] = useState(false);
  const [showNewPswd, setShowNewPswd] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const oldPasswordFieldRef = useRef();
  const newPasswordFieldRef = useRef();
  const [amplifyError, setAmplifyError] = useState({ error: false, message: null });
  const [updateSucceededMessage, setUpdateSucceededMessage] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const submitUpdatePassword = async () => {
    try {
      setAmplifyError({ error: false, message: null });
      setSubmitLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPswd, newPswd);
      setSubmitLoading(false);
      setSubmitDisabled(false);
      setUpdateSucceededMessage(true);
      setOldPswd('');
      setNewPswd('');
      setTimeout(() => {
        setUpdateSucceededMessage(false);
      }, 5000);
    } catch (e) {
      setSubmitLoading(false);
      if (e.name === 'InvalidParameterException') {
        setAmplifyError({
          error: true,
          message: 'The password you entered is incorrect. Please try again.',
        });
      } else if (e.name === 'LimitExceededException') {
        setAmplifyError({
          error: true,
          message: 'You reached the maximum amount of password reset requests. Please try again in a few minutes.',
        });
      } else {
        setAmplifyError({
          error: true,
          message: 'The provided password does not match your current password.',
        });
      }
    }
  };

  useEffect(() => {
    setSubmitDisabled(submitLoading
                      || !newPswdValid.valid
                      || !oldPswd.length)
  }, [newPswd, submitLoading, oldPswd]);

  const newPswdPopupProps = {
    fieldRef: newPasswordFieldRef,
    validity: newPswdValid,
  };

  return (
    <div id="amplify-reset-password-container" className="relative flex items-center content-center min-h-full h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
      <div className="w-[452px] p-2 m-auto mt-8">
        <XEALogo {...props} />

        <div className="flex flex-col">

          { amplifyError.error && (
            <div className="message error mb-4">
              <span className="error-message">{amplifyError.message}</span>
            </div>
          )}

          { updateSucceededMessage && (
            <div className="message success mb-4">
              <span className="success-message">Your password has been updated.</span>
            </div>
          )}

          <div className="flex flex-col">
            <div className="input-field-container relative">
              <label className="input-field-label" htmlFor="old-pswd-text-input">
                <span className="text-xred-800 mr-1">*</span>
                Current password
              </label>
              <div className="input-field pswd-input-field">
                <input
                  type={showOldPswd ? 'text' : 'password'}
                  ref={oldPasswordFieldRef}
                  id="old-pswd-text-input"
                  className="login-text-input"
                  value={oldPswd}
                  onChange={e => setOldPswd(e.target.value)}
                  tabIndex="1"
                />
                <button tabIndex="-1" className="show-pswd-btn" type="button" onClick={() => setShowOldPswd(!showOldPswd)}>
                  <ShowPasswordIcon hide={showOldPswd} />
                </button>
              </div>
            </div>

            <div className="input-field-container relative">
              <PasswordPopup {...newPswdPopupProps} />
              <label className="input-field-label" htmlFor="new-pswd-text-input">
                <span className="text-xred-800 mr-1">*</span>
                New password
              </label>
              <div className="input-field pswd-input-field">
                <input
                  type={showNewPswd ? 'text' : 'password'}
                  ref={newPasswordFieldRef}
                  id="new-pswd-text-input"
                  className="login-text-input"
                  value={newPswd}
                  onChange={e => {
                    setNewPswd(e.target.value);
                    setNewPswdValid(checkPassword(e.target.value));
                  }}
                  tabIndex="2"
                />
                <button tabIndex="-1" className="show-pswd-btn" type="button" onClick={() => setShowNewPswd(!showNewPswd)}>
                  <ShowPasswordIcon hide={showNewPswd} />
                </button>
              </div>
            </div>
          </div>

          <button className="mt-8 submit-button" disabled={submitDisabled} tabIndex="3" onClick={submitUpdatePassword}>Update Password</button>
        </div>
      </div>
    </div>
  );
};

export default AmplifyUpdatePassword;
