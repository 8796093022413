import React from "react";

import { logoutCurrentUser } from "../../store/currentUser";

const logoutAction = async (event, props) => {
  try {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    await logoutCurrentUser();
  } catch(e) {
    console.error(`Error logging user out:`, e);
  }
};

const LogoutButton = (props) => {
  return (
    <button onClick={(e) => logoutAction(e, props)}>Log Out</button>
  );
};

export default LogoutButton;

export {
  logoutAction
};
