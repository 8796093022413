import React, { useState, useRef, useEffect } from 'react';
import { Checkbox, Label } from 'flowbite-react';

import { theme as FlowbiteCustomTheme } from '../flowbite-theme';
import XEALogo from './XEALogo/XEALogo';
import { checkPassword } from '../misc/helpers';
import PasswordPopup from './Password/PasswordPopup';
import ShowPasswordIcon from './Svg/ShowPasswordIcon';

const passwordMinLength = 8;

const AmplifySignup = (props) => {
  const { navigate, didSignupCb } = props;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [pswd, setPswd] = useState('');
  const [pswdValid, setPswdValid] = useState({valid: false});
  const [showPswd, setShowPswd] = useState(false);
  const [terms, setTerms] = useState(false);
  const [authWorking, setAuthWorking] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [signupBtnDisabled, setSignupBtnDisabled] = useState(true);
  const emailFieldRef = useRef(null);
  const pswdFieldRef = useRef(null);

  const onSignupClick = async (_event) => {
    try {
      setAuthWorking(true);
      setEmailError(false);
      const signupResult = await didSignupCb(email, pswd);
      if (!signupResult) {
        console.error(`Error during signup`);
        setEmailError('Authentication failed, please try again');
        setAuthWorking(false);
      }
    } catch(e) {
      console.error(`Error occurred during signup:`, e);
      setAuthWorking(false);
      setEmailError('Something went wrong, please try again');
    }
  };

  const newPswdPopupProps = {
    fieldRef: pswdFieldRef,
    validity: pswdValid,
  };

  useEffect(() => {
    setSignupBtnDisabled(!email.length
                         || !emailFieldRef.current?.validity?.valid
                         || !pswd.length
                         || !pswdValid.valid
                         || !terms
                         || authWorking);
    }, [email, emailFieldRef.current, pswd, pswdValid, terms, authWorking]);

  const goToLogin = () => {
    const url = new URL('/login', window.location.origin);
    navigate(url);
  };

  const preventSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const keyDownHandler = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      if (!signupBtnDisabled) {
        onSignupClick();
      }
    }
  };

  return (
    <>
      <div id="amplify-signup-container" className="relative flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
        <div className="w-[452px] p-2 m-auto">
          <XEALogo {...props} />

          <form className="flex flex-col" onSubmit={preventSubmit}>
            <div className="text-center text-style-xl-semibold text-xgray-900 dark:text-white">Create Your Account</div>

            { emailError && (
              <div className="message error mt-4">
                <span className="error-message">{emailError}</span>
              </div>
            )}

            <div className="input-field-container">
              <label className="input-field-label" htmlFor="email-text-input">
                <span className="text-xred-800 mr-1">*</span>
                Your email
              </label>
              <input
                type="email"
                ref={emailFieldRef}
                tabIndex="1"
                autoFocus={true}
                id="email-text-input"
                className="input-field login-text-input email-field"
                value={email}
                onChange={e => setEmail(e.target.value.toLowerCase())}
                onBlur={e => setEmailFormatError(!e.target.validity.valid)}
                onKeyDown={keyDownHandler}
              />
              <div className={`input-field-error-message ${emailFormatError ? '' : 'hidden'}`}>The email must be a valid email address</div>
            </div>
            <div className="input-field-container relative">
              <PasswordPopup {...newPswdPopupProps} />
              <label className="input-field-label" htmlFor="pswd-text-input">
                <span className="text-xred-800 mr-1">*</span>
                Password
              </label>
              <div className="input-field pswd-input-field">
                <input
                  ref={pswdFieldRef}
                  tabIndex="2"
                  type={showPswd ? 'text' : 'password'}
                  minLength={passwordMinLength}
                  id="pswd-text-input"
                  className="login-text-input"
                  value={pswd}
                  onChange={e => {
                    setPswd(e.target.value);
                    setPswdValid(checkPassword(e.target.value));
                  }}
                  onKeyDown={keyDownHandler}
                />
                <button tabIndex="-1" className="show-pswd-btn" type="button" onClick={() => setShowPswd(!showPswd)}>
                  <ShowPasswordIcon hide={showPswd} />
                </button>
              </div>
            </div>
            <div className="input-field-container checkbox-container">
              <Checkbox
                theme={FlowbiteCustomTheme.theme.checkbox}
                id="terms-checkbox"
                // className="checkbox-field"
                tabIndex="3"
                checked={terms}
                onChange={e => setTerms(!terms)}
              />
              <Label className="checkbox-field-label" htmlFor="terms-checkbox">
                I agree to <a tabIndex="-1" href="https://www.ideracorp.com/Legal/Terms-of-Use" target="_blank">terms & conditions</a> and <a tabIndex="-1" href="https://www.ideracorp.com/Legal/XBlend/PrivacyStatement" target="_blank">privacy policy</a>.
              </Label>
            </div>
            <button tabIndex="4" className="mt-4 submit-button" disabled={signupBtnDisabled} onClick={onSignupClick}>Create Account</button>
            <p className="mt-4 h-[19px] text-center text-style-small-medium text-xgray-500">
              Go back to the
              <button tabIndex="5" className="inline-block h-[19px] text-xray-primary-select ml-1.5" onClick={goToLogin}>login screen</button>.
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default AmplifySignup;
