import React from "react";

const LoadingApp = () => {
  return (
    <div className='flex flex-col items-center h-[100vh]'>
      <img src="/xea-logo-2024.svg" alt="Xray Logo" className="w-[334px] m-auto animate-pulse" />
    </div>
  );
};

export default LoadingApp;