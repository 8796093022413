import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Workspace from './Workspace';
import FirstSession from './FirstSession';
import SessionsTable from './SessionsTable';
import CreateTeamWorkspaceModal from '../Account/modals/CreateTeamWorkspace';
import { modalTypes } from '../../store/slices/modals';
import { useCookies } from 'react-cookie';
import TeamWorkspaceCreatedAlert from './TeamWorkspaceCreatedAlert';

import { openModal } from '../../store/slices/modals';
import { getAllowedWorkspaceType } from '../../store/currentUser';
import { MembershipContext } from '../../context/MembershipContext';
import { UserContext } from '../../context/UserContext';
import { ownerSharedWorkspace } from '../../models/membership';

const UserSessions = (props) => {
  const { sessions, workspaces } = props;
  const { currentUser } = useContext(UserContext);
  const { memberships } = useContext(MembershipContext)
  const modal = useSelector((state) => state.modals);
  const [showCreateTeamWorkspace, setShowCreateTeamWorkspace] = useState(undefined);
  const [cookies,,removeCookie] = useCookies(['signup-selection', 'first-landing', 'team-workspace-created']);
  const {'first-landing': firstLandingCookie, 'signup-selection': signupSelectionCookie, 'team-workspace-created': teamWorkspaceCreatedCookie} = cookies;
  const [showTeamWorkspaceCreatedAlert, setShowTeamWorkspaceCreatedAlert] = useState(undefined);
  const [showTeamWorkspaceCreatedTooltip, setShowTeamWorkspaceCreatedTooltip] = useState(undefined);
  const [wsWizardModalChecked, setWsWizardModalChecked] = useState(false);
  const [downloadModalChecked, setDownloadModalChecked] = useState(false);
  const dispatch = useDispatch();

  // Handle team workspace created alert
  useEffect(() => {
    if (showTeamWorkspaceCreatedTooltip === undefined && teamWorkspaceCreatedCookie && !firstLandingCookie && modal.type === modalTypes.NO_MODAL) {
      setTimeout(() => {
        setShowTeamWorkspaceCreatedAlert(true);
        setShowTeamWorkspaceCreatedTooltip(true);
        removeCookie('team-workspace-created', { path: '/' });
      }, 1000);
    }
  }, [removeCookie, showTeamWorkspaceCreatedTooltip, teamWorkspaceCreatedCookie, firstLandingCookie, modal.type]);

  // Check the workspace wizard modal
  useEffect(() => {
    const checkWsWizardModal = async () => {
      const ownsSharedWorkspace = ownerSharedWorkspace(memberships.data, workspaces);
      const allowedWorkspace = await getAllowedWorkspaceType(currentUser, memberships?.data);
      if (allowedWorkspace?.selection && (!allowedWorkspace?.subscription || !allowedWorkspace?.subscription?.ended) && !ownsSharedWorkspace) {
        dispatch(openModal(modalTypes.CREATE_TEAM_WORKSPACE));
        setShowCreateTeamWorkspace(true);
      } else {
        removeCookie('signup-selection', { path: '/' });
      }
      setWsWizardModalChecked(true);
    };
    if (signupSelectionCookie) {
      if (!wsWizardModalChecked && memberships?.data?.length > 0 && currentUser && workspaces?.length > 0 && modal.type === modalTypes.NO_MODAL) {
        checkWsWizardModal();
      }
    } else {
      setWsWizardModalChecked(true);
    }
  }, [currentUser, dispatch, memberships.data, modal.type, removeCookie, signupSelectionCookie, workspaces, wsWizardModalChecked]);

  // Check the download modal on first landing, after the workspace wizard
  useEffect(() => {
    if (firstLandingCookie) {
      if (wsWizardModalChecked && !downloadModalChecked && modal.type === modalTypes.NO_MODAL) {
        dispatch(openModal(modalTypes.DOWNLOAD));
        setDownloadModalChecked(true);
      }
    } else {
      setDownloadModalChecked(true);
    }
  }, [dispatch, downloadModalChecked, firstLandingCookie, modal.type, removeCookie, wsWizardModalChecked]);

  const innerProps = {
    ...props,
    showTeamWorkspaceCreatedAlert,
    setShowTeamWorkspaceCreatedAlert,
    showTeamWorkspaceCreatedTooltip,
    setShowTeamWorkspaceCreatedTooltip,
  }

  return (
    <Workspace {...innerProps}>
      { showTeamWorkspaceCreatedAlert && <TeamWorkspaceCreatedAlert {...innerProps} />}
      { showCreateTeamWorkspace && <CreateTeamWorkspaceModal {...props} />}
      { !sessions?.length && <FirstSession {...props} /> }
      { sessions?.length > 0 && <SessionsTable {...props} key="all-sessions" />}
    </Workspace>
  )
};

export default UserSessions;