import { useEffect } from 'react';

export function useObserverHandler(requiredId, observeFn, deps, destroyCallbackRef = {}, shouldClear = true) {
  useEffect(() => {
    // Return if we already have a destroyCallbackRef or we do not have a requiredId
    if(destroyCallbackRef.current || !requiredId) return;

    observeFn().then((destroyCallback) => { destroyCallbackRef.current = destroyCallback; });
    return () => {
      if(shouldClear && destroyCallbackRef.current) return destroyCallbackRef.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return destroyCallbackRef;
}