import apollo from '../apollo';

import { GetUserDocument, CreateUserDocument, ListUsersDocument, UpdateUserDocument, ListMembershipsDocument, CurrentUserDocument } from '../apollo/components';

import { getCognitoUser } from '../store/currentUser';
import { sendSQSMessage } from '@stoatlabs/xea-client-shared-components';

import awsconfig from '../awsconfig';

const XEA_EMAILS_CONSUMER_QUEUE_URL = process.env.REACT_APP_XEA_EMAILS_CONSUMER_QUEUE_URL;

export async function lookupUser(userId) {
  const response =  await apollo.client.query({ query: CurrentUserDocument });
  return response?.data?.currentUser;
}

export async function addUser(user) {
  try {
    const savedUser = await apollo.client.mutate({
      mutation: CreateUserDocument,
      variables: { input: user },
      refetchQueries: [CurrentUserDocument, GetUserDocument, ListMembershipsDocument],
    })

    return savedUser;
  } catch (error) {
    console.error('Error adding user', error);
  }
}

export async function updateUser(userId, userData) {
  try {
    const original = await apollo.client.query({ query: ListUsersDocument, variables: { ids: [userId] } });
    if (original) {
      await apollo.client.mutate({
        mutation: UpdateUserDocument,
        variables: {
          input: {
            id: userId,
            ...userData
          }
        },
        refetchQueries: [ListUsersDocument],
      });
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error updating user', error);
  }
}

export async function createOrUpdateUserFromAuthedUser(user) {
  try {
    const existingUser = await lookupUser(user.id);

    if(!existingUser) {
      const userData = {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        jobTitle: user.jobTitle,
        country: user.country,
        businessName: user.businessName,
        marketingOptIn: user.marketingOptIn,
      };
      if (user.stripeCustomerId) {
        userData.stripeCustomerId = user.stripeCustomerId;
      }
      return await addUser(userData)
    } else {
      const userData = {
        firstName: user.firstName,
        lastName: user.lastName,
        jobTitle: user.jobTitle,
        country: user.country,
        businessName: user.businessName,
        email: existingUser.email,
      };
      if (existingUser.stripeCustomerId) {
        userData.stripeCustomerId = existingUser.stripeCustomerId;
      }
      return await updateUser(existingUser.id, userData);
    }
  } catch (error) {
    console.error(`Error adding user from authed user`, error);
  }
}

export const sendInviteEmail = async (membership, currentWorkspace) => {
  const cognitoUser = await getCognitoUser();
  const inviter = await lookupUser(membership.inviterId);
  const firstName = inviter.firstName;
  const lastName = inviter.lastName;
  const inviterEmail = inviter.email;
  const workspaceName = currentWorkspace.name;
  const sqsPayload = {
    action: 'invite',
    data: {
      email: membership.inviteEmail,
      subject: `${firstName} invites you to join ${workspaceName} in the Xray Exploratory App`,
      link: `${window.location.origin}/invite/${membership.id}`,
      inviter: {
        email: inviterEmail,
        firstName: firstName,
        lastName: lastName
      },
      workspaceName
    }
  };
  const jwtoken = cognitoUser.signInUserSession.idToken.jwtToken;
  // Send the message with the empty duplicationId since it's going to create one on its own
  await sendSQSMessage(sqsPayload, XEA_EMAILS_CONSUMER_QUEUE_URL, null, jwtoken, awsconfig);
};

export const sendInviteAcceptedEmail = async (membership, currentUser) => {
  const cognitoUser = await getCognitoUser();
  const firstName = membership.inviter.firstName;
  const lastName = membership.inviter.lastName;
  const inviterEmail = membership.inviter.email;
  const workspaceName = membership.workspace.name;
  const sqsPayload = {
    action: 'invite-accepted',
    data: {
      email: inviterEmail,
      subject: `${currentUser.firstName} accepted your invite to join ${workspaceName} in the Xray Exploratory App`,
      invitee: {
        email: membership.inviteEmail,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
      },
      inviter: {
        email: inviterEmail,
        firstName: firstName,
        lastName: lastName
      },
      workspaceName,
    }
  };
  const jwtoken = cognitoUser.signInUserSession.idToken.jwtToken;
  // Send the message with the empty duplicationId since it's going to create one on its own
  await sendSQSMessage(sqsPayload, XEA_EMAILS_CONSUMER_QUEUE_URL, null, jwtoken, awsconfig);
};
