import React, { useContext } from "react";
import { Alert }  from "flowbite-react";

import { CloseCircle } from "@stoatlabs/xea-client-shared-components";
import { WorkspaceContext } from "../../context/WorkspaceContext";
import { getUpgradeLink } from "../Account/utils/upgrade";
import { MembershipContext } from "../../context/MembershipContext";
import { membershipForWorkspace } from "../../models/membership";

const LowStorageWarning = (props) => {
  const { removeLowStorageWarningPanel, workspacePercent } = props;
  const { workspaces, currentWorkspace } = useContext(WorkspaceContext);
  const { memberships } = useContext(MembershipContext);

  const currentMembership = membershipForWorkspace(memberships?.data, currentWorkspace);
  // const description = workspacePercent < 100 ? 'If you run out of storage, you won\'t be able to create or edit test sessions. Manage your plan to increase storage and continue running test sessions.' : 'You are out of storage and can\'t create or edit test sessions. Manage your plan to increase storage and continue running test sessions.';
  let description;
  if (workspacePercent < 100) {
    if (currentMembership.isOwner) {
      description = `If you run out of storage, you won't be able to create or edit test sessions. Manage your plan to increase storage and continue running test sessions.`;
    } else {
      description = `If you run out of storage, you won't be able to create or edit test sessions. Free up space to continue running test sessions.`
    }
  } else {
    if (currentMembership.isOwner) {
      description = `You are out of storage and can't create or edit test sessions. Manage your plan to increase storage and continue running test sessions.`
    } else {
      description = `You are out of storage and can't create or edit test sessions. Free up space to continue running test sessions.`;
    }
  }

  const upgradeLink = getUpgradeLink(memberships?.data, workspaces, currentWorkspace);

  const additionalContent = (
    <div className="flex flex-col">
      <div className="text-style-small text-xgray-700">
        {description}
      </div>
      {!!upgradeLink && <a className="red-button btn-xs mt-2 mr-auto" href={upgradeLink.href} title={upgradeLink.title} target="_blank" rel="noreferrer">Upgrade</a>}
    </div>
  );

  return (
    <Alert color="failure" onDismiss={() => removeLowStorageWarningPanel()} icon={CloseCircle} additionalContent={additionalContent} className="mt-4">
      <span>
        <p>
        <span className="text-style-semibold text-xred-700">Your Storage is {Math.min(100, workspacePercent)}% Full!</span>
        </p>
      </span>
    </Alert>
  );
};

export default LowStorageWarning;
