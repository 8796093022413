import React, { useState, useRef, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import XEALogo from './XEALogo/XEALogo';
import { checkPassword } from '../misc/helpers';
import PasswordPopup from './Password/PasswordPopup';
import PasscodeField, { passcodeLength, passcodeRegEx } from './Passcode/PasscodeField';
import ShowPasswordIcon from './Svg/ShowPasswordIcon';
import RedErrorBang from './Svg/RedErrorBang';

const AmplifyResetPassword = (props) => {
  const { navigate, didResetPasswordCb } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const emailParam = urlParams.get('email');
  const [passcode, setPasscode] = useState('');
  const [newPswd, setNewPswd] = useState('');
  const [newPswdValid, setNewPswdValid] = useState({valid: false});
  const [showNewPswd, setShowNewPswd] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const newPasswordFieldRef = useRef();
  // Amplify error can be of 2 types: 'passcode' or 'password'
  const [amplifyError, setAmplifyError] = useState({ error: false, type: null, message: null });
  const [submitPasscodeDisabled, setSubmitPasscodeDisabled] = useState(true);

  const passcodeDidChange = (newPasscode) => {
    setPasscode(newPasscode);
  };

  const goToForgotPassword = (email, errorMessage) => {
    const url = new URL('/forgot-password', window.location.origin);
    url.searchParams.set('email', email);
    if (errorMessage) {
      url.searchParams.set('errorMessage', errorMessage);
    }
    navigate(url);
  };

  const submitPasscode = async () => {
    try {
      setSubmitLoading(true);
      await Auth.forgotPasswordSubmit(emailParam, passcode, newPswd);
      setSubmitLoading(false);
      didResetPasswordCb();
    } catch (e) {
      setSubmitLoading(false);
      if (e.name === 'InvalidParameterException') {
        setAmplifyError({
          error: true,
          type: 'password',
          message: 'The password you entered is incorrect. Please try again.',
        });
      } else if (e.name === 'CodeMismatchException') {
        setAmplifyError({
          error: true,
          type: 'passcode',
          message: 'The passcode you entered is incorrect. Please try again.',
        });
      } else if (e.name === 'LimitExceededException') {
        goToForgotPassword(emailParam, 'You reached the maximum amount of password reset requests. Please try again in a few minutes.');
      } else {
        setAmplifyError({
          error: true,
          type: 'passcode',
          message: 'Something went wrong. Please try again.',
        });
      }
    }
  };

  useEffect(() => {
    setSubmitPasscodeDisabled(!passcode.length
                              || !passcode.match(passcodeRegEx)
                              || submitLoading
                              || !newPswdValid.valid)
  }, [passcode, newPswd, submitLoading]);

  let passcodeDescription = 'We have sent a passcode to your email address. Please enter the code to you to reset your password.';
  if (emailParam) {
    passcodeDescription = (
      <span>
        We have sent a passcode to the email <strong>{emailParam}</strong>. Please enter the code to you to reset your password.
      </span>
    )
  }

  const newPswdPopupProps = {
    fieldRef: newPasswordFieldRef,
    validity: newPswdValid,
  };

  return (
    <div id="amplify-reset-password-container" className="relative flex items-center content-center min-h-full h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
      <div className="w-[452px] p-2 m-auto mt-8">
        <XEALogo {...props} />

        <div className="flex flex-col">
          <div className="text-style-xl-semibold text-xgray-900 text-center">Enter Passcode</div>
          <p className="text-style-base text-xgray-500 text-center mt-2">{passcodeDescription}</p>

          { amplifyError.error && amplifyError.type === 'passcode' && (
            <div className="message error">
              <span className="error-icon">
                <RedErrorBang />
              </span>
              <span className="error-message">{amplifyError.message}</span>
            </div>
          )}

          <PasscodeField {...props} passcodeDidChange={passcodeDidChange} />

          { amplifyError.error && amplifyError.type === 'password' && (
            <div className="message error">
              <span className="error-icon">
                <RedErrorBang />
              </span>
              <span className="error-message">{amplifyError.message}</span>
            </div>
          )}

          <div className="flex flex-col">
            <div className="input-field-container relative">
              <PasswordPopup {...newPswdPopupProps} />
              <label className="input-field-label" htmlFor="new-pswd-text-input">
                <span className="text-xred-800 mr-1">*</span>
                Enter new password
              </label>
              <div className="input-field pswd-input-field">
                <input
                  type={showNewPswd ? 'text' : 'password'}
                  ref={newPasswordFieldRef}
                  role='password-field'
                  id="new-pswd-text-input"
                  className="login-text-input"
                  value={newPswd}
                  onChange={e => {
                    setNewPswd(e.target.value);
                    setNewPswdValid(checkPassword(e.target.value));
                  }}
                  tabIndex={passcodeLength + 1}
                />
                <button tabIndex="-1" className="show-pswd-btn" type="button" onClick={() => setShowNewPswd(!showNewPswd)}>
                  <ShowPasswordIcon hide={showNewPswd} />
                </button>
              </div>
            </div>
          </div>
          <button className="submit-button" disabled={submitPasscodeDisabled} tabIndex={passcodeLength + 3} onClick={submitPasscode}>Reset password</button>
          <p className="mt-4 h-[19px] text-center text-style-small-medium text-xgray-500">
            Didn't receive the code? 
            <button className="inline-block h-[19px] border-nonde outline-none bg-[transparent] ml-1.5 text-xray-primary-select" onClick={() => goToForgotPassword(emailParam)}>Click to resend</button>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AmplifyResetPassword;
