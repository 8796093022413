// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ModelAttributeTypes = {
  "BINARY": "binary",
  "BINARY_SET": "binarySet",
  "BOOL": "bool",
  "LIST": "list",
  "MAP": "map",
  "NUMBER": "number",
  "NUMBER_SET": "numberSet",
  "STRING": "string",
  "STRING_SET": "stringSet",
  "NULL": "_null"
};

const ModelSortDirection = {
  "ASC": "ASC",
  "DESC": "DESC"
};

const MembershipRole = {
  "ADMIN": "ADMIN",
  "USER": "USER"
};

const MembershipState = {
  "PENDING": "PENDING",
  "ACCEPTED": "ACCEPTED",
  "EXPIRED": "EXPIRED"
};

const SessionStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const SessionMode = {
  "LOCAL": "LOCAL",
  "JIRA": "JIRA",
  "XRAY": "XRAY"
};

const SessionType = {
  "SESSION": "SESSION",
  "CHARTER": "CHARTER"
};

const WorkspaceScope = {
  "PRIVATE": "PRIVATE",
  "SHARED": "SHARED"
};

const WorkspaceStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const { Configuration, Membership, Session, User, Workspace, ModelMembershipConnection, ModelSessionConnection, ModelUserConnection, ModelWorkspaceConnection } = initSchema(schema);

export {
  Configuration,
  Membership,
  Session,
  User,
  Workspace,
  ModelAttributeTypes,
  ModelSortDirection,
  MembershipRole,
  MembershipState,
  SessionStatus,
  SessionMode,
  SessionType,
  WorkspaceScope,
  WorkspaceStatus,
  ModelMembershipConnection,
  ModelSessionConnection,
  ModelUserConnection,
  ModelWorkspaceConnection
};