import { createContext, useState } from 'react';

import { useObserveConfigurations } from '../hooks/useObserveConfigurations';

export const ConfigurationContext = createContext(null);

const ConfigurationProvider = ({ children }) => {
  const [configurations, setConfigurations] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useObserveConfigurations(confs => {
    setConfigurations(confs);
    setLoaded(true);
  });

  const store = {
    configurations: configurations,
    loaded
  }

  return (
    <ConfigurationContext.Provider value={store}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;