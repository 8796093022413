import React from "react";
import { XEALogo } from "@stoatlabs/xea-client-shared-components";

import ComponentWrapper from "../../ComponentWrapper";
import { logoutCurrentUser } from "../../../store/currentUser";

const InviteWrongAccount = (props) => {
  const { membership, currentUser } = props;

  const workspaceName = membership?.workspace?.name;

  const logoutAction = async () => {
    await logoutCurrentUser();
    window.location = `/invite/${membership?.id}`;
  }

  return (
    <ComponentWrapper {...props} hideCloseButton={true} hideFooter={true}>
      <div id="amplify-login-container" className="flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
        <div className="w-[452px] p-2 m-auto mt-8">
          <XEALogo {...props} />

          <div className="flex flex-col">
            <div className="text-style-xl-semibold text-xgray-900 text-center">You're Logged Into the Wrong Account.</div>
            <div className="text-style-small text-xgray-500 text-center mt-4">
              You are logged in as <span className="text-style-small-semibold text-xgray-900">{currentUser?.email}</span> but the invitation to join {workspaceName} workspace was sent to <span className="text-style-small-semibold text-xgray-900">{membership?.inviteEmail}</span>. Sign out and login to the correct account to continue.
            </div>
            <button className="w-full primary-button mt-4" onClick={logoutAction}>Sign Out</button>
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

export default InviteWrongAccount;