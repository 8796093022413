import { Auth } from 'aws-amplify';
import { sendSQSMessage } from '@stoatlabs/xea-client-shared-components';
import * as Sentry from '@sentry/react';

import awsconfig from '../awsconfig';
import { createOrUpdateUserFromAuthedUser } from '../models/user';

import apollo from '../apollo';
import { GetUserDocument } from '../apollo/components';

const USER_INFO_CONSUME_QUEUE_URL = process.env.REACT_APP_USER_INFO_CONSUMER_QUEUE_URL;
const WAREHOUSE_EVENTS_QUEUE_URL = process.env.REACT_APP_WAREHOUSE_EVENTS_QUEUE_URL;

export async function getCognitoUser() {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    if (error !== 'The user is not authenticated') {
      console.error(`Error getting current Cognito user`, error);
    } else {
      throw error;
    }
  }
}

export async function fetchCurrentUser(userId, setState) {
  try {
    const usersResp = await apollo.client.query({ query: GetUserDocument, variables: { id: userId } });
    if (usersResp) {
      const curUser = usersResp.data.getUser;
      if (setState) {
        setState(curUser);
      }
      return curUser;
    }
  } catch (e) {
    console.error(`Error fetching current user with id ${userId}`, e);
  }
}

export async function updateCognitoUser(cognitoUser, userData) {
  try {
    const attrs = {
      'custom:firstName': userData.firstName,
      'custom:lastName': userData.lastName,
      'custom:jobTitle': userData.jobTitle,
      'custom:country': userData.country,
      'custom:businessName': userData.businessName,
      'custom:marketingOptIn': userData.marketingOptIn ? 'TRUE' : 'FALSE',
    };
    return await Auth.updateUserAttributes(cognitoUser, attrs);
  } catch (e) {
    console.error(`Error storing user attributes in Cognito`, e);
    return null;
  }
}

export async function updateStoredUser(userData) {
  return new Promise(async (resolve, reject) => {
    const cognitoUser = await getCognitoUser();
    cognitoUser.getUserData(async (err, data) => {
      if (err) {
        console.error(`Error getting user data`, err);
        reject(err);
        return;
      }
      if (await updateCognitoUser(cognitoUser, userData)) {
        try {
          const identityId = data.UserAttributes.find((attr) => attr.Name === 'sub').Value;
          const jwtoken = cognitoUser.signInUserSession.idToken.jwtToken;
          const userDataWithEmail = {
            ...userData,
            userId: identityId,
            email: data.UserAttributes.find((attr) => attr.Name === 'email').Value,
          };

          const { marketingOptIn, ...storedUserData } = userDataWithEmail;
          await createOrUpdateUserFromAuthedUser({...storedUserData, id: identityId});
          await sendSQSMessage(userDataWithEmail, USER_INFO_CONSUME_QUEUE_URL, `update-user-${identityId}`, jwtoken, awsconfig);
          // Force refresh the user data from cognito
          await getCognitoUser();
          resolve();
        } catch (e) {
          console.error(`Error sending SQS message`, e);
          reject(e);
        }
      } else {
        reject(new Error('Error storing user attributes in Cognito'));
      }
    });
  });
};

const invokePaymentsActionLambda = async (data) => {
  const cognitoUser = await getCognitoUser();
  return await fetch(process.env.REACT_APP_PAYMENTS_ACTION_LAMBDA, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${cognitoUser.signInUserSession.accessToken.jwtToken}`,
    },
    mode: 'cors',
  });
}

export const createBillingSession = async (currentUser, redirectUrl) => {
  try {
    const data = {
      action: 'billing',
      data: {
        stripeCustomerId: currentUser.stripeCustomerId,
        domain: window.location.origin, // Use origin instead of REACT_APP_XRAY_APP_DOMAIN for dev cases
        fromWorkspaceUrl: redirectUrl,
      }
    };
    const resp = await invokePaymentsActionLambda(data);
    if (resp.status !== 200) {
      throw new Error(`Failed to create billing session: status code is ${resp.status}`);
    }
    const respBody = await resp.json();
    if (respBody.error) {
      throw new Error(`Failed to create billing session: ${respBody.message}`);
    }
    return respBody.session.url;
  } catch(err) {
    console.error(err);
  }
  return null;
}

export const createCheckoutSession = async (userId, stripeCustomerId, selection = 'team', plan = 'monthly', requirePayment = false) => {
  try {
    const cognitoUser = await getCognitoUser();
    const data = {
      action: 'checkout',
      data: {
        email: cognitoUser.attributes.email,
        selection,
        plan,
        domain: window.location.origin, // Use origin instead of REACT_APP_XRAY_APP_DOMAIN for dev cases
        userId,
        stripeCustomerId,
        requirePayment,
      }
    };
    const resp = await invokePaymentsActionLambda(data);
    if (resp.status !== 200) {
      throw new Error(`Failed to create checkout session: status code is ${resp.status}`);
    }
    const respBody = await resp.json();
    if (respBody.error) {
      throw new Error(`Failed to create checkout session: ${respBody.message}`);
    }
    return {
      stripeCustomerId: respBody.customer.id,
      sessionUrl: respBody.session.url,
    };
  } catch(error) {
    console.error(`Failed creating checkout session for user ${userId}`, error);
  }
  return null;
}

export const getOrCreateSubscription = async (userId, selection = 'team', plan = 'monthly') => {
  try {
    const cognitoUser = await getCognitoUser();
    const data = {
      action: 'getOrCreateSubscription',
      data: {
        email: cognitoUser.attributes.email,
        userId,
        selection,
        plan,
      }
    };
    const resp = await invokePaymentsActionLambda(data);
    if (resp.status !== 200) {
      throw new Error(`Failed to get or create subscription: status code is ${resp.status}`);
    }
    const respBody = await resp.json();
    if (respBody.error) {
      throw new Error(`Failed to get or create subscription: ${respBody.message}`);
    }

    return {
      stripeCustomerId: respBody.customer.id,
      success: respBody.success,
      createdTrial: respBody.createdTrial,
    };
  } catch(error) {
    console.error(`Failed get or create subscription for user ${userId}`, error);
  }
  return null;
}

export const getSubscriptionForCustomer = async (customer) => {
  try {
    const { stripeCustomerId, email } = customer;
    const data = {
      action: 'getSubscription',
      data: {
        stripeCustomerId,
        email
      }
    }
    const resp = await invokePaymentsActionLambda(data);
    if (resp.status !== 200) {
      throw new Error(`Failed to create checkout session: status code is ${resp.status}. Full resp:`, await resp.json());
    }
    const respBody = await resp.json();
    return respBody;
  } catch(error) {
    console.error(`Failed checking user subscription for stripeCustomerId ${customer?.stripeCustomerId}:`, {error, customer});
    Sentry.captureException(error);
    return null;
  }
};

export const canCreateSharedWorkspace = (currentUser, memberships) => {
  const ownedWorkspaces = memberships.filter((membership) => membership.owner?.id === currentUser.id);
  return ownedWorkspaces?.length <= 0;
};

export const getAllowedWorkspaceType = async (currentUser, memberships) => {
  if (canCreateSharedWorkspace(currentUser, memberships)) {
    const activeSubscription = await getSubscriptionForCustomer(currentUser);
    if (activeSubscription && activeSubscription?.plan?.id) {
      if ([process.env.REACT_APP_STRIPE_PRICE_ENTERPRISE_MONTHLY, process.env.REACT_APP_STRIPE_PRICE_ENTERPRISE_ANNUAL].includes(activeSubscription?.plan?.id)) {
        return {
          selection: 'enterprise',
          plan: activeSubscription?.plan?.interval === 'year' ? 'annual' : 'monthly',
          subscription: activeSubscription?.subscription,
        };
      }
      if ([process.env.REACT_APP_STRIPE_PRICE_TEAM_MONTHLY, process.env.REACT_APP_STRIPE_PRICE_TEAM_ANNUAL].includes(activeSubscription?.plan?.id)) {
        return {
          selection: 'team',
          plan: activeSubscription?.plan?.interval === 'year' ? 'annual' : 'monthly',
          subscription: activeSubscription?.subscription,
        };
      }
    }
  }
};

export async function logoutCurrentUser() {
  try {
    await Auth.signOut();
    return null;
  } catch(e) {
    console.error(`Error logging out user`, e);
    return null;
  }
}

export async function login(email, pswd) {
  try {
    const authedUser = await Auth.signIn(email, pswd);
    apollo.configure(!!authedUser);
    return authedUser;
  } catch(e) {
    if (e.name === 'UserNotConfirmedException') {
      throw e;
    }
    console.error(`Error logging in user`, e);
    return null;
  }
}

export async function subscriptionCreatedEvent(subscriptionId, email, tier, term, current_period_start, current_period_end, ws_name, ws_url, utm) {
  // Send an SQS message to the warehouse stripe event queue with the informations
  const cognitoUser = await getCognitoUser();
  const data = {
    action: 'subscribed',
    data: {
      subscriptionId,
      email,
      tier,
      term,
      current_period_start,
      current_period_end,
      ws_name,
      ws_url,
      ...utm,
    }
  };
  const jwtoken = cognitoUser.signInUserSession.idToken.jwtToken;
  await sendSQSMessage(data, WAREHOUSE_EVENTS_QUEUE_URL, null, jwtoken, awsconfig);
}