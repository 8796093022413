import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from "flowbite-react";
import { CloseCircle } from "@stoatlabs/xea-client-shared-components";
import { closeModal, modalTypes } from "../../../store/slices/modals";

const DownloadSessionErrorModal = () => {
  const modal = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const isOpen = modal.type === modalTypes.DOWNLOAD_SESSION_ERROR;

  const confirmClicked = (_) => {
    dispatch(closeModal(modalTypes.DOWNLOAD_SESSION_ERROR));
  };

  const modalContent = (
    <>
      <CloseCircle className="w-12 h-12 text-red-500 mt-4" />
      <p className="text-style-base text-gray-500 mt-5 text-center">
        There was an error when attempting to download your session. Please try again, or contact support if the problem persists.
      </p>
      <div className="flex mt-4">
        <button className='primary-button mr-4' onClick={confirmClicked}>
          Ok
        </button>
      </div>
    </>
  );

  return (
    <Modal show={isOpen} onClose={() => dispatch(closeModal(modalTypes.DOWNLOAD_SESSION_ERROR))} size="md">
      <Modal.Header></Modal.Header>
      <Modal.Body className='flex flex-col items-center'>
        {modalContent}
      </Modal.Body>
    </Modal>
  );
};

export default DownloadSessionErrorModal;
