import React from 'react';

const XrayLogoBrand = (props) => {
  const { prefersColorScheme } = props;
  if (prefersColorScheme === 'dark') {
    return (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 60">
        <defs>
          <style>{`.cls-1{opacity:.8;}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7{stroke-width:0px;}.cls-1,.cls-3,.cls-5{fill:#26b34b;}.cls-2{fill:#25b34b;}.cls-2,.cls-3,.cls-8{opacity:.5;}.cls-6{fill:#fff;}.cls-7{fill:#00a984;}`}</style>
        </defs>
        <rect className="cls-4" width="150" height="60"/>
        <path className="cls-5" d="M47.93,59.44h2.4v.51h-2.99v-4.12h2.91v.51h-2.32v3.1ZM47.88,57.61h2.12v.5h-2.12v-.5Z"/>
        <path className="cls-5" d="M50.58,59.95l1.71-2.34v.45l-1.62-2.24h.67l1.28,1.76h-.27s1.28-1.76,1.28-1.76h.64l-1.61,2.2v-.42l1.71,2.34h-.68l-1.37-1.88h.26l-1.35,1.88h-.68Z"/>
        <path className="cls-5" d="M54.84,59.95v-4.12h1.61c.36,0,.67.06.93.17.26.11.45.28.59.49.14.22.21.47.21.77s-.07.55-.21.77c-.14.21-.33.38-.59.49-.26.12-.56.17-.93.17h-1.28l.27-.28v1.53h-.59ZM55.43,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.68s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44Z"/>
        <path className="cls-5" d="M58.8,59.95v-4.12h.59v3.61h2.23v.51h-2.82Z"/>
        <path className="cls-5" d="M63.96,60c-.31,0-.61-.05-.87-.16-.27-.11-.5-.25-.7-.44-.2-.19-.35-.41-.46-.67-.11-.26-.16-.53-.16-.84s.05-.58.16-.84c.11-.26.26-.48.46-.67.2-.19.43-.34.7-.44s.56-.16.88-.16.6.05.87.16c.27.1.5.25.7.44.2.19.35.41.46.67.11.26.16.54.16.84s-.05.59-.16.84c-.11.26-.26.48-.46.67-.2.19-.43.34-.7.44s-.56.16-.87.16ZM63.96,59.48c.23,0,.44-.04.64-.12.19-.08.36-.19.51-.33.14-.14.26-.31.34-.5.08-.19.12-.4.12-.63s-.04-.44-.12-.63c-.08-.19-.19-.36-.34-.5s-.31-.26-.51-.34-.41-.12-.64-.12-.44.04-.63.12-.36.19-.51.34c-.15.15-.26.31-.34.5-.08.19-.12.4-.12.63s.04.44.12.63c.08.19.19.36.34.5.15.14.32.25.51.33.19.08.41.12.63.12Z"/>
        <path className="cls-5" d="M66.7,59.95v-4.12h1.61c.36,0,.67.06.92.17s.45.28.59.49.21.47.21.77-.07.55-.21.77-.33.38-.59.49c-.26.11-.56.17-.92.17h-1.28l.27-.27v1.53h-.59ZM67.29,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.69s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44ZM69.46,59.95l-1.05-1.5h.63l1.06,1.5h-.64Z"/>
        <path className="cls-5" d="M70.36,59.95l1.87-4.12h.58l1.87,4.12h-.62l-1.67-3.79h.24l-1.67,3.79h-.61ZM71.15,58.92l.16-.47h2.32l.17.47h-2.65Z"/>
        <path className="cls-5" d="M75.44,59.95v-3.61h-1.41v-.51h3.41v.51h-1.41v3.61h-.58Z"/>
        <path className="cls-5" d="M79.64,60c-.31,0-.61-.05-.87-.16-.27-.11-.5-.25-.7-.44-.2-.19-.35-.41-.46-.67-.11-.26-.16-.53-.16-.84s.05-.58.16-.84c.11-.26.26-.48.46-.67.2-.19.43-.34.7-.44s.56-.16.88-.16.6.05.87.16c.27.1.5.25.7.44.2.19.35.41.46.67.11.26.16.54.16.84s-.05.59-.16.84c-.11.26-.26.48-.46.67-.2.19-.43.34-.7.44s-.56.16-.87.16ZM79.64,59.48c.23,0,.44-.04.64-.12.19-.08.36-.19.51-.33.14-.14.26-.31.34-.5.08-.19.12-.4.12-.63s-.04-.44-.12-.63c-.08-.19-.19-.36-.34-.5s-.31-.26-.51-.34-.41-.12-.64-.12-.44.04-.63.12-.36.19-.51.34c-.15.15-.26.31-.34.5-.08.19-.12.4-.12.63s.04.44.12.63c.08.19.19.36.34.5.15.14.32.25.51.33.19.08.41.12.63.12Z"/>
        <path className="cls-5" d="M82.38,59.95v-4.12h1.61c.36,0,.67.06.93.17.26.11.45.28.59.49.14.22.21.47.21.77s-.07.55-.21.77c-.14.21-.33.38-.59.49-.26.11-.56.17-.93.17h-1.28l.27-.27v1.53h-.59ZM82.97,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.69s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44ZM85.13,59.95l-1.05-1.5h.63l1.06,1.5h-.64Z"/>
        <path className="cls-5" d="M87.48,59.95v-1.58l.14.37-1.77-2.91h.63l1.49,2.46h-.34l1.5-2.46h.58l-1.77,2.91.13-.37v1.58h-.58Z"/>
        <path className="cls-5" d="M90.55,59.95l1.87-4.12h.58l1.87,4.12h-.62l-1.67-3.79h.24l-1.67,3.79h-.61ZM91.34,58.92l.16-.47h2.32l.17.47h-2.65Z"/>
        <path className="cls-5" d="M95.37,59.95v-4.12h1.61c.36,0,.67.06.92.17.26.11.45.28.59.49.14.22.21.47.21.77s-.07.55-.21.77c-.14.21-.33.38-.59.49-.26.12-.56.17-.92.17h-1.28l.27-.28v1.53h-.59ZM95.96,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.68s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44Z"/>
        <path className="cls-5" d="M99.33,59.95v-4.12h1.61c.36,0,.67.06.92.17.26.11.45.28.59.49.14.22.21.47.21.77s-.07.55-.21.77c-.14.21-.33.38-.59.49-.26.12-.56.17-.92.17h-1.28l.27-.28v1.53h-.59ZM99.92,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.68s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44Z"/>
        <path className="cls-6" d="M58.81,41.91l2.18,3.12,2.18-3.12h2.15l-3.18,4.54,3.45,4.95h-2.18l-2.42-3.39-2.4,3.39h-2.12l3.41-4.87-3.19-4.62h2.12Z"/>
        <path className="cls-6" d="M72.91,51.41l-1.64-2.81c-.11.01-.22.01-.34.01h-2.14v2.79h-1.81v-9.49h3.95c2.45,0,3.84,1.18,3.84,3.27,0,1.49-.66,2.56-1.88,3.08l2.08,3.15h-2.07ZM68.8,47.01h2.14c1.35,0,2.11-.58,2.11-1.78s-.76-1.74-2.11-1.74h-2.14v3.51Z"/>
        <path className="cls-6" d="M83.21,49.4h-4.63l-.84,2.01h-1.88l4.15-9.49h1.88l4.1,9.49h-1.95l-.84-2.01ZM82.56,47.84l-1.65-3.99-1.68,3.99h3.33Z"/>
        <path className="cls-6" d="M90.08,48.3v3.11h-1.81v-3.04l-3.53-6.46h1.84l2.58,4.46,2.54-4.46h1.83l-3.45,6.39Z"/>
        <polygon className="cls-3" points="82.08 18.55 75 20.32 71.46 33.54 82.08 18.55"/>
        <polygon className="cls-1" points="82.08 18.55 67.92 22.09 67.92 13.24 78.54 13.24 82.08 18.55"/>
        <polygon className="cls-5" points="67.92 13.24 67.92 22.09 78.54 3.52 67.92 13.24"/>
        <polygon className="cls-3" points="62.52 10.45 66.89 10.45 66.89 6.07 57.77 1.33 62.52 10.45"/>
        <polygon className="cls-3" points="87.48 26.62 83.11 26.62 83.11 31 92.23 35.74 87.48 26.62"/>
        <path className="cls-2" d="M82.29,33c-2.19,1.11-4.67,1.73-7.29,1.73-8.95,0-16.2-7.25-16.2-16.2,0-2.61.62-5.08,1.72-7.26l-1.29-2.47c-1.75,2.83-2.76,6.16-2.76,9.74,0,10.24,8.3,18.53,18.53,18.53,3.58,0,6.93-1.02,9.76-2.78l-2.47-1.29Z"/>
        <path className="cls-2" d="M75,0c-3.58,0-6.93,1.02-9.76,2.78l2.47,1.29c2.19-1.11,4.67-1.73,7.29-1.73,8.95,0,16.2,7.25,16.2,16.2,0,2.61-.62,5.08-1.72,7.26l1.29,2.47c1.75-2.83,2.76-6.16,2.76-9.74,0-10.24-8.3-18.53-18.53-18.53Z"/>
        <g className="cls-8">
          <polygon className="cls-7" points="63.54 18.53 63.54 18.53 63.54 18.53 63.54 18.53"/>
        </g>
      </svg>
    );
  } else {
    return (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 60">
        <defs>
          <style>{`.cls-1{opacity:.8;}.cls-1,.cls-2,.cls-3{fill:#26b34b;}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6{stroke-width:0px;}.cls-2,.cls-7{opacity:.5;}.cls-4{fill:#211f1e;}.cls-5{fill:#fff;}.cls-6{fill:#00a984;}`}</style>
        </defs>
        <rect className="cls-5" width="150" height="60"/>
        <path className="cls-3" d="M47.93,59.44h2.4v.51h-2.99v-4.12h2.91v.51h-2.32v3.1ZM47.88,57.61h2.12v.5h-2.12v-.5Z"/>
        <path className="cls-3" d="M50.58,59.95l1.71-2.34v.45l-1.62-2.24h.67l1.28,1.76h-.27s1.28-1.76,1.28-1.76h.64l-1.61,2.2v-.42l1.71,2.34h-.68l-1.37-1.88h.26l-1.35,1.88h-.68Z"/>
        <path className="cls-3" d="M54.84,59.95v-4.12h1.61c.36,0,.67.06.93.17.26.11.45.28.59.49.14.22.21.47.21.77s-.07.55-.21.77c-.14.21-.33.38-.59.49-.26.12-.56.17-.93.17h-1.28l.27-.28v1.53h-.59ZM55.43,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.68s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44Z"/>
        <path className="cls-3" d="M58.8,59.95v-4.12h.59v3.61h2.23v.51h-2.82Z"/>
        <path className="cls-3" d="M63.96,60c-.31,0-.61-.05-.87-.16-.27-.11-.5-.25-.7-.44-.2-.19-.35-.41-.46-.67-.11-.26-.16-.53-.16-.84s.05-.58.16-.84c.11-.26.26-.48.46-.67.2-.19.43-.34.7-.44s.56-.16.88-.16.6.05.87.16c.27.1.5.25.7.44.2.19.35.41.46.67.11.26.16.54.16.84s-.05.59-.16.84c-.11.26-.26.48-.46.67-.2.19-.43.34-.7.44s-.56.16-.87.16ZM63.96,59.48c.23,0,.44-.04.64-.12.19-.08.36-.19.51-.33.14-.14.26-.31.34-.5.08-.19.12-.4.12-.63s-.04-.44-.12-.63c-.08-.19-.19-.36-.34-.5s-.31-.26-.51-.34-.41-.12-.64-.12-.44.04-.63.12-.36.19-.51.34c-.15.15-.26.31-.34.5-.08.19-.12.4-.12.63s.04.44.12.63c.08.19.19.36.34.5.15.14.32.25.51.33.19.08.41.12.63.12Z"/>
        <path className="cls-3" d="M66.7,59.95v-4.12h1.61c.36,0,.67.06.92.17s.45.28.59.49.21.47.21.77-.07.55-.21.77-.33.38-.59.49c-.26.11-.56.17-.92.17h-1.28l.27-.27v1.53h-.59ZM67.29,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.69s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44ZM69.46,59.95l-1.05-1.5h.63l1.06,1.5h-.64Z"/>
        <path className="cls-3" d="M70.36,59.95l1.87-4.12h.58l1.87,4.12h-.62l-1.67-3.79h.24l-1.67,3.79h-.61ZM71.15,58.92l.16-.47h2.32l.17.47h-2.65Z"/>
        <path className="cls-3" d="M75.44,59.95v-3.61h-1.41v-.51h3.41v.51h-1.41v3.61h-.58Z"/>
        <path className="cls-3" d="M79.64,60c-.31,0-.61-.05-.87-.16-.27-.11-.5-.25-.7-.44-.2-.19-.35-.41-.46-.67-.11-.26-.16-.53-.16-.84s.05-.58.16-.84c.11-.26.26-.48.46-.67.2-.19.43-.34.7-.44s.56-.16.88-.16.6.05.87.16c.27.1.5.25.7.44.2.19.35.41.46.67.11.26.16.54.16.84s-.05.59-.16.84c-.11.26-.26.48-.46.67-.2.19-.43.34-.7.44s-.56.16-.87.16ZM79.64,59.48c.23,0,.44-.04.64-.12.19-.08.36-.19.51-.33.14-.14.26-.31.34-.5.08-.19.12-.4.12-.63s-.04-.44-.12-.63c-.08-.19-.19-.36-.34-.5s-.31-.26-.51-.34-.41-.12-.64-.12-.44.04-.63.12-.36.19-.51.34c-.15.15-.26.31-.34.5-.08.19-.12.4-.12.63s.04.44.12.63c.08.19.19.36.34.5.15.14.32.25.51.33.19.08.41.12.63.12Z"/>
        <path className="cls-3" d="M82.38,59.95v-4.12h1.61c.36,0,.67.06.93.17.26.11.45.28.59.49.14.22.21.47.21.77s-.07.55-.21.77c-.14.21-.33.38-.59.49-.26.11-.56.17-.93.17h-1.28l.27-.27v1.53h-.59ZM82.97,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.69s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44ZM85.13,59.95l-1.05-1.5h.63l1.06,1.5h-.64Z"/>
        <path className="cls-3" d="M87.48,59.95v-1.58l.14.37-1.77-2.91h.63l1.49,2.46h-.34l1.5-2.46h.58l-1.77,2.91.13-.37v1.58h-.58Z"/>
        <path className="cls-3" d="M90.55,59.95l1.87-4.12h.58l1.87,4.12h-.62l-1.67-3.79h.24l-1.67,3.79h-.61ZM91.34,58.92l.16-.47h2.32l.17.47h-2.65Z"/>
        <path className="cls-3" d="M95.37,59.95v-4.12h1.61c.36,0,.67.06.92.17.26.11.45.28.59.49.14.22.21.47.21.77s-.07.55-.21.77c-.14.21-.33.38-.59.49-.26.12-.56.17-.92.17h-1.28l.27-.28v1.53h-.59ZM95.96,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.68s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44Z"/>
        <path className="cls-3" d="M99.33,59.95v-4.12h1.61c.36,0,.67.06.92.17.26.11.45.28.59.49.14.22.21.47.21.77s-.07.55-.21.77c-.14.21-.33.38-.59.49-.26.12-.56.17-.92.17h-1.28l.27-.28v1.53h-.59ZM99.92,58.49l-.27-.29h1.27c.38,0,.66-.08.86-.24.19-.16.29-.39.29-.68s-.1-.52-.29-.68c-.19-.16-.48-.24-.86-.24h-1.27l.27-.29v2.44Z"/>
        <path className="cls-4" d="M58.81,41.91l2.18,3.12,2.18-3.12h2.15l-3.18,4.54,3.45,4.95h-2.18l-2.42-3.39-2.4,3.39h-2.12l3.41-4.87-3.19-4.62h2.12Z"/>
        <path className="cls-4" d="M72.91,51.41l-1.64-2.81c-.11.01-.22.01-.34.01h-2.14v2.79h-1.81v-9.49h3.95c2.45,0,3.84,1.18,3.84,3.27,0,1.49-.66,2.56-1.88,3.08l2.08,3.15h-2.07ZM68.8,47.01h2.14c1.35,0,2.11-.58,2.11-1.78s-.76-1.74-2.11-1.74h-2.14v3.51Z"/>
        <path className="cls-4" d="M83.21,49.4h-4.63l-.84,2.01h-1.88l4.15-9.49h1.88l4.1,9.49h-1.95l-.84-2.01ZM82.56,47.84l-1.65-3.99-1.68,3.99h3.33Z"/>
        <path className="cls-4" d="M90.08,48.3v3.11h-1.81v-3.04l-3.53-6.46h1.84l2.58,4.46,2.54-4.46h1.83l-3.45,6.39Z"/>
        <polygon className="cls-2" points="82.08 18.55 75 20.32 71.46 33.54 82.08 18.55"/>
        <polygon className="cls-1" points="82.08 18.55 67.92 22.09 67.92 13.24 78.54 13.24 82.08 18.55"/>
        <polygon className="cls-3" points="67.92 13.24 67.92 22.09 78.54 3.52 67.92 13.24"/>
        <polygon className="cls-2" points="62.52 10.45 66.89 10.45 66.89 6.07 57.77 1.33 62.52 10.45"/>
        <polygon className="cls-2" points="87.48 26.62 83.11 26.62 83.11 31 92.23 35.74 87.48 26.62"/>
        <g className="cls-7">
          <path className="cls-2" d="M82.29,33c-2.19,1.11-4.67,1.73-7.29,1.73-8.95,0-16.2-7.25-16.2-16.2,0-2.61.62-5.08,1.72-7.26l-1.29-2.47c-1.75,2.83-2.76,6.16-2.76,9.74,0,10.24,8.3,18.53,18.53,18.53,3.58,0,6.93-1.02,9.76-2.78l-2.47-1.29Z"/>
          <path className="cls-2" d="M75,0c-3.58,0-6.93,1.02-9.76,2.78l2.47,1.29c2.19-1.11,4.67-1.73,7.29-1.73,8.95,0,16.2,7.25,16.2,16.2,0,2.61-.62,5.08-1.72,7.26l1.29,2.47c1.75-2.83,2.76-6.16,2.76-9.74,0-10.24-8.3-18.53-18.53-18.53Z"/>
        </g>
        <g className="cls-7">
          <polygon className="cls-6" points="63.54 18.53 63.54 18.53 63.54 18.53 63.54 18.53"/>
        </g>
      </svg>
    );
  }
};

export default XrayLogoBrand;