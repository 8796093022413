import { React, useContext } from "react";
import { Dropdown } from "flowbite-react";
import { FlowbiteCustomTheme } from "@stoatlabs/xea-client-shared-components";

import { logoutAction } from "../LogoutButton";
import RoundedUserAvatar from "../RoundedUserAvatar";

import { generateFakeSession } from '../../../models/session';

import { UserContext } from '../../../context/UserContext';
import { WorkspaceContext } from "../../../context/WorkspaceContext";

const DropdownMenu = (props) => {
  const { navigate } = props;
  const { currentWorkspace, setCurrentWorkspace } = useContext(WorkspaceContext);
  const { currentUser } = useContext(UserContext);

  const firstName = currentUser ? currentUser.firstName : 'Anonymous';
  const lastName = currentUser ? currentUser.lastName : 'User';
  const email = currentUser ? currentUser.email : 'Personal profile';
  const fullName = `${firstName} ${lastName}`.trim();

  const nav = (event, path) => {
    setCurrentWorkspace(null);
    if (event) {
      event.preventDefault();
    }
    navigate(path);
  };

  const generateSessionData = async () => {
    if(currentWorkspace) {
      for(let i = 0; i < 5; i++) {
        await generateFakeSession(currentUser.id, currentWorkspace.id, currentWorkspace?.configuration?.name?.toLowerCase());
      }
    }
  };

  return (
    <Dropdown
      theme={FlowbiteCustomTheme.theme.dropdown.main}
      inline
      className="focus:outline-none p-0"
      label={<RoundedUserAvatar {...props} />}
      arrowIcon={false}>
      <Dropdown.Header>
        <div className="flex flex-col items-start">
          <span className="text-style-semibold text-xgray-900">{fullName}</span>
          <span className="text-style-small text-xgray-500">{email}</span>
        </div>
      </Dropdown.Header>
      <Dropdown.Item onClick={e => nav(e, '/profile')}>
        My Profile
      </Dropdown.Item>
      { process.env.REACT_APP_STAGE !== 'prod' &&
        <>
          <Dropdown.Divider />
          <Dropdown.Item onClick={generateSessionData}>
            Generate Session Data
          </Dropdown.Item>
        </> }
      <Dropdown.Divider />
      <Dropdown.Item onClick={(e) => logoutAction(e, props)}>
        Log Out
      </Dropdown.Item>  
    </Dropdown>
  );
};

export default DropdownMenu;