import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AmplifySignup } from '@stoatlabs/xea-client-shared-components';
import { useCookies } from 'react-cookie';
import { Auth } from 'aws-amplify';

import ComponentWrapper from '../ComponentWrapper';

export const didSignupCb = async (email, pswd, setCookie, navigate) => {
  try {
    await Auth.signUp({
      username: email.toLowerCase(),
      password: pswd,
      autoSignIn: {
        // optional - enables auto sign in after user is confirmed
        enabled: true,
      }
    });
    if (setCookie) {
      setCookie('first-landing', 'true', { path: '/' });
    }
    const url = new URL('/confirm-passcode', window.location.origin);
    url.searchParams.set('email', email);
    navigate(url);
    return true;
  } catch(e) {
    console.error(`Error during signup callback`, e);
    return false;
  }
};

const Signup = (props) => {
  const [, setCookie] = useCookies(['first-landing', 'signup-selection']);
  const { navigate } = props;
  const { selection, plan } = useParams();


  useEffect(() => {
    const windowSearchParams = new URLSearchParams(window.location.search);
    const UTMSource = windowSearchParams.get("utm_source");
    const UTMCampaign = windowSearchParams.get("utm_campaign");
    const UTMContent = windowSearchParams.get("utm_content");
    const UTMMedium = windowSearchParams.get("utm_medium");
    const UTMTerm = windowSearchParams.get("utm_term");
    if (UTMSource) {
      setCookie('utm_source', UTMSource, { path: '/' });
    }
    if (UTMCampaign) {
      setCookie('utm_campaign', UTMCampaign, { path: '/' });
    }
    if (UTMContent) {
      setCookie('utm_content', UTMContent, { path: '/' });
    }
    if (UTMMedium) {
      setCookie('utm_medium', UTMMedium, { path: '/' });
    }
    if (UTMTerm) {
      setCookie('utm_term', UTMTerm, { path: '/' });
    }
  }, [setCookie]);

  useEffect(() => {
    if (['team', 'enterprise'].includes(selection)) {
      const planValue = !plan || plan === 'monthly' ? 'monthly' : 'annual';
      setCookie('signup-selection', selection, { path: '/' });
      setCookie('signup-plan', planValue, { path: '/' });
    }
  }, [selection, plan, setCookie]);

  const childProps = {
    ...props,
    didSignupCb: async (email, pswd) => didSignupCb(email, pswd, setCookie, navigate),
  }

  return (
    <ComponentWrapper {...childProps}>
      <AmplifySignup {...childProps} />
    </ComponentWrapper>
  );
};

export default Signup;