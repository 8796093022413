import React, { useId, useState, useRef, useEffect } from "react";
import { XEALogo, Envelope, checkPassword, PasswordPopup, ShowPasswordIcon, FlowbiteCustomTheme } from "@stoatlabs/xea-client-shared-components";
import { TextInput, Checkbox, Label } from "flowbite-react";
import { useCookies } from "react-cookie";

import ComponentWrapper from "../../ComponentWrapper";
import WorkspaceCard from "../WorkspaceCard";
import { loginRedirectCookieName, loginRedirectCookieValue } from "../LoginRedirect";
import { didSignupCb } from "../Signup";
import { login } from "../../../store/currentUser";

const passwordMinLength = 8;

const InviteSignup = (props) => {
  const [, setCookie,] = useCookies([loginRedirectCookieName, 'signup-invite']);
  const { membership, navigate, userExists } = props;
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const inviterName = `${membership.inviter.firstName} ${membership.inviter.lastName}`.trim()
  const inviterLabel = inviterName.length ? `${inviterName} (${membership.inviter.email})` : membership.inviter.email;
  const description = `${inviterLabel} has invited you to join ${membership.workspace.name} Workspace. ${isLoggingIn ? 'Log in' : 'Sign up'} to start collaborating.`
  const passwordId = useId();
  const pswdFieldRef = useRef(null);
  const [pswd, setPswd] = useState('');
  const [showPswd, setShowPswd] = useState(false);
  const [pswdValid, setPswdValid] = useState({valid: false});
  const [terms, setTerms] = useState(false);
  const [signupBtnDisabled, setSignupBtnDisabled] = useState(true);
  const [authWorking, setAuthWorking] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const newPswdPopupProps = {
    fieldRef: pswdFieldRef,
    validity: pswdValid,
  };

  useEffect(() => {
    setIsLoggingIn(userExists);
  }, [userExists]);

  useEffect(() => {
    setSignupBtnDisabled(!pswd.length
                         || !pswdValid.valid
                         || (!isLoggingIn && !terms)
                         || authWorking);
    }, [pswd, pswdValid, terms, authWorking, isLoggingIn]);

  const preventSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const keyDownHandler = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      if (!signupBtnDisabled) {
        if (isLoggingIn) {
          onLoginClick();
        } else {
          onSignupClick();
        }
      }
    }
  };

  const onSignupClick = async (_event) => {
    try {
      setAuthWorking(true);
      setEmailError(false);
      setCookie('signup-invite', `${window.location.pathname}${window.location.search}${window.location.hash}`, { path: '/' });
      const signupResult = await didSignupCb(membership.inviteEmail, pswd, setCookie, navigate)
      if (!signupResult) {
        setEmailError('Something went wrong, please try again');
        setAuthWorking(false);
      }
    } catch (e) {
      console.error(`Error during signup:`, e);
      setAuthWorking(false);
    }
  };

  const onLoginClick = async (_event) => {
    try {
      setCookie(loginRedirectCookieName, loginRedirectCookieValue, { path: '/' });
      setAuthWorking(true);
      setEmailError(false);
      const loginResult = await login(membership.inviteEmail, pswd);
      setAuthWorking(false);
      if (!loginResult) {
        setEmailError('Something went wrong, please try again');
      } else {
        navigate(`/invite/${membership.id}`);
      }
    } catch (e) {
      console.error(`Error during signup:`, e);
      setAuthWorking(false);
    }
  };

  // const toggleLoginSignup = () => {
  //   setIsLoggingIn(!isLoggingIn);
  // };

  return (
    <ComponentWrapper {...props} hideFooter={true} hideCloseButton={true}>
      <div id="amplify-login-container" className="flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
        <div className="w-[452px] p-2 m-auto mt-8">
          <XEALogo {...props} />
          <form className="flex flex-col" onSubmit={preventSubmit}>
            <div className="text-style-xl-semibold text-xgray-900 text-center">You're Invited!</div>
            <p className="text-style-base text-xgray-500 text-center my-2">{description}</p>
            <WorkspaceCard {...props} workspace={membership.workspace} />

            { emailError && (
              <div className="message error mt-4">
                <span className="error-message">{emailError}</span>
              </div>
            )}

            <div className="input-field-container">
              <label className="input-field-label">
                <span className="text-xred-800 mr-1">*</span>
                Your email
              </label>
              <TextInput
                className="w-full"
                // className="input-field login-text-input email-field"
                icon={Envelope}
                name="email"
                value={membership.inviteEmail}
                required
                type="email"
                disabled
                readOnly
              />
            </div>
            <div className="input-field-container relative">
              {!isLoggingIn && <PasswordPopup {...newPswdPopupProps} />}
              <label className="input-field-label" htmlFor={passwordId}>
                <span className="text-xred-800 mr-1">*</span>
                Password
              </label>
              <div className="input-field pswd-input-field">
                <input
                  ref={pswdFieldRef}
                  type={showPswd ? 'text' : 'password'}
                  minLength={passwordMinLength}
                  id={passwordId}
                  className="login-text-input"
                  value={pswd}
                  onChange={e => {
                    setPswd(e.target.value);
                    setPswdValid(checkPassword(e.target.value));
                  }}
                  onKeyDown={keyDownHandler}
                />
                <button className="show-pswd-btn" type="button" onClick={() => setShowPswd(!showPswd)}>
                  <ShowPasswordIcon hide={showPswd} />
                </button>
              </div>
            </div>
            {!isLoggingIn &&
              <div className="input-field-container checkbox-container">
                <Checkbox
                  theme={FlowbiteCustomTheme.theme.checkbox}
                  id="terms-checkbox"
                  // className="checkbox-field"
                  tabIndex="6"
                  checked={terms}
                  onChange={e => setTerms(!terms)}
                />
                <Label className="checkbox-field-label" htmlFor="terms-checkbox">
                  I agree to <a href="https://www.ideracorp.com/Legal/Terms-of-Use" target="_blank" rel="noreferrer">terms & conditions</a> and <a href="https://www.ideracorp.com/Legal/XBlend/PrivacyStatement" target="_blank" rel="noreferrer">privacy policy</a>.
                </Label>
              </div>}

            <button className="mt-4 submit-button" disabled={signupBtnDisabled} onClick={isLoggingIn ? onLoginClick : onSignupClick}>{isLoggingIn ? 'Log In' : 'Create Account'}</button>
            {/* <p className="mt-4 h-[19px] text-center text-style-small-medium text-gray-500">
              {isLoggingIn ? 'Don\'t have an account?' : 'Already have an account?'}
              <button className="inline-block h-[19px] text-xray-primary-select ml-1.5" onClick={toggleLoginSignup}>{isLoggingIn ? 'Sign up' : 'Log in' }</button>.
            </p> */}
          </form>
        </div>
      </div>
    </ComponentWrapper>
  );
};

export default InviteSignup;