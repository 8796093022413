import React, { useState, useEffect, useContext } from "react";
import { Sidebar as FlowbiteSidebar } from "flowbite-react";
import { FlowbiteCustomTheme, AngleLeft, FolderOpen } from "@stoatlabs/xea-client-shared-components";

import StorageIndicator from "./StorageIndicator";
import WorkspacePicker from "./WorkspacePicker";
import { workspaceTitle } from "../WorkspaceTitle";
import { WorkspaceContext } from "../../../context/WorkspaceContext";
import { MembershipContext } from "../../../context/MembershipContext";
import { WorkspaceScope } from "../../../models";
import { isOwnerOfWorkspace } from "../../../models/membership";

const Sidebar = (props) => {
  const { userProfile, cancelAccount, changePassword, navigate, usersManagement, subscriptionManagement, aboutWorkspace, navbarClassType } = props;
  const { currentWorkspace, setCurrentWorkspace, workspaces } = useContext(WorkspaceContext);
  const { memberships } = useContext(MembershipContext);

  const isWorkspaceOwner = currentWorkspace && isOwnerOfWorkspace(memberships?.data, currentWorkspace);
  const isSharedWorkspace = currentWorkspace && currentWorkspace.scope === WorkspaceScope.SHARED

  const currentWorkspaceUrl = currentWorkspace?.url ? `/workspace/${currentWorkspace.url}` : "/";

  const nav = (event, path) => {
    if (event) event.preventDefault();

    navigate(path);
  };

  const [isUserProfile, setIsUserProfile] = useState(false);
  useEffect(() => {
    setIsUserProfile(userProfile || cancelAccount || changePassword);
  }, [userProfile, cancelAccount, changePassword]);

  const [isTeamManagement, setIsTeamManagement] = useState(false);
  useEffect(() => {
    setIsTeamManagement(usersManagement || subscriptionManagement || aboutWorkspace);
  }, [usersManagement, subscriptionManagement, aboutWorkspace]);

  const navigateToWorkspace = (workspace) => {
    return (event) => {
      if(workspace) {
        setCurrentWorkspace(workspace);
        nav(event, `/workspace/${workspace.url}`);
      } else {
        nav(event, '/');
      }
    };
  };

  const navToWs = (event) => {
    if(event) event.preventDefault();

    if(currentWorkspace) {
      navigate(`/workspace/${currentWorkspace.url}`)
    } else {
      navigate(`/`);
    }
  };

  return (
    <FlowbiteSidebar theme={FlowbiteCustomTheme.theme.sidebar} className={`sidebar-${navbarClassType}-banner`}>
      <div className="h-full flex flex-col">
        <div className="flex items-start pb-6 border-b border-b-xgray-200 px-[6px] pt-4">
        { currentWorkspace &&
            <WorkspacePicker {...props} navigateToWorkspace={navigateToWorkspace} />}
        </div>
        { !isUserProfile && !isTeamManagement && currentWorkspace &&
          <a href={currentWorkspaceUrl} onClick={navToWs} className={`flex items-center mt-4 px-2.5 py-1.5 rounded-lg ${!isUserProfile ? 'bg-xgray-996' : ''}`}>
            <FolderOpen className="w-5 text-xgray-600" />
            <div className="ml-3 text-style-medium text-xgray-900 overflow-x-hidden text-ellipsis whitespace-pre">
              {workspaceTitle(currentWorkspace)}
            </div>
          </a>
        }
        { !isUserProfile && !isTeamManagement && !currentWorkspace &&
          <div className="flex flex-col">
            <a href={currentWorkspaceUrl} onClick={(e) => nav(e, '/')}>
              <div className="flex items-center mt-4 p-[6px]">
                <AngleLeft className="w-2 text-xgray-500" />
                <div className="ml-3 text-style-medium text-xgray-900">
                  Back to workspace
                </div>
              </div>
            </a>
          </div>
        }
        { isTeamManagement &&
          <div className="flex flex-col">
            <a href={currentWorkspaceUrl} onClick={(e) => nav(e, currentWorkspaceUrl)}>
              <div className="flex items-center mt-4 p-[6px]">
                <AngleLeft className="w-2 text-xgray-500" />
                <div className="ml-3 text-style-medium text-xgray-900">
                  Back to workspace
                </div>
              </div>
            </a>
            <a href={`${currentWorkspaceUrl}/users`} onClick={(e) => nav(e, `${currentWorkspaceUrl}/users`)} className={`flex items-center mt-4 text-style-medium px-2.5 py-1.5 rounded-lg ${usersManagement ? 'bg-xgray-996' : 'text-xgray-900'}`}>
              Manage Team
            </a>
            {isSharedWorkspace && isWorkspaceOwner &&
              <a href={`${currentWorkspaceUrl}/subscription`} target="_blank" rel="noreferrer" className={`flex items-center mt-4 text-style-medium px-2.5 py-1.5 rounded-lg ${subscriptionManagement ? 'bg-xgray-996' : 'text-xgray-900'}`}>
                Manage Subscription
              </a>}
            <a href={`${currentWorkspaceUrl}/about-workspace`} onClick={(e) => nav(e, `${currentWorkspaceUrl}/about-workspace`)} className={`flex items-center mt-4 text-style-medium px-2.5 py-1.5 rounded-lg ${aboutWorkspace ? 'bg-xgray-996' : 'text-xgray-900'}`}>
              About Workspace
            </a>
          </div>
        }
        { isUserProfile &&
          <div className="flex flex-col">
            <a href={currentWorkspaceUrl} onClick={(e) => nav(e, currentWorkspaceUrl)}>
              <div className="flex items-center mt-4 p-[6px]">
                <AngleLeft className="w-2 text-xgray-500" />
                <div className="ml-3 text-style-medium text-xgray-900">
                  Back to workspace
                </div>
              </div>
            </a>
            <a href="/profile" onClick={(e) => nav(e, '/profile')} className={`flex items-center mt-4 text-style-medium px-2.5 py-1.5 rounded-lg ${userProfile ? 'bg-xgray-996' : 'text-xgray-900'}`}>
              My Profile
            </a>
            <a href="/profile/change-password" onClick={(e) => nav(e, '/profile/change-password')} className={`flex items-center mt-4 text-style-medium px-2.5 py-1.5 rounded-lg ${changePassword ? 'bg-xgray-996' : 'text-xgray-900'}`}>
              Change Password
            </a>
          </div>
        }
        { currentWorkspace && <StorageIndicator {...props} memberships={memberships?.data} workspaces={workspaces} currentWorkspace={currentWorkspace} /> }
      </div>
    </FlowbiteSidebar>
  );
};

export default Sidebar;