import { createContext, useReducer } from 'react';

import { observerReducer, initialState } from '../reducer/observerReducer';
import { useObserveCurrentWorkspace } from '../hooks/useObserveCurrentWorkspace';

export const CurrentWorkspaceContext = createContext(null);

const CurrentWorkspaceProvider = ({ children }) => {
  const [memberships, setMemberships] = useReducer(observerReducer, initialState);
  const [sessions, setSessions] = useReducer(observerReducer, initialState);
  const [users, setUsers] = useReducer(observerReducer, initialState);

  const clearCurrentWorkspaceData = () => {
    setMemberships({ type: 'reset' });
    setSessions({ type: 'reset' });
    setUsers({ type: 'reset' });
  };

  useObserveCurrentWorkspace(setSessions, setUsers, setMemberships, clearCurrentWorkspaceData);

  const store = {
    memberships,
    sessions,
    users,
    clearCurrentWorkspaceData,
  }

  return (
    <CurrentWorkspaceContext.Provider value={store}>
      {children}
    </CurrentWorkspaceContext.Provider>
  );
};

export default CurrentWorkspaceProvider;