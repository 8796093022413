import React from 'react';

const ShowPasswordIcon = ({ hide }) => {
  if (hide) {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.96149 9.98785C2.83024 10.3804 2.40577 10.5924 2.01304 10.4615C1.62008 10.3305 1.4077 9.90579 1.53869 9.51283C1.52503 9.55852 1.53925 9.51117 1.53925 9.51117C1.54754 9.48657 1.55639 9.46217 1.56545 9.43784C1.58089 9.39636 1.60307 9.33906 1.63261 9.26819C1.6916 9.12657 1.78025 8.93001 1.90333 8.69681C2.14882 8.23167 2.53538 7.61307 3.10358 6.99321C4.25155 5.74089 6.13018 4.5 9.0002 4.5C11.8702 4.5 13.7489 5.74089 14.8968 6.99321C15.4651 7.61307 15.8516 8.23167 16.097 8.69681C16.2202 8.93001 16.3088 9.12657 16.3678 9.26819C16.3973 9.33906 16.4195 9.39636 16.435 9.43784C16.4396 9.45023 16.4551 9.52871 16.4699 9.60409C16.4834 9.67265 16.4965 9.73863 16.5004 9.75C16.5004 9.75 16.6256 10.2488 15.9874 10.4615C15.5953 10.5922 15.1715 10.381 15.0395 9.98964L15.0389 9.98785L15.0383 9.98622L15.0292 9.96109C15.0202 9.93666 15.0049 9.89728 14.9832 9.84511C14.9397 9.74062 14.8701 9.58563 14.7705 9.39694C14.5707 9.01833 14.2541 8.51193 13.7911 8.00679C12.8765 7.00911 11.3802 6 9.0002 6C6.62023 6 5.12386 7.00911 4.20932 8.00679C3.74627 8.51193 3.42973 9.01833 3.2299 9.39694C3.13033 9.58563 3.06076 9.74062 3.01721 9.84511C2.99548 9.89728 2.98031 9.93666 2.97122 9.96109L2.96149 9.98785Z" fill="#999999"/>
        <path d="M6 10.5C6 8.84314 7.34314 7.5 9 7.5C10.6569 7.5 12 8.84314 12 10.5C12 12.1569 10.6569 13.5 9 13.5C7.34314 13.5 6 12.1569 6 10.5Z" fill="#999999"/>
      </svg>
    );
  }
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.56898 0.931612C1.39324 0.755869 1.10832 0.755869 0.932589 0.931612C0.756846 1.10735 0.756846 1.39227 0.932589 1.568L4.08152 4.71694C2.57413 5.74241 1.45179 7.34625 1.05288 9.25792C1.00212 9.50119 1.15819 9.7396 1.40148 9.79036C1.64477 9.84112 1.88314 9.68506 1.93391 9.4417C2.29734 7.70009 3.34204 6.25641 4.73117 5.3666L6.15483 6.79024C5.35936 7.362 4.84131 8.29537 4.84131 9.34981C4.84131 11.0895 6.25161 12.4998 7.99131 12.4998C9.04568 12.4998 9.97907 11.9818 10.5508 11.1863L14.4325 15.068C14.6083 15.2437 14.8933 15.2437 15.069 15.068C15.2447 14.8923 15.2447 14.6073 15.069 14.4316L1.56898 0.931612Z" fill="#999999"/>
      <path d="M8.11133 6.20215L11.1381 9.229C11.0761 7.58542 9.75491 6.26416 8.11133 6.20215Z" fill="#999999"/>
      <path d="M5.7793 3.86882L6.50205 4.59157C6.98563 4.46618 7.48844 4.4 8.00132 4.4C10.8864 4.4 13.453 6.49419 14.0682 9.44189C14.1189 9.68525 14.3573 9.84131 14.6006 9.79055C14.8439 9.7397 14.9999 9.50138 14.9492 9.25811C14.2496 5.90581 11.3254 3.5 8.00132 3.5C7.23166 3.5 6.48344 3.62898 5.7793 3.86882Z" fill="#999999"/>
    </svg>
  );
}

export default ShowPasswordIcon;