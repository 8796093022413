import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from "flowbite-react";
import { closeModal, modalTypes } from "../../../store/slices/modals";
import { renameSession } from "../../../models/session"
import { useAutoFocusCallback } from "../../../hooks/useAutoFocusCallback";

const RenameSessionModal = () => {
  const modal = useSelector((state) => state.modals);
  const [name, setName] = useState(modal.session.name);
  const dispatch = useDispatch();
  const isOpen = modal.type === modalTypes.RENAME_SESSION;
  const nameAutoFocusCb = useAutoFocusCallback();

  const confirmClicked = (_) => {
    if (name.length) {
      renameSession(modal.session, name, modal.currentWorkspace);
      dispatch(closeModal(modalTypes.RENAME_SESSION));
    }
  };

  useEffect(() => {
    setName(modal.session.name);
  }, [modal.session.name]);

  const keyDownHandler = e => {
    if (e.keyCode === 13) {
      confirmClicked();
    }
  }

  const cancelSubmit = e => {
    e?.stopPropagation();
    e?.preventDefault();
  };

  return (
    <Modal show={isOpen} onClose={() => dispatch(closeModal(modalTypes.RENAME_SESSION))} size="md">
      <Modal.Header></Modal.Header>
      <Modal.Body className='flex flex-col'>
        <form className="input-field-container" onSubmit={cancelSubmit}>
          <label className="input-field-label" htmlFor="name-text-input">
            Rename
          </label>
          <input
            type="text"
            required
            id="name-text-input"
            className={`input-field ${modal.error ? 'invalid' : ''}`}
            name="rename-name"
            value={name}
            autoFocus
            ref={nameAutoFocusCb}
            onKeyDown={keyDownHandler}
            onChange={ e => setName(e.target.value) }
          />
          {modal.errorMessage &&
            <div className="input-field-error-message mt-4">
              {modal.errorMessage}
            </div>
          }
        </form>
        <div className="flex mt-4">
          <button className='primary-button mr-4 ml-auto' disabled={!name.length} onClick={confirmClicked}>
            Save Changes
          </button>
          <button className='secondary-button mr-auto' onClick={() => dispatch(closeModal(modalTypes.RENAME_SESSION))}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RenameSessionModal;
