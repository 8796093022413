import React from "react";
import { Card, Avatar } from "flowbite-react";
import { FlowbiteCustomTheme } from "@stoatlabs/xea-client-shared-components";

import { MembershipState } from "../../models";

const WorkspaceCard = (props) => {
  const { workspace, acceptInvite, loading, disabled } = props;

  const usersCount = workspace.memberships?.filter(m => m.state === MembershipState.ACCEPTED).length || 0;

  return (
    <Card theme={FlowbiteCustomTheme.theme.workspaceCard} className="w-full mt-4">
      <div className="flex items-start">
        <Avatar theme={FlowbiteCustomTheme.theme.teamWorkspaceAvatar} placeholderInitials={workspace?.name?.charAt(0)?.toUpperCase() || 'T'} size="md" />
        <div className="flex flex-col items-start justify-start ml-3">
          <div className="text-style-semibold text-xgray-900 !leading-4 mb-0.5">{workspace?.name}</div>
          {!!workspace.memberships && <div className="text-style-small text-xgray-500 !leading-4 mb-0.5">{usersCount} user{usersCount !== 1 ? 's' : ''}</div>}
          {!workspace?.owner && <div className="text-style-small text-xgray-500 !leading-4">Team Workspace</div>}
        </div>
        { acceptInvite &&
          <button className="ml-auto primary-button btn-xs" disabled={loading || disabled} onClick={acceptInvite}>Join Now</button>}
      </div>
    </Card>
  );
};

export default WorkspaceCard;