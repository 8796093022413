import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

export const loginRedirectCookieName = 'login-redirect';

export const loginRedirectCookieValue = `${window.location.pathname}${window.location.search}${window.location.hash}`;

const LoginRedirect = (props) => {
  const { navigate } = props;
  const [, setCookie,] = useCookies([loginRedirectCookieName]);

  useEffect(() => {
    if (!['/', '/login', '/logout', '/signup', '/confirm-passcode', '/verification-success', '/create-profile', '/create-workspace'].includes(window.location.pathname)) {
      setCookie(loginRedirectCookieName, loginRedirectCookieValue, { path: '/' });
    }
    navigate('/login');
  }, [setCookie, navigate]);

  return (
    <></>
  );
};

export default LoginRedirect;