import { useRef, useEffect } from 'react';

import { observeConfigurations } from '../models/configuration';

export function useObserveConfigurations(setConfigurations) {
  const destroyCallbackRef = useRef(null);

  useEffect(() => {
    const fn = async () => {
      async function startObserving() {
        observeConfigurations(setConfigurations).then((destroyCallback) => {
          destroyCallbackRef.current = destroyCallback;
        });
      }

      startObserving();
    }
    fn();

    if (destroyCallbackRef.current) {
      return destroyCallbackRef.current;
    }
  }, [setConfigurations]);
};
