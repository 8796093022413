import React, { useState } from "react";
import { XEALogo } from "@stoatlabs/xea-client-shared-components";

import ComponentWrapper from "../../ComponentWrapper";
import WorkspaceCard from "../WorkspaceCard";
import { acceptMembershipInvite } from "../../../models/membership";
import { sendInviteAcceptedEmail } from "../../../models/user";
import { MembershipState } from "../../../models";
import InviteExpired from "./Expired";

const InviteAccept = (props) => {
  const { membership, currentUser } = props;
  const [loading, setLoading] = useState(false);

  const preventSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const { inviter, workspace } = membership;
  const { configuration, memberships } = workspace;

  const acceptedInvites = memberships.filter(m => m.state === MembershipState.ACCEPTED);
  const isTeamOverflow = configuration.maxUsers && acceptedInvites.length >= configuration.maxUsers;

  if (isTeamOverflow) {
    return <InviteExpired {...props} />
  }

  const inviterName = `${inviter.firstName} ${inviter.lastName}`.trim();

  const acceptInvite = async (e) => {
    setLoading(true);
    await acceptMembershipInvite(membership, currentUser, membership.workspace);
    await sendInviteAcceptedEmail(membership, currentUser);
    window.location = `/workspace/${membership.workspace.url}`;
  };

  return (
    <ComponentWrapper {...props} hideCloseButton={true} hideFooter={true}>
      <div id="amplify-login-container" className="flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
        <div className="w-[452px] p-2 m-auto mt-4">
          <XEALogo {...props} />

          <form className="flex flex-col" onSubmit={preventSubmit}>
            <div className="text-style-xl-semibold text-xgray-900 text-center">Get Started With {workspace?.name}</div>
            <div className="text-style-small text-xgray-500 text-center mx-8 mt-4">
              { inviterName } ({inviter.email}) has invited you to join their Workspace. Join now to start collaborating.
            </div>
            <WorkspaceCard {...props}
              workspace={workspace}
              acceptInvite={acceptInvite}
              loading={loading}
              disabled={isTeamOverflow} />
          </form>
        </div>
      </div>
    </ComponentWrapper>
  );
};

export default InviteAccept;