import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import {SQSClient} from "@aws-sdk/client-sqs";
import { SendMessageCommand } from "@aws-sdk/client-sqs";
import { Lambda } from '@aws-sdk/client-lambda';
import { Auth } from 'aws-amplify';

const checkPassword = (pswdValue) => {
  if (!pswdValue) {
    return { valid: false };
  }
  const hasNumber = pswdValue.match(/\d+/g);
  const hasLowerCaseLetter = pswdValue.match(/[a-z]+/g);
  const hasUpperCaseLetter = pswdValue.match(/[A-Z]+/g);
  const hasSpecialCharacter = pswdValue.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]+/g);
  const hasMinimumLength = pswdValue.length >= 8;
  return {
    valid: hasNumber && hasLowerCaseLetter
           && hasUpperCaseLetter && hasSpecialCharacter
           && hasMinimumLength,
    hasNumber: !!hasNumber,
    hasLowerCaseLetter: !!hasLowerCaseLetter,
    hasUpperCaseLetter: !!hasUpperCaseLetter,
    hasSpecialCharacter: !!hasSpecialCharacter,
    hasMinimumLength: !!hasMinimumLength,
  };
};

const sendSQSMessage = async (msg, sqsQueueUrl, deduplicationId, jwtoken, awsconfig) => {
  try {
    const sqsClient = new SQSClient({
      region: awsconfig.aws_project_region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: awsconfig.aws_project_region }),
        identityPoolId: awsconfig.aws_cognito_identity_pool_id,
        logins: {
          [`cognito-idp.${awsconfig.aws_project_region}.amazonaws.com/${awsconfig.aws_user_pools_id}`]: jwtoken
        }
      }),
    });
    const strBody = JSON.stringify(msg)
    const commandBody = {
      MessageBody: strBody,
      MessageGroupId: "Group1",  // Grouping for the FIFO policy, same for all messages in our case
      QueueUrl: sqsQueueUrl
    };
    if (deduplicationId?.length) {
      commandBody['MessageDeduplicationId'] = deduplicationId;
    }
    var command = new SendMessageCommand(commandBody);

    return await sqsClient.send(command);
  } catch(e) {
    console.error(`Error sending SQS message`, e);
  }
};

const invokeLambda = async (lambdaFunction, payload, awsconfig) => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentCredentials().then(credentials => {
        const lambda = new Lambda({
          credentials: Auth.essentialCredentials(credentials),
          region: awsconfig.aws_project_region,
        });
        const body = {
          ...payload,
          "userPoolId": `${awsconfig.aws_user_pools_id}`,
        };
        const requestPayload = {
          "Records": [
            {"body": JSON.stringify(body)} // SQS message body is a string, so we need to stringify the payload
          ]
        }
        lambda.invoke({
          FunctionName: lambdaFunction,
          Payload: JSON.stringify(requestPayload),
        }).then((data) => {
          const decodedPayload = new TextDecoder('ascii').decode(data.Payload);
          if (decodedPayload?.length) {
            const parsedPayload = JSON.parse(decodedPayload);
            const parsedBody = JSON.parse(parsedPayload.body);
            resolve(parsedBody);
          } else {
            reject("No payload returned")
          }
        }, reject);
      })
    } catch(error) {
      console.log(`error invoking lambda ${lambdaFunction}`, error);
      reject(error);
    }
  });
};

export { checkPassword, sendSQSMessage, invokeLambda };