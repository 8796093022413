import React from 'react';
import { AmplifyResetPassword } from '@stoatlabs/xea-client-shared-components';

import ComponentWrapper from '../ComponentWrapper';

const ResetPassword = (props) => {
  const { navigate } = props;

  const childProps = {
    ...props,
    didResetPasswordCb: () => {
      const url = new URL('/login', window.location.origin);
      url.searchParams.set('passwordReset', 'ok');
      url.searchParams.set('message', 'Your password is reset. Please log in with your new password.');
      navigate(url);
    },
  };

  return (
    <ComponentWrapper {...childProps}>
      <AmplifyResetPassword {...childProps} />
    </ComponentWrapper>
  );
};

export default ResetPassword;