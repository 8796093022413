import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from "flowbite-react";
import { InfoCircle } from "@stoatlabs/xea-client-shared-components";
import { closeModal, modalTypes, sessionErrors } from "../../../store/slices/modals";

const LockedSessionModal = () => {
  const modal = useSelector((state) => state.modals);
  const user = modal.user;
  const dispatch = useDispatch();
  const isOpen = modal.type === modalTypes.LOCKED_SESSION;

  const confirmClicked = (_) => {
    dispatch(closeModal(modalTypes.LOCKED_SESSION));
  };

  const modalContent = (
    <>
      <InfoCircle className="w-12 h-12 text-[#1da1f2] mt-4" />
      <span className="text-style-semibold text-gray-900 mt-4">Test Session in Use!</span>
      <p className="text-style-base text-gray-500 mt-5 text-center">
        The "
        <span className="text-style-semibold text-gray-900">{modal.session.name}</span>
        " session is currently being used by <span className="text-style-semibold text-gray-900">{user.firstName} {user.lastName}</span>. A session can be used by one user at a time. Contact the user or try again later.
      </p>
      <div className="flex mt-4">
        <button className='primary-button' onClick={confirmClicked}>
          Ok, I'll Try Again Later
        </button>
      </div>
    </>
  );

  const notExistModalError = (
    <>
    <InfoCircle className="w-12 h-12 text-[#1da1f2] mt-4" />
      <p className="text-style-base text-gray-500 mt-5 text-center">
        The "
        <span className="text-style-semibold text-gray-900">{modal.name}</span>
        " session doesn't exist.
      </p>
      <div className="flex mt-4">
        <button className='primary-button mr-4' onClick={() => dispatch(closeModal(modalTypes.LOCKED_SESSION))}>
          Ok
        </button>
      </div>
    </>
  );

  return (
    <Modal show={isOpen} onClose={() => dispatch(closeModal(modalTypes.LOCKED_SESSION))} size="md">
      <Modal.Header></Modal.Header>
      <Modal.Body className='flex flex-col items-center'>
        {!modal.error && modalContent}
        {modal.error && modal.errorType === sessionErrors.LOCKED_SESSION.NOT_EXISTS && notExistModalError}
      </Modal.Body>
    </Modal>
  );
};

export default LockedSessionModal;
