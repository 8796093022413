import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FlowbiteCustomTheme, CloseCircle } from "@stoatlabs/xea-client-shared-components";

import { Modal, Alert } from "flowbite-react";
import { closeModal, modalTypes } from "../../../store/slices/modals";
import { duplicateSession } from "../../../models/session"
import { useAutoFocusCallback } from "../../../hooks/useAutoFocusCallback";
import { checkWorkspace } from "../../../models/workspace";

const DuplicateSessionModal = () => {
  const [name, setName] = useState('');
  const [workspace, setWorkspace] = useState('');
  const modal = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const isOpen = modal.type === modalTypes.DUPLICATE_SESSION;
  const autoFocusRef = useAutoFocusCallback();
  const [error, setError] = useState(null);

  const confirmClicked = async (_) => {
    setError(null);
    const respWs = await checkWorkspace(workspace.id);
    if (respWs?.success) {
      await duplicateSession(modal.session, name, workspace.id);
      dispatch(closeModal(modalTypes.DUPLICATE_SESSION));
    } else {
      setError({
        ...respWs,
        workspace,
      });
    }
  };

  useEffect(() => {
    setName(`${modal.name} Copy`);
  }, [modal.name])

  useEffect(() => {
    setWorkspace(modal.workspaces[0]);
  }, [modal.workspaces])

  const cancelSubmit = e => {
    e?.stopPropagation();
    e?.preventDefault();
  };

  const additionalContentForError = () => {
    if (error) {
      if (error.workspaceFull) {
        return (<span>There is no space left on the selected workspace. Please choose another workspace.</span>)
      } else if (error.paymentsRequired) {
        return (<span>The selected workspace is suspended due to a payment issue. Please choose another workspace.</span>)
      } else if (!error.success) {
        return (<span>Something went wrong while duplicating to the selected workspace. Please try again.</span>)
      }
    }
  }

  const titleForError = () => {
    if (error) {
      if (error.workspaceFull) {
        return `${error.workspace?.name} Cloud Storage is Full.`
      } else if (error.paymentsRequired) {
        return "Expired Subscription."
      } else if (!error.success) {
        return "An Error Occurred."
      }
    }
  }

  return (
    <Modal show={isOpen} onClose={() => dispatch(closeModal(modalTypes.DUPLICATE_SESSION))} size="md">
      <Modal.Header></Modal.Header>
      <Modal.Body className='flex flex-col'>
        <span className="text-style-xl-semibold text-xgray-900 text-center">Duplicate Test Session</span>
        <form className="input-field-container" onSubmit={cancelSubmit}>
          <label className="input-field-label" htmlFor="name-text-input">
            Name
          </label>
          <input
            type="text"
            required
            id="name-text-input"
            className="input-field"
            value={name}
            name="duplicate-name"
            ref={autoFocusRef}
            autoFocus
            onChange={e => setName(e.target.value)}
          />
        </form>
        { modal.workspaces.length > 1 &&
          <div className="input-field-container">
            <label className="input-field-label" htmlFor="workspace-text-input">
              Workspace
            </label>
            <select
              type="text"
              required
              id="workspace-text-input"
              className="input-field"
              value={workspace.id}
              onChange={e => setWorkspace(modal.workspaces.find(ws => ws.id === e.target.value))}>
              {modal.workspaces.map((ws) => {
                return (<option key={ws.id} value={ws.id}>{ws.name}</option>)
              })}
            </select>
          </div> }
        {!!error &&
          <Alert theme={FlowbiteCustomTheme.theme.alert} color="failure" icon={CloseCircle} additionalContent={additionalContentForError()} className="mt-4">
            {titleForError()}
          </Alert>}
        <div className="flex mt-6">
          <button className='primary-button mr-4 ml-auto' disabled={!name.length || !workspace} onClick={confirmClicked}>
            Duplicate
          </button>
          <button className='secondary-button mr-auto' onClick={() => dispatch(closeModal(modalTypes.DUPLICATE_SESSION))}>
            Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DuplicateSessionModal;
