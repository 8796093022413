import React, { useState, useMemo, useCallback, useContext } from "react";
import { Table, Footer, Pagination, Dropdown, Badge } from 'flowbite-react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, getSortedRowModel, flexRender } from "@tanstack/react-table";
import titleize from "titleize";

import { FlowbiteCustomTheme, ArrowUp, ArrowDown, DotsHorizontal } from '@stoatlabs/xea-client-shared-components';
import { MembershipRole, MembershipState } from "../../models";
import { makeAdmin, revokeAdmin } from "../../models/membership";
import { useDispatch, useSelector } from "react-redux";
import { modalTypes, openModal } from "../../store/slices/modals";
import RemoveUserModal from "./modals/RemoveUser";
import { sendInviteEmail } from "../../models/user";
import { WorkspaceContext } from "../../context/WorkspaceContext";
import { CurrentWorkspaceContext } from "../../context/CurrentWorkspaceContext";

const initialPageSize = 10;

const UsersTable = (props) => {
  const { userId } = props;
  const { currentWorkspace } = useContext(WorkspaceContext);
  const { memberships } = useContext(CurrentWorkspaceContext);
  const modal = useSelector((state) => state.modals);
  const dispatch = useDispatch();

  const [sorting, setSorting] = useState([{
    id: 'role',
    desc: false
  }]);
  const [pageSize] = useState(initialPageSize);
  const [pageIndex, setPageIndex] = useState(0);

  const resendInvitation = useCallback(async (membership) => {
    await sendInviteEmail(membership, currentWorkspace);
  }, [currentWorkspace]);

  const toAdmin = useCallback(async (membership) => {
    await makeAdmin(membership, currentWorkspace);
  }, [currentWorkspace]);

  const toUser = useCallback(async (membership) => {
    await revokeAdmin(membership, currentWorkspace);
  }, [currentWorkspace]);

  const removeUser = useCallback(async (membership) => {
    dispatch(openModal({
      type: modalTypes.REMOVE_USER,
      user: membership.user,
      membership: membership,
      workspace: currentWorkspace,
    }));
  }, [currentWorkspace, dispatch]);

  const columns = useMemo(() => {
    const getName = (row) => {
      if (row.state === MembershipState.ACCEPTED) {
        if (row.user.firstName || row.user.lastName) {
          return `${row.user.firstName} ${row.user.lastName}`.trim();
        }
      }
      return 'Invited';
    };
    const colorForMembership = (row) => {
      return row.state !== MembershipState.ACCEPTED ? 'text-xgray-400' : 'text-xgray-500';
    };
    return [
      {
        accessorKey: 'name',
        header: () => 'Name',
        cell: info => {
          const rowValue = info.cell.row.original;
          const color = colorForMembership(rowValue);
          return (<span className={color}>{getName(rowValue)}</span>);
        },
        sortingFn: (rowA, rowB) => {
          const valA = getName(rowA.original).toLowerCase();
          const valB = getName(rowB.original).toLowerCase();

          return valA < valB ? 1 : valA > valB ? -1 : 0;
        },
      },
      {
        accessorKey: 'email',
        cell: info => {
          const rowValue = info.cell.row.original;
          const color = colorForMembership(rowValue);
          const email = rowValue.state === MembershipState.ACCEPTED ? rowValue.user?.email : rowValue.inviteEmail;
          return <span className={color}>{email}</span>
        },
        sortingFn: (rowA, rowB) => {
          const valA = rowA.original;
          const valB = rowB.original;
          const emailA = valA.state === MembershipState.ACCEPTED ? valA.user?.email : valA.inviteEmail;
          const emailB = valB.state === MembershipState.ACCEPTED ? valB.user?.email : valB.inviteEmail;
          return emailA === emailB ? 1 : emailA < emailB ? 1 : -1;
        },
        header: () => 'Email',
      },
      {
        accessorKey: 'role',
        cell: info => {
          const rowValue = info.cell.row.original;
          const badgeColor = rowValue.state !== MembershipState.ACCEPTED ? 'gray' : (rowValue.role === MembershipRole.ADMIN ? 'success' : 'indigo');
          return (
            <span className="flex">
              <Badge color={badgeColor} theme={FlowbiteCustomTheme.theme.badge}>{titleize(rowValue.role)}</Badge>
              { rowValue.isOwner && <span className="text-xray-primary-link ml-2 text-style-small-medium">(Owner)</span> }
            </span>
          );
        },
        sortingFn: (rowA, rowB) => {
          const userA = rowA.original;
          const userB = rowB.original;
          const isAUser = userA.user?.id === userId;
          const isBUser = userB.user?.id === userId;
          const valA = isAUser ? 0 : (userA.role === MembershipRole.ADMIN ? 1 : 2);
          const valB = isBUser ? 0 : (userB.role === MembershipRole.ADMIN ? 1 : 2);
          return valA > valB ? 1 : (valA < valB ? -1 : 0);
        },
        header: () => 'Role',
      },
      {
        accessorKey: 'actions',
        header: () => '',
        enableSorting: false,
        cell: info => {
          const rowValue = info.cell.row.original;
          const hasAccepted = rowValue.state === MembershipState.ACCEPTED;
          if (rowValue.isOwner) {
            return null;
          }
          return (
            <div className='w-4'>
              <Dropdown
                theme={FlowbiteCustomTheme.theme.dropdown.main}
                label={<><DotsHorizontal className='w-4 h-4 text-xgray-800' /></>}
                inline
                arrowIcon={false}
                >
                { !hasAccepted &&
                  <Dropdown.Item onClick={() => resendInvitation(rowValue)}>
                    Resend Invitation
                  </Dropdown.Item> }
                { rowValue.role === MembershipRole.USER &&
                  <Dropdown.Item onClick={() => toAdmin(rowValue)}>
                    Assign as Admin
                  </Dropdown.Item>}
                { rowValue.role === MembershipRole.ADMIN &&
                  <Dropdown.Item onClick={() => toUser(rowValue)}>
                    Revoke Admin
                  </Dropdown.Item>}
                <Dropdown.Item className="text-xred-700" onClick={() => removeUser(rowValue)}>
                  {hasAccepted ? "Remove User" : "Revoke Invitation"}
                </Dropdown.Item>
              </Dropdown>
            </div>
          );
        }
      },
    ]
  }, [userId, resendInvitation, toAdmin, toUser, removeUser]);

  const table = useReactTable({
    data: memberships?.data,
    columns,
    enableSortingRemoval: false,
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      }
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const startIndex = pageSize * pageIndex;
  const endIndex = startIndex + table.getPaginationRowModel().rows.length;

  const flowbitePagination = (
    <Footer theme={FlowbiteCustomTheme.theme.tableFooter} container>
      <div className='flex w-full items-center'>
        <span className='text-style-small text-xgray-600'>Showing <span className='text-style-small-semibold text-xgray-900'>{startIndex + 1}-{endIndex}</span> of <span className='text-style-small-semibold text-xgray-900'>{table.getCoreRowModel().rows.length}</span></span>
        <Pagination
          theme={FlowbiteCustomTheme.theme.pagination}
          previousLabel=''
          nextLabel=''
          showIcons
          currentPage={pageIndex + 1}
          onPageChange={(page) => setPageIndex(page - 1)}
          totalPages={table.getPageCount()}
          className='ml-auto'
        />
      </div>
    </Footer>
  );

  // 349, 264, 196, 196, 48
  const columnSizes = ['w-[calc(46.94-48px)]', 'w-[34.37%]', 'w-[18.61%]', 'w-[48px]'];

  const tableHead = table.getHeaderGroups().map(headerGroup => (
    <Table.Head key={headerGroup.id} style={{...{width: '100%'}}}>
      {headerGroup.headers.map((header, index) => (
        <Table.HeadCell key={header.id} className={`p-4 bg-xgray-50 border-b border-xgray-200 ${columnSizes[index]}`}>
          <div
            className={`flex items-center text-xgray-500 ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`}
            onClick={header.column.getToggleSortingHandler()}
            >
            {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
            {{
              asc: (<ArrowUp className="w-3 h-3 ml-1 text-xgray-500" />),
              desc: (<ArrowDown className="w-3 h-3 ml-1 text-xgray-500" />),
              none: (<span className='w-3 h-3 ml-1'></span>),
            }[header.column.getIsSorted() || 'none']}
          </div>
        </Table.HeadCell>
      ))}
    </Table.Head>
  ));

  const closingGroup = 'group-last/body:group-last/row:first:rounded-bl-none group-last/body:group-last/row:last:rounded-br-none'

  const tableBody = (
    <Table.Body>
      { table.getRowModel().rows.map(row => {
        return (
          <Table.Row key={row.id} className={`border-b border-xgray-200 ${row.original.state === MembershipState.ACCEPTED ? 'bg-white' : 'bg-xgray-100/25'}`}>
            {row.getVisibleCells().map(cell => (
              <Table.Cell key={cell.id} className={`p-4 text-style-base text-xgray-900 ${closingGroup}`} align={(cell.column.columnDef.meta)?.align || 'left'}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Table.Cell>
            ))}
          </Table.Row>)
        })}
    </Table.Body>
  );

  return (
    <div className='flex flex-col mt-8'>
      { modal.type === modalTypes.REMOVE_USER && <RemoveUserModal {...props} />}
      <Table className='bg-white'>
        { tableHead }
        { tableBody }
      </Table>
      <div className="">
        { flowbitePagination }
      </div>
    </div>
  );
};

export default UsersTable;
