import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from "flowbite-react";
import { CloseCircle, InfoCircle } from "@stoatlabs/xea-client-shared-components";
import { closeModal, errorMessage, modalTypes, sessionErrors } from "../../../store/slices/modals";

import { removeSession } from "../../../models/session";

const DeleteSessionModal = () => {
  const modal = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const isOpen = modal.type === modalTypes.DELETE_SESSION;

  const confirmClicked = (_) => {
    const succeeded = removeSession(modal.id);
    if (succeeded) {
      dispatch(closeModal(modalTypes.DELETE_SESSION));
    } else {
      dispatch(errorMessage({errorType: sessionErrors.DELETE_SESSION.NOT_EXISTS}));
    }
  };

  const modalContent = (
    <>
      <CloseCircle className="w-12 h-12 text-xred-500 mt-4" />
      <p className="text-style-base text-xgray-500 mt-5 text-center">
        By deleting the “
        <span className="text-style-semibold text-xgray-900">{modal.name}</span>
        ” session, all related assets (screenshots, reports, etc.) will be {' '}
        <span className="text-style-semibold text-xred-500">permanently deleted</span>
        . Please confirm you want to delete this session. This action cannot be undone.
      </p>
      <div className="flex mt-4">
        <button className='red-button mr-4' onClick={confirmClicked}>
          Yes, Delete Session
        </button>
        <button className='secondary-button' onClick={() => dispatch(closeModal(modalTypes.DELETE_SESSION))}>
          No, Cancel
        </button>
      </div>
    </>
  );

  const notOwnerModalError = (
    <>
    <InfoCircle className="w-12 h-12 text-[#1da1f2] mt-4" />
      <p className="text-style-base text-xgray-500 mt-5 text-center">
        The "
        <span className="text-style-semibold text-xgray-900">{modal.name}</span>
        " session cannot be deleted as it was created by another user. You don't have permission to delete sessions from another user.
      </p>
      <div className="flex mt-4">
        <button className='primary-button mr-4' onClick={() => dispatch(closeModal(modalTypes.DELETE_SESSION))}>
          Ok
        </button>
      </div>
    </>
  );

  const notExistModalError = (
    <>
    <InfoCircle className="w-12 h-12 text-[#1da1f2] mt-4" />
      <p className="text-style-base text-xgray-500 mt-5 text-center">
        The "
        <span className="text-style-semibold text-xgray-900">{modal.name}</span>
        " session doesn't exist or was deleted already.
      </p>
      <div className="flex mt-4">
        <button className='primary-button mr-4' onClick={() => dispatch(closeModal(modalTypes.DELETE_SESSION))}>
          Ok
        </button>
      </div>
    </>
  );

  return (
    <Modal show={isOpen} onClose={() => dispatch(closeModal(modalTypes.DELETE_SESSION))} size="md">
      <Modal.Header></Modal.Header>
      <Modal.Body className='flex flex-col items-center'>
        {!modal.error && modalContent}
        {modal.error && modal.errorType === sessionErrors.DELETE_SESSION.NOT_EXISTS && notExistModalError}
        {modal.error && modal.errorType === sessionErrors.DELETE_SESSION.NOT_OWNER && notOwnerModalError}
      </Modal.Body>
    </Modal>
  );
};

export default DeleteSessionModal;
