import React, { useEffect } from 'react';
import { AmplifyConfirmPasscode } from '@stoatlabs/xea-client-shared-components';

import ComponentWrapper from '../ComponentWrapper';

const ConfirmPasscode = (props) => {
  const { navigate } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const hasEmailParam = urlParams.has("email");

  // Redirect to login in case the user email is missing
  // means the user came here directly
  useEffect(() => {
    if (!hasEmailParam) {
      navigate("/login");
    }
  }, [hasEmailParam, navigate]);

  const childProps = {
    ...props,
    didConfirmPasscodeCb: (autoSignInSucceed, _userObject) => {
      if (!autoSignInSucceed) {
        const url = new URL('/login', window.location.origin);
        url.searchParams.set('collect', 'true');
        navigate(url);
      } else {
        navigate('/verification-success');
      }
    },
  };

  return (
    <ComponentWrapper {...childProps}>
      <AmplifyConfirmPasscode {...childProps} />
    </ComponentWrapper>
  );
};

export default ConfirmPasscode;