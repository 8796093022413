import React from 'react';

import XrayLogoBrand from '../Svg/XrayLogoBrand';

const XEALogo = (props) => {
  const { hideXEALogo } = props;

  return (
    <div className={`xray-logo-container w-[224px] h-[56px] mx-auto mb-[24px] ${ hideXEALogo ? 'hidden' : '' }`}>
      <XrayLogoBrand {...props} />
    </div>
  );
};

export default XEALogo;
