import { useRef, useEffect } from 'react';

import { useObserverHandler } from './useObserverHandler';
import { observeMembershipsForUser, observeMembership } from '../models/membership';

import { observeMembershipCreatesForUser, observeMembershipUpdatesForUser, observeMembershipDeletesForUser } from '../models/membership';

export function useObserveMembershipsForUser(userId, setMemberships) {
  useObserverHandler(userId,
                     () => observeMembershipsForUser(setMemberships),
                     [userId, setMemberships]);

  useObserverHandler(userId,
                     () => observeMembershipCreatesForUser(userId, setMemberships),
                     [userId, setMemberships])

  useObserverHandler(userId,
                     () => observeMembershipUpdatesForUser(userId, setMemberships),
                     [userId, setMemberships])

  useObserverHandler(userId,
                     () => observeMembershipDeletesForUser(userId, setMemberships),
                     [userId, setMemberships])
};

export function useObserveMembership(membershipId, setMembership) {
  const destroyCallbackRef = useRef(null);

  useEffect(() => {
    if(!membershipId) return;

    async function startObserving() {
      observeMembership(membershipId, setMembership).then((destroyCallback) => {
        destroyCallbackRef.current = destroyCallback;
      });
    }

    startObserving();

    if (destroyCallbackRef.current) {
      return destroyCallbackRef.current;
    }
  }, [membershipId, setMembership]);
};
