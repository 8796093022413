import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from "flowbite-react";
import { CloseCircle } from "@stoatlabs/xea-client-shared-components";
import { closeModal, modalTypes } from "../../../store/slices/modals";

import { removeMembership } from "../../../models/membership";

const RemoveUserModal = () => {
  const modal = useSelector((state) => state.modals);
  const { type, membership, user, workspace } = modal;
  const dispatch = useDispatch();
  const isOpen = type === modalTypes.REMOVE_USER;

  const confirmClicked = async (_) => {
    try {
      await removeMembership(membership, workspace);
      dispatch(closeModal(modalTypes.REMOVE_USER));
    } catch(error) {

    }
  };

  const userName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim();
  const email = user?.email ?? membership.inviteEmail;

  return (
    <Modal show={isOpen} onClose={() => dispatch(closeModal(modalTypes.REMOVE_USER))} size="md">
      <Modal.Header></Modal.Header>
      <Modal.Body className='flex flex-col items-center px-14'>
        <CloseCircle className="w-12 h-12 text-xred-500 mt-4" />
        <span className="text-style-xl-semibold text-xgray-900 text-center mt-4">Remove User From Team?</span>
        <p className="w-full text-style-base text-xgray-500 mt-5 self-start">
          { !!userName.length && <strong className="text-style-semibold">{userName}</strong> }
          <span className="block w-full overflow-hidden text-ellipsis whitespace-pre">{ email }</span>
        </p>
        <ul className="text-style-base text-xgray-500 mt-5 list-disc">
          <li>User won't be able to login to team workspace.</li>
          <li>User's test sessions will be displayed as '@unknown', and editing and deletion rights will be granted to the workspace owner.</li>
          {/* <li>No charge for unused seat on the next invoice.</li> */}
        </ul>
        <div className="flex mt-4">
          <button className='red-button mr-4' onClick={confirmClicked}>
            Yes, Delete User
          </button>
          <button className='secondary-button' onClick={() => dispatch(closeModal(modalTypes.REMOVE_USER))}>
            No, Cancel
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveUserModal;
