import React, { useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useReactTable, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel } from '@tanstack/react-table';
import { Pagination, Table, Footer, Dropdown, Tooltip } from 'flowbite-react';
import prettyBytes from 'pretty-bytes';

import { DesktopPc, DotsHorizontal, FlowbiteCustomTheme, ArrowUp, ArrowDown, VideoPlay, FileLines, JiraIcon, XrayJiraIcon, Lock } from '@stoatlabs/xea-client-shared-components';

import DeleteSessionModal from './modals/DeleteSession';
import DuplicateSessionModal from './modals/DuplicateSession';
import RenameSessionModal from './modals/RenameSession';
import UnlockSessionModal from './modals/UnlockSession';
import LockedSessionModal from './modals/LockedSession';
import DownloadSessionErrorModal from './modals/DownloadSessionError';
import { modalTypes, openModal } from '../../store/slices/modals';
import { MembershipRole, SessionMode, SessionType, WorkspaceStatus } from '../../models';
import LowStorageWarning from './LowStorageWarning';
import SubscriptionExpiredWarning from './SubscriptionExpiredWarning';
import WorkspaceTitle from './WorkspaceTitle';
import { UserContext } from '../../context/UserContext';
import { CurrentWorkspaceContext } from '../../context/CurrentWorkspaceContext';
import { WorkspaceContext } from '../../context/WorkspaceContext';

const initialPageSize = 10;

const SessionsTable = (props) => {
  const { currentUser } = useContext(UserContext);
  const { sessions, users } = useContext(CurrentWorkspaceContext);
  const { lowStorageWarningPanel, userMembership } = props;
  const { workspaces, currentWorkspace } = useContext(WorkspaceContext);
  const modal = useSelector((state) => state.modals);
  const [sorting, setSorting] = useState([{
    id: 'updatedAt',
    desc: true
  }]);
  const [pageSize] = useState(initialPageSize);
  const [pageIndex, setPageIndex] = useState(0);
  const dispatch = useDispatch();

  const columns = useMemo(() => {
    const localSessionIcon = (
      <Tooltip content='Standalone, No Connection to Jira' position='top' theme={FlowbiteCustomTheme.theme.tooltip}>
        <DesktopPc className="text-xgray-400 w-4 h-4" />
      </Tooltip>
    );
  
    const jiraSessionIcon = (domain) => (
      <Tooltip content={`Jira • ${domain}`} position='top' theme={FlowbiteCustomTheme.theme.tooltip}>
        <JiraIcon className="w-6 h-6 -ml-[3px]" />
      </Tooltip>
    );
  
    const xraySessionIcon = (domain) => (
      <Tooltip content={`Jira/Xray • ${domain}`} position='top' theme={FlowbiteCustomTheme.theme.tooltip}>
        <XrayJiraIcon className="w-6 h-6 -ml-[3px]" />
      </Tooltip>
    );
    
    const charterCell = (
      <div className='flex items-center'>
        <FileLines className='w-3 h-3 mr-2' />
        Charter
      </div>
    );

    const sessionCell = (
      <div className='flex items-center'>
        <VideoPlay className='w-3 h-3 mr-2' />
        Session
      </div>
    );

    const downloadSession = async (rowValue) => {
      let requestUrl = `${process.env.REACT_APP_STORAGE_AUTHORIZER_ENDPOINT}?`;
      requestUrl += `sessionId=${rowValue.id}&`;
      requestUrl += `workspaceId=${encodeURIComponent(currentWorkspace.id)}&`;
      requestUrl += `source=download`

      const currentAuthUser = await Auth.currentAuthenticatedUser();
      const authorizationToken = currentAuthUser?.signInUserSession?.accessToken?.jwtToken;

      const requestResult = await fetch(requestUrl, {
        headers: {
          Authorization: authorizationToken,
          'x-xea-request-type': 'GET',
        },
      })

      const requestBody = await requestResult.json();

      const { status } = requestResult;
      const { message } = requestBody;

      if(status === 200) {
        return window.open(message, '_blank');
      } else {
        return dispatch(openModal({
          type: modalTypes.DOWNLOAD_SESSION_ERROR
        }))
      }
    }

    const duplicateSession = (rowValue) => {
      return dispatch(openModal({
        type: modalTypes.DUPLICATE_SESSION,
        session: rowValue,
        id: rowValue.id,
        name: rowValue.name,
        workspaces: workspaces.map((workspace) => { return { id: workspace.id, name: workspace.name } }),
        currentUserId: currentUser.id
      }));
    };

    const renameSession = (rowValue) => {
      dispatch(openModal({
        type: modalTypes.RENAME_SESSION,
        session: rowValue,
        currentWorkspace
      }))
    };

    const continueInDesktopApp = (rowValue) => {
      const session = rowValue;
      const lockedBy = rowValue?.lockedBy;
      const lockedAt = rowValue?.lockedAt;

      if(lockedAt && lockedBy?.id && lockedBy?.id !== currentUser.id) {
        const lockedByUser = users?.data?.find(u => u.id === lockedBy?.id);
        dispatch(openModal({
          type: modalTypes.LOCKED_SESSION,
          user: lockedByUser,
          session,
          currentWorkspace
        }))
      } else {
        // old deep link proxy url https://xea-launcher.getxray.app/start
        const url = new URL(window.location.origin);
        url.pathname = '/start';
        url.searchParams.set('jiraURL', session.domain ?? '');
        url.searchParams.set('workspaceId', currentWorkspace.id);
        url.searchParams.set('sessionId', session.id);
        url.searchParams.set('userId', currentUser.id);
        if (SessionMode.LOCAL === session.mode) {
          // type = '&sessionType=local&jiraType=JS';
          url.searchParams.set('sessionType', 'local');
          url.searchParams.set('jiraType', 'JS');
        } else if (SessionMode.JIRA === session.mode) {
          // type = '&sessionType=jira&jiraType=JS';
          url.searchParams.set('sessionType', 'jira');
          url.searchParams.set('jiraType', 'JS');
        } else {
          // type = '&sessionType=xray&jiraType=JS';
          url.searchParams.set('sessionType', 'xray');
          url.searchParams.set('jiraType', 'JS');
        }
        window.open(url, '_blank');
      }
    };

    const unlockSession = (rowValue) => {
      const session = rowValue;
      const lockedBy = rowValue?.lockedBy;
      const lockedByUser = users?.data?.find(u => u.id === lockedBy?.id);

      dispatch(openModal({
        type: modalTypes.UNLOCK_SESSION,
        user: lockedByUser,
        session,
        currentWorkspace
      }))
    };

    return [
      {
        accessorKey: 'mode',
        header: () => 'Mode',
        cell: info => {
          const rowValue = info.cell.row.original;
          const mode = rowValue.mode;
          const domain = rowValue.domain
          if (SessionMode.LOCAL === mode) {
            return localSessionIcon;
          } else if (SessionMode.JIRA === mode) {
            return jiraSessionIcon(domain);
          } else {
            return xraySessionIcon(domain);
          }
        },
        meta: {
          align: 'center',
        },
      },
      {
        accessorKey: 'name',
        cell: info => {
          const rowValue = info.cell.row.original;
          const lockedAt = parseInt(rowValue.lockedAt, 10);
          const lockedBy = rowValue?.lockedBy;
          const lockedByUser = users?.data?.find(u => u.id === lockedBy?.id);
          const name = info.getValue();

          if(rowValue?.lockedAt && lockedByUser) {
            return (
              <div className='flex items-center'>
                <span className='mr-2'>{name}</span>
                <Tooltip content={`Locked by ${lockedByUser.firstName} ${lockedByUser.lastName} on ${new Date(lockedAt).toLocaleString()}`} position='top' theme={FlowbiteCustomTheme.theme.tooltip}>
                  <Lock className="text-gray-400 w-4 h-4" />
                </Tooltip>
              </div>
            )
          } else {
            return name;
          }
        },
        header: () => 'Name',
      },
      {
        accessorKey: 'type',
        cell: info => {
          const rowValue = info.cell.row.original;
          const charterType = rowValue.type === SessionType.CHARTER;
          return charterType ? charterCell : sessionCell;
        },
        header: 'Type'
      },
      {
        accessorKey: 'updatedAt',
        cell: info => {
          const rowValue = info.cell.row.original;
          const userId = rowValue?.user?.id;
          const user = users?.data?.find(u => u?.id === userId);
          const latestModifier = user?.firstName ? `${user?.firstName} ${user?.lastName}`.trim() : 'Unknown User';
          const createdAt = parseInt(info.getValue(), 10);
          const updatedAt = parseInt(rowValue.updatedAt, 10);
          return (
            <Tooltip content={`Modified by ${latestModifier} on ${new Date(updatedAt).toLocaleString()}`} position='top' theme={FlowbiteCustomTheme.theme.tooltip}>
              {new Date(createdAt).toDateString()}
            </Tooltip>
          )
        },
        header: () => 'Modified',
      },
      {
        accessorKey: 'size',
        cell: info => {
          const bytes = info.getValue();
          if (!bytes || bytes.length === 0 || bytes === "0") {
            return "";
          }

          // Sizes are stored in Megabytes, so we need to convert to bytes
          return prettyBytes(Number(bytes));
        },
        sortingFn: (rowA, rowB) => {
          const valA = Number(rowA.original.size);
          const valB = Number(rowB.original.size);
          return valA > valB ? 1 : (valA < valB ? -1 : 0);
        },
        header: () => 'Size',
      },
      {
        accessorKey: 'userObject',
        cell: info => {
          const rowValue = info.cell.row.original;
          const userId = rowValue?.user?.id;
          const user = users?.data?.find(u => u?.id === userId);
          const userFullName = user?.firstName ? `${user.firstName} ${user.lastName}`.trim() : 'Unknown User';
          return (
            <Tooltip content={`Owned by ${userFullName}`} position='top' theme={FlowbiteCustomTheme.theme.tooltip}>
              {user?.firstName || user?.lastName || 'Unknown User'}
            </Tooltip>
          );
        },
        sortingFn: (rowA, rowB) => {
          const userA = users?.data?.find(u => u.id === rowA.original.user.id);
          const userB = users?.data?.find(u => u.id === rowB.original.user.id);
          const valA = `${userA.firstName} ${userA.lastName}`.trim();
          const valB = `${userB.firstName} ${userB.lastName}`.trim();
          return valA > valB ? 1 : (valA < valB ? -1 : 0);
        },
        header: () => 'Owner',
      },
      {
        accessorKey: 'actions',
        header: () => '',
        enableSorting: false,
        cell: info => {
          const rowValue = info.cell.row.original;
          const isOwnerOrAdmin = userMembership?.role === MembershipRole.ADMIN || rowValue.user.id === currentUser.id;
          const isLockerOrAdmin = userMembership?.role === MembershipRole.ADMIN || rowValue.lockedBy?.id === currentUser.id;
          const rowHasSize = !rowValue.size || rowValue.size === 0 || rowValue.size === "0";
          return (
            <div className='w-4'>
              <Dropdown
                theme={FlowbiteCustomTheme.theme.dropdown.main}
                label={<><DotsHorizontal className='w-4 h-4 text-xgray-800' /></>}
                inline
                arrowIcon={false}
                >
                <Dropdown.Item onClick={() => continueInDesktopApp(rowValue)}>
                  <span>Continue in desktop app</span>
                </Dropdown.Item>
                { rowValue.mode === SessionMode.XRAY && rowValue.reportUrl &&
                  <Dropdown.Item onClick={() => window.open(rowValue.reportUrl, '_blank')}>
                    <span>View test case in Xray</span>
                  </Dropdown.Item>
                }
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => duplicateSession(rowValue)}>
                  { workspaces.length > 1 && <span>Duplicate to</span>}
                  { workspaces.length <= 1 && <span>Duplicate</span>}
                </Dropdown.Item>
                <Dropdown.Item className={`${rowHasSize ? 'text-gray-400 cursor-default' : ''}`} disabled={rowHasSize} onClick={() => downloadSession(rowValue)}>
                  <span>Download</span>
                </Dropdown.Item>
                { isOwnerOrAdmin &&
                  <Dropdown.Divider /> }
                { isOwnerOrAdmin &&
                  <Dropdown.Item onClick={() => renameSession(rowValue)}>
                    <span>Rename</span>
                  </Dropdown.Item> }
                { isLockerOrAdmin && rowValue.lockedAt &&
                  <Dropdown.Item onClick={() => unlockSession(rowValue)}>
                    <span>Unlock</span>
                  </Dropdown.Item> }
                { isOwnerOrAdmin &&
                  <Dropdown.Item title={`${rowValue.lockedAt && 'Cannot delete a locked session'}`} className={`${rowValue.lockedAt ? 'text-gray-400 cursor-default' : ''}`} disabled={rowValue.lockedAt} onClick={() => dispatch(openModal({type: modalTypes.DELETE_SESSION, workspaceId: currentWorkspace.id, id: rowValue.id, name: rowValue.name}))}>
                    <span>Delete</span>
                  </Dropdown.Item> }
              </Dropdown>
            </div>
          );
        }
      }];
    }, [dispatch, currentWorkspace, workspaces, users, currentUser.id, userMembership?.role]);

  const table = useReactTable({
    data: sessions.data,
    columns,
    enableSortingRemoval: false,
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      }
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const startIndex = pageSize * pageIndex;
  const endIndex = startIndex + table.getPaginationRowModel().rows.length;

  const flowbitePagination = (
    <Footer theme={FlowbiteCustomTheme.theme.tableFooter} container>
      <div className='flex w-full items-center'>
        <span className='text-style-small text-xgray-600'>Showing <span className='text-style-small-semibold text-xgray-900'>{startIndex + 1}-{endIndex}</span> of <span className='text-style-small-semibold text-xgray-900'>{table.getCoreRowModel().rows.length}</span></span>
        <Pagination
          theme={FlowbiteCustomTheme.theme.pagination}
          previousLabel=''
          nextLabel=''
          showIcons
          currentPage={pageIndex + 1}
          onPageChange={(page) => setPageIndex(page - 1)}
          totalPages={table.getPageCount()}
          className='ml-auto'
        />
      </div>
    </Footer>
  );

  const columnSizes = ['w-[84px]', 'w-[calc(58.32%-168px)]', 'w-[12.50%]', 'w-[12.50%]', 'w-[8.33%]', 'w-[8.33%]', 'w-[84px]'];

  const tableHead = table.getHeaderGroups().map(headerGroup => (
    <Table.Head key={headerGroup.id} style={{...{width: '100%'}}}>
      {headerGroup.headers.map((header, index) => (
        <Table.HeadCell key={header.id} className={`p-4 bg-xgray-50 border-b border-xgray-200 ${columnSizes[index]}`}>
          <div
            className={`flex items-center text-xgray-500 ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`}
            onClick={header.column.getToggleSortingHandler()}
            >
            {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
            {{
              asc: (<ArrowUp className="w-3 h-3 ml-1 text-xgray-500" />),
              desc: (<ArrowDown className="w-3 h-3 ml-1 text-xgray-500" />),
              none: (<span className='w-3 h-3 ml-1'></span>),
            }[header.column.getIsSorted() || 'none']}
          </div>
        </Table.HeadCell>
      ))}
    </Table.Head>
  ));

  const closingGroup = 'group-last/body:group-last/row:first:rounded-bl-none group-last/body:group-last/row:last:rounded-br-none'

  const tableBody = (
    <Table.Body>
      { table.getRowModel().rows.map(row => (
        <Table.Row key={row.id} className='border-b border-xgray-200'>
          {row.getVisibleCells().map(cell => (
            <Table.Cell key={cell.id} className={`p-4 text-style-base text-xgray-900 ${closingGroup}`} align={(cell.column.columnDef.meta)?.align || 'left'}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  );

  const inactiveWorkspace = currentWorkspace && currentWorkspace.status === WorkspaceStatus.INACTIVE;

  return (
    <>
      <WorkspaceTitle {...props} />
      {!inactiveWorkspace && lowStorageWarningPanel && <LowStorageWarning {...props} />}
      {inactiveWorkspace && <SubscriptionExpiredWarning {...props} />}
      {modal.type === modalTypes.DELETE_SESSION && <DeleteSessionModal />}
      {modal.type === modalTypes.DUPLICATE_SESSION && <DuplicateSessionModal />}
      {modal.type === modalTypes.RENAME_SESSION && <RenameSessionModal />}
      {modal.type === modalTypes.UNLOCK_SESSION && <UnlockSessionModal />}
      {modal.type === modalTypes.LOCKED_SESSION && <LockedSessionModal />}
      {modal.type === modalTypes.DOWNLOAD_SESSION_ERROR && <DownloadSessionErrorModal />}
      <div className='flex flex-col mt-8'>
        <Table className='bg-white'>
          { tableHead }
          { tableBody }
        </Table>
        <div className="">
          { flowbitePagination }
        </div>
      </div>
    </>
  )
};

export default SessionsTable;
