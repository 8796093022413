import awsExports from './aws-exports';

const awsConfig = {
  ...awsExports,
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  aws_apollo_http_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_apollo_ws_endpoint: process.env.REACT_APP_WEBSOCKET_ENDPOINT,
  aws_storage_authorizer_url: process.env.REACT_APP_STORAGE_AUTHORIZER_ENDPOINT,
};

export default awsConfig;