import { WorkspaceScope, WorkspaceStatus } from "../../../models";
import { ownerSharedWorkspace, isOwnerOfWorkspace } from "../../../models/membership";

const pricingLink = "https://www.getxray.app/exploratory-testing#pricing";

const getUpgradeLink = (memberships, workspaces, currentWorkspace) => {
  // Hide all upgrade buttons until the memberships and workspaces are loaded
  if (memberships?.length > 0 && workspaces?.length > 0 && currentWorkspace) {
    const currentWS = typeof currentWorkspace === 'string' ? workspaces.find(w => w.id === currentWorkspace) : currentWorkspace;

    let userHasSharedWorkspace = false;
    const isPersonalWorkspace = currentWS?.scope === WorkspaceScope.PRIVATE;
    if (memberships?.length > 0) {
      userHasSharedWorkspace = !!ownerSharedWorkspace(memberships, workspaces);
    }
    const isOwnerOfCurrentWorkspace = isOwnerOfWorkspace(memberships, currentWS);
    const isEnterpriseWorkspace = currentWS?.configuration?.name?.toLowerCase() === "enterprise";

    if (isPersonalWorkspace) {
      if (!userHasSharedWorkspace) {
        return {
          type: 'href',
          href: pricingLink,
          title: 'Upgrade'
        };
      }
    } else {
      if (isOwnerOfCurrentWorkspace) {
        if (currentWS.status === WorkspaceStatus.INACTIVE) {
          return {
            type: 'href',
            href: pricingLink,
            title: 'Manage Subscription'
          }
        } else if (isEnterpriseWorkspace && currentWS.status === WorkspaceStatus.ACTIVE) {
          return {
            type: 'href',
            href: `https://www.getxray.app/xea-contact`,
            title: 'Contact Us'
          }
        } else {
          return {
            type: 'href',
            href: `/workspace/${currentWS?.url}/subscription`,
            title: 'Manage Subscription'
          }
        }
      }
    }
  }
  return null;
};

const readonlyWorkspace = (workspace, workspaceSize) => {
  return workspace.status === WorkspaceStatus.INACTIVE || workspaceSize >= workspace.configuration.maxStorage * 1000 * 1000;
};

export { getUpgradeLink, readonlyWorkspace };