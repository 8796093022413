import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { WorkspaceScope } from "../../models";
import { WorkspaceContext } from "../../context/WorkspaceContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useCookies } from "react-cookie";
import { UserContext } from "../../context/UserContext";
import { lastWsCookieName } from "../workspace/CurrentWorkspace";

const WorkspaceRedirect = (props) => {
  const { workspaceId } = props;
  const { selection } = useParams();
  const { currentUser } = useContext(UserContext);
  const lastWsCookie = lastWsCookieName(currentUser);
  const [cookies, setCookies,] = useCookies(['signup-selection', lastWsCookie]);

  const { workspaces, workspacesLoaded } = useContext(WorkspaceContext);

  const lastWsForUser = cookies[lastWsCookie];
  let redirectToWorkspace = null;
  // Use the last ws saved value for the current user
  if (lastWsForUser) {
    redirectToWorkspace = workspaces?.find(ws => cookies[lastWsCookie] && ws.id === lastWsForUser);
  }
  // If the last ws is not present or is not found in the workspace list, use the user's personal workspace
  if (!redirectToWorkspace) {
    redirectToWorkspace = workspaces?.find(ws => workspaceId ? ws.id === workspaceId : ws.scope === WorkspaceScope.PRIVATE);
  }

  useEffect(() => {
    if (selection) {
      setCookies('signup-selection', selection, {path: '/'});
    }
  }, [selection, setCookies]);

  return (
    <>
      { !!redirectToWorkspace && <Redirect push to={`/workspace/${redirectToWorkspace?.url}`} /> }
      { workspacesLoaded && !redirectToWorkspace && <Redirect push to="/create-workspace" />}
    </>
  )

};

export default WorkspaceRedirect;