import React, { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';

import XEALogo from './XEALogo/XEALogo';
import PasscodeField, { passcodeLength, passcodeRegEx } from './Passcode/PasscodeField';
import RedErrorBang from './Svg/RedErrorBang';
import GreenSucceedCheckmark from './Svg/GreenSucceedCheckmark';

const emptyAmplifyError = { error: false, type: null, message: null };

const AmplifyConfirmPasscode = (props) => {
  const { didConfirmPasscodeCb } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const emailParam = urlParams.get('email');
  const [passcode, setPasscode] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  // Amplify error can be of 2 types: 'passcode' or 'password'
  const [amplifyError, setAmplifyError] = useState(emptyAmplifyError);
  const [codeResent, setCodeResent] = useState(null);
  const [submitPasscodeDisabled, setSubmitPasscodeDisabled] = useState(true);

  const passcodeDidChange = (newPasscode) => {
    setPasscode(newPasscode);
  };

  const submitPasscode = async () => {
    try {
      setAmplifyError(emptyAmplifyError);
      setSubmitLoading(true);
      // Listen for auth or autoSignIn_failure events
      Hub.listen('auth', async (ev) => {
        const { payload: { event, data: payloadData } } = ev;
        if (event === 'autoSignIn') {
          // assign user
          return didConfirmPasscodeCb(true, payloadData);
        } else if (event === 'autoSignIn_failure') {
          // redirect to sign in page
          return didConfirmPasscodeCb(false);
        }
      });
      // Set amplify-auto-sign-in to true to auto sign in after confirmSignUp
      localStorage.setItem('amplify-auto-sign-in', 'true');
      await Auth.confirmSignUp(emailParam, passcode);
    } catch (e) {
      setSubmitLoading(false);
      setAmplifyError({
        error: true,
        type: 'passcode',
        message: e.name === 'CodeMismatchException' ? 'The passcode you entered is incorrect, please try again.' : 'Something went wrong, please try again.',
      });
    }
  };

  useEffect(() => {
    setSubmitPasscodeDisabled(!passcode.length
                              || !passcode.match(passcodeRegEx)
                              || submitLoading);
  }, [passcode, submitLoading]);

  const resendPasscode = async () => {
    try {
      await Auth.resendSignUp(emailParam);
      setCodeResent('done');
      setTimeout(() => {
        setCodeResent(null);
      }, 5000);
    } catch(e) {
      console.error(e);
      setCodeResent('error');
      setTimeout(() => {
        setCodeResent(null);
      }, 5000);
    }
  };

  const submitPasscodeCb = async () => {
    if (!submitPasscodeDisabled) {
      await submitPasscode();
    }
  };

  const passcodeDescription = (<p className="text-style-small text-xgray-500 text-center mt-4 mx-auto px-2">
                                To start using your account, you need to verify your email address <strong className="font-bold text-[#191919] dark:text-[#FFFFFF]">{emailParam}</strong> by entering the code we emailed you.
                               </p>);

  return (
    <div id="amplify-confirm-passcode-container" className="flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919]','dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
      <div className="w-[452px] p-2 m-auto mt-8">
        <XEALogo {...props} />

        <div className="flex flex-col">
          <div className="text-style-xl-semibold text-center text-xgray-900">Enter Passcode</div>
          {passcodeDescription}

          { codeResent === 'done' && (
            <div className="message success mt-2">
              <span className="success-icon">
                <GreenSucceedCheckmark />
              </span>
              <span className="success-message">Passcode resent, check your email inbox.</span>
            </div>
          )}

          { codeResent === 'error' && (
            <div className="message error mt-2">
              <span className="error-message">An error occurred resending the passcode, please try again.</span>
            </div>
          )}


          { amplifyError.error && amplifyError.type === 'passcode' && (
            <div className="message error mt-4">
              <span className="error-message">{amplifyError.message}</span>
            </div>
          )}

          <PasscodeField {...props} passcodeDidChange={passcodeDidChange} submitPasscodeCb={submitPasscodeCb} />

          { amplifyError.error && amplifyError.type === 'password' && (
            <div className="message error mt-2">
              <span className="error-icon">
                <RedErrorBang />
              </span>
              <span className="error-message">{amplifyError.message}</span>
            </div>
          )}

          <button className="submit-button mt-0" disabled={submitPasscodeDisabled} tabIndex={passcodeLength + 1} onClick={submitPasscode}>Verify Email</button>
          <p className="mt-4 h-[19px] text-center mx-auto text-style-small-medium text-xgray-500 dark:text-[#FFFFFFCC]">
            Didn't receive the code?
            <button className="inline-block h-[19px] border-none outline-none text-xray-primary-select bg-[transparent] ml-1.5" onClick={resendPasscode}>Click to resend</button>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default AmplifyConfirmPasscode;
