import React, { useContext } from "react";
import { FlowbiteCustomTheme, Check } from "@stoatlabs/xea-client-shared-components";
import { Avatar, Dropdown } from "flowbite-react";

import { WorkspaceScope } from "../../../models";
import { WorkspaceContext } from "../../../context/WorkspaceContext";

const WorkspacePicker = (props) => {
  const { navigateToWorkspace } = props;
  const { workspaces, currentWorkspace } = useContext(WorkspaceContext);

  if (!workspaces.length) return <></>;

  const workspaceLabel = (workspace, isLabel) => {
    const isPersonal = workspace?.scope === WorkspaceScope.PRIVATE;
    const fbTheme = isPersonal ? FlowbiteCustomTheme.theme.avatar : FlowbiteCustomTheme.theme.teamWorkspaceAvatar;
    return (
      <div className={`flex items-start ${isLabel ? 'w-[90%]' : 'w-full'}`}>
        <div className="mt-0.5">
          <Avatar theme={fbTheme} placeholderInitials={workspace?.name.charAt(0).toUpperCase()} size="xs" />
        </div>
        <div className="flex flex-col items-start justify-start ml-2 w-full">
          <span className="text-style-bold text-xgray-900 overflow-hidden text-ellipsis whitespace-pre max-w-[95%]">{workspace?.name}</span>
          <span className="text-style-8 text-xgray-500">{ isPersonal ? 'Personal Account' : (workspace?.configuration?.name?.toLowerCase() === "enterprise" ? "Enterprise Account" : 'Team Account')}</span>
        </div>
        { !isLabel && workspace?.url === currentWorkspace.url &&
          <div className="ml-auto mr-0.5 mt-0.5">
            <Check className="text-xgreen-500 w-3 h-3" />
          </div>}
      </div>
    );
  };

  if (workspaces.length === 1) {
    return workspaceLabel(workspaces[0], true);
  }

  return (
    <Dropdown theme={FlowbiteCustomTheme.theme.dropdown.workspacePicker} label={workspaceLabel(currentWorkspace, true)} inline>
      { workspaces.map(ws =>
          <Dropdown.Item key={ws.id} theme={FlowbiteCustomTheme.theme.dropdown.workspacePicker.floating.item} onClick={navigateToWorkspace(ws)}>
            {workspaceLabel(ws)}
          </Dropdown.Item>) }
    </Dropdown>
  );
};

export default WorkspacePicker;