import React, { useContext, useEffect, useState, useCallback } from "react";
import { useRouteMatch, useParams, Switch, Route } from "react-router-dom";

import { MembershipContext } from "../../context/MembershipContext";
import UserSessions from "./UserSessions";
import AboutWorkspace from "./AboutWorkspace";
import UsersManagement from "../Account/UsersManagement";
import NotFound from "../404";
import { MembershipRole, WorkspaceScope } from "../../models";
import { UserContext } from "../../context/UserContext";
import { WorkspaceContext } from "../../context/WorkspaceContext";
import { CurrentWorkspaceContext } from "../../context/CurrentWorkspaceContext";
import LoadingApp from "../LoadingApp";
import SubscriptionManagement from "../Account/SubscriptionManagement";
import { useCookies } from "react-cookie";

export const lastWsCookieName = (currentUser) => currentUser ? `last-ws-${currentUser?.id}` : null;

const CurrentWorkspace = (props) => {
  const { lowStorageWarningLimit } = props;
  const { path } = useRouteMatch();
  const { workspaceUrl } = useParams();

  // We need a local state to avoid briefly showing the 404 page when the
  // workspaces are loaded but a workspace has not been set as current yet
  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useContext(UserContext);
  const { memberships } = useContext(MembershipContext);
  const { workspaces, currentWorkspace, setCurrentWorkspace, workspacesLoaded } = useContext(WorkspaceContext);
  const { sessions } = useContext(CurrentWorkspaceContext);
  const lastWsCookie = lastWsCookieName(currentUser);
  const [,setCookie,] = useCookies([lastWsCookie]);
  useEffect(() => {
    if (currentWorkspace && lastWsCookie) {
      setCookie(lastWsCookie, currentWorkspace.id, {path: '/', maxAge: 60 * 60 * 24 * 365});
    }
  }, [lastWsCookie, currentWorkspace, setCookie]);
  const workspaceMemberships = memberships?.data?.map(m => {
    const membership = {
      ...m,
      workspace: {
        ...m.workspace,
      }
    };
    return membership;
  });

  const [lowStorageWarningPanel, setLowStorageWarningPanel] = useState(false);
  const [workspaceMax, setWorkspaceMax] = useState(5 * 1000 * 1000 * 1000); // Default to 5GB
  const [workspaceSize, setWorkspaceSize] = useState(0);
  const [workspacePercent, setWorkspacePercent] = useState(0);
  const [workspaceWarning, setWorkspaceWarning] = useState(false);

  useEffect(() => {
    const bytes = sessions?.data?.reduce((acc, session) => {
      if(!session.size || session.size.length === 0 || session.size === "0") {
        return acc;
      } else {
        return acc + Number(session.size);
      }
    }, 0) || 0;

    setWorkspaceSize(bytes);
    const wsPerc = parseInt((100 * workspaceSize) / workspaceMax);
    setWorkspacePercent(wsPerc);
    const showWarning = wsPerc >= lowStorageWarningLimit;
    setWorkspaceWarning(showWarning);
    setLowStorageWarningPanel(showWarning && true);
    setWorkspaceMax(currentWorkspace?.configuration?.maxStorage * 1000 * 1000); // maxStorage is stored in GB
  }, [sessions, workspaceMax, workspaceSize, lowStorageWarningLimit, currentWorkspace?.configuration?.maxStorage]);

  // Update the current workspace, update the max storage infos and remember that the loading is done
  const updateCurrentWorkspace = useCallback(async (ws) => {
    setIsLoading(false);
    await setCurrentWorkspace(ws);
  }, [setIsLoading, setCurrentWorkspace])

  // Select the current workspace, in case we don't have one yet or we switched workspace
  useEffect(() => {
    const loadWorkspaceForUrl = async (url) => {
      if(workspaces?.length) {
        const workspaceByUrl = workspaces?.find(w => w.url === url);
        if (workspaceByUrl) {
          await updateCurrentWorkspace(workspaceByUrl);
        } else {
          setIsLoading(false);
        }
      }
    };

    if(workspacesLoaded) {
      if (!currentWorkspace || currentWorkspace?.url !== workspaceUrl) {
        loadWorkspaceForUrl(workspaceUrl);
      }
    }
  }, [isLoading, workspacesLoaded, workspaceUrl, currentWorkspace, workspaces, updateCurrentWorkspace]);

  const currentUserMembership = workspaceMemberships?.find(m => m.workspace.id === currentWorkspace?.id && m.user?.id === currentUser?.id);

  const innerProps = {
    ...props,
    sessions: sessions.data,
    memberships: workspaceMemberships,
    workspaces,
    workspaceSize,
    workspacePercent,
    workspaceMax,
    workspaceWarning,
    lowStorageWarningPanel,
    userMembership: currentUserMembership,
    removeLowStorageWarningPanel: () => setLowStorageWarningPanel(false),
  };

  const isAdmin = currentUserMembership?.role === MembershipRole.ADMIN
  const isOwner = currentUserMembership?.isOwner;

  if (!workspacesLoaded || isLoading) {
    return <LoadingApp />;
  }

  return (
    <Switch>
      <Route path={`/workspace/${currentWorkspace?.url}`} exact>
        <UserSessions {...innerProps} />
      </Route>
      { isAdmin && currentWorkspace?.scope === WorkspaceScope.SHARED &&
        <Route path={`${path}/users`} exact>
          <UsersManagement {...innerProps} usersManagement={true} />
        </Route>}
      { isOwner && currentWorkspace?.scope === WorkspaceScope.SHARED &&
        <Route path={`${path}/subscription`} exact>
          <SubscriptionManagement {...innerProps} />
        </Route>}
      { isAdmin && currentWorkspace?.scope === WorkspaceScope.SHARED &&
        <Route path={`${path}/about-workspace`} exact>
          <AboutWorkspace {...innerProps} aboutWorkspace={true} />
        </Route>}
      <Route path="*">
        <NotFound {...innerProps} />
      </Route>
    </Switch>
  );

};

export default CurrentWorkspace;