import React, { useState } from "react";
import { TrashBin } from '@stoatlabs/xea-client-shared-components';
import { v4 as uuid } from 'uuid';

import Steps from "./Steps";
import InviteInlineForm from "../../InviteInlineForm";
import { MembershipRole } from "../../../../models";

const defaultInvite = {
  email: '',
  role: MembershipRole.USER,
  valid: false
};


const InviteStep = (props) => {
  const { nextStep, configurationName, memberships, disableSubmitButton } = props;
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const capitalizedConfigurationName = configurationName?.charAt(0).toUpperCase() + configurationName?.slice(1);

  const [invites, setInvites] = useState([{...defaultInvite, index: 1, id: uuid()}]);

  const inviteFormFieldChanged = (index) => (valid, email, role) => {
    const invite = invites.find(i => i.index === index);
    invite.valid = valid;
    invite.email = email;
    invite.role = role;
    setInvites([...invites]);
    setSubmitButtonDisabled(invites.some(i => i.email?.length > 0 && !i.valid));
  };

  const addMoreInvites = (curInvites) => {
    const lastInvite = curInvites.at(-1);
    const lastInviteIndex = lastInvite.index;
    const nextIndex = lastInviteIndex + 1;
    setInvites([...invites, {...defaultInvite, index: nextIndex, id: uuid()}]);
  };

  const removeInvite = (index) => () => {
    if (invites.length === 1) {
      const newInvites = [{...defaultInvite, index: 1, id: uuid()}];
      setInvites(newInvites);
    } else {
      const newInvites = invites.filter(i => i.index !== index).map((i, index) => ({...i, index: index + 1}) );
      setInvites(newInvites);
    }
  };

  return (
    <>
      <div className="text-style-lg-semibold text-xgray-900 border-b border-b-xgray-200 p-6 w-full items-start">
        Set Up Your {capitalizedConfigurationName}'s Workspace
      </div>
      <div className="flex w-full min-h-[386px] overflow-auto">
        <Steps activeStep={3} />
        <div className="flex flex-col w-[546px] p-6 gap-4 overflow-y-auto">
          <span className="text-style-xl-semibold text-xgray-900 text-center">Invite Users to The Workspace</span>
          <span className="text-style-small text-xgray-500 text-center mx-auto w-[444px]">Invite your team to download the Xray Exploratory App and to collaborate on exploratory testing sessions.</span>
          <div className="flex">
            <span className="text-style-medium text-xgray-900 ml-[33px]">
              <span className="text-red-800 mr-1">*</span>
              User Email
            </span>
            <span className="text-style-medium text-xgray-900 ml-[228px]">
              <span className="text-red-800 mr-1">*</span>
              Role
            </span>
          </div>
          {invites.map(({index, id}) => (
            <div className="flex items-center" key={id}>
              <span className="mr-2">0{index}</span>
              <InviteInlineForm memberships={memberships} onFieldChange={inviteFormFieldChanged(index)} hideButton={true} />
              <button className="w-8 h-8" onClick={removeInvite(index)}>
                <TrashBin className="text-xgray-500 w-4 h-4 mx-auto" />
              </button>
            </div>))}
          {invites.length < 5 &&
            <div className="flex items-center ml-[33px]">
              <button className="text-xray-primary-link mr-4" onClick={() => addMoreInvites(invites)}>
                + Add More
              </button>
            </div>}
        </div>
      </div>
      <div className="border-t border-t-xgray-200 w-full p-6 flex">
        <button className="text-xray-primary-link ml-auto mr-4" onClick={() => nextStep(null)}>
          Skip
        </button>
        <button className="primary-button" disabled={disableSubmitButton || submitButtonDisabled} onClick={() => nextStep(invites)}>
          Invite and Continue
        </button>
      </div>
    </>
  );
}

export default InviteStep;