import { createContext, useReducer, useState, useCallback } from 'react';

import { observerReducer, initialState } from '../reducer/observerReducer';
import { useObserveMembershipsForUser } from '../hooks/useObserveMemberships';

export const MembershipContext = createContext(null);

const MembershipProvider = ({ userId, children }) => {
  const [memberships, _setMemberships] = useReducer(observerReducer, initialState);
  const [membershipLoaded, setMembershipLoaded] = useState(false);

  const setMemberships = useCallback(mems => {
    _setMemberships(mems);
    if (!membershipLoaded) {
      setMembershipLoaded(true);
      if (mems.refObj.length === 0) {
        setTimeout(() => document.dispatchEvent(new CustomEvent('workspaces-loaded')), 180);
      }
    }
  }, [membershipLoaded]);

  useObserveMembershipsForUser(userId, setMemberships);

  const clearMembershipsContext = useCallback(() => {
    _setMemberships({ type: 'reset' });
    setMembershipLoaded(false);
  }, [_setMemberships, setMembershipLoaded])

  const store = {
    memberships,
    clearMembershipsContext,
  }

  return (
    <MembershipContext.Provider value={store}>
      {children}
    </MembershipContext.Provider>
  );
};

export default MembershipProvider;