import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import InviteProvider, { InviteContext } from "../../../context/InviteContext";
import { UserContext } from "../../../context/UserContext";
import InviteAccept from "./Accept";
import InviteSignup from "./Signup";
import InviteWrongAccount from "./WrongAccount";
import LoadingApp from "../../LoadingApp";
import { MembershipState } from "../../../models";
import InviteExpired from "./Expired";

import awsConfig from "../../../awsconfig";

const InviteLanding = (props) => {
  const { currentUser } = useContext(UserContext);
  const [initialState, setInitialState] = useState(null);
  const [userExistenceChecked, setUserExistenceChecked] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const { membership, loaded: membershipLoaded } = useContext(InviteContext);

  useEffect(() => {
    const userExistenceFn = async () => {
      try {
        const userExistence = await fetch(process.env.REACT_APP_COGNITO_EXISTENCE_LAMBDA, {
          method: "POST",
          body: JSON.stringify({email: membership.inviteEmail, userPoolId: awsConfig.aws_user_pools_id}),
          mode: "cors"
        });
        const body = await userExistence.json();
        setUserExistenceChecked(true);
        setUserExists(body.exists);
      } catch {
        setUserExistenceChecked(true);
        setUserExists(false);
      }
    };
    if (membershipLoaded) {
      if (!currentUser) {
        userExistenceFn();
      } else {
        setUserExistenceChecked(true);
      }
    }
  }, [membershipLoaded, currentUser, membership?.inviteEmail]);

  useEffect(() => {
    if (!initialState && membership?.state) {
      setInitialState(membership.state);
    }
  }, [initialState, membership]);

  if (!userExistenceChecked) {
    return <LoadingApp />;
  }

  const innerProps = {
    ...props,
    workspace: membership?.workspace,
    membership,
    currentUser,
    userExists,
  }

  if (!userExistenceChecked) {
    return <LoadingApp />;
  }

  if (!membership) {
    if (membershipLoaded) {
      return <InviteExpired {...innerProps} />
    } else {
      return <LoadingApp />;
    }
  } else {
    if (initialState === MembershipState.ACCEPTED) {
      window.location = `/workspace/${membership.workspace.url}`;
      return <LoadingApp />;
    } else if (currentUser && membership && currentUser?.email !== membership?.inviteEmail) {
      return <InviteWrongAccount {...innerProps} />;
    } else if (currentUser) {
      return <InviteAccept {...innerProps} />;
    } else {
      return <InviteSignup {...innerProps} />;
    }
  }
}

const IniviteLandingWrapper = (props) => {
  const { inviteId } = useParams();
  return (
    <InviteProvider membershipId={inviteId}>
      <InviteLanding {...props} />
    </InviteProvider>
  )
};

export default IniviteLandingWrapper;