export const initialState = { loading: true, error: null, data: [] };

export function observerReducer(state, action) {
  const { type, refObj } = action;

  if(type === 'create') {
    return _create(state, refObj)
  } else if(action.type === 'update') {
    if(refObj?.__typename === 'Membership') {
      // Memberships are created first and then assigned to us after the fact,
      // so they are not "created" in the same sense as they are "updated", so
      // check and see if this membership exists and, if not, create it.
      const existingObject = state.data.find(object => object.id === refObj.id);
      if(!existingObject) return _create(state, refObj);
    }

    return _update(state, refObj);
  } else if(type === 'delete') {
    return _delete(state, refObj);
  } else if(type === 'set') {
    return _set(state, refObj);
  } else if(type === 'reset') {
    return initialState;
  }
}

function _create(state, refObj) {
  const existingObject = state.data.find(object => object.id === refObj.id);

  if(existingObject) {
    return state;
  }

  return {
    ...state,
    data: [...state.data, refObj],
  };
}

function _update(state, refObj) {
  const updatedData = state.data.map((object) => {
    if(object.id === refObj.id) return refObj;
    return object;
  });

  return {
    ...state,
    data: updatedData,
  };
}

function _delete(state, refObj) {
  const updatedData = state.data.filter((object) => object.id !== refObj.id);

  return {
    ...state,
    data: updatedData,
  };
}

function _set(state, refObj) {
  return {
    ...state,
    loading: false,
    data: refObj,
  }
}