import React, { useEffect } from "react";

import { logoutCurrentUser } from "../../store/currentUser";

const Logout = (props) => {
  const { navigate } = props;
  useEffect(() => {
    const logout = async () => {
      try {
        await logoutCurrentUser();
      } finally {
        navigate('/login');
      }
    };
    logout();
  });
  return <></>;
};

export default Logout;
