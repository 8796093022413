import React from 'react';
import { ConfirmationView as AmplifyConfirmationView } from '@stoatlabs/xea-client-shared-components';

import ComponentWrapper from '../ComponentWrapper';

const ConfirmationView = (props) => {
  const { navigate } = props;
  const childProps = {
    ...props,
    confirmationViewTitle: 'Verification Success',
    confirmationViewDescription: 'Thank you for your support, we have successfully verified your email address, you can now proceed to complete your profile.',
    confirmationViewCTA: 'Create your profile',
    confirmationViewCb: () => {
      navigate('/create-profile');
    },
  }
  return (
    <ComponentWrapper {...childProps}>
      <AmplifyConfirmationView {...childProps} />
    </ComponentWrapper>
  );
}

export default ConfirmationView;