import React from "react";
import { Alert } from "flowbite-react";

import Steps from "./Steps";

const Welcome = (props) => {
  const { currentUser, nextStep } = props;
  return (
    <>
      <div className="text-style-lg-semibold text-xgray-900 border-b border-b-xgray-200 p-6 w-full items-start">
        Set Up Your Team's Workspace
      </div>
      <div className="flex w-full min-h-[406px]">
        <Steps activeStep={1} />
        <div className="flex flex-col items-center w-[546px] p-6">
          <img
            alt="Flowbite React Logo"
            className="w-[224px]"
            src="/xea-logo-2024.svg"
          />
          <span className="text-style-xl-semibold text-xgray-900 mt-8">Welcome {currentUser?.firstName}</span>
          <span className="text-style-small text-xgray-500 mt-4 px-4 text-center">Lets set up your team workspace and start sharing test sessions with your team.</span>
          <Alert color="gray" className="mt-8 w-full">
            <span>
              <p className="text-style-small text-xgray-500">
                <span className="text-style-small-semibold mr-2">
                  Please note:
                </span>
                You will not be able to access or use your
                <br />
                workspace until the wizard is completed.
              </p>
            </span>
          </Alert>
        </div>
      </div>
      <div className="border-t border-t-xgray-200 w-full p-6 flex">
        <button className="primary-button ml-auto" onClick={nextStep}>
          Let's Get Started
        </button>
      </div>
    </>
  );
};

export default Welcome;