import React from "react";
import { CloseCircle } from "@stoatlabs/xea-client-shared-components";

import ComponentWrapper from "../../ComponentWrapper";
import WorkspaceCard from "../WorkspaceCard";

const InviteExpired = (props) => {
  const { workspace, currentUser } = props;

  const preventSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const backTo = () => {
    window.location = "/"
  };

  return (
    <ComponentWrapper {...props} hideCloseButton={true} hideFooter={true}>
      <div id="amplify-login-container" className="flex items-center content-center min-h-full rounded bg-[#FFFFFF] text-[#191919] dark:bg-[#2D2D2D] dark:text-[#FFFFFF]">
        <div className="w-[452px] p-2 m-auto mt-4">

          <form className="flex flex-col" onSubmit={preventSubmit}>
            <CloseCircle className="text-xred-500 w-12 h-12 mb-8 mx-auto" />
            <div className="text-style-xl-semibold text-xgray-900 text-center">The Invitation is No Longer Active</div>
            <div className="text-style-small text-xgray-500 text-center mx-4 mt-4">
              Invitation is not available at the moment; it may have expired, been revoked, or intended for another account. Please contact the workspace admin to send you a new link.
            </div>
            {!!workspace &&
              <WorkspaceCard {...props}
                workspace={workspace}
                acceptInvite={() => void 0}
                disabled={true} />}
            {!workspace &&
              <button className="submit-button mt-8 mx-auto" onClick={backTo}>Back to { currentUser ? 'Workspace' : 'Home' }</button>}
          </form>
        </div>
      </div>
    </ComponentWrapper>
  );
};

export default InviteExpired;