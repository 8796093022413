import React from 'react';

const XrayLogoBrand = (props) => {
  const { prefersColorScheme } = props;
  if (prefersColorScheme === 'dark') {
    return (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 36">
        <defs>
          <style>{`.cls-1{opacity:.8;}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6{stroke-width:0px;}.cls-1,.cls-2,.cls-5{fill:#25b34b;}.cls-2{opacity:.5;}.cls-4{fill:#6cbe99;}.cls-6{fill:#fff;}`}</style>
        </defs>
        <rect className="cls-3" width="144" height="36"/>
        <polygon className="cls-6" points="24.62 14.4 22.97 14.4 21.3 16.79 19.63 14.4 18 14.4 20.45 17.95 17.84 21.67 19.46 21.67 21.3 19.08 23.15 21.67 24.82 21.67 22.18 17.88 24.62 14.4"/>
        <path className="cls-6" d="m31.86,16.91c0-1.6-1.07-2.5-2.94-2.5h-3.03v7.27h1.39v-2.14h1.64c.09,0,.17,0,.26-.01l1.25,2.15h1.58s-1.6-2.41-1.6-2.41c.94-.39,1.44-1.22,1.44-2.36Zm-2.94,1.4h-1.64v-2.69h1.64c1.04,0,1.62.44,1.62,1.33s-.58,1.36-1.62,1.36Z"/>
        <path className="cls-6" d="m35.88,14.4l-3.18,7.27h1.44l.64-1.54h3.55l.65,1.54h1.49l-3.14-7.27h-1.44Zm-.6,4.54l1.29-3.05,1.26,3.05h-2.55Z"/>
        <polygon className="cls-6" points="44.83 14.4 42.88 17.82 40.9 14.4 39.49 14.4 42.2 19.35 42.2 21.68 43.59 21.68 43.59 19.3 46.23 14.4 44.83 14.4"/>
        <path className="cls-5" d="m49.12,20.69h4.17v.89h-5.19v-7.15h5.05v.89h-4.03v5.37Zm-.09-3.18h3.68v.87h-3.68v-.87Z"/>
        <path className="cls-5" d="m53.72,21.58l2.97-4.06v.79l-2.81-3.88h1.16l2.23,3.05h-.46s2.22-3.05,2.22-3.05h1.11l-2.79,3.82v-.74l2.97,4.07h-1.17l-2.37-3.26h.45l-2.34,3.26h-1.17Z"/>
        <path className="cls-5" d="m61.09,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3.44.2.78.48,1.02.86.24.37.36.82.36,1.34s-.12.96-.36,1.33c-.24.37-.58.66-1.02.86-.44.2-.98.3-1.6.3h-2.23l.46-.48v2.65h-1.02Zm1.02-2.54l-.46-.51h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.67.51-1.18s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Z"/>
        <path className="cls-5" d="m67.96,21.58v-7.15h1.02v6.26h3.87v.89h-4.89Z"/>
        <path className="cls-5" d="m76.92,21.66c-.54,0-1.05-.09-1.52-.28-.47-.18-.87-.44-1.21-.77-.34-.33-.61-.72-.8-1.16-.19-.44-.29-.93-.29-1.45s.1-1.01.29-1.45c.19-.44.46-.83.8-1.16.34-.33.74-.59,1.21-.77s.97-.28,1.52-.28,1.05.09,1.51.27c.46.18.86.44,1.21.77.34.33.6.72.79,1.16.19.45.28.93.28,1.46s-.09,1.02-.28,1.46c-.19.44-.45.83-.79,1.16-.34.33-.74.59-1.21.77s-.97.27-1.51.27Zm-.01-.91c.4,0,.77-.07,1.11-.2.34-.14.63-.33.88-.58.25-.25.44-.54.58-.87.14-.33.21-.7.21-1.09s-.07-.76-.21-1.09c-.14-.33-.33-.62-.58-.87s-.54-.45-.88-.58-.71-.2-1.11-.2-.76.07-1.1.2-.63.33-.88.58c-.25.25-.45.54-.59.87-.14.33-.21.69-.21,1.09s.07.76.21,1.09c.14.33.34.63.59.87.25.25.55.44.88.58.34.14.7.2,1.1.2Z"/>
        <path className="cls-5" d="m81.66,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3s.78.48,1.02.86.36.82.36,1.34-.12.96-.36,1.33-.58.66-1.02.85c-.44.2-.98.3-1.6.3h-2.23l.46-.47v2.65h-1.02Zm1.02-2.54l-.46-.5h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.68.51-1.19s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Zm3.76,2.54l-1.82-2.59h1.09l1.84,2.59h-1.11Z"/>
        <path className="cls-5" d="m88,21.58l3.24-7.15h1.01l3.25,7.15h-1.07l-2.89-6.58h.41l-2.89,6.58h-1.05Zm1.38-1.79l.28-.82h4.02l.3.82h-4.6Z"/>
        <path className="cls-5" d="m96.81,21.58v-6.26h-2.45v-.89h5.91v.89h-2.45v6.26h-1.01Z"/>
        <path className="cls-5" d="m104.1,21.66c-.54,0-1.05-.09-1.52-.28-.47-.18-.87-.44-1.21-.77-.34-.33-.61-.72-.8-1.16-.19-.44-.29-.93-.29-1.45s.1-1.01.29-1.45c.19-.44.46-.83.8-1.16.34-.33.74-.59,1.21-.77s.97-.28,1.52-.28,1.05.09,1.51.27c.46.18.86.44,1.21.77.34.33.6.72.79,1.16.19.45.28.93.28,1.46s-.09,1.02-.28,1.46c-.19.44-.45.83-.79,1.16-.34.33-.74.59-1.21.77s-.97.27-1.51.27Zm-.01-.91c.4,0,.77-.07,1.11-.2.34-.14.63-.33.88-.58.25-.25.44-.54.58-.87.14-.33.21-.7.21-1.09s-.07-.76-.21-1.09c-.14-.33-.33-.62-.58-.87s-.54-.45-.88-.58-.71-.2-1.11-.2-.76.07-1.1.2-.63.33-.88.58c-.25.25-.45.54-.59.87-.14.33-.21.69-.21,1.09s.07.76.21,1.09c.14.33.34.63.59.87.25.25.55.44.88.58.34.14.7.2,1.1.2Z"/>
        <path className="cls-5" d="m108.84,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3.44.2.78.48,1.02.86.24.37.36.82.36,1.34s-.12.96-.36,1.33c-.24.37-.58.66-1.02.85-.44.2-.98.3-1.6.3h-2.23l.46-.47v2.65h-1.02Zm1.02-2.54l-.46-.5h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.68.51-1.19s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Zm3.76,2.54l-1.82-2.59h1.09l1.84,2.59h-1.11Z"/>
        <path className="cls-5" d="m117.69,21.58v-2.74l.23.63-3.07-5.05h1.09l2.58,4.26h-.59l2.59-4.26h1.01l-3.06,5.05.22-.63v2.74h-1.01Z"/>
        <path className="cls-5" d="m123.01,21.58l3.24-7.15h1.01l3.25,7.15h-1.07l-2.89-6.58h.41l-2.89,6.58h-1.05Zm1.38-1.79l.28-.82h4.03l.3.82h-4.6Z"/>
        <path className="cls-5" d="m131.36,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3.44.2.78.48,1.02.86.24.37.36.82.36,1.34s-.12.96-.36,1.33c-.24.37-.58.66-1.02.86-.44.2-.98.3-1.6.3h-2.23l.46-.48v2.65h-1.02Zm1.02-2.54l-.46-.51h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.67.51-1.18s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Z"/>
        <path className="cls-5" d="m138.23,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3.44.2.78.48,1.02.86.24.37.36.82.36,1.34s-.12.96-.36,1.33c-.24.37-.58.66-1.02.86-.44.2-.98.3-1.6.3h-2.23l.46-.48v2.65h-1.02Zm1.02-2.54l-.46-.51h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.67.51-1.18s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Z"/>
        <polygon className="cls-4" points="4.8 15.78 4.8 19.49 9.25 11.71 4.8 15.78"/>
        <polygon className="cls-2" points="10.73 18.01 7.76 18.75 6.28 24.29 10.73 18.01"/>
        <polygon className="cls-1" points="10.73 18.01 4.8 19.49 4.8 15.78 9.25 15.78 10.73 18.01"/>
        <polygon className="cls-5" points="4.8 15.78 4.8 19.49 9.25 11.71 4.8 15.78"/>
        <polygon className="cls-2" points="2.54 14.61 4.37 14.61 4.37 12.78 .55 10.79 2.54 14.61"/>
        <polygon className="cls-2" points="12.99 21.39 11.16 21.39 11.16 23.22 14.98 25.21 12.99 21.39"/>
        <path className="cls-2" d="m10.82,24.06c-.92.46-1.96.73-3.06.73-3.75,0-6.79-3.04-6.79-6.79,0-1.09.26-2.13.72-3.04l-.54-1.04c-.73,1.19-1.16,2.58-1.16,4.08,0,4.29,3.48,7.76,7.76,7.76,1.5,0,2.9-.43,4.09-1.16l-1.04-.54Z"/>
        <path className="cls-2" d="m7.76,10.24c-1.5,0-2.9.43-4.09,1.16l1.04.54c.92-.46,1.96-.73,3.06-.73,3.75,0,6.79,3.04,6.79,6.79,0,1.09-.26,2.13-.72,3.04l.54,1.04c.73-1.19,1.16-2.58,1.16-4.08,0-4.29-3.48-7.76-7.76-7.76Z"/>
      </svg>
    );
  } else {
    return (
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 36">
        <defs>
          <style>{`.cls-1{opacity:.8;}.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6{stroke-width:0px;}.cls-1,.cls-2,.cls-4{fill:#25b34b;}.cls-2{opacity:.5;}.cls-3{fill:#6cbe99;}.cls-5{fill:#211f1e;}.cls-6{fill:transparent;}`}</style>
        </defs>
        <rect className="cls-6" width="144" height="36"/>
        <polygon className="cls-3" points="4.8 15.78 4.8 19.49 9.25 11.71 4.8 15.78"/>
        <polygon className="cls-2" points="10.73 18.01 7.76 18.75 6.28 24.29 10.73 18.01"/>
        <polygon className="cls-1" points="10.73 18.01 4.8 19.49 4.8 15.78 9.25 15.78 10.73 18.01"/>
        <polygon className="cls-4" points="4.8 15.78 4.8 19.49 9.25 11.71 4.8 15.78"/>
        <polygon className="cls-2" points="2.54 14.61 4.37 14.61 4.37 12.78 .55 10.79 2.54 14.61"/>
        <polygon className="cls-2" points="12.99 21.39 11.16 21.39 11.16 23.22 14.98 25.21 12.99 21.39"/>
        <path className="cls-2" d="m10.82,24.06c-.92.46-1.96.73-3.06.73-3.75,0-6.79-3.04-6.79-6.79,0-1.09.26-2.13.72-3.04l-.54-1.04c-.73,1.19-1.16,2.58-1.16,4.08,0,4.29,3.48,7.76,7.76,7.76,1.5,0,2.9-.43,4.09-1.16l-1.04-.54Z"/>
        <path className="cls-2" d="m7.76,10.24c-1.5,0-2.9.43-4.09,1.16l1.04.54c.92-.46,1.96-.73,3.06-.73,3.75,0,6.79,3.04,6.79,6.79,0,1.09-.26,2.13-.72,3.04l.54,1.04c.73-1.19,1.16-2.58,1.16-4.08,0-4.29-3.48-7.76-7.76-7.76Z"/>
        <polygon className="cls-5" points="24.62 14.4 22.97 14.4 21.3 16.79 19.63 14.4 18 14.4 20.45 17.95 17.84 21.67 19.46 21.67 21.3 19.08 23.15 21.67 24.82 21.67 22.18 17.88 24.62 14.4"/>
        <path className="cls-5" d="m31.86,16.91c0-1.6-1.07-2.5-2.94-2.5h-3.03v7.27h1.39v-2.14h1.64c.09,0,.17,0,.26-.01l1.25,2.15h1.58s-1.6-2.41-1.6-2.41c.94-.39,1.44-1.22,1.44-2.36Zm-2.94,1.4h-1.64v-2.69h1.64c1.04,0,1.62.44,1.62,1.33s-.58,1.36-1.62,1.36Z"/>
        <path className="cls-5" d="m35.88,14.4l-3.18,7.27h1.44l.64-1.54h3.55l.65,1.54h1.49l-3.14-7.27h-1.44Zm-.6,4.54l1.29-3.05,1.26,3.05h-2.55Z"/>
        <polygon className="cls-5" points="44.83 14.4 42.88 17.82 40.9 14.4 39.49 14.4 42.2 19.35 42.2 21.68 43.59 21.68 43.59 19.3 46.23 14.4 44.83 14.4"/>
        <path className="cls-4" d="m49.12,20.69h4.17v.89h-5.19v-7.15h5.05v.89h-4.03v5.37Zm-.09-3.18h3.68v.87h-3.68v-.87Z"/>
        <path className="cls-4" d="m53.72,21.58l2.97-4.06v.79l-2.81-3.88h1.16l2.23,3.05h-.46s2.22-3.05,2.22-3.05h1.11l-2.79,3.82v-.74l2.97,4.07h-1.17l-2.37-3.26h.45l-2.34,3.26h-1.17Z"/>
        <path className="cls-4" d="m61.09,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3.44.2.78.48,1.02.86.24.37.36.82.36,1.34s-.12.96-.36,1.33c-.24.37-.58.66-1.02.86-.44.2-.98.3-1.6.3h-2.23l.46-.48v2.65h-1.02Zm1.02-2.54l-.46-.51h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.67.51-1.18s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Z"/>
        <path className="cls-4" d="m67.96,21.58v-7.15h1.02v6.26h3.87v.89h-4.89Z"/>
        <path className="cls-4" d="m76.92,21.66c-.54,0-1.05-.09-1.52-.28-.47-.18-.87-.44-1.21-.77-.34-.33-.61-.72-.8-1.16-.19-.44-.29-.93-.29-1.45s.1-1.01.29-1.45c.19-.44.46-.83.8-1.16.34-.33.74-.59,1.21-.77s.97-.28,1.52-.28,1.05.09,1.51.27c.46.18.86.44,1.21.77.34.33.6.72.79,1.16.19.45.28.93.28,1.46s-.09,1.02-.28,1.46c-.19.44-.45.83-.79,1.16-.34.33-.74.59-1.21.77s-.97.27-1.51.27Zm-.01-.91c.4,0,.77-.07,1.11-.2.34-.14.63-.33.88-.58.25-.25.44-.54.58-.87.14-.33.21-.7.21-1.09s-.07-.76-.21-1.09c-.14-.33-.33-.62-.58-.87s-.54-.45-.88-.58-.71-.2-1.11-.2-.76.07-1.1.2-.63.33-.88.58c-.25.25-.45.54-.59.87-.14.33-.21.69-.21,1.09s.07.76.21,1.09c.14.33.34.63.59.87.25.25.55.44.88.58.34.14.7.2,1.1.2Z"/>
        <path className="cls-4" d="m81.66,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3s.78.48,1.02.86.36.82.36,1.34-.12.96-.36,1.33-.58.66-1.02.85c-.44.2-.98.3-1.6.3h-2.23l.46-.47v2.65h-1.02Zm1.02-2.54l-.46-.5h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.68.51-1.19s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Zm3.76,2.54l-1.82-2.59h1.09l1.84,2.59h-1.11Z"/>
        <path className="cls-4" d="m88,21.58l3.24-7.15h1.01l3.25,7.15h-1.07l-2.89-6.58h.41l-2.89,6.58h-1.05Zm1.38-1.79l.28-.82h4.02l.3.82h-4.6Z"/>
        <path className="cls-4" d="m96.81,21.58v-6.26h-2.45v-.89h5.91v.89h-2.45v6.26h-1.01Z"/>
        <path className="cls-4" d="m104.1,21.66c-.54,0-1.05-.09-1.52-.28-.47-.18-.87-.44-1.21-.77-.34-.33-.61-.72-.8-1.16-.19-.44-.29-.93-.29-1.45s.1-1.01.29-1.45c.19-.44.46-.83.8-1.16.34-.33.74-.59,1.21-.77s.97-.28,1.52-.28,1.05.09,1.51.27c.46.18.86.44,1.21.77.34.33.6.72.79,1.16.19.45.28.93.28,1.46s-.09,1.02-.28,1.46c-.19.44-.45.83-.79,1.16-.34.33-.74.59-1.21.77s-.97.27-1.51.27Zm-.01-.91c.4,0,.77-.07,1.11-.2.34-.14.63-.33.88-.58.25-.25.44-.54.58-.87.14-.33.21-.7.21-1.09s-.07-.76-.21-1.09c-.14-.33-.33-.62-.58-.87s-.54-.45-.88-.58-.71-.2-1.11-.2-.76.07-1.1.2-.63.33-.88.58c-.25.25-.45.54-.59.87-.14.33-.21.69-.21,1.09s.07.76.21,1.09c.14.33.34.63.59.87.25.25.55.44.88.58.34.14.7.2,1.1.2Z"/>
        <path className="cls-4" d="m108.84,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3.44.2.78.48,1.02.86.24.37.36.82.36,1.34s-.12.96-.36,1.33c-.24.37-.58.66-1.02.85-.44.2-.98.3-1.6.3h-2.23l.46-.47v2.65h-1.02Zm1.02-2.54l-.46-.5h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.68.51-1.19s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Zm3.76,2.54l-1.82-2.59h1.09l1.84,2.59h-1.11Z"/>
        <path className="cls-4" d="m117.69,21.58v-2.74l.23.63-3.07-5.05h1.09l2.58,4.26h-.59l2.59-4.26h1.01l-3.06,5.05.22-.63v2.74h-1.01Z"/>
        <path className="cls-4" d="m123.01,21.58l3.24-7.15h1.01l3.25,7.15h-1.07l-2.89-6.58h.41l-2.89,6.58h-1.05Zm1.38-1.79l.28-.82h4.03l.3.82h-4.6Z"/>
        <path className="cls-4" d="m131.36,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3.44.2.78.48,1.02.86.24.37.36.82.36,1.34s-.12.96-.36,1.33c-.24.37-.58.66-1.02.86-.44.2-.98.3-1.6.3h-2.23l.46-.48v2.65h-1.02Zm1.02-2.54l-.46-.51h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.67.51-1.18s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Z"/>
        <path className="cls-4" d="m138.23,21.58v-7.15h2.79c.63,0,1.16.1,1.6.3.44.2.78.48,1.02.86.24.37.36.82.36,1.34s-.12.96-.36,1.33c-.24.37-.58.66-1.02.86-.44.2-.98.3-1.6.3h-2.23l.46-.48v2.65h-1.02Zm1.02-2.54l-.46-.51h2.2c.65,0,1.15-.14,1.49-.42.34-.28.51-.67.51-1.18s-.17-.91-.51-1.19c-.34-.28-.83-.42-1.49-.42h-2.2l.46-.51v4.23Z"/>
      </svg>
    );
  }
};

export default XrayLogoBrand;