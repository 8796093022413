import React from 'react';
import { AmplifyForgotPassword } from '@stoatlabs/xea-client-shared-components';

import ComponentWrapper from '../../ComponentWrapper';

const UnauthedForgotPassword = (props) => {

  return (
    <ComponentWrapper {...props}>
      <AmplifyForgotPassword {...props} />
    </ComponentWrapper>
  );
};

export default UnauthedForgotPassword;