import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { WorkspaceScope, WorkspaceStatus } from "../../models";
import { WorkspaceContext } from "../../context/WorkspaceContext";
import { MembershipContext } from "../../context/MembershipContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { UserContext } from "../../context/UserContext";
import { ownerSharedWorkspace } from "../../models/membership";
import { createBillingSession, createCheckoutSession, getSubscriptionForCustomer } from "../../store/currentUser";
import { updateUser } from "../../models/user";
import LoadingApp from "../LoadingApp";

const WorkspaceRedirect = (props) => {
  const { workspaceId, navigate } = props;
  const { selection, plan } = useParams();
  const [checked, setChecked] = useState(false);
  const [redirectToWorkspace, setRedirectToWorkspace] = useState(null);
  const [redirectToWorkspaceCreation, setRedirectToWorkspaceCreation] = useState(null);
  const [, setCookie] = useState(null);

  const { workspaces, workspacesLoaded } = useContext(WorkspaceContext);
  const { memberships } = useContext(MembershipContext);
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    const windowSearchParams = new URLSearchParams(window.location.search);
    const UTMSource = windowSearchParams.get("utm_source");
    const UTMCampaign = windowSearchParams.get("utm_campaign");
    const UTMContent = windowSearchParams.get("utm_content");
    const UTMMedium = windowSearchParams.get("utm_medium");
    const UTMTerm = windowSearchParams.get("utm_term");
    if (UTMSource) {
      setCookie('utm_source', UTMSource, { path: '/' });
    }
    if (UTMCampaign) {
      setCookie('utm_campaign', UTMCampaign, { path: '/' });
    }
    if (UTMContent) {
      setCookie('utm_content', UTMContent, { path: '/' });
    }
    if (UTMMedium) {
      setCookie('utm_medium', UTMMedium, { path: '/' });
    }
    if (UTMTerm) {
      setCookie('utm_term', UTMTerm, { path: '/' });
    }
  }, [setCookie]);

  useEffect(() => {
    const checkUser = async () => {
      const userSubscription = await getSubscriptionForCustomer(currentUser);
      // If there is no subscription we create one for the user
      if (!userSubscription?.subscription || userSubscription?.subscription?.ended) {
        const requirePayement = userSubscription?.subscription?.ended;
        const checkoutResp = await createCheckoutSession(currentUser.id, currentUser?.stripeCustomerId || userSubscription?.customer?.id, selection, plan, requirePayement);

        if (checkoutResp.stripeCustomerId !== currentUser.stripeCustomerId) {
          const { __typename, ...userData } = currentUser;
          await updateUser(currentUser.id, {
            ...userData,
            stripeCustomerId: checkoutResp.stripeCustomerId,
          });
        }
        // Can't open a new window, popup blockers kicks in
        // window.open(checkoutResp.sessionUrl, '_blank');
        // Give some time to the updateUser call to save the stripeCustomerId
        window.location = checkoutResp.sessionUrl;
      } else {
        const personalWorkspace = workspaces?.find(ws => ws.scope === WorkspaceScope.PRIVATE);
        const redirectUrl = await createBillingSession(currentUser, `/workspace/${personalWorkspace.url}`);
        // Can't open a new window, popup blockers kicks in
        // window.open(redirectUrl, '_blank');
        window.location = redirectUrl;
      }
    };

    if (workspacesLoaded && currentUser && !checked) {
      setChecked(true);
      if (workspaces?.length > 0) {
        const ownedWorkspace = ownerSharedWorkspace(memberships?.data, workspaces);
        if (!ownedWorkspace) {
          checkUser();
        } else if (ownedWorkspace.status === WorkspaceStatus.INACTIVE) {
          checkUser();
        } else {
          setRedirectToWorkspace(ownedWorkspace);
        }
      } else {
        setRedirectToWorkspaceCreation(true);
      }
    }
  }, [checked, currentUser, memberships?.data, navigate, plan, selection, workspaceId, workspaces, workspacesLoaded])

  return (
    <>
      { !!redirectToWorkspace
        ? <Redirect push to={`/workspace/${redirectToWorkspace?.url}`} /> 
        : <LoadingApp />}
      { !!redirectToWorkspaceCreation && <Redirect push to={`/create-workspace`} /> }
    </>
  )

};

export default WorkspaceRedirect;