import React, { useState } from "react";
import FileCopyAlt from '../flowbite-icons/file-copy-alt.svg';
import CustomTooltip from './Tooltip/CustomTooltip';

const copyTooltipInitial = 'Copy';
const copyTooltipFeedback = 'Copied';

const CopyToClipboard = (props) => {
  const { copyToClipboardFn } = props;
  const [showCopyFeedback, setShowCopyFeedback] = useState(false);
  const [hoveringTooltip, setHoveringTooltip] = useState(false);

  const copyToClipboard = () => {
    window.navigator.clipboard.writeText(copyToClipboardFn());
    setShowCopyFeedback(true);
    setTimeout(() => {
      setShowCopyFeedback(false);
    }, 10000);
  };

  const copyUrlTooltipButton = (
    <span className={`absolute cursor-pointer p-3.5 right-0 top-0 text-style-base ${showCopyFeedback || hoveringTooltip ? 'text-xgray-900' : 'text-xgray-500'}`} onClick={copyToClipboard}>
      <FileCopyAlt className="w-4 h-4" />
    </span>
  );

  return (
    <div className="absolute inset-y-0 right-0 pl-3 flex items-center w-[40px] h-[40px]" onMouseOver={() => setHoveringTooltip(true)} onMouseOut={() => setHoveringTooltip(false)}>
      { !showCopyFeedback && hoveringTooltip &&
        <CustomTooltip visible={true} styleClasses="-top-[40px] -left-[10px] w-[55px]" arrowStyle="top-[31px] left-[22px]">
          { copyTooltipInitial }
        </CustomTooltip> }
      { showCopyFeedback &&
        <CustomTooltip visible={true} styleClasses="-top-[40px] -left-[16px] w-[67px]" arrowStyle="top-[31px] left-[28px]">
          { copyTooltipFeedback }
        </CustomTooltip>}
      { copyUrlTooltipButton }
    </div>
  );
};

export default CopyToClipboard;