import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from "flowbite-react";
import { InfoCircle } from "@stoatlabs/xea-client-shared-components";
import { closeModal, errorMessage, modalTypes, sessionErrors } from "../../../store/slices/modals";

import { unlockSession } from "../../../models/session";

const UnlockSessionModal = () => {
  const modal = useSelector((state) => state.modals);
  const user = modal.user;
  const dispatch = useDispatch();
  const isOpen = modal.type === modalTypes.UNLOCK_SESSION;

  const confirmClicked = (_) => {
    const succeeded = unlockSession(modal.session);
    if (succeeded) {
      dispatch(closeModal(modalTypes.UNLOCK_SESSION));
    } else {
      dispatch(errorMessage({errorType: sessionErrors.UNLOCK_SESSION.NOT_EXISTS}));
    }
  };

  const modalContent = (
    <>
      <InfoCircle className="w-12 h-12 text-[#faca15] mt-4" />
      <span className="text-style-semibold text-gray-900 mt-4">Test Session in Use!</span>
      <p className="text-style-base text-gray-500 mt-5 text-center">
        The "
        <span className="text-style-semibold text-gray-900">{modal.session.name}</span>
        " session is currently being used by <span className="text-style-semibold text-gray-900">{user.firstName} {user.lastName}</span>. Are you sure you want to unlock the session?
      </p>
      <div className="flex mt-4">
        <button className='primary-button mr-4' onClick={confirmClicked}>
          Yes, unlock
        </button>
        <button className='secondary-button' onClick={() => dispatch(closeModal(modalTypes.UNLOCK_SESSION))}>
          No, keep locked
        </button>
      </div>
    </>
  );

  const notOwnerModalError = (
    <>
      <InfoCircle className="w-12 h-12 text-[#1da1f2] mt-4" />
      <p className="text-style-base text-gray-500 mt-5 text-center">
        The "
        <span className="text-style-semibold text-gray-900">{modal.session.name}</span>
        " session cannot be unlocked as it was created by another user. You don't have permission to unlock sessions from another user.
      </p>
      <div className="flex mt-4">
        <button className='primary-button mr-4' onClick={() => dispatch(closeModal(modalTypes.UNLOCK_SESSION))}>
          Ok
        </button>
      </div>
    </>
  );

  const notExistModalError = (
    <>
    <InfoCircle className="w-12 h-12 text-[#1da1f2] mt-4" />
      <p className="text-style-base text-gray-500 mt-5 text-center">
        The "
        <span className="text-style-semibold text-gray-900">{modal.session.name}</span>
        " session doesn't exist.
      </p>
      <div className="flex mt-4">
        <button className='primary-button mr-4' onClick={() => dispatch(closeModal(modalTypes.UNLOCK_SESSION))}>
          Ok
        </button>
      </div>
    </>
  );

  return (
    <Modal show={isOpen} onClose={() => dispatch(closeModal(modalTypes.UNLOCK_SESSION))} size="md">
      <Modal.Header></Modal.Header>
      <Modal.Body className='flex flex-col items-center'>
        {!modal.error && modalContent}
        {modal.error && modal.errorType === sessionErrors.UNLOCK_SESSION.NOT_EXISTS && notExistModalError}
        {modal.error && modal.errorType === sessionErrors.UNLOCK_SESSION.NOT_OWNER && notOwnerModalError}
      </Modal.Body>
    </Modal>
  );
};

export default UnlockSessionModal;
